import React from 'react';
import { getWhitelabelSeoClientConfig } from '../../../../utils/whitelabelSeoConfigs';

const BBBodyEnd = ({ clientKey = 'bybest' }) => {
    const clientConfig = getWhitelabelSeoClientConfig(clientKey);

    return (
        <>
            {clientConfig.bodyScripts.end.zendesk && (
                <script src={`https://static.zdassets.com/ekr/snippet.js?key=${clientConfig.bodyScripts.end.zendesk}`} id="ze-snippet" />
            )}

            {clientConfig.bodyScripts.end.hotjar && (
                <script dangerouslySetInnerHTML={{__html: `
                    /* Hotjar Tracking Code */
                    (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:${clientConfig.bodyScripts.end.hotjar.hjid},hjsv:${clientConfig.bodyScripts.end.hotjar.hjsv}};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}} />
            )}

            {clientConfig.bodyScripts.end.googleAnalytics && (
                <>
                    <script dangerouslySetInnerHTML={{__html: `
                        /* Global site tag (gtag.js) - Google Analytics */
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${clientConfig.bodyScripts.end.googleAnalytics}');
                    `}} />
                    <script async src={`https://www.googletagmanager.com/gtag/js?id=${clientConfig.bodyScripts.end.googleAnalytics}`} />
                </>
            )}
        </>
    );
};

export default BBBodyEnd;