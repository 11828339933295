import GoogleMapContainer from "../../../components/GoogleMapContainer";

const AccommodationLocation = ({ accId, location, address }) => {
  return (
    <div className="flex flex-col col-span-2 w-full mx-auto">
      <h2 className="text-[#2D3748] text-24 md:text-[30px] font-bold leading-[120%] mb-2">
        Where's located?
      </h2>
      <span className="text-s text-black1 mb-4">
        {accId !== "OAS9218APPU" && (
          <span>
            This is the location of the property. You can also take a virtual
            tour of the property by clicking the button below. This really is
            the best neighborhood in Tirana. "Sami Frasheri" street is one of
            the most popular streets in Tirana, which leads directly to the main
            avenues and Lana river, only river that crosses the city in between.
          </span>
        )}

        {accId === "OAS9218APPU" && (
          <span>
            This is the location of the property. You can also take a virtual
            tour of the property by clicking the button below. It boasts a prime
            location, just a stone's throw away from the bustling main avenues
            and the serene Lana River, making it an ideal setting for both urban
            exploration and tranquil retreats. With its tree-lined streets and a
            diverse array of shops, restaurants, and cultural attractions
            nearby, West Blaine Street offers the perfect blend of convenience
            and leisure.{" "}
          </span>
        )}
      </span>
      <div className="">
        <GoogleMapContainer
          accId={accId}
          location={location}
          address={address}
          showSearch={false}
        />
      </div>
      <div className="mt-[37px]">
        <p className="text-[#240b3b] text-18 md:text-20 font-medium leading-7 mb-4">
          {accId !== "OAS9218APPU" && (

            <span>Rruga Sami Frasheri Metropol,
              Tirane, AL 1001.</span>
          )}
          {accId === "OAS9218APPU" && (
            <span>West Blaine Street, Riverside CA 93950, US</span>
          )}
        </p>
      </div>
    </div>
  );
};

export default AccommodationLocation;
