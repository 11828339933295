import React from "react";

import img1 from "../../../assets/images/new-home-potential-1.webp";
import img2 from "../../../assets/images/new-home-potential-2.webp";
import img3 from "../../../assets/images/new-home-potential-3.webp";
import img4 from "../../../assets/images/new-home-potential-4.webp";
import {useNavigate} from "react-router-dom";
import BusinessServicesCarousel from "../../../components/BusinessServicesCarousel";

const potentialList = [
  {
    img: img1,
    title: "Entertainment Venues",
    type: "entertainment_venues",
    url: "/who-we-serve/entertainment-venues",
    style: {background: "#F4D35E"},
  },
  {
    img: img2,
    title: "Accommodation",
    type: "accommodation",
    url: "/who-we-serve/accommodation",
    style: {background: "#6DDFE2"},
  },
  {
    img: img3,
    title: "Retail Management",
    type: "retail",
    url: "/who-we-serve/retail-management",
    style: {background: "#E14848"},
  },
  {
    img: img4,
    title: "Food & Beverage",
    type: "food",
    url: "/who-we-serve/food-and-beverage",
    style: {background: "#17CE8C"},
  },
];

const Potential = () => {
  const navigate = useNavigate();
  return (
    <div className={"align-col-middle view-terms mt-0"}>
      <div className='w-[100vw] relative bg-white'>
        <div className='mx-auto max-w-screen-main px-7 sm:px-6 md:px-8 lg:px-16'>
          <div className='flex flex-col items-center py-8 md:py-20 gap-2'>
            <h1 className='text-primary1 text-28 md:text-44 font-black text-center md:text-left px-8'>
              One Platform, Endless Possibilities
            </h1>
            <span className='max-w-[850px] text-sm md:text-xl text-gray4 text-semibold text-left md:text-center'>
              Transform your venue with VenueBoost. Here, intuitive technology
              meets custom-fit solutions, giving you the tools to break barriers
              and unleash your business's true potential for growth and
              excellence.
            </span>
            <div className='grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-10 mt-12'>
              {potentialList.map((item, index) => (
                <div key={index} className='flex flex-col w-full'>
                  <div
                    onClick={() => {
                      const path = item.url;
                      if (path) {
                        navigate(path);
                      } else {
                      }
                    }}
                    className='w-full cursor-pointer  flex flex-col justify-center items-center  rounded-3xl bg-pink2'
                  >
                    <div className='h-[190px] w-[90%] flex flex-col relative justify-center items-center'>
                      <img
                        alt={item.title}
                        src={item.img}
                        className={` absolute top-0 left-0 h-[190px] mx-auto w-full object-contain`}
                      />
                      {/*<img*/}
                      {/*  src={playIcon}*/}
                      {/*  className=" z-10 cursor-pointer w-[60px] h-[60px] object-contain"*/}
                      {/*/>*/}
                    </div>
                    <div
                      className='flex justify-center items-center w-full rounded-b-3xl bg-primary1 py-2'
                      style={item.style}
                    >
                      <span className='text-primary1 text-lg font-bold text-center'>
                        {item.title}
                      </span>
                    </div>
                  </div>
                  <div className='w-full flex flex-col px-1'>
                    <BusinessServicesCarousel type={item.type} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Potential;
