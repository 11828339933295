import React from "react";
import { products } from "./data";
import { MdClose } from "react-icons/md";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestWishList = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] uppercase ">
            BYBEST {`>`} Lista e dëshirave
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="mt-5 w-full px-[15px] py-[10px] rounded-[5px] text-[14px] text-white bg-[#000]">
            PRODUKTE
          </div>
          <div className="mt-5 w-full">
            {products.slice(0, 3).map((p) => (
              <div className="relative w-full mt-3 pb-3 flex justify-center items-center border-b border-b-[#d2d2d2]">
                <div className=" p-4 h-[220px] w-[220px] flex justify-center items-center rounded-[5px] border border-[#d2d2d2]">
                  <img
                    src={p.img1}
                    className="w-full h-full object-contain"
                    alt=""
                  />
                </div>
                <div className="flex-1 h-[220px] ml-4 flex flex-col justify-start items-start">
                  <h2 className="text-[15px] text-[#000] font-bold">
                    {p.title}
                  </h2>
                  <p className="text-[13px] text-[#000] font-medium">
                    {p.category}
                  </p>
                  <div className="flex-1 w-full flex justify-between items-end">
                    <p className="text-[13px] text-[#000] font-medium">
                      CMIMI: <b>LEK {p.sale_price}</b>
                    </p>
                    <button className=" mt-2 rounded-[5px] bg-[#000]  h-[42px]  font-bold text-[14px] text-white uppercase">
                      SHTO NE SHORTE
                    </button>
                  </div>
                </div>
                <div className=" absolute top-3 right-3 w-[38px] h-[38px] rounded border border-[#222] flex justify-center items-center">
                  <MdClose color="#222" size={20} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestWishList;
