import React, {useEffect, useState} from "react";
import "./index.css";
import FormTextInput from "../../components/FormTextInput";
import FormSelect from "../../components/FormSelect";
import {getRegisterConfig} from "../../redux/actions/contactsales";
import {NotificationManager} from "react-notifications";
import {Seo} from "../../components/Seo";
import {t} from "i18next";
import {getStorageKey, KEYS} from "../../utils/storage";
import Dot1 from "../../assets/svgs/dot1-getstarted.svg";
import Dot2 from "../../assets/svgs/dot2-getstarted.svg";
import Dot3 from "../../assets/svgs/dot3-getstartd.svg";
import Dot4 from "../../assets/svgs/dot4.svg";
import {
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import {Checkbox} from "antd";
import InformationIcon from "../../assets/svgs/info-icon.svg";
import {useNavigate, useParams} from "react-router-dom";
import {
  recommendPlan,
  trackOnboarding,
  verifyOnboardingLink,
  createCheckoutSession,
  confirmOnboarding,
  confirmTrialSubscription,
} from "../../redux/actions/onboarding";
import confirmed_success from "../../assets/svgs/confirmed_success_brand.svg";
import {isMobile} from "react-device-detect";
import TBDIcon from "../../assets/svgs/TBD.svg";

const contactReason = [
  {value: "", title: "Please select"},
  {value: "Access Free Plan", title: "Access Free Plan"},
  {
    value: "Request Personalized Consultation",
    title: "Request Personalized Consultation",
  },
  {value: "Subscribe to Updates", title: "Subscribe to Updates"},
];

const howDidYouHearOptions = [
  {value: "", title: "Please select"},
  {value: "Email", title: "Email"},
  {value: "Social media", title: "Social media"},
  {value: "Search engines", title: "Search engines"},
  {value: "Ads", title: "Ads"},
  {value: "Referral", title: "Referral"},
  {value: "From a friend", title: "From a friend"},
  {value: "Other", title: "Other"},
];

const businessChallenges = [
  {value: "", title: "Please select"},
  {value: "Staffing Issues", title: "Staffing Issues"},
  {value: "Sales and Marketing", title: "Sales and Marketing"},
  {value: "Inventory Management", title: "Inventory Management"},
  {value: "Operational Efficiency", title: "Operational Efficiency"},
  {
    value: "Brand Development and Positioning",
    title: "Brand Development and Positioning",
  },
  {
    value: "Customer Engagement and Retention",
    title: "Customer Engagement and Retention",
  },
  {value: "Other", title: "Other"},
];

const socialMediaOptions = [
  {label: "Facebook", value: "facebook"},
  {label: "Twitter", value: "twitter"},
  {label: "Instagram", value: "instagram"},
  {label: "TikTok", value: "tiktok"},
];

const featuresByIndustryAndPlan = {
  food: {
    Launch: [
      "Includes all from Discover plus:",
      "Unlimited Streamlined Reservations",
      "Full Inventory Management",
      "Staff Management",
      "Enhanced Marketing Strategy tools",
      "Loyalty and Retention Program",
      "Payment Links",
      "Basic Guest Surveys and Ratings",
    ],
    Elevate: [
      "Includes all from Launch plus:",
      "Marketing Automation",
      "Affiliate Partnerships",
      "Advanced Analytics & Reporting",
      "Delivery Orders management",
      "Advanced Guest Behavior Analytics",
    ],
    Optimize: [
      "Includes all from Elevate plus:",
      "Premium Tables with Pricing / Bidding and Floorplan Visibility",
      "Dining Guest Loyalty Program",
      "Customizable Brand Profile",
      "In-Person Payments",
    ],
  },
  accommodation: {
    Launch: [
      "Includes all from Discover plus:",
      "Unlimited Bookings Management",
      "Unlimited Units/Rooms Management",
      "Full Inventory Management",
      "Staff Management",
      "Enhanced Marketing Tools",
      "Basic Loyalty and Retention Program",
      "Payment Links",
      "Basic Guest Surveys and Ratings",
    ],
    Elevate: [
      "Includes all from Launch plus:",
      "Marketing Automation including Guest Journey support",
      "Advanced Analytics & Reporting",
      "iCal Integration",
      "Basic Affiliate Partnerships",
      "Advanced Guest Behavior Analytics",
    ],
    Optimize: [
      "All Elevate Plan features with higher limits",
      "Accommodation Guest Loyalty Program",
      "Housekeeping Management with Real-Time Updates\n" + "\n",
      "Advanced Affiliate Partnerships",
      "Customizable Brand Profile",
      "In-Person Payments",
    ],
  },

  sport_entertainment: {
    Launch: [
      "Includes all from Discover plus:",
      "Unlimited Bookings Management",
      "Full Inventory Management",
      "Staff Management",
      "Enhanced Marketing Tools",
      "Basic Loyalty and Retention Program",
      "Basic Payment Links",
    ],
    Elevate: [
      "Includes all from Launch plus:",
      "Marketing Automation",
      "Entertainment Membership Program",
      "Advanced Analytics & Reporting",
      "Customer Surveys and Ratings",
      "Basic Affiliate Partnerships",
    ],
    Optimize: [
      "Includes all from Elevate plus:",
      "Advanced Customer Behavior Analytics",
      "Advanced Marketing Tools",
      "Advanced Affiliate Partnerships",
      "Customizable Brand Profile",
      "In-Person Payments",
    ],
  },

  retail_management: {
    Launch: [
      "Includes all from Discover plus",
      "Unlimited Order Management",
      "Full Inventory Management",
      "Staff Management",
      "Enhanced Marketing Tools",
      "Basic Loyalty and Retention Program",
    ],
    Elevate: [
      "Includes all from Launch plus:",
      "Marketing Automation",
      "Retail Customer Loyalty Program",
      "Advanced Dashboard & Revenue Analytics",
      "Consistent Inventory",
      "Basic Affiliate Partnerships",
      "Customer Surveys and Ratings",
    ],
    Optimize: [
      "Includes all from Elevate plus:",
      "Advanced Order Management",
      "Advanced Customer Behavior Analytics",
      "Centralized Analytics for Multi-Brand Retailers",
      "Advanced Store Management",
      "Customizable Brand Profile",
      "In-Person Payments",
    ],
  },
};

const countryList = [
  {value: "United States", title: "United States"},
  // { value: "Italy", title: "Italy" },
  // { value: "Montenegro", title: "Montenegro" },
];

const industryList = [
  {value: "", title: "Please select"},
  {value: "Food", title: "Food & Beverage"},
  {value: "Sport & Entertainment", title: "Entertainment Venues"},
  {value: "Accommodation", title: "Accommodation"},
  {value: "Retail", title: "Retail Management"},
  // { value: "Healthcare", title: "Healthcare" },
];

const categoryList = {
  Food: [
    {value: "", title: "Please select"},
    {value: "restaurant", title: "Restaurant"},
    {value: "bistro", title: "Bistro"},
    {value: "cafe", title: "Cafe"},
    {value: "bar", title: "Bar"},
    {value: "pub_club", title: "Pub & Club"},
    {value: "food_truck", title: "Food Truck"},
    {value: "fine_dining", title: "Fine Dining"},
    {value: "bakery", title: "Bakery"},
    {value: "catering_service", title: "Catering Service"},
    {value: "fast_food", title: "Fast Food"},
    {value: "ice_cream_parlor", title: "Ice Cream Parlor"},
    {value: "food_stall", title: "Food Stall"},
    {value: "buffet", title: "Buffet"},
    {value: "pop_up_dining", title: "Pop-Up Dining"},
    {value: "juice_bar", title: "Juice Bar"},
    {value: "food_hall", title: "Food Hall"},
    {value: "supper_club", title: "Supper Club"},
    {value: "pizzeria", title: "Pizzeria"},
    {value: "coffee_shop", title: "Coffee Shop"},
    {value: "delis", title: "Delis"},
    {value: "food_court", title: "Food Court"},
  ],
  "Sport & Entertainment": [
    {value: "", title: "Please select"},
    {value: "golf_venue", title: "Golf Course"},
    {value: "bowling", title: " Bowling Alley"},
    {value: "gym", title: "Gym & Fitness Center"},
    {value: "arcade_and_game_center", title: "Arcade & Game Center"},
    {value: "sports_arena", title: "Sports Arena"},
    {value: "concert_hall_and_theater", title: "Concert Hall & Theater"},
    {value: "amusement_and_theme_park", title: "Amusement & Theme Park"},
    {value: "ski_resort", title: "Ski Resort"},
    {value: "museum", title: "Museum"},
    {value: "cinema", title: "Cinema"},
  ],
  Accommodation: [
    {value: "", title: "Please select"},
    {value: "hotel", title: "Hotel"},
    {value: "cozy_retreat", title: "Cozy Retreat"},
    {value: "hostel", title: "Hostel"},
    {value: "hotel_chain", title: "Hotel Chain"},
    {value: "vacation_rental", title: "Vacation Rental"},
    {value: "luxury_resort", title: " Luxury Resort"},
    {value: "family_resort", title: "Family Resorts"},
    {value: "service_apartment", title: "Service Apartment"},
    {value: "bed_and_breakfast", title: "Bed and Breakfast"},
    {value: "motel", title: "Motel"},
    {value: "capsule_hotel", title: "Capsule Hotel"},
    {value: "youth_hostel", title: "Youth Hostel"},
    {value: "campground", title: "Campground"},
    {value: "rv_park", title: "RV Park"},
  ],
  Retail: [
    {value: "", title: "Please select"},
    {value: "sport_essentials", title: "Sporting Goods Store"},
    {value: "home_decor", title: "Home Décor Store"},
    {value: "fashion_threads", title: "Fashion Boutique"},
    {value: "retail_chain", title: "Retail Chain"},
    {value: "specialty_store", title: "Specialty Store"},
    {value: "online_shop", title: "Online Shop"},
    {value: "grocery_store", title: "Grocery Store"},
    {value: "electronics_store", title: "Electronics Store"},
    {value: "pharmacy", title: "Pharmacy"},
    {value: "auto_motorcycle_parts", title: "Auto Parts Store"},
    {value: "liquor_store", title: "Liquor Store"},
    {value: "pet_store", title: "Pet Store"},
  ],
};

const pricingData = {
  food: {
    monthly: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 49,
      launchSubtitle:
        "Ideal for small businesses, this plan offers expanded functionality to enhance your operational efficiency.",
      elevate: 129,
      elevateSubtitle:
        "Scale your business seamlessly with advanced tools and analytics tailored for expanding businesses.",
      optimize: 249,
      optimizeSubtitle:
        "Unlock the full potential of VenueBoost with sophisticated features.",
    },
    annual: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 490,
      launchSubtitle:
        "Ideal for small businesses, this plan offers expanded functionality to enhance your operational efficiency.",
      elevate: 1290,
      elevateSubtitle:
        "Scale your business seamlessly with advanced tools and analytics tailored for expanding businesses.",
      optimize: 2490,
      optimizeSubtitle:
        "Unlock the full potential of VenueBoost with sophisticated features.",
    },
  },
  sport_entertainment: {
    monthly: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 79,
      launchSubtitle:
        "Get started with essential tools to manage bookings and engage your audience.",
      elevate: 169,
      elevateSubtitle:
        "Scale your business seamlessly with advanced tools and analytics tailored for expanding businesses.",
      optimize: 329,
      optimizeSubtitle:
        "Unlock the full potential of VenueBoost with sophisticated features.",
    },
    annual: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 790,
      launchSubtitle:
        "Get started with essential tools to manage bookings and engage your audience.",
      elevate: 1690,
      elevateSubtitle:
        "Scale your business seamlessly with advanced tools and analytics tailored for expanding businesses.",
      optimize: 3290,
      optimizeSubtitle:
        "Unlock the full potential of VenueBoost with sophisticated features.",
    },
  },
  accommodation: {
    monthly: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 59,
      launchSubtitle:
        "Ideal for small businesses, this plan offers expanded functionality to enhance your operational efficiency.",
      elevate: 149,
      elevateSubtitle:
        "Unlock a comprehensive set of features to enhance guest experiences and streamline operations.",
      optimize: 299,
      optimizeSubtitle:
        "Access advanced capabilities to optimize guest services and boost revenue",
    },
    annual: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 590,
      launchSubtitle:
        "Ideal for small businesses, this plan offers expanded functionality to enhance your operational efficiency.",
      elevate: 1490,
      elevateSubtitle:
        "Unlock a comprehensive set of features to enhance guest experiences and streamline operations.",
      optimize: 2990,
      optimizeSubtitle:
        "Access advanced capabilities to optimize guest services and boost revenue",
    },
  },
  retail: {
    monthly: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 69,
      launchSubtitle:
        "Begin your retail journey with essential tools for inventory management and customer engagement.",
      elevate: 159,
      elevateSubtitle:
        "Gain access to a comprehensive suite of features to streamline inventory and sales.",
      optimize: 319,
      optimizeSubtitle:
        "Access advanced capabilities to optimize guest services and boost revenue",
    },
    annual: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 690,
      launchSubtitle:
        "Begin your retail journey with essential tools for inventory management and customer engagement.",
      elevate: 1590,
      elevateSubtitle:
        "Gain access to a comprehensive suite of features to streamline inventory and sales.",
      optimize: 3190,
      optimizeSubtitle:
        "Unlock advanced capabilities to manage retail operations and boost profitability.",
    },
  },
};

const Onboarding = () => {
  const navigate = useNavigate();
  const {token} = useParams();

  const [billingMethod, setBillingMethod] = useState("monthly");
  const [data, setData] = useState({});

  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [countries, setCountries] = useState([
    {value: "", title: "Please select"},
  ]);
  const [states, setStates] = useState([{value: "", title: "Please select"}]);
  const [cities, setCities] = useState([{value: "", title: "Please select"}]);

  const navigateToUrl = url => {
    navigate(url);
  };

  const [onboardingEmail, setOnboardingEmail] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [whichSelected, setWhichSelected] = useState("Discover");
  const [loadingFirstStep, setLoadingFirstStep] = useState(false);
  const [loadingSecondStep, setLoadingSecondStep] = useState(false);
  const [loadingThirdStep, setLoadingThirdStep] = useState(false);
  const [startingTrial1, setStartingTrial1] = useState(false);
  const [startingTrial2, setStartingTrial2] = useState(false);
  const [startingTrial3, setStartingTrial3] = useState(false);

  const [step, setStep] = useState(1);
  const [venueType, setVenueType] = useState("food");

  const [suggestedPlan, setSuggestedPlan] = useState("");
  const [onboardingPricingPlans, setOnboardingPricingPlans] = useState([]);
  const [suggestedPlanFeatures, setSuggestedPlanFeatures] = useState([]);

  const currentPricing = pricingData[venueType][billingMethod];

  // For the free plan
  const freePrice = currentPricing ? currentPricing.free : 0;
  const freeSubtitle = currentPricing ? currentPricing.freeSubtitle : 0;

  // For the launch plan
  const launchPrice = currentPricing ? currentPricing.launch : 0;
  const launchSubtitle = currentPricing ? currentPricing.launchSubtitle : "";

  // For the elevate plan
  const elevatePrice = currentPricing ? currentPricing.elevate : 0;
  const elevateSubtitle = currentPricing ? currentPricing.elevateSubtitle : "";

  // For the optimize plan
  const optimizePrice = currentPricing ? currentPricing.optimize : 0;
  const optimizeSubtitle = currentPricing
    ? currentPricing.optimizeSubtitle
    : "";

  useEffect(() => {
    checkOnboardingLink();
    loadRegisterConfig();

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      confirmSubscription(query.get("session_id"));
    } else if (query.get("canceled")) {
      NotificationManager.error(
        "Subscription to trial not continued",
        "Error",
        3000
      );
    }
  }, []);

  const loadRegisterConfig = () => {
    getRegisterConfig()
      .then(data => {
        setAllCountries(data?.countries || []);
        // setCountries(data?.countries.map(country => ({
        //   value: country.name,
        //   title: country.name
        // })) || []);
        setCountries([
          {value: "", title: "Please select"},
          {
            value: "USA",
            title: "USA",
          },
        ]);
        setAllStates(data?.states || []);
        setAllCities(data?.cities || []);
      })
      .catch(error => {});
  };

  const getPricingPlanId = (planName, billingMethod) => {
    const plan = onboardingPricingPlans.find(p => p.name === planName);
    if (!plan) return null;

    const priceInfo = plan.prices.find(price =>
      billingMethod === "monthly"
        ? price.recurring.interval === "month"
        : price.recurring.interval === "year"
    );

    return priceInfo ? priceInfo.stripe_id : null;
  };

  // When the billing method is changed
  const onBillingMethodChange = method => {
    setBillingMethod(method);
  };

  const confirmSubscription = session_id => {
    confirmTrialSubscription({
      session_id: session_id,
    })
      .then(data => {
        setStep(4);
        NotificationManager.success(
          "Your trial period has been activated successfully!",
          "Success",
          3000
        );
        const finalData = {
          email: data.email,
          freemium: false,
          session_id: session_id,
        };
        confirmOnboarding(finalData)
          .then(data => {
            // do nothing
          })
          .catch(err => {
            // do nothing
          });
      })
      .catch(error => {
        const message = error.message || "Something went wrong!";
        NotificationManager.error(message, "Error", 3000);
      });
  };

  const checkOnboardingLink = () => {
    verifyOnboardingLink({token})
      .then(data => {
        setOnboardingEmail(data?.email);
        if (data?.current_onboarding_step) {
          const currentOnboardingStep = data?.current_onboarding_step;
          if (currentOnboardingStep === "email_verified") {
            setStep(1);
          } else if (currentOnboardingStep === "business_details") {
            setStep(2);
          } else if (currentOnboardingStep === "interest_engagement") {
            const industryFeatures =
              featuresByIndustryAndPlan[data?.industry] || {};
            const planFeatures =
              industryFeatures[data?.recommended_pricing_plan] || [];
            setSuggestedPlan(data?.recommended_pricing_plan);
            setOnboardingPricingPlans(data?.onboarding_pricing_plans);
            setVenueType(data?.industry);
            setSuggestedPlanFeatures(planFeatures);
            setStep(3);
          } else if (currentOnboardingStep === "subscription_plan_selection") {
            setStep(4);
          }
        }
      })
      .catch(error => {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      });
  };

  const radioStyle = {
    marginLeft: "20px", // Adjust the margin between radio buttons
  };

  const customRadioStyle = {
    width: "20px", // Adjust the width of the radio button
    height: "20px", // Adjust the height of the radio button
    cursor: "pointer",
  };

  const customCheckedRadioStyle = {};

  const handleThirdStepSelect = () => {
    const finalData = {
      email: onboardingEmail,
      freemium: true,
      requested_custom: whichSelected === "Enterprise",
      mode: billingMethod,
    };
    setLoadingThirdStep(true);
    confirmOnboarding(finalData)
      .then(data => {
        // do nothing
        setLoadingThirdStep(false);
        setStep(4);
      })
      .catch(err => {
        // do nothing
        setLoadingThirdStep(false);
        setStep(4);
      });
  };

  const [showAffiliateInput, setShowAffiliateInput] = useState(false);

  const handleAffiliateClick = () => {
    setShowAffiliateInput(true);
  };

  const referral_code = getStorageKey(KEYS.REFERRAL_CODE);
  const affiliate_code = getStorageKey(KEYS.AFFILIATE_CODE);

  const handleChange = e => {
    const {name, value} = e.target;

    // Update the data state as usual
    setData(data => ({
      ...data,
      [name]: value,
    }));

    if (name === "business_challenge" && !isMobile) {
      if (value.includes("") && value.length > 1) {
        // Remove the empty string value if other options are selected
        const filteredValues = value.filter(val => val !== "");
        setData(data => ({
          ...data,
          business_challenge: filteredValues,
        }));
      }
    }

    if (name === "restaurant_country") {
      // Get states for the selected country
      const selectedCountry = allCountries.find(
        country => country.name === value
      );
      setCountry(selectedCountry?.id);
      const statesForSelectedCountry =
        selectedCountry && selectedCountry.id
          ? [
              {value: "", title: "Please select"},
              ...selectedCountry.states?.map(state => ({
                value: state.name,
                title: state.name,
              })),
            ]
          : [{value: "", title: "Please select"}];

      setStates(statesForSelectedCountry);

      // Reset state and city selections in form data
      setData(data => ({
        ...data,
        restaurant_state: "",
        restaurant_city: "",
      }));
    } else if (name === "restaurant_state") {
      // Get cities for the selected state
      const selectedState = allStates.find(state => state.name === value);
      setState(selectedState?.id);
      const citiesForSelectedState =
        selectedState && selectedState.id
          ? [
              {value: "", title: "Please select"},
              ...selectedState.cities?.map(city => ({
                value: city.name,
                title: city.name,
              })),
            ]
          : [{value: "", title: "Please select"}];

      setCities(citiesForSelectedState);
      setCities(citiesForSelectedState);

      // Reset city selection in form data
      setData(data => ({
        ...data,
        restaurant_city: "",
      }));
    } else if (name === "restaurant_city") {
      const selectedCity = allCities.find(city => city.name === value);
      setCity(selectedCity?.id);
    }
  };

  const handleChangeCountry = e => {
    setData(info => ({...info, restaurant_country: "US"}));
  };

  const handleCheckboxChange = (e, value) => {
    const isChecked = e.target.checked;
    setData(data => {
      // Get the current array of selected social media, or initialize it as an empty array
      const selectedSocialMedia = data.social_media || [];

      if (isChecked) {
        // Add the value to the array if it's checked and not already present
        return {
          ...data,
          social_media: [...selectedSocialMedia, value],
        };
      } else {
        // Remove the value from the array if it's unchecked
        return {
          ...data,
          social_media: selectedSocialMedia.filter(item => item !== value),
        };
      }
    });
  };

  const onSubmitBusinessStep = () => {
    if (!data["restaurant_name"]) {
      NotificationManager.warning(
        `Business name field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["restaurant_country"]) {
      NotificationManager.warning(
        `Business country field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["restaurant_city"]) {
      NotificationManager.warning(
        `Business city field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["restaurant_state"]) {
      NotificationManager.warning(
        `Business state field is required`,
        "Warning",
        3000
      );
      return;
    }
    if (!data["restaurant_zipcode"]) {
      NotificationManager.warning(
        `Business postal code field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["venue_industry"]) {
      NotificationManager.warning(
        `Business industry is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["venue_type"]) {
      NotificationManager.warning(`Business type is required`, "Warning", 3000);
      return;
    }

    if (!data["years_in_business"]) {
      NotificationManager.warning(
        `Years in business field is required`,
        "Warning",
        3000
      );
      return;
    }

    data["email"] = onboardingEmail;
    data["step"] = "business_details";
    data["address_line1"] = "-";
    data["country"] = country;
    data["state"] = state;
    data["city"] = city;

    setLoadingFirstStep(true);
    trackOnboarding(data)
      .then(data => {
        setStep(2);
        window.scrollTo(0, 0);
        setLoadingFirstStep(false);
      })
      .catch(err => {
        setLoadingFirstStep(false);
        // Check if err.error is a string
        if (typeof err.error === "string") {
          NotificationManager.error(err.error);
        } else {
          // Handle case where err.error is an object
          const errorKeys = Object.keys(err.error || {});
          if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
            const firstErrorMessage = err.error[firstErrorKey][0];
            NotificationManager.error(
              firstErrorMessage || "Something went wrong!"
            );
          } else {
            NotificationManager.error(
              "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io."
            );
          }
        }
        throw new Error("An error occurred while submitting the data");
      });
  };

  const onStartTrial = product => {
    // Assuming 'billingMethod' is either "monthly" or "annual"
    const priceId = getPricingPlanId(product, billingMethod);

    const stripeData = {
      email: onboardingEmail,
      price_id: priceId,
    };

    if (product === "Launch") {
      setStartingTrial1(true);
    }

    if (product === "Elevate") {
      setStartingTrial2(true);
    }

    if (product === "Optimize") {
      setStartingTrial3(true);
    }

    createCheckoutSession(stripeData)
      .then(data => {
        document.location.href = data?.url;

        if (product === "Launch") {
          setStartingTrial1(false);
        }

        if (product === "Elevate") {
          setStartingTrial2(false);
        }

        if (product === "Optimize") {
          setStartingTrial3(false);
        }
      })
      .catch(err => {
        if (product === "Launch") {
          setStartingTrial1(false);
        }

        if (product === "Elevate") {
          setStartingTrial2(false);
        }

        if (product === "Optimize") {
          setStartingTrial3(false);
        }

        if (typeof err?.error === "string") {
          NotificationManager.error(err?.error);
        } else {
          const errorKeys = Object.keys(err?.error || {});
          if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
            const firstErrorMessage = err?.error[firstErrorKey][0];
            NotificationManager.error(
              firstErrorMessage || "Something went wrong!"
            );
          } else {
            NotificationManager.error("Something went wrong!");
          }
        }

        throw new Error("An error occurred while subscribing with trial");
      });
  };

  const onSubmitInterestStep = () => {
    // Business Challenge validation
    if (
      (data?.business_challenge === "Other" ||
        (Array.isArray(data?.business_challenge) &&
          data?.business_challenge.includes("Other"))) &&
      !data?.other_business_challenge
    ) {
      NotificationManager.warning(
        `Please specify your challenge`,
        "Warning",
        3000
      );
      return false;
    }

    if (
      data?.how_did_you_hear_about_us === "Other" &&
      !data?.how_did_you_hear_about_us_other
    ) {
      NotificationManager.warning(
        `Please specify Other reason`,
        "Warning",
        3000
      );
      return false;
    }

    if (!data["nr_of_employees"]) {
      NotificationManager.warning(
        `Nr of employees field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["business_challenge"]) {
      NotificationManager.warning(
        `Business challenge field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["annual_revenue"]) {
      NotificationManager.warning(
        `Annual Revenue field is required`,
        "Warning",
        3000
      );
      return;
    }
    if (!data["social_media"]) {
      NotificationManager.warning(
        `Active Social Media Presence field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["contact_reason"]) {
      NotificationManager.warning(
        `Interest reason field is required`,
        "Warning",
        3000
      );
      return;
    }

    data["email"] = onboardingEmail;
    data["step"] = "interest_engagement";

    // // Check if it's mobile
    // if (!isMobile) {
    //   // Send as a single string
    //   data['business_challenge'] = data['business_challenge'].join(', ');
    // }

    // Create a copy of data for submission
    let submissionData = {
      ...data,
    };

    // Check if it's mobile
    if (!isMobile) {
      submissionData = {
        ...data,
        business_challenge: data.business_challenge.join(", "),
      };
    }

    setLoadingSecondStep(true);
    trackOnboarding(submissionData)
      .then(data => {
        const newData = {
          email: data?.potentialVenueLead?.email,
        };
        recommendPlan(newData)
          .then(data => {
            const industryFeatures =
              featuresByIndustryAndPlan[data?.industry] || {};
            const planFeatures =
              industryFeatures[data?.recommended_pricing_plan] || [];
            setSuggestedPlan(data?.recommended_pricing_plan);
            setOnboardingPricingPlans(data?.onboarding_pricing_plans);
            setVenueType(data?.industry);
            setSuggestedPlanFeatures(planFeatures);
            setStep(3);
            window.scrollTo(0, 0);
            setLoadingSecondStep(false);
          })
          .catch(err => {
            setLoadingSecondStep(false);
            // Check if err.error is a string
            if (typeof err.error === "string") {
              NotificationManager.error(err.error);
            } else {
              // Handle case where err.error is an object
              const errorKeys = Object.keys(err.error || {});
              if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                const firstErrorMessage = err.error[firstErrorKey][0];
                NotificationManager.error(
                  firstErrorMessage || "Something went wrong!"
                );
              } else {
                NotificationManager.error(
                  "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io."
                );
              }
            }
            throw new Error("An error occurred while submitting the data");
          });
      })
      .catch(err => {
        setLoadingSecondStep(false);
        // Check if err.error is a string
        if (typeof err.error === "string") {
          NotificationManager.error(err.error);
        } else {
          // Handle case where err.error is an object
          const errorKeys = Object.keys(err.error || {});
          if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
            const firstErrorMessage = err.error[firstErrorKey][0];
            NotificationManager.error(
              firstErrorMessage || "Something went wrong!"
            );
          } else {
            NotificationManager.error(
              "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io."
            );
          }
        }
        throw new Error("An error occurred while submitting the data");
      });
  };

  return (
    <div className={"align-col-middle "}>
      <Seo title={"Get Started | VenueBoost"} />
      <div className=' w-[100vw] getstarted-bg'>
        <div className='mx-auto max-w-screen-main px-4 sm:px-6 md:px-0 lg:px-0 '>
          <div className='flex md:flex-row justify-center flex-col pt-4 pb-11 h-full'>
            <div className=' w-full md:w-[100%] bg-[#F9F7F7] border-[1px] border-[#E0E0E0] rounded-[13px] p-4 md:p-4 py-6 gap-y-4 gap-x-8'>
              <div>
                {step === 1 && (
                  <div
                    className='  bg-white rounded-[31px] p-[24px] md:p-[33px] relative'
                    style={{height: isMobile ? "851px" : "721px"}}
                  >
                    <div className='flex justify-start  items-center'>
                      <span className='text-[32px] md:text-32 font-extrabold leading-[50px] my-6]'>
                        Business Details
                      </span>
                    </div>
                    <p className='text-18 font-semibold text-[#240b3b] my-4'>
                      Welcome to VenueBoost! To ensure we tailor our services to
                      your unique business needs, please provide a few details
                      about your establishment. This information will help us
                      create a more personalized experience for you.
                    </p>

                    <div className=''>
                      <div className='grid grid-cols-2 gap-[14px]'>
                        <div>
                          <FormTextInput
                            name='restaurant_name'
                            placeholder='Business Name'
                            value={data?.restaurant_name || ""}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                        <div>
                          <FormSelect
                            name={"venue_industry"}
                            placeholder='Industry'
                            value={
                              data?.venue_industry || industryList[0].value
                            }
                            dataList={industryList}
                            onChanage={handleChange}
                            required={true}
                          />
                        </div>
                        <div className={"mt-5"}>
                          <FormSelect
                            name={"venue_type"}
                            placeholder='Business Type'
                            value={data?.venue_type || ""}
                            dataList={
                              data?.venue_industry
                                ? categoryList[data?.venue_industry]
                                : [{value: "", title: "Please select"}]
                            }
                            onChanage={handleChange}
                            required={true}
                          />
                        </div>
                        <div className={"mt-5"}>
                          <FormTextInput
                            name='years_in_business'
                            placeholder='Years in business'
                            value={data?.years_in_business || ""}
                            onChange={handleChange}
                            required={true}
                            tooltipText={
                              "Help us understand your business's journey and experience."
                            }
                          />
                        </div>
                        <div className={"mt-5"}>
                          <FormSelect
                            name={"restaurant_country"}
                            placeholder='Country'
                            value={data?.restaurant_country}
                            dataList={countries}
                            onChanage={handleChange}
                            required={true}
                          />
                        </div>
                        <div className={"mt-5"}>
                          <FormSelect
                            name={"restaurant_state"}
                            placeholder='State'
                            value={data?.restaurant_state}
                            dataList={states}
                            onChanage={handleChange}
                            required={true}
                          />
                        </div>
                        <div className={"mt-5"}>
                          <FormSelect
                            name={"restaurant_city"}
                            placeholder='City'
                            value={data?.restaurant_city}
                            dataList={cities}
                            onChanage={handleChange}
                            required={true}
                          />
                        </div>
                        <div className={"mt-5"}>
                          <FormTextInput
                            name='restaurant_zipcode'
                            placeholder='Zip/Postal Code'
                            value={data?.restaurant_zipcode || ""}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='w-full px-8 justify-between flex absolute bottom-[58px] left-0'>
                      <button
                        // onClick={() => setStep(2)}
                        className='text-[#707070] text-16 px-0 mt-5 leading-[16px]  h-[36px] font-semibold'
                      ></button>
                      <button
                        onClick={onSubmitBusinessStep}
                        className='bg-[#240b3b]  rounded-[63px] px-[26px] h-[36px] text-white text-16 mt-5 self-end leading-[16px] font-semibold'
                      >
                        {loadingFirstStep ? (
                          <CircularProgress size={16} />
                        ) : (
                          t("who_we_serve.accommodation_industry.next")
                        )}
                      </button>
                    </div>
                    <div className='w-full align-middle absolute bottom-[30px] left-0'>
                      <img src={Dot1} />
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div
                    className='  bg-white rounded-[31px] p-[24px] md:p-[33px] relative'
                    style={{
                      height: isMobile
                        ? data?.business_challenge === "Other" &&
                          data?.how_did_you_hear_about_us === "Other"
                          ? "1415px"
                          : data?.business_challenge === "Other" ||
                            data?.how_did_you_hear_about_us === "Other"
                          ? "1315px"
                          : "1215px"
                        : (data?.business_challenge === "Other" ||
                            (Array.isArray(data?.business_challenge) &&
                              data?.business_challenge.includes("Other"))) &&
                          data?.how_did_you_hear_about_us === "Other"
                        ? "950px"
                        : data?.business_challenge === "Other" ||
                          (Array.isArray(data?.business_challenge) &&
                            data?.business_challenge.includes("Other")) ||
                          data?.how_did_you_hear_about_us === "Other"
                        ? "860px"
                        : "760px",
                    }}
                  >
                    <div className='flex justify-start  items-center'>
                      <span className='text-[32px] md:text-32 font-extrabold leading-[50px] my-6]'>
                        Interest and Engagement
                      </span>
                    </div>
                    <p className='text-18 font-semibold text-[#240b3b] my-4'>
                      Let's dive a bit deeper to align our services with your
                      business priorities. Your insights here are crucial for us
                      to deliver solutions that truly make a difference.
                    </p>

                    <div className=''>
                      <div className='grid md:grid-cols-2 gap-[14px]'>
                        <div>
                          <FormTextInput
                            name='nr_of_employees'
                            placeholder='Number of employees'
                            value={data?.nr_of_employees || ""}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                        <div>
                          <FormTextInput
                            name='annual_revenue'
                            placeholder='Annual Revenue'
                            value={data?.annual_revenue || ""}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className='grid md:grid-cols-2 gap-[14px]'>
                        <div className={"mt-6"}>
                          <div className={"flex"}>
                            <p className='text-[#232323] text-14 md:text-16 font-medium mb-2'>
                              Active Social Media Presence?
                            </p>
                            <span style={{color: "#F44336", marginLeft: "2px"}}>
                              *
                            </span>
                          </div>
                          <div style={{marginLeft: "10px"}}>
                            {socialMediaOptions.map((option, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={data?.social_media?.includes(
                                      option.value
                                    )}
                                    onChange={e =>
                                      handleCheckboxChange(e, option.value)
                                    }
                                    name='social_media'
                                    style={{marginRight: "4px"}}
                                  />
                                }
                                label={option.label}
                                style={{marginRight: "20px"}}
                              />
                            ))}
                          </div>
                        </div>

                        <div className={"md:mt-6"}>
                          <label>Do You Have a Website?</label>
                          <div className={"flex"} style={{marginTop: "10px"}}>
                            <input
                              type='radio'
                              name='website'
                              value='yes'
                              checked={data?.website === "yes"}
                              onChange={handleChange}
                              style={{
                                ...customRadioStyle,
                                ...(data?.website === "no"
                                  ? customCheckedRadioStyle
                                  : {}),
                              }}
                            />
                            <span
                              style={{marginLeft: "5px", marginTop: "-2px"}}
                            >
                              Yes
                            </span>
                            <input
                              type='radio'
                              name='website'
                              value='no'
                              checked={data?.website === "no"}
                              onChange={handleChange}
                              style={{
                                ...radioStyle,
                                ...customRadioStyle,
                                ...(data?.website === "no"
                                  ? customCheckedRadioStyle
                                  : {}),
                              }}
                            />
                            <span
                              style={{marginLeft: "5px", marginTop: "-2px"}}
                            >
                              No
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='grid md:grid-cols-1 gap-[14px]'>
                        <div className={"mt-5"}>
                          {!isMobile && (
                            <div className={"flex flex-col"}>
                              <div className={"flex"}>
                                <p className='text-[#232323] text-14 md:text-16 font-medium mb-2'>
                                  Business Challenges
                                </p>
                                <span
                                  style={{color: "#F44336", marginLeft: "2px"}}
                                >
                                  *
                                </span>
                              </div>
                              <Select
                                name={"business_challenge"}
                                multiple
                                id='business-challenges-select'
                                value={data.business_challenge || [""]}
                                // error={!isEmpty(errors.business_challenge)}
                                onChange={e => {
                                  handleChange(e); // Call your form's handleChange function
                                }}
                                sx={{height: "50px"}}
                              >
                                {businessChallenges.map(option => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    disabled={option.value === ""}
                                  >
                                    {option.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          )}

                          {isMobile && (
                            <FormSelect
                              name={"business_challenge"}
                              placeholder='Business Challenges'
                              value={data?.business_challenge || ""}
                              dataList={businessChallenges}
                              onChanage={handleChange}
                              required={true}
                            />
                          )}
                          {/* Conditional rendering for 'Other' option */}
                          {(data?.business_challenge === "Other" ||
                            (Array.isArray(data?.business_challenge) &&
                              data?.business_challenge.includes("Other"))) && (
                            <FormTextInput
                              className={"mt-5"}
                              name='other_business_challenge'
                              placeholder='Please specify your challenge'
                              value={data?.other_business_challenge || ""}
                              onChange={handleChange}
                              required={true}
                            />
                          )}
                        </div>
                      </div>
                      <div className='grid md:grid-cols-2 gap-[14px]'>
                        <div className={"mt-5"}>
                          <div className={"flex"}>
                            <p className='text-[#232323] cursor-pointer text-14 md:text-16 font-medium'>
                              <Tooltip
                                arrow
                                placement='top'
                                title={
                                  <React.Fragment>
                                    {/*<Typography color="inherit">Tooltip with HTML</Typography>*/}
                                    <p>
                                      <b>{"Access Free Plan:"}</b> Explore
                                      VenueBoost's features at no cost.
                                    </p>
                                    <p>
                                      <b>
                                        {"Request Personalized Consultation:"}
                                      </b>{" "}
                                      Get in touch with our team for a tailored
                                      service plan.
                                    </p>
                                    <p>
                                      <b>{"Subscribe to Updates:"}</b> Stay
                                      informed with the latest news and updates
                                      from VenueBoost.
                                    </p>
                                  </React.Fragment>
                                }
                              >
                                Interested in
                              </Tooltip>
                            </p>
                            <span style={{color: "#F44336", marginLeft: "2px"}}>
                              *
                            </span>
                          </div>
                          <FormSelect
                            name={"contact_reason"}
                            enableLabel={false}
                            // placeholder="Interested in"
                            value={
                              data?.contact_reason || contactReason[0].value
                            }
                            dataList={contactReason}
                            onChanage={handleChange}
                            required={true}
                          />
                        </div>
                        <div className={"mt-5"}>
                          <FormSelect
                            name={"how_did_you_hear_about_us"}
                            placeholder='How did you hear about us?'
                            value={data?.how_did_you_hear_about_us || ""}
                            dataList={howDidYouHearOptions}
                            onChanage={handleChange}
                          />

                          {/* Conditional rendering for 'Other' option */}
                          {data?.how_did_you_hear_about_us === "Other" && (
                            <FormTextInput
                              className={"mt-5"}
                              name='how_did_you_hear_about_us_other'
                              placeholder='Please specify'
                              value={
                                data?.how_did_you_hear_about_us_other || ""
                              }
                              onChange={handleChange}
                              required={true}
                            />
                          )}
                        </div>
                      </div>

                      <div className={"mt-5"}>
                        <FormTextInput
                          name='biggest_additional_change'
                          placeholder='What’s your biggest operational challenge?'
                          value={data?.biggest_additional_change || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='w-full px-8 justify-between flex absolute bottom-[58px] left-0'>
                      <button
                        // onClick={() => setStep(2)}
                        className='text-[#707070] text-16 px-0 mt-5 leading-[16px]  h-[36px] font-semibold'
                      ></button>
                      <button
                        onClick={onSubmitInterestStep}
                        className='bg-[#240b3b]  rounded-[63px] px-[26px] h-[36px] text-white text-16 mt-5 self-end leading-[16px] font-semibold'
                      >
                        {loadingSecondStep ? (
                          <CircularProgress size={16} />
                        ) : (
                          t("who_we_serve.accommodation_industry.next")
                        )}
                      </button>
                    </div>
                    <div className='w-full align-middle absolute bottom-[30px] left-0'>
                      <img src={Dot2} />
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className='  bg-white rounded-[31px] p-[24px] md:p-[33px] relative'>
                    <div className='flex justify-start  items-center'>
                      <span className='text-[32px] md:text-32 font-extrabold leading-[50px] my-6]'>
                        Plan Recommendation
                      </span>
                    </div>
                    <div>
                      <p className='text-18 font-semibold text-[#240b3b] my-4 mb-6'>
                        Based on the information you've provided, we recommend
                        the VenueBoost <b>{suggestedPlan}</b> Plan for your
                        business.
                      </p>
                      <p className='text-18 font-semibold text-[#240b3b] my-4 mb-6'>
                        This plan includes:
                        <ul
                          style={{
                            listStyleType: "disc",
                            marginTop: "10px",
                            marginLeft: "20px",
                          }}
                        >
                          {suggestedPlanFeatures.map((feature, index) => (
                            <li key={index} style={{marginBottom: "10px"}}>
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </p>
                      <p className='text-18 font-semibold text-[#240b3b] my-4 mb-6'>
                        If you'd like to discuss this recommendation or explore
                        other options, we're here for a chat or call at your
                        convenience.
                      </p>
                    </div>

                    <div className='mt-8'>
                      <div
                        className='flex flex-col md:flex-row align-middle mt-6 md:mt-8 mb-4 md:mb-8'
                        style={{justifyContent: "right"}}
                      >
                        <div className='flex h-full align-middle mt-4 md:mt-0'>
                          <div className='border-[1px] border-[#E3E3E3] flex flex-row rounded-[15px] align-middle '>
                            <div
                              onClick={() => onBillingMethodChange("monthly")}
                              // onClick={() => setBillingMethod("monthly")}
                              className={`cursor-pointer text-[13px] leading-6 md:text-14 font-bold px-4 md:px-7 py-3 ${
                                billingMethod === "monthly"
                                  ? "text-white bg-primary1 rounded-[15px]"
                                  : ""
                              }`}
                            >
                              MONTHLY
                            </div>
                            <div
                              onClick={() => onBillingMethodChange("annual")}
                              // onClick={() => setBillingMethod("annual")}
                              className={`cursor-pointer text-[13px] leading-6 md:text-14 font-bold px-4 md:px-7 py-3 ${
                                billingMethod === "annual"
                                  ? "text-white bg-primary1 rounded-[15px]"
                                  : ""
                              } `}
                            >
                              YEARLY (SAVE 17%)
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-3 xl:gap-3 mb-20"
                        }
                      >
                        <div
                          className={`rounded-[13px] border-[#E0E0E0] relative border-[1px] overflow-hidden `}
                        >
                          <div className='text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px]   bg-[#808080] border-[#808080] border-[1px] box-content translate-x-[-1px]'>
                            Welcome aboard!
                          </div>
                          <div className='bg-white h-full rounded-2xl w-full  flex flex-col '>
                            <div
                              className='bg-white  pt-10 px-6 flex flex-col justify-start border-[#E0E0E0]'
                              style={{paddingBottom: "55px"}}
                            >
                              <div className='h-full flex flex-col items-start'>
                                <div
                                  className={`flex w-full justify-left items-left leading-[8px] mb-2`}
                                >
                                  <p className='text-20 text-[#333333] font-extrabold leading-[22px] mb-[0px]'>
                                    Discover
                                  </p>
                                </div>
                                <p className='text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]'>
                                  ${freePrice}
                                  <span className='text-[15px] content-center ml-1'>
                                    {billingMethod === "monthly"
                                      ? "month"
                                      : "year"}
                                  </span>
                                </p>
                                <p className='text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]'>
                                  {freeSubtitle}
                                </p>
                              </div>
                              <div className='flex'>
                                <button
                                  disabled={whichSelected === "Discover"}
                                  className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                                  style={
                                    whichSelected === "Discover"
                                      ? {
                                          background: "#80808014",
                                          pointerEvents: "none",
                                          color: "#240b3b",
                                          border: "1PX SOLID #240b3b",
                                        }
                                      : {
                                          color: "#240b3b",
                                          border: "1PX SOLID #240b3b",
                                        }
                                  }
                                  onClick={() => {
                                    setWhichSelected("Discover");
                                  }}
                                >
                                  {whichSelected === "Discover"
                                    ? "Selected"
                                    : "Select"}
                                </button>
                              </div>
                            </div>
                            <hr className='border-[#E0E0E0] border-[1px] mx-6' />
                            <div className='mt-6 px-6 w-full'>
                              <p className='text-16 mb-6 font-bold'>
                                Discover includes:
                              </p>
                              {venueType === "food" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage and streamline your booking process efficiently. Limit: 25/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Streamlined Reservations: Limited to 25
                                      reservations per month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Efficiently organize and update your venue's menu. This feature allows you to add and edit up to 25 menu items per month, ensuring your offerings are always current and appealing to your customers"
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Menu Management: Limited to 25 items per
                                      month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Keep track of your stock levels with real-time updates. Low stock alerts included."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Inventory Management: Alerts for low
                                      stock levels, limited to 25 items
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Analytics & Reporting: Essential
                                      insights for small-scale operations.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage guest profiles and preferences effectively. Up to 50 profiles for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Guest Management: Manage up to 50
                                      guest profiles
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Marketing Strategy: Limited to 1
                                      campaign per month.
                                    </p>
                                  </div>
                                </>
                              )}

                              {venueType === "sport_entertainment" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage and streamline your booking process efficiently. Limit: 40/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Bookings Management: Limit of 40 bookings
                                      per month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Efficiently organize and update your venue's items. This feature allows you to add and edit up to 40 items per month."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Items Management: Limited to 40 items per
                                      month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Keep track of your stock levels with real-time updates. Limit: 40/month for Discover; Unlimited for other plans. "
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Inventory Management: Manage up to 40
                                      inventory items.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Analytics & Reporting: Basic reports only.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage customer profiles and preferences effectively. Up to 80 profiles for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Customer Management: Up to 80 customer
                                      profiles.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3 '
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Marketing Tools: Limited to 1 campaign per
                                      month.
                                    </p>
                                  </div>
                                </>
                              )}
                              {venueType === "accommodation" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage and streamline your booking process efficiently. Limit: 30/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Bookings Management: Limit of 30 bookings
                                      per month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Efficiently organize and manage your available units or rooms. Limit: 30/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Units/Rooms Management: Limited to 30
                                      units/rooms per month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Keep track of your stock levels with real-time updates. Limit: 30/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Inventory Management: Manage up to 30
                                      inventory items per month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Efficiently organize and update your venue's items. Limit: 30/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Items Management: Limited to 30 items per
                                      month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage guest profiles and preferences effectively. Up to 75 profiles for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Guest Management: Up to 75 guest profiles.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Marketing Tools: Limited to 1 campaign per
                                      month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Analytics & Reporting: Basic reports only.
                                    </p>
                                  </div>
                                </>
                              )}

                              {venueType === "retail" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage and streamline your orders efficiently. Limit: 50/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Order Management: Limit of 50 orders per
                                      month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Efficiently organize and update your store's items. This feature allows you to add and edit up to 50 products per month."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Products Management: Up to 50 products.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Keep track of your stock levels with real-time updates. Low stock alerts included."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Inventory Management: Manage up to 50
                                      inventory items.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Gain insights into your business performance with basic analytics. Advanced analytics available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-6'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Dashboard & Revenue Analytics: Basic
                                      reports only.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Marketing Tools: Limited to 1 campaign per
                                      month.
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage your store’s essential operations with ease."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Store Management
                                    </p>
                                  </div>
                                </>
                              )}
                              <hr
                                className='border-[#E0E0E0] border-[2px] mb-4 mx-0'
                                style={{borderColor: "#3d1e59"}}
                              />
                              <p className='text-16 mb-2 font-bold'>
                                Customization:
                              </p>

                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip arrow placement='top' title={""}>
                                  <img
                                    src={InformationIcon}
                                    className='w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  Not available in the Discover plan.
                                </p>
                              </div>
                              <p className='text-16 mb-2 font-bold'>Support:</p>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip arrow placement='top' title={""}>
                                  <img
                                    src={InformationIcon}
                                    className='w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p
                                  className='text-16 mb-6'
                                  style={{textWrap: "balance"}}
                                >
                                  Access to online help center and community
                                  forums.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`rounded-[13px] ${
                            suggestedPlan === "Launch"
                              ? "border-[#333333] border-[2px] relative box-border"
                              : "border-[#E0E0E0] border-[1px] overflow-hidden"
                          }`}
                        >
                          <div
                            className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
                              suggestedPlan === "Launch"
                                ? "absolute top-[-16px] bg-[#240b3b] border-[#240b3b]"
                                : "bg-[#808080] border-[#808080]"
                            }`}
                          >
                            Great Value
                          </div>
                          <div className='bg-white h-full rounded-2xl w-full  flex flex-col '>
                            <div className='bg-white  pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]'>
                              <div className='h-full flex flex-col items-start'>
                                <div
                                  className={`flex w-full justify-left ${
                                    suggestedPlan === "Launch"
                                      ? "items-center leading-[22px]"
                                      : "items-left leading-[8px]"
                                  } mb-2`}
                                >
                                  <p
                                    className={`text-20 text-[#333333] font-extrabold ${
                                      suggestedPlan === "Launch"
                                        ? ""
                                        : "leading-[22px] mb-[0px]"
                                    }`}
                                  >
                                    Launch
                                  </p>
                                  {suggestedPlan === "Launch" && (
                                    <div
                                      className={
                                        "flex justify-center items-center "
                                      }
                                      style={{marginLeft: "10px"}}
                                    >
                                      <div
                                        className={`
                                            w-0 h-0 border-t-[8px] border-t-transparent
                                            border-r-[8px] border-r-[#240b3b]
                                            border-b-[8px] border-b-transparent`}
                                      />
                                      <div
                                        className={`px-3 py-1 rounded-[8px] bg-[#240b3b] text-white text-[12px]`}
                                      >
                                        Suggested
                                        <br />
                                        for you
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <p className='text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]'>
                                  ${launchPrice}
                                  <span className='text-[15px] content-center ml-1'>
                                    {billingMethod === "monthly"
                                      ? "month"
                                      : "year"}
                                  </span>
                                </p>
                                <p className='text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]'>
                                  {launchSubtitle}
                                </p>
                              </div>
                              <div className='flex'>
                                <button
                                  disabled={startingTrial1}
                                  onClick={() => onStartTrial("Launch")}
                                  className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                                  style={{
                                    background: "white",
                                    color: "rgb(46, 39, 60)",
                                    border: "1PX SOLID rgb(46, 39, 60)",
                                    marginTop: `${
                                      suggestedPlan === "Launch" ? "5px" : "0px"
                                    }`,
                                  }}
                                >
                                  {startingTrial1 ? (
                                    <CircularProgress
                                      sx={{
                                        "& .MuiCircularProgress-svg": {
                                          color: "rgb(46, 39, 60)",
                                        },
                                      }}
                                      size={16}
                                    />
                                  ) : (
                                    "Start your free trial"
                                  )}
                                </button>
                              </div>
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={
                                  "Experience all premium features risk-free for 14 days. Available for Launch and Elevate plans."
                                }
                              >
                                <div
                                  style={{
                                    color: "#3d1e59",
                                    textAlign: "center",
                                    marginTop: "10px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                >
                                  14-DAYS FREE TRIAL
                                </div>
                              </Tooltip>
                            </div>
                            <hr className='border-[#E0E0E0] border-[1px] mx-6' />
                            <div className='mt-6 px-6 w-full'>
                              <p className='text-16 mb-6 font-bold'>
                                All from Discover plus:
                              </p>
                              {venueType === "food" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage and streamline your booking process efficiently. Limit: 25/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Unlimited Streamlined Reservations
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Keep track of your stock levels with real-time updates. Low stock alerts included."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Full Inventory Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Efficiently manage your team with integrated staff scheduling and time tracking. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Staff Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Enhanced Marketing Strategy tools
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Build customer loyalty with customizable retention programs. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Loyalty and Retention Program
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Facilitate easy payments with secure, shareable payment links. Available from Launch plan"
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Payment Links
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Collect valuable feedback directly from your customers. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Guest Surveys and Ratings
                                    </p>
                                  </div>
                                </>
                              )}

                              {venueType === "sport_entertainment" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage and streamline your booking process efficiently. Limit: 40/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Unlimited Bookings Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Keep track of your stock levels with real-time updates. Limit: 40/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Full Inventory Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Efficiently manage your team with integrated staff scheduling and time tracking. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Staff Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-2'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Enhanced Marketing Tools
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Build customer loyalty with customizable retention programs. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Loyalty and Retention Program
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Facilitate easy payments with secure, shareable payment links. Available from Launch plan"
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Payment Links
                                    </p>
                                  </div>
                                </>
                              )}
                              {venueType === "accommodation" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage and streamline your booking process efficiently. Limit: 30/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Unlimited Bookings Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage and streamline your Units/Rooms efficiently. Limit: 30/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Unlimited Units/Rooms Management
                                    </p>
                                  </div>

                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Keep track of your stock levels with real-time updates. Limit: 30/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Full Inventory Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Efficiently manage your team with integrated staff scheduling and time tracking. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Staff Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-2'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Enhanced Marketing Tools
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Build customer loyalty with customizable retention programs. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Loyalty and Retention Program
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Facilitate easy payments with secure, shareable payment links. Available from Launch plan"
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Payment Links
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Collect valuable feedback directly from your customers. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Guest Surveys and Ratings
                                    </p>
                                  </div>
                                </>
                              )}

                              {venueType === "retail" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Manage and streamline your orders efficiently. Limit: 50/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Unlimited Order Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Keep track of your stock levels with real-time updates. Limit: 50/month for Discover; Unlimited for other plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Full Inventory Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Efficiently manage your team with integrated staff scheduling and time tracking. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Staff Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-2'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Enhanced Marketing Tools
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='top'
                                      title={
                                        "Build customer loyalty with customizable retention programs. Available from Launch plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Loyalty and Retention Program
                                    </p>
                                  </div>
                                </>
                              )}

                              <hr
                                className='border-[#E0E0E0] border-[2px] mb-4 mx-0'
                                style={{borderColor: "#3d1e59"}}
                              />
                              <p className='text-16 mb-2 font-bold'>
                                Customization:
                              </p>

                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Customize the look and feel of your platform to match your brand. Contact us for pricing and options."
                                  }
                                >
                                  <img
                                    src={InformationIcon}
                                    className='cursor-pointer w-5 h-5 mt-1'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  {venueType === "food" &&
                                    "UI Changes: $50 - $100 one-time fee."}
                                  {venueType === "accommodation" &&
                                    "$75 - $150 one-time fee."}
                                  {venueType === "sport_entertainment" &&
                                    "$85 - $170 one-time fee."}
                                  {venueType === "retail" &&
                                    "$80 - $160 one-time fee."}
                                </p>
                              </div>
                              <p className='text-16 mb-2 font-bold'>
                                Communication Templates:
                              </p>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Personalize communication templates for emails and notifications. Pricing varies by plan. Contact us for pricing and options."
                                  }
                                >
                                  <img
                                    src={InformationIcon}
                                    className='cursor-pointer w-5 h-5'
                                  />
                                </Tooltip>
                                <p
                                  className='text-16 mb-6'
                                  style={{textWrap: "balance"}}
                                >
                                  {venueType === "food" && "$50 per template."}
                                  {venueType === "accommodation" &&
                                    "$60 one-time fee."}
                                  {venueType === "sport_entertainment" &&
                                    "$70 one-time fee."}
                                  {venueType === "retail" &&
                                    "$65 one-time fee."}
                                </p>
                              </div>
                              <p className='text-16 mb-2 font-bold'>Support:</p>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Get assistance via email. Response time varies by plan: 48 hours for Launch, 24 hours for Elevate, 12 hours for Optimize."
                                  }
                                >
                                  <img
                                    src={InformationIcon}
                                    className='cursor-pointer w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p
                                  className='text-16 mb-6'
                                  style={{textWrap: "balance"}}
                                >
                                  Email support with a 48-hour response time.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`rounded-[13px] ${
                            suggestedPlan === "Elevate"
                              ? "border-[#333333] border-[2px] relative box-border"
                              : "border-[#E0E0E0] border-[1px] overflow-hidden"
                          }`}
                        >
                          <div
                            className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
                              suggestedPlan === "Elevate"
                                ? "absolute top-[-16px] bg-[#240b3b] border-[#240b3b]"
                                : "bg-[#808080] border-[#808080]"
                            }`}
                          >
                            Just Right
                          </div>
                          <div className='bg-white h-full w-full rounded-[13px]  flex flex-col '>
                            <div className='bg-white  pt-10 pb-6 px-6 flex flex-col justify-start'>
                              <div className='h-full flex flex-col items-start'>
                                <div
                                  className={`flex w-full justify-left ${
                                    suggestedPlan === "Elevate"
                                      ? "items-center leading-[22px]"
                                      : "items-left leading-[8px]"
                                  } mb-2`}
                                >
                                  <p
                                    className={`text-20 text-[#333333] font-extrabold ${
                                      suggestedPlan === "Elevate"
                                        ? ""
                                        : "leading-[22px] mb-[0px]"
                                    }`}
                                  >
                                    Elevate
                                  </p>
                                  {suggestedPlan === "Elevate" && (
                                    <div
                                      className={
                                        "flex justify-center items-center "
                                      }
                                      style={{marginLeft: "10px"}}
                                    >
                                      <div
                                        className={`
                                            w-0 h-0 border-t-[8px] border-t-transparent
                                            border-r-[8px] border-r-[#240b3b]
                                            border-b-[8px] border-b-transparent`}
                                      />
                                      <div
                                        className={`px-3 py-1 rounded-[8px] bg-[#240b3b] text-white text-[12px]`}
                                      >
                                        Suggested
                                        <br />
                                        for you
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <p
                                  className='text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]'
                                  style={{
                                    marginBottom: `${
                                      suggestedPlan === "Elevate"
                                        ? "20px"
                                        : "15px"
                                    }`,
                                  }}
                                >
                                  ${elevatePrice}
                                  <span className='text-[15px] content-center ml-1'>
                                    {billingMethod === "monthly"
                                      ? "month"
                                      : "year"}
                                  </span>
                                </p>
                                <p className='text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]'>
                                  {elevateSubtitle}
                                </p>
                              </div>
                              <div className='flex'>
                                <button
                                  disabled={startingTrial2}
                                  onClick={() => onStartTrial("Elevate")}
                                  className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                                  style={{
                                    background: "white",
                                    color: "#240b3b",
                                    border: "1PX SOLID #240b3b",
                                  }}
                                >
                                  {startingTrial2 ? (
                                    <CircularProgress
                                      sx={{
                                        "& .MuiCircularProgress-svg": {
                                          color: "rgb(46, 39, 60)",
                                        },
                                      }}
                                      size={16}
                                    />
                                  ) : (
                                    "Start your free trial"
                                  )}
                                </button>
                              </div>
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={
                                  "Experience all premium features risk-free for 14 days. Available for Launch and Elevate plans."
                                }
                              >
                                <div
                                  style={{
                                    color: "#3d1e59",
                                    textAlign: "center",
                                    marginTop: "10px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                >
                                  14-DAYS FREE TRIAL
                                </div>
                              </Tooltip>
                            </div>
                            <hr className='border-[#E0E0E0] border-[1px] mx-6' />

                            <div className='mt-6 px-6 w-[100%]'>
                              <p className='text-16 mb-6 font-bold'>
                                All from Launch plus:
                              </p>
                              {venueType === "food" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Automate your marketing activities for efficiency and consistency. Available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Marketing Automation
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Expand your reach through strategic affiliate partnerships. Available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Affiliate Partnerships
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Analytics & Reporting
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Streamline your delivery process with comprehensive order management tools. Available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Delivery Orders management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Unlock deeper insights into customer preferences and behaviors."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Guest Behavior Analytics
                                    </p>
                                  </div>
                                </>
                              )}

                              {venueType === "sport_entertainment" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Automate your marketing activities for efficiency and consistency. Available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Marketing Automation
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Expand your reach through strategic affiliate partnerships. Available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Affiliate Partnerships
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Analytics & Reporting
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Elevate the customer experience with our Entertainment Membership Program. Offer exclusive benefits."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Entertainment Membership Program
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Gather valuable insights with Advanced Customer Surveys. Track ratings to continually improve cuest experiences and services."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Customer Surveys and Ratings
                                    </p>
                                  </div>
                                </>
                              )}
                              {venueType === "accommodation" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Automate your marketing activities for efficiency and consistency. Available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Marketing Automation including Guest
                                      Journey support
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Expand your reach through strategic affiliate partnerships. Available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Affiliate Partnerships
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Analytics & Reporting
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "With iCal integration, synchronize your bookings across various platforms to ensure seamless coordination and up-to-date scheduling."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      iCal Integration
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Unlock deeper insights into customer preferences and behaviors."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Guest Behavior Analytics
                                    </p>
                                  </div>
                                </>
                              )}

                              {venueType === "retail" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Automate your marketing activities for efficiency and consistency. Available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Marketing Automation
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Expand your reach through strategic affiliate partnerships. Available from Elevate plan."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Basic Affiliate Partnerships
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Gain comprehensive insights with our Advanced Dashboard. Track key performance indicators and analyze revenue streams to make data-driven decisions and boost your business growth."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Dashboard & Revenue Analytics
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Build strong customer relationships with our Retail Customer Loyalty Program. Offer rewards and personalized experiences to encourage repeat business and foster brand loyalty."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Retail Customer Loyalty Program
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Gather valuable insights with Advanced Customer Surveys. Track ratings to continually improve cuest experiences and services."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Customer Surveys and Ratings
                                    </p>
                                  </div>

                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Ensure real-time inventory synchronization with 3rd party integrations like Woocommerce and Shopify. This feature helps in maintaining an up-to-date and consistent inventory across multiple sales channels, reducing the risk of stock discrepancies and improving customer satisfaction."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Consistent Inventory
                                    </p>
                                  </div>
                                </>
                              )}

                              <hr
                                className='border-[#E0E0E0] border-[2px] mb-4 mx-0'
                                style={{borderColor: "#3d1e59"}}
                              />
                              <p className='text-16 mb-2 font-bold'>
                                Customization:
                              </p>

                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Enhance your platform with additional features tailored to your needs. Contact us for pricing and options."
                                  }
                                >
                                  <img
                                    src={InformationIcon}
                                    className='cursor-pointer w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  {venueType === "food" &&
                                    "Feature Additions: $100 - $300 per feature."}
                                  {venueType === "accommodation" &&
                                    "Feature Additions: $150 - $400 per feature."}
                                  {venueType === "sport_entertainment" &&
                                    "Feature Additions: $170 - $500 per feature."}
                                  {venueType === "retail" &&
                                    "Feature Additions: $160 - $450 per feature."}
                                </p>
                              </div>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Customize the look and feel of your platform to match your brand. Contact us for pricing and options."
                                  }
                                >
                                  <img
                                    src={InformationIcon}
                                    className='cursor-pointer w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  {venueType === "food" &&
                                    "Advanced UI Customization: $200 - $500 one-time fee."}
                                  {venueType === "accommodation" &&
                                    "Advanced UI Customization: $250 - $600 one-time fee."}
                                  {venueType === "sport_entertainment" &&
                                    "Advanced UI Customization: $270 - $700 one-time fee."}
                                  {venueType === "retail" &&
                                    "Advanced UI Customization: $260 - $650 one-time fee."}
                                </p>
                              </div>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip arrow placement='top' title={""}>
                                  <img
                                    src={InformationIcon}
                                    className='w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  10% discount on customization for Elevate
                                  subscribers.
                                </p>
                              </div>
                              <p className='text-16 mb-2 font-bold'>Support:</p>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Get assistance via email. Response time varies by plan: 48 hours for Launch, 24 hours for Elevate, 12 hours for Optimize."
                                  }
                                >
                                  <img
                                    src={InformationIcon}
                                    className='cursor-pointer w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p
                                  className='text-16 mb-6'
                                  style={{textWrap: "balance"}}
                                >
                                  Priority email support with a 24-hour response
                                  time.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`rounded-[13px] ${
                            suggestedPlan === "Optimize"
                              ? "border-[#333333] border-[2px] relative box-border"
                              : "border-[#E0E0E0] border-[1px] overflow-hidden"
                          }`}
                        >
                          <div
                            className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
                              suggestedPlan === "Optimize"
                                ? "absolute top-[-16px] bg-[#240b3b] border-[#240b3b]"
                                : "bg-[#808080] border-[#808080]"
                            }`}
                          >
                            Worth It
                          </div>
                          <div className='bg-white h-full w-full rounded-[13px]  flex flex-col '>
                            <div className='bg-white  pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]'>
                              <div className='h-full flex flex-col items-start'>
                                <div
                                  className={`flex w-full justify-left ${
                                    suggestedPlan === "Optimize"
                                      ? "items-center leading-[22px]"
                                      : "items-left leading-[8px]"
                                  } mb-2`}
                                >
                                  <p
                                    className={`text-20 text-[#333333] font-extrabold ${
                                      suggestedPlan === "Optimize"
                                        ? ""
                                        : "leading-[22px] mb-[0px]"
                                    }`}
                                  >
                                    Optimize
                                  </p>
                                  {suggestedPlan === "Optimize" && (
                                    <div
                                      className={
                                        "flex justify-center items-center "
                                      }
                                      style={{marginLeft: "10px"}}
                                    >
                                      <div
                                        className={`
                                            w-0 h-0 border-t-[8px] border-t-transparent
                                            border-r-[8px] border-r-[#240b3b]
                                            border-b-[8px] border-b-transparent`}
                                      />
                                      <div
                                        className={`px-3 py-1 rounded-[8px] bg-[#240b3b] text-white text-[12px]`}
                                      >
                                        Suggested
                                        <br />
                                        for you
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <p
                                  className='text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]'
                                  style={{
                                    marginTop: `${
                                      suggestedPlan === "Optimize"
                                        ? "5px"
                                        : "0px"
                                    }`,
                                  }}
                                >
                                  ${optimizePrice}
                                  <span className='text-[15px] content-center ml-1'>
                                    {billingMethod === "monthly"
                                      ? "month"
                                      : "year"}
                                  </span>
                                </p>
                                <p className='text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]'>
                                  {optimizeSubtitle}
                                </p>
                              </div>
                              <div className='flex'>
                                <button
                                  disabled={startingTrial3}
                                  onClick={() => onStartTrial("Optimize")}
                                  className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                                  style={{
                                    background: "white",
                                    color: "#240b3b",
                                    border: "1PX SOLID #240b3b",
                                  }}
                                >
                                  {startingTrial3 ? (
                                    <CircularProgress
                                      sx={{
                                        "& .MuiCircularProgress-svg": {
                                          color: "rgb(46, 39, 60)",
                                        },
                                      }}
                                      size={16}
                                    />
                                  ) : (
                                    "Start your free trial"
                                  )}
                                </button>
                              </div>
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={
                                  "Extended 30-day trial to fully explore advanced features."
                                }
                              >
                                <div
                                  style={{
                                    color: "#3d1e59",
                                    textAlign: "center",
                                    marginTop: "10px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                >
                                  30-DAYS FREE TRIAL
                                </div>
                              </Tooltip>
                            </div>
                            <hr className='border-[#E0E0E0] border-[1px] mx-6' />

                            <div className='mt-6 px-6 w-[100%]'>
                              <p className='text-16 mb-6 font-bold'>
                                All from Elevate plus:{" "}
                                {/*Solutions include, but not limited to:*/}
                              </p>
                              {venueType === "food" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Offer premium dining experiences with table bidding options. Exclusive to Optimize and Enterprise plans."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-5'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Premium Tables with Pricing / Bidding and
                                      Floorplan Visibility
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Enhance customer retention with a tailored dining loyalty program."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>

                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Dining Guest Loyalty Program
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Personalize your VenueBoost interface with your brand's unique identity."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Customizable Brand Profile
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Accept payments securely in-person at your venue place."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      In-Person Payments
                                    </p>
                                  </div>
                                </>
                              )}

                              {venueType === "sport_entertainment" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Unlock deep insights into customer preferences and behaviors with our Advanced Analytics. Make data-driven decisions to optimize your services."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-5'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Customer Behavior Analytics
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Elevate your marketing efforts with our suite of Advanced Marketing Tools. Enhance engagement and maximize your outreach potential."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>

                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Marketing Tools
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Personalize your VenueBoost interface with your brand's unique identity."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Customizable Brand Profile
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Accept payments securely in-person at your venue place."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      In-Person Payments
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Expand your business reach with our Advanced Affiliate Partnerships."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Affiliate Partnerships
                                    </p>
                                  </div>
                                </>
                              )}
                              {venueType === "accommodation" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Enhance guest retention with our Loyalty Program. Reward frequent guests, personalize their experience, and encourage repeat bookings."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Accommodation Guest Loyalty Program
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Streamline your housekeeping operations with real-time updates, ensuring efficient room management and superior guest satisfaction."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-5'
                                      />
                                    </Tooltip>

                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Housekeeping Management with Real-Time
                                      Updates
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Expand your business reach with our Advanced Affiliate Partnerships."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-2'
                                      />
                                    </Tooltip>

                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Affiliate Partnerships
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Personalize your VenueBoost interface with your brand's unique identity."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Customizable Brand Profile
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Accept payments securely in-person at your venue place."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      In-Person Payments
                                    </p>
                                  </div>
                                </>
                              )}

                              {venueType === "retail" && (
                                <>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Streamline and optimize your order processing with Advanced Order Management."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-2'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Order Management
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Harness the power of data with Centralized Analytics for Multi-Brand Retailers."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>

                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Centralized Analytics for Multi-Brand
                                      Retailers
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Personalize your VenueBoost interface with your brand's unique identity."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Customizable Brand Profile
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Accept payments securely in-person at your venue place."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-1'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      In-Person Payments
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Delve into customer insights with Advanced Customer Behavior Analytics. Understand your customers' preferences and purchasing habits to tailor your offerings and marketing strategies."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Customer Behavior Analytics
                                    </p>
                                  </div>
                                  <div className='flex flex-row-reverse justify-between gap-2'>
                                    <Tooltip
                                      arrow
                                      placement='bottom'
                                      title={
                                        "Manage your store’s essential operations with advanced features."
                                      }
                                    >
                                      <img
                                        src={InformationIcon}
                                        className='cursor-pointer w-5 h-5 mt-3'
                                      />
                                    </Tooltip>
                                    <p
                                      className='text-16 mb-6'
                                      style={{textWrap: "balance"}}
                                    >
                                      Advanced Store Management
                                    </p>
                                  </div>
                                </>
                              )}
                              <hr
                                className='border-[#E0E0E0] border-[2px] mb-4 mx-0'
                                style={{borderColor: "#3d1e59"}}
                              />
                              <p className='text-16 mb-2 font-bold'>
                                Customization:
                              </p>

                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Enhance your platform with additional features tailored to your needs. Contact us for pricing and options."
                                  }
                                >
                                  <img
                                    src={InformationIcon}
                                    className='cursor-pointer w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  {venueType === "food" &&
                                    "Major Feature Integration: $500 - $2000 per feature."}
                                  {venueType === "accommodation" &&
                                    "Major Feature Integration: $600 - $2500 per feature."}
                                  {venueType === "sport_entertainment" &&
                                    "Major Feature Integration: $700 - $3000 per feature."}
                                  {venueType === "retail" &&
                                    "Major Feature Integration: $650 - $2700 per feature."}
                                </p>
                              </div>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip arrow placement='top' title={""}>
                                  <img
                                    src={InformationIcon}
                                    className='w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  15% discount on customization for Optimize
                                  subscribers.
                                </p>
                              </div>

                              <p className='text-16 mb-2 font-bold'>Support:</p>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Direct phone support for immediate assistance."
                                  }
                                >
                                  <img
                                    src={InformationIcon}
                                    className='cursor-pointer w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p
                                  className='text-16 mb-6'
                                  style={{textWrap: "balance"}}
                                >
                                  Phone and email support with a 12-hour
                                  response time.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`rounded-[13px] border-[#E0E0E0] border-[1px] overflow-hidden`}
                        >
                          <div className='text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] bg-[#808080] border-[#808080] border-[1px] box-content translate-x-[-1px]'>
                            Built for you
                          </div>
                          <div className='bg-white h-full rounded-2xl w-full  flex flex-col '>
                            <div className='bg-white pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]'>
                              <div className='h-full flex flex-col items-start'>
                                <p className='text-20 text-[#333333] font-extrabold leading-[22px] mb-[6px]'>
                                  Enterprise
                                </p>
                                <img
                                  src={TBDIcon}
                                  className='h-[55px] w-[55px]'
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                  }}
                                />
                                <p className='text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]'>
                                  Get a custom quote for tailored solutions for
                                  large-scale operations, ensuring optimal
                                  performance.
                                </p>
                              </div>
                              <div className='flex'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Once selected, our team will reach out to you directly to discuss and define the unique features and capabilities that best align with your business objectives. We'll work closely with you to craft a plan that precisely fits your venue's requirements, ensuring you get the most out of VenueBoost."
                                  }
                                >
                                  <button
                                    disabled={whichSelected === "Enterprise"}
                                    className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                                    style={
                                      whichSelected === "Enterprise"
                                        ? {
                                            background: "#80808014",
                                            pointerEvents: "none",
                                            color: "#240b3b",
                                            border: "1PX SOLID #240b3b",
                                          }
                                        : {
                                            color: "#240b3b",
                                            border: "1PX SOLID #240b3b",
                                          }
                                    }
                                    onClick={() => {
                                      setWhichSelected("Enterprise");
                                    }}
                                  >
                                    {whichSelected === "Enterprise"
                                      ? "Selected"
                                      : "Select"}
                                  </button>
                                </Tooltip>
                              </div>
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={
                                  "Extended 30-day trial to fully explore tailored solutions built for you."
                                }
                              >
                                <div
                                  style={{
                                    color: "#3d1e59",
                                    textAlign: "center",
                                    marginTop: "10px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                >
                                  30-DAYS FREE TRIAL
                                </div>
                              </Tooltip>
                            </div>
                            <hr className='border-[#E0E0E0] border-[1px] mx-6' />

                            <div className='mt-6 px-6 w-[100%]'>
                              <p className='text-16 mb-6 font-bold'>
                                All from Optimize, plus:
                                {/*Solutions include, but not limited to:*/}
                              </p>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip
                                  arrow
                                  placement='top'
                                  title={
                                    "Receive personalized service and support from a dedicated account manager. Exclusive to Enterprise plan"
                                  }
                                >
                                  <img
                                    src={InformationIcon}
                                    className='cursor-pointer w-5 h-5 mt-4'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  Dedicated account manager.{" "}
                                </p>
                              </div>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <img
                                  src={InformationIcon}
                                  className='w-5 h-5 mt-8'
                                />
                                <p className='text-16 mb-6'>
                                  Custom integrations and feature development.
                                </p>
                              </div>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <img
                                  src={InformationIcon}
                                  className='w-5 h-5 mt-3'
                                />
                                <p className='text-16 mb-6'>
                                  Premium support and training.
                                </p>
                              </div>

                              <hr
                                className='border-[#E0E0E0] border-[2px] mb-4 mx-0'
                                style={{borderColor: "#3d1e59"}}
                              />
                              <p className='text-16 mb-2 font-bold'>Price:</p>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip arrow placement='top' title={""}>
                                  <img
                                    src={InformationIcon}
                                    className='w-5 h-5 mt-5'
                                  />
                                </Tooltip>
                                <p
                                  className='text-16 mb-6'
                                  style={{textWrap: "balance"}}
                                >
                                  {venueType === "accommodation" &&
                                    "Custom pricing (starting around $599 per month)"}
                                  {venueType === "retail" &&
                                    "Custom pricing (starting around $649 per month)"}
                                  {venueType === "sport_entertainment" &&
                                    "Custom pricing (starting around $699 per month)"}
                                  {venueType === "food" &&
                                    "Custom pricing (starting around $499 per month)"}
                                </p>
                              </div>

                              <p className='text-16 mb-2 font-bold'>
                                Customization:
                              </p>

                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip arrow placement='top' title={""}>
                                  <img
                                    src={InformationIcon}
                                    className='w-5 h-5 mt-7'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  Custom Feature Development: Custom pricing
                                  based on requirements.
                                </p>
                              </div>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip arrow placement='top' title={""}>
                                  <img
                                    src={InformationIcon}
                                    className='w-5 h-5 mt-5'
                                  />
                                </Tooltip>
                                <p className='text-16 mb-6'>
                                  20% discount on all customization for
                                  Enterprise subscribers.
                                </p>
                              </div>
                              <p className='text-16 mb-2 font-bold'>Support:</p>
                              <div className='flex flex-row-reverse justify-between gap-2'>
                                <Tooltip arrow placement='top' title={""}>
                                  <img
                                    src={InformationIcon}
                                    className='w-5 h-5 mt-3'
                                  />
                                </Tooltip>
                                <p
                                  className='text-16 mb-6'
                                  style={{textWrap: "balance"}}
                                >
                                  24/7 priority phone and email support with
                                  immediate response.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='w-full px-8 justify-between flex absolute bottom-[58px] left-0'>
                      <button className='text-[#707070] text-16 px-0 mt-5 leading-[16px]  h-[36px] font-semibold'></button>
                      <button
                        onClick={handleThirdStepSelect}
                        className='bg-[#240b3b]  rounded-[63px] px-[26px] h-[36px] text-white text-16 mt-5 self-end leading-[16px] font-semibold'
                      >
                        {loadingThirdStep ? (
                          <CircularProgress size={16} />
                        ) : (
                          "Proceed with " + whichSelected
                        )}
                      </button>
                    </div>
                    <div className='w-full align-middle absolute bottom-[30px] left-0'>
                      <img src={Dot3} />
                    </div>
                  </div>
                )}

                {step === 4 && (
                  <div
                    className='  bg-white rounded-[31px] p-[24px] md:p-[33px] relative'
                    style={{height: "600px"}}
                  >
                    <div className='flex justify-start  items-center'>
                      <span className='text-[32px] md:text-32 font-extrabold leading-[50px] my-6]'>
                        Thank you!
                      </span>
                    </div>
                    <p className='text-18 font-semibold text-[#240b3b] my-4'>
                      We're excited to have you as part of the VenueBoost
                      platform. You will soon receive an email from us with
                      details on how to log in to your VenueBoost Admin Panel.
                    </p>

                    <div className='flex justify-center'>
                      <img
                        src={confirmed_success}
                        alt='Confirmation Icon'
                        className='w-[200px] h-[200px] py-4'
                      />
                    </div>
                    <div className='w-full px-8 justify-between flex absolute bottom-[58px] left-0'>
                      <button className='text-[#707070] text-16 px-0 mt-5 leading-[16px]  h-[36px] font-semibold'></button>
                      <button
                        onClick={() => navigate("/")}
                        className='bg-[#240b3b]  rounded-[63px] px-[26px] h-[36px] text-white text-16 mt-5 self-end leading-[16px] font-semibold'
                      >
                        Finish
                      </button>
                    </div>
                    <div className='w-full align-middle absolute bottom-[30px] left-0'>
                      <img src={Dot4} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
