import React from "react";

import "./index.css";
// import lineImg from "../../../assets/images/pink-line.webp";
// import SvgHomeGrowth from "../../../assets/svgs/homegrowth.svg";
import SvgHomeGrowth from "../../../assets/images/homegrowth.webp";
import SvgHomeDot from "../../../assets/svgs/homedot.webp";

const Growth = () => {
  return (
    <div className={"align-col-middle view-terms mt-0"}>
      <div className='bg-white w-[100vw] relative  '>
        <div className='mx-auto max-w-screen-main px-7 sm:px-6 md:px-8 lg:px-16'>
          <div className='flex flex-col md:flex-row pt-8 md:pt-20 pb-8 md:pb-24'>
            <div className='flex flex-col w-full md:w-[33%]'>
              <img
                alt={"VenueBoost Growth"}
                src={SvgHomeGrowth}
                className='w-full h-full'
              />
            </div>
            <div className='flex flex-col items-center md:items-start w-full md:w-[67%] pl-0 md:pl-11'>
              <h2 className='text-primary1 font-extrabold text-28 md:text-44 mt-6 md:mt-0 text-center md:text-left'>
                Supporting your growth everytime
              </h2>
              <p className='mt-3 text-[#666A73] font-semibold text-sm md:text-xl leading-5 md:leading-7 text-center md:text-left'>
                Explore our extensive range of resources, including guides,
                FAQs, and blogs, curated to provide valuable insights and
                practical tips for optimizing your operations. If you require
                personalized assistance, our dedicated support team is readily
                available via phone, chat, or email to address any inquiries and
                ensure your satisfaction.
              </p>
              <div className=' my-10 grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8'>
                <a
                  aria-label='Contact VenueBoost'
                  href='/contact-us'
                  className='flex flex-row  justify-start items-center'
                >
                  <img
                    src={SvgHomeDot}
                    className='w-[24px] h-[24px] md:w-[30px] md:h-[30px]'
                    alt='VenueBoost Contact'
                  />
                  <span className='ml-4  text-[#240B3B] font-extrabold text-14 md:text-16'>
                    Connect With Us
                  </span>
                </a>
                <a
                  aria-label='Check our blog'
                  href='/blog'
                  className='flex flex-row  justify-start items-center'
                >
                  <img
                    src={SvgHomeDot}
                    className='w-[24px] h-[24px] md:w-[30px] md:h-[30px]'
                    alt='Follow our latest blog articles'
                  />
                  <span className='ml-4  text-[#240B3B] font-extrabold text-14 md:text-16'>
                    Check Our Blog
                  </span>
                </a>
                <a
                  aria-label='Best Practices'
                  href='/resources/best-practices'
                  className='flex flex-row justify-start items-center'
                >
                  <img
                    src={SvgHomeDot}
                    className='w-[24px] h-[24px] md:w-[30px] md:h-[30px]'
                    alt='VenueBoost Resources'
                  />
                  <span className='ml-4  text-[#240B3B] font-extrabold text-14 md:text-16'>
                    Explore Best Practices
                  </span>
                </a>
              </div>
              <div className='flex flex-col md:flex-row justify-center md:justify-start items-center'>
                <a
                  aria-label='Unlock free plan'
                  href='/get-started'
                  className='get-started rounded-md w-max px-9 h-[54px] flex items-center'
                >
                  <span className='text-base text-gray2 font-black'>
                    UNLOCK FREE PLAN
                  </span>
                </a>
                <a
                  aria-label='Visit our customer hub'
                  href='/resources'
                  className='ml:0 md:ml-5 mt-6 md:mt-0  text-[#240B3B] font-semibold text-14 md:text-16'
                >
                  Visit our Resource Center {">"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Growth;
