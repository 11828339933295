import { useState } from "react";
import GolfHeader from "./golf-header";
import GolfInfo from "./golf-info";
import GolfPhotos from "./golf-photos";
import GolfBook from "./golf-book";
import GolfTab from "./golf-tab";
import GolfScheule from "./golf-schedule";
import ReservationForm from "../../components/ReservationForm";
import { useNavigate, useParams } from "react-router-dom";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import moment from "moment";
import Enroll from "../../components/venue/enroll";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";
import { isEmpty } from "../../utils/common";
import { SET_VENUE_DATA } from "../../redux/actions/types";
import { useDispatch } from "react-redux";
import SocialMedia from "../venue-retail/retail-shop/social-media";

const VenueGolf = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [canEnroll, setCanEnroll] = useState(false);
  const [success, setSuccess] = useState(false);
  const { app_key: golf_id } = useParams();
  const [reservation, setReservation] = useState({});
  const [enrollEmail, setEnrollEmail] = useState(null);
  const [email, setEmail] = useState("");
  const [enrolledCompleted, setEnrolledCompleted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
  }, [golf_id]);

  // eslint-disable-next-line
  const getDetails = () => {
    WhiteLabelService.getDetails(golf_id).then(
      (res) => {
        dispatch({ type: SET_VENUE_DATA, payload: res.venue });

        setDetails(res?.venue);
        identifyVenue(golf_id);
        trackEvent("White Label - Golf", {
          action_category: "Page Load",
          action_outcome: "success",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "golf",
          source: "vb_web",
        });

        // golf_venue
      },
      (error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );

        identifyVenue(golf_id);
        trackEvent("White Label - Golf", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "golf",
          source: "vb_web",
        });
      }
    );
  };

  const handleSubmit = (data) => {
    const payload = {
      start_time: moment(reservation?.date + " " + reservation?.time).format(
        "YYYY-MM-DD hh:mm"
      ),
      is_for_main: false,
      guest_count: reservation?.people,
      facility_type: reservation?.reservation,
      main_guest: {
        first_name: data.name,
        last_name: data?.lastName,
        email: data?.email,
        phone: data?.phone,
      },
      notes: data?.additional,
    };
    setEnrollEmail(data?.email);
    WhiteLabelService.reservation(payload, golf_id)
      .then((res) => {
        setSuccess(true);
        if (res.data?.can_enroll) {
          setCanEnroll(true);
        }
        identifyVenue(golf_id);
        trackEvent("White Label - Golf Book", {
          action_category: "Confirm booking",
          action_outcome: "success",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "golf",
          source: "vb_web",
        });
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong!");
        identifyVenue(golf_id);
        trackEvent("White Label - Golf Book", {
          action_category: "Confirm booking",
          action_outcome: "fail",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "golf",
          source: "vb_web",
        });
      });
  };

  const onEnrollSubmit = () => {
    const payload = {
      email: enrollEmail,
    };
    WhiteLabelService.guestEnroll(payload, golf_id)
      .then((res) => {
        NotificationManager.success(
          res?.message ?? "Guest enrolled successfully"
        );
        identifyVenue(golf_id);
        trackEvent("White Label - Golf Loyalty", {
          action_category: "Guests Enrolled",
          action_outcome: "success",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "golf",
          source: "vb_web",
        });
        setEnrolledCompleted(true);
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong!");
        identifyVenue(golf_id);
        trackEvent("White Label - Golf Loyalty", {
          action_category: "Guests Enrolled",
          action_outcome: "fail",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "golf",
          source: "vb_web",
        });
      });
  };

  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const showSocials = details
    ? details?.other_customations?.facebook_link ||
      details?.other_customations?.instagram_link ||
      details?.other_customations?.twitter_link ||
      details?.other_customations?.linkedin_link ||
      details?.other_customations?.pinterest_link
    : false;
  const showComm =
    details?.other_customations?.call_us_text ||
    details?.other_customations?.support_phone;

  const SubscribeButtonBrand = details?.brand_profile?.filter(
    (item) => item?.element_name === "SubscribeBtn"
  )?.[0];

  const SubscribeButtonData = {
    text: isEmpty(
      SubscribeButtonBrand?.venue_brand_profile_customizations?.find(
        (c) => c?.customization_key == "text"
      )?.customization_value
    )
      ? SubscribeButtonBrand?.default_name
      : SubscribeButtonBrand?.venue_brand_profile_customizations?.find(
          (c) => c?.customization_key == "text"
        )?.customization_value,
    bgColor: SubscribeButtonBrand?.venue_brand_profile_customizations?.find(
      (c) => c?.customization_key == "color"
    )?.customization_value,
    textColor: SubscribeButtonBrand?.venue_brand_profile_customizations?.find(
      (c) => c?.customization_key == "text_color"
    )?.customization_value,
  };

  const TagsBrand = details?.brand_profile?.filter(
    (item) => item.element_name === "Tags"
  )[0];
  const TagsButtonData = {
    text: isEmpty(
      TagsBrand?.venue_brand_profile_customizations?.find(
        (c) => c.customization_key == "text"
      )?.customization_value
    )
      ? TagsBrand?.default_name
      : TagsBrand?.venue_brand_profile_customizations?.find(
          (c) => c.customization_key == "text"
        )?.customization_value,
    bgColor: TagsBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key == "color"
    )?.customization_value,
    textColor: TagsBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key == "text_color"
    )?.customization_value,
  };

  const onSubscribe = () => {
    if (email?.match(isValidEmail)) {
      const payload = {
        email,
      };
      WhiteLabelService.emailSubscribe(payload, golf_id)
        .then((data) => {
          NotificationManager.success(
            data?.message ||
              "Thank you for subscribing to our newsletter. We will keep you updated with the latest news and updates.",
            "Success",
            3000
          );
          setEmail("");
        })
        .catch((error) => {
          NotificationManager.error(
            error?.error ||
              "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
            "Error",
            3000
          );
        });
    } else {
      NotificationManager.warning(
        "Please enter a valid email address.",
        "Error",
        3000
      );
    }
  };

  const otherCustomations = details?.other_customations && (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-[28px] mb-32">
      {details?.other_customations?.show_newsletter && (
        <div className="rounded-lg border border-[#DEE2E6] px-10 py-9 flex flex-col gap-4">
          <div className=" text-[#222222] font-bold text-20">
            {details?.other_customations?.subscribe_label_text}
          </div>
          <div className="border border-[#DEE2E6] rounded-lg pl-6 flex flex-row justify-between">
            <input
              type="email"
              className="border-none font-semibold text-14 w-full focus:border-none focus:outline-none h-[44px]"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <input
              type="button"
              style={{
                backgroundColor: SubscribeButtonData
                  ? SubscribeButtonData?.bgColor
                  : "#240B3B",
                color: SubscribeButtonData
                  ? SubscribeButtonData?.textColor
                  : "white",
              }}
              className="bg-[#240B3B] cursor-pointer rounded-lg font-semibold text-14 px-5 py-3"
              value={
                SubscribeButtonData ? SubscribeButtonData?.text : "Subscribe"
              }
              onClick={onSubscribe}
            />
          </div>
        </div>
      )}

      {(showComm || showSocials) && (
        <div className="rounded-lg border border-[#DEE2E6] px-10 py-9 gap-10 grid grid-cols-1 lg:grid-cols-5">
          {showComm && (
            <div className="flex flex-col gap-4 lg:col-span-3">
              {details?.other_customations?.call_us_text && (
                <div className=" text-[#222222] font-bold text-20">
                  {details?.other_customations?.call_us_text}
                </div>
              )}
              {details?.other_customations?.support_phone && (
                <div className="border border-[#DEE2E6] rounded-lg flex flex-row justify-between bg-[#EBEBEB] items-center pl-4 gap-2">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8235 20.9996C15.9085 20.9996 14.6232 20.6686 12.6985 19.5933C10.3581 18.2808 8.54774 17.0691 6.21993 14.7474C3.97555 12.5044 2.88337 11.0522 1.35477 8.27066C-0.372103 5.13003 -0.0777282 3.48378 0.251334 2.78019C0.643209 1.93925 1.22165 1.43628 1.9693 0.937065C2.39397 0.658832 2.84337 0.420323 3.3118 0.224565C3.35868 0.204409 3.40227 0.18519 3.44118 0.167846C3.67321 0.0633151 4.02477 -0.0946537 4.47008 0.0740963C4.76727 0.185659 5.03258 0.41394 5.4479 0.824096C6.29962 1.6641 7.46352 3.53488 7.8929 4.45363C8.18118 5.07285 8.37196 5.4816 8.37243 5.94003C8.37243 6.47675 8.10243 6.89066 7.77477 7.33738C7.71337 7.42128 7.65243 7.50144 7.59337 7.57925C7.23665 8.048 7.15837 8.18347 7.20993 8.42535C7.31446 8.91144 8.09399 10.3585 9.37508 11.6368C10.6562 12.915 12.0615 13.6453 12.5495 13.7494C12.8016 13.8033 12.9399 13.7218 13.4237 13.3524C13.4931 13.2994 13.5643 13.2446 13.6388 13.1897C14.1385 12.818 14.5332 12.555 15.0573 12.555H15.0601C15.5162 12.555 15.9066 12.7528 16.5535 13.0791C17.3973 13.5047 19.3243 14.6536 20.1695 15.5063C20.5806 15.9207 20.8098 16.185 20.9218 16.4818C21.0906 16.9285 20.9316 17.2786 20.8281 17.513C20.8107 17.5519 20.7915 17.5946 20.7713 17.6419C20.574 18.1095 20.3341 18.558 20.0546 18.9816C19.5563 19.7269 19.0515 20.3039 18.2087 20.6963C17.7759 20.901 17.3023 21.0047 16.8235 20.9996Z"
                      fill="#240B3B"
                    />
                  </svg>
                  <input
                    className="border-none font-semibold text-14 w-full focus:border-none focus:outline-none h-[44px] disabled:bg-[#EBEBEB] rounded-lg text-[#595959]"
                    placeholder="Your email"
                    value={details?.other_customations?.support_phone}
                    disabled
                  ></input>
                </div>
              )}
            </div>
          )}

          {showSocials && <SocialMedia details={details} />}
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col">
      <GolfHeader TagsButtonData={TagsButtonData} data={details} />
      {submitted ? (
        <div>
          <ReservationForm
            success={success}
            data={reservation}
            onBack={() => setSubmitted(false)}
            onSubmit={(data) => handleSubmit(data)}
          />
          {canEnroll ? (
            <Enroll onSubmit={onEnrollSubmit} completed={enrolledCompleted} />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="bg-white">
          <div className="grid md:grid-cols-5 grid-cols-1 gap-0 md:gap-10 max-w-screen-main mx-auto w-full">
            <div className="md:col-span-3 flex flex-col px-0">
              <GolfPhotos photos={details?.photos ?? []} />
              <GolfScheule
                availability={
                  details?.additional_information?.availability ?? []
                }
                additional={details?.additional_information?.additional ?? ""}
              />
            </div>
            <div className="md:col-span-2 flex flex-col">
              <GolfInfo
                data={details?.additional_information}
                overview={details?.overview}
              />
            </div>
          </div>

          {otherCustomations}
        </div>
      )}
    </div>
  );
};

export default VenueGolf;
