import moment from "moment";
import { isMobile } from "react-device-detect";
import RouteNames from "../constants/route_names";

export const isEmpty = (string) => {
  if (string != null && string.length > 0) {
    return false;
  }
  return true;
};

export const ucFirst = (str) => {
  if (str == null || str === "") {
    return "";
  }
  return str
    ?.split(" ")
    ?.map((s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
    ?.join(" ");
};

export const getDuration = (startDate, endDate) => {
  const duration = moment.duration(moment(endDate).diff(startDate));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let time = '';
  if (days > 0) {
    time = time + `${days}D` + ' '
  }
  if (hours > 0) {
    time = time + `${hours}H` + ' '
  }
  if (minutes > 0) {
    time = time + `${minutes}M` + ' '
  }
  if (seconds > 0) {
    time = time + `${seconds}S`
  }
  return time;
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  let value = `${year}-${month}-${day}`;
  return { value: value, label: moment(date).format('DD MMMM') };
}

export function generateDateArray() {
  const numDays = 90;
  const dateArray = [];
  const today = new Date();

  for (let i = 0; i < numDays; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() + i);
    dateArray.push(formatDate(currentDate));
  }

  return dateArray;
}

export function formatTime(hour, minute) {
  const period = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  const formattedMinute = String(minute).padStart(2, "0");
  const time = `${formattedHour}:${formattedMinute} ${period}`;
  return { value: time, label: time };
}

export function generateTimeArray() {
  const timeArray = [];

  for (let hour = 7; hour <= 22; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      timeArray.push(formatTime(hour, minute));
    }
  }

  return timeArray;
}

export function getUtcTimeIntervalsWithDuration(start, end, intervalMinutes, durationHours) {
  const startTime = new Date(`1970-01-01T${start}Z`);
  const endTime = new Date(`1970-01-01T${end}Z`);
  const intervals = [];

  // Calculate the interval duration in milliseconds
  const intervalDuration = intervalMinutes * 60 * 1000;
  const durationMilliseconds = durationHours * 60 * 60 * 1000;

  for (let t = startTime; t <= endTime; t.setMinutes(t.getMinutes() + intervalMinutes)) {
    const formattedStart = t.toISOString().substr(11, 8); // Start time in HH:MM:SS format (UTC)

    const formattedEnd = new Date(t.getTime() + durationMilliseconds)// End time
    if (formattedEnd < endTime) {
      let splits = formattedStart.split(':');
      intervals.push(formatTime(splits[0], splits[1]));
    }
  }

  return intervals;
}

export const createUUID = () => {
  let s = [];
  let hexDigits = "0123456789ABCDEF";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  let uuid = s.join("");
  return uuid;
};

export const calculateWordCount = (currentBlog) => {
  if (currentBlog) {
    const countWords = (text) =>
      (text?.split(/\s+/) ?? [])?.filter((word) => word.length > 0)?.length;

    let totalWordCount = countWords(currentBlog.introduction);

    for (let i = 1; i <= 8; i++) {
      const sectionTitle = currentBlog[`section_${i}_title`] || "";
      const sectionContent = currentBlog[`section_${i}_content`] || "";

      totalWordCount += countWords(sectionTitle) + countWords(sectionContent);
    }

    return totalWordCount;
  }
  return 0;
};

export const modalButtonProps = {
  okButtonProps: {
    style: {
      background:
        "linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%)",
      color: "white",
      height: "40px",
      width: isMobile ? "100%" : "50%",
      marginLeft: isMobile ? "0px" : "8px",
      marginTop: isMobile ? "15px" : "0",
    },
  },
  cancelButtonProps: {
    style: {
      backgroundColor: "transparent",
      color: "#240b3b",
      borderColor: "#CBD5E1",
      height: "40px",
      width: isMobile ? "100%" : "50%",
    },
  },
};
