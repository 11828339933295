import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestVacancies = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
            BYBEST {`>`} FAQE {`>`} RRETH NESH
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <p className=" text-[13px] leading-[25px] text-[#000]">
                Permbajtja - vjen se shpejti
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestVacancies;
