import { useState, useEffect } from "react";
import axios from "axios";
import { getWhitelabelSeoClientConfig } from "../../utils/whitelabelSeoConfigs";
import { useLocation } from "react-router-dom";

const useSeoData = (basename = "https://bybest.shop") => {
  const [pageSEO, setPageSEO] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    const fetchSEO = async () => {
      try {
        const response = await axios.post(
          "https://crmapi.pixelbreeze.xyz/api/seo-page/get-by-url",
          {
            url: basename + (pathname === "/" ? "" : pathname),
          }
        );

        if (response.data.success) {
          const seoData = response.data.data;
          setPageSEO({
            title: seoData.pageTitle,
            description: seoData.metaDescription,
            keywords: seoData.metaKeywords.join(", "),
            ogTitle: seoData.ogTitle,
            ogDescription: seoData.metaDescription,
            ogImage: seoData.ogImage,
            ogUrl: seoData.ogUrl,
            canonicalUrl: seoData.canonicalUrl,
            robots: seoData.robots,
          });
        } else {
          throw new Error("Failed to fetch SEO data");
        }
      } catch (error) {
        console.error("Error fetching SEO data:", error);
        // Fallback to client config
        const clientConfig = getWhitelabelSeoClientConfig("bybest");
        setPageSEO(clientConfig.seo);
      } finally {
        console.log("finally!");
      }
    };

    fetchSEO();
  }, [basename, pathname]);

  return { pageSEO };
};

export default useSeoData;
