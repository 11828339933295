import { ChevronRight } from "@mui/icons-material";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";

export const CollapseMenu = ({ menu, isOpened, onSelect, onCloseDrawer }) => {
  const { app_key } = useParams();
  const navigate = useNavigate();
  const goCategory = (category, item) => {
    onCloseDrawer();
    navigate(
      `/shop/${app_key}/category/${item.replace(/\s+/g, "-").toLowerCase()}`,
      {
        state: {
          category: category,
          item: item,
        },
      }
    );
  };

  return (
    <>
      <div
        className="w-full flex justify-center items-center px-4 py-3"
        onClick={() => onSelect(menu)}
      >
        <div className="flex-1">
          <a className=" no-underline text-[#000] text-[14px] w-full">
            {menu.label}
          </a>
        </div>
        <ChevronRight fontSize={"small"} />
      </div>
      {isOpened && (
        <div className="w-full px-4 bg-[#f4f4f4]">
          {menu.menus.map((item, i) => (
            <div
              key={i}
              className="flex flex-col justify-start items-start py-3"
            >
              <p className="text-[26px] text-[#000]">{item.category}</p>
              {item.items.map((_item, i) => (
                <a
                  key={i}
                  className="flex justify-start items-center mt-5 no-underline text-[#000] text-[13px] w-full"
                  onClick={() => goCategory(item.category, _item)}
                >
                  {_item}
                  {(_item === "Flik flak" || _item === "SWATCH") && (
                    <img
                      alt=""
                      src="https://bybest.shop/assets/img/icons/swiss.png"
                      className="ml-1 h-[13px] object-contain"
                    />
                  )}
                </a>
              ))}
              <p className="mt-5 text-[13px] font-bold text-[#000] uppercase">
                {t("bybest.header.see_all")}
              </p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
