import React, { useEffect, useState } from "react";
import "./index.css";
import Carousel from "react-multi-carousel";
import { Pagination, Select } from "antd";
import "react-multi-carousel/lib/styles.css";

import BlogCard from "../../components/BlogCard";
import { Seo } from "../../components/Seo";
import { isMobile } from "react-device-detect";
import { analyticsService } from "../../services/analytics";
import { blogData, responsive, tags } from "./data";

const Blog = () => {
  const [data, setData] = useState([]);

  const filteredData = data.filter((item) => item.is_new_type === 1);

  const getBlogs = () => {
    analyticsService()
      .getBlogs()
      .then((res = []) => {
        const filterResponse = res.filter((item) => item?.is_new_type === 1);
        setData(filterResponse);
      })
      .catch((err) => console.log(err, "error"));
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(window.innerWidth > 640 ? 15 : 5);
  const onPaginationChange = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
  };

  const currentUrl = "https://venueboost.io/blog";
  const seoTitle = "Discover Our Latest Blog Updates";
  const seoDescription =
    "Explore VenueBoost's blog for essential tips, feature enhancements, and the newest innovations in venue management. Stay ahead of the curve with our latest posts and updates.";

  return (
    <div className="flex flex-col align-col-middle view-terms">
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />
      <div className="flex flex-col align-middle">
        <div className="mx-auto flex items-center justify-center mt-16 mb-8">
          <div className="h-[34px] w-[280px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]">
            <div className="flex h-full w-full items-center justify-center bg-white rounded-md">
              <h1 className="text-xl font-normal text-[#454545]">
                New Insights & Updates
              </h1>
            </div>
          </div>
        </div>

        <div className="text-[#252525] text-[36px] md:text-[48px] font-normal text-center leading-[48px]">
          Discover VenueBoost's Edge
        </div>
        <h1
          style={{ paddingBottom: "15px" }}
          className="mt-2 text-[36px] md:text-[48px] font-extrabold text-center text-transparent bg-clip-text leading-[48px] bg-gradient-to-br from-[#240B3B] via-[#3D1E59] via-[#8319B4] to-[#BC14FE]"
        >
          Stay Informed with the Latest Features and Innovations
        </h1>

        <p className="text-[#252525] text-16 md:text-18 leading-5 md:leading-7 font-medium text-center w-[80%] xl:w-[742px] mt-4 mb-6 ">
          Explore our latest posts and updates designed to keep you ahead of the
          curve. Dive into the heart of VenueBoost, where we share essential
          tips, feature enhancements, and the newest innovations shaping the
          future of venue management. Stay connected, stay ahead.{" "}
        </p>
      </div>

      <div className="w-[100vw] bg-[#FDF9F8]">
        <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 py-8">
          <div className="blog-carousel-wrapper">
            <Carousel
              responsive={responsive}
              // infinite={true}
              // autoPlay={true}
              showDots={false}
              partialVisible={false}
              containerClass={"py-3 blog-carousel"}
            >
              <div>
                <div className="hidden md:block">
                  <div className="flex flex-col md:flex-row rounded-[13px] border-[1px] border-[#E0E0E0] ">
                    <img
                      src={blogData[0].image}
                      className="w-full md:w-[40%] object-cover flex rounded-l-[13px]"
                      alt={blogData[0].title}
                    />
                    <div className="w-full md:w-[60%] py-[76px] px-[83px]">
                      <div className="flex flex-row  items-center justify-start gap-4">
                        <div class="h-[40px] w-[214px] rounded-[60px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]">
                          <div class="flex h-full w-full items-center justify-center bg-white rounded-[60px]">
                            <h1 class="text-14 font-semibold text-[#373941]">
                              {blogData[0].category}
                            </h1>
                          </div>
                        </div>
                        <p className="text-14 font-normal text-[#717484]">
                          {blogData[0].date}
                        </p>
                      </div>
                      <p className="text-[#373941] text-[32px] leading-[44px] font-semibold mt-6 mb-10">
                        {blogData[0].title}
                      </p>
                      <a
                        href={"/blog/" + blogData[0].slug}
                        className="text-white rounded-[6px] text-16 leading-[22px] py-4 px-[25px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE]"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="block md:hidden">
                  <BlogCard
                    img={blogData[0].image}
                    tag={blogData[0].category}
                    title={blogData[0].title}
                    date={blogData[0].date}
                    slug={blogData[0].slug}
                  />
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      <div className="w-full px-4">
        <div
          className={
            isMobile
              ? "flex flex-col gap-[10px] md:gap-5 mt-10 mb-10"
              : "flex flex-wrap gap-[10px] md:gap-5 mt-10 mb-10"
          }
        >
          <a
            href={"#"}
            className={`text-14  md:text-16 leading-4 px-[27px] py-[15px] h-[48px] rounded-md text-white bg-gradient-to-r from-[#240B3B] via-[#601B86] to-[#BC14FE]`}
          >
            All
          </a>

          <div class="h-[48px] w-[100%] md:w-[180px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]">
            <Select
              defaultValue="Select topic"
              className="w-full h-full [&>div]:!h-full [&>div]:!items-center [&>div>span:last-child]:!text-[20px]"
              options={tags}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 mt-0 sm:grid-cols-3 gap-[38px] px-4">
        {blogData &&
          blogData.length > 0 &&
          [...filteredData, ...blogData]
            .slice(minValue, maxValue)
            .map((card, index) => (
              <BlogCard
                img={card.image}
                tag={card.category ?? card.category_text}
                title={card.title}
                date={card.date}
                slug={card.slug}
              />
            ))}
      </div>
      <div className="mx-auto my-[56px] w-full align-middle blog-pagination">
        <Pagination
          total={[...filteredData, ...blogData].length}
          defaultCurrent={1}
          pageSize={window.innerWidth > 640 ? 15 : 5}
          onChange={onPaginationChange}
        />
      </div>
    </div>
  );
};

export default Blog;
