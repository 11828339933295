import { useEffect } from "react";
import { useState } from "react";

function RenderTable({
  isSelected,
  num,
  content,
  statusDescription,
  isHChair,
  vertical,
  shape,
  onClick,
}) {
  const getStatusNumber = (status) => {
    if (status === "reserved") {
      return 1;
    } else if (status === "not available") {
      return 2;
    }
    return 0;
  };

  const calculateChairsinHalf = (chairs, hasHorizontalChairs) => {
    let chairsArray = [[], []];
    if (chairs % 2 === 0) {
      for (let i = 0; i < num / 2; i++) chairsArray[0].push(1);
      for (let i = 0; i < num / 2; i++) chairsArray[1].push(1);
    } else {
      for (let i = 0; i < (num - 1) / 2; i++) chairsArray[0].push(1);
      for (let i = 0; i < (num - 1) / 2; i++) chairsArray[1].push(1);
      chairsArray[0].push(1); // add a extra on Top
    }
    if (hasHorizontalChairs) {
      // if has horizontal
      chairsArray[0].splice(-1, 1);
      chairsArray[1].splice(-1, 1);
    }
    setChairs(chairsArray);
  };

  const [selected, setSelected] = useState(isSelected);
  const [chairs, setChairs] = useState([[], []]);
  const status = getStatusNumber(statusDescription);

  const tableColors = [
    { bgColor: "#D9F6DA", textColor: "#8cf58f" },
    { bgColor: "#FEE8D9", textColor: "#fcc39d" },
    { bgColor: "#EDEAFF", textColor: "#d3ccff" },
  ];
  useEffect(() => {
    calculateChairsinHalf(num);
  }, [num]);

  useEffect(() => {
    setSelected(isSelected)
  }, [isSelected])

  if (shape === "round") {
    return (
      <div
        className="flex mb-[20px] mr-[12px] min-w-[50px] "
        style={{
          transform: vertical ? "rotate(90deg)" : "",
          paddingTop: vertical ? "20px" : "",
        }}
      >
        <div className="flex flex-col flex-1 items-center justify-center mb-[12px] circle-wrapper rounded-full">
          <div className="flex w-full mb-[12px] justify-center">
            {chairs[0].map((item, i) => {
              return (
                <div
                  className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
                  key={i}
                  style={{ width: "17%", minWidth: "40px" }}
                />
              );
            })}
          </div>
          <div
            className={`h-[80px] w-[80px] border ${
              selected
                ? `border-[${tableColors[status].bgColor}] border-[3px]`
                : "border-[#border-[#F9F7F7]]"
            } bg-white  mr-[12px] flex items-center justify-center rounded-full`}
            style={{
              borderColor: selected
                ? tableColors[status].textColor
                : "#border-[#F9F7F7]",
            }}
            onClick={() => {
              if (onClick) {
                onClick(!isSelected, content);
                setSelected(!selected);
              }
            }}
          >
            <div
              className="h-[55px] w-[55px] rounded-[45px] align-middle font-bold"
              style={{
                backgroundColor: tableColors[status].bgColor,
                fontWeight: "bold",
              }}
            >
              {content}
            </div>
          </div>
          <div className="flex w-full mt-[12px] justify-center">
            {chairs[1].map((item, i) => {
              return (
                <div
                  className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
                  key={i}
                  style={{ width: "17%", minWidth: "40px" }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  if (shape === "square") {
    return (
      <div
        className="flex mb-[20px] mr-[12px] min-w-[50px]"
        style={{
          transform: vertical ? "rotate(90deg)" : "",
          paddingTop: vertical ? "20px" : "",
        }}
      >
        <div className="flex flex-col flex-1 items-center justify-center mb-[12px] circle-wrapper rounded-12">
          <div className="flex w-full mb-[12px] justify-center">
            {chairs[0].map((item, i) => {
              return (
                <div
                  className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
                  key={i}
                  style={{ width: "17%", minWidth: "40px" }}
                />
              );
            })}
          </div>
          <div
            className={`h-[80px] w-[80px] border ${
              selected
                ? `border-[${tableColors[status].bgColor}] border-[3px]`
                : "border-[#border-[#F9F7F7]]"
            } bg-white  mr-[12px] flex items-center justify-center rounded-12`}
            style={{
              borderColor: selected
                ? tableColors[status].textColor
                : "#border-[#F9F7F7]",
            }}
            onClick={() => {
              if (onClick) {
                onClick(!isSelected, content);
                setSelected(!selected);
              }
            }}
          >
            <div
              className="h-[55px] w-[55px] rounded-[45px] align-middle font-bold"
              style={{
                backgroundColor: tableColors[status].bgColor,
                fontWeight: "bold",
              }}
            >
              {content}
            </div>
          </div>
          <div className="flex w-full mt-[12px] justify-center">
            {chairs[1].map((item, i) => {
              return (
                <div
                  className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
                  key={i}
                  style={{ width: "17%", minWidth: "40px" }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  } else if (shape === "communal") {
    return (
      <div
        className="flex mb-[20px] mr-[12px] min-w-[50px]"
        style={{
          transform: vertical ? "rotate(90deg)" : "",
          paddingTop: vertical ? "20px" : "",
        }}
      >
        <div className="flex items-center justify-center mb-[12px]">
          {isHChair ? (
            <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]" />
          ) : (
            ""
          )}
        </div>

        <div className="flex flex-col flex-1 items-center justify-center mb-[12px]">
          <div className="flex w-full mb-[12px] justify-center">
            <div
              className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
              style={{ width: "70%", minWidth: "40px" }}
            />
          </div>
          <div
            className={`h-[80px] border ${
              selected
                ? `border-[${tableColors[status].bgColor}] border-[3px]`
                : "border-[#border-[#F9F7F7]]"
            } bg-white mr-[12px] flex items-center justify-center ${
              shape === "oval" ? "rounded-[48px]" : "rounded-[16px]"
            }`}
            style={{
              width: "100%",
              borderColor: selected
                ? tableColors[status].textColor
                : "#border-[#F9F7F7]",
            }}
            onClick={() => {
              if (onClick) {
                onClick(!isSelected, content);
                setSelected(!selected);
              }
            }}
          >
            <div
              className="h-[55px] w-[55px] rounded-[45px] align-middle font-bold"
              style={{
                backgroundColor: tableColors[status].bgColor,
                fontWeight: "bold",
                // color: stateColors[status].textColor,
              }}
            >
              {content}
            </div>
          </div>
          <div className="flex w-full mt-[12px] justify-center">
            <div
              className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
              style={{ width: "70%", minWidth: "40px" }}
            />
          </div>
        </div>
        <div className="flex items-center justify-center mb-[12px]">
          {isHChair ? (
            <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]" />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else if (shape === "bar" || shape === "u-shape") {
    return (
      <div
        className="flex mb-[20px] mr-[12px] min-w-[50px]"
        style={{
          transform: vertical ? "rotate(90deg)" : "",
          paddingTop: vertical ? "20px" : "",
        }}
      >
        <div className="flex items-center justify-center mb-[12px]">
          {isHChair ? (
            <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]" />
          ) : (
            ""
          )}
        </div>

        <div className="flex flex-col flex-1 items-center justify-center mb-[12px]">
          <div className="flex w-full mb-[12px] justify-center">
            <div
              className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
              style={{ width: "70%", minWidth: "40px" }}
            />
          </div>
          <div
            className={`h-[80px] border ${
              selected
                ? `border-[${tableColors[status].bgColor}] border-[3px]`
                : "border-[#border-[#F9F7F7]]"
            } bg-white mr-[12px] flex items-center justify-center ${
              shape === "oval" ? "rounded-[48px]" : "rounded-[16px]"
            }`}
            style={{
              width: "100%",
              borderColor: selected
                ? tableColors[status].textColor
                : "#border-[#F9F7F7]",
            }}
            onClick={() => {
              if (onClick) {
                onClick(!isSelected, content);
                setSelected(!selected);
              }
            }}
          >
            <div
              className="h-[55px] w-[55px] rounded-[45px] align-middle font-bold"
              style={{
                backgroundColor: tableColors[status].bgColor,
                fontWeight: "bold",
              }}
            >
              {content}
            </div>
          </div>
          <div className="flex w-full mt-[12px] justify-center">
            <div
              className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
              style={{ width: "70%", minWidth: "40px" }}
            />
          </div>
        </div>
        <div className="flex items-center justify-center mb-[12px]">
          {isHChair ? (
            <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]" />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
  return (
    <div
      className="flex mb-[20px] mr-[12px] min-w-[50px]"
      style={{
        transform: vertical ? "rotate(90deg)" : "",
        paddingTop: vertical ? "20px" : "",
      }}
    >
      <div className="flex items-center justify-center mb-[12px]">
        {isHChair ? (
          <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]" />
        ) : (
          ""
        )}
      </div>

      <div className="flex flex-col flex-1 items-center justify-center mb-[12px]">
        <div className="flex w-full mb-[12px] justify-center">
          {chairs[0].map((_, i) => {
            return (
              <div
                className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
                key={i}
                style={{ width: "17%", minWidth: "40px" }}
              />
            );
          })}
        </div>
        <div
          className={`h-[80px] border ${
            selected
              ? `border-[${tableColors[status].bgColor}] border-[3px]`
              : "border-[#border-[#F9F7F7]]"
          } bg-white  mr-[12px] flex items-center justify-center ${
            shape === "oval" ? "rounded-[48px]" : "rounded-[16px]"
          }`}
          style={{
            width: "100%",
            borderColor: selected
              ? tableColors[status].textColor
              : "#border-[#F9F7F7]",
          }}
          onClick={() => {
            if (onClick) {
              onClick(!isSelected, content);
              setSelected(!selected);
            }
          }}
        >
          <div
            className="h-[55px] w-[55px] rounded-[45px] align-middle font-bold"
            style={{
              backgroundColor: tableColors[status].bgColor,
              fontWeight: "bold",
              // color: stateColors[status].textColor,
            }}
          >
            {content}
          </div>
        </div>
        <div className="flex w-full mt-[12px] justify-center">
          {chairs[1].map((item, i) => {
            return (
              <div
                className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]"
                key={i}
                style={{ width: "17%", minWidth: "40px" }}
              />
            );
          })}
        </div>
      </div>
      <div className="flex items-center justify-center mb-[12px]">
        {isHChair ? (
          <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]" />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default RenderTable;
