// Contact Sales
export const API_CONTACT_SALES = {
  content_type: "application/json",
  path: "vendor-contact-applications",
  has_token: false,
};

// Marketing Waitlist
export const API_MARKETING_WAITLIST = {
  content_type: "application/json",
  path: "marketing-waitlist",
  has_token: false,
};

// Privacy Rights Request Form
export const API_PRIVACY_RIGHTS_FORM = {
  content_type: "application/json",
  path: "privacy-rights-requests",
  has_token: false,
};

// Marketing Onboarding
export const API_MARKETING_ONBOARDING = {
  content_type: "application/json",
  path: "onboarding",
  has_token: false,
};

export const API_WEB = {
  content_type: "application/json",
  path: "web",
  has_token: false,
};

// RESTAURANTS
export const API_RESTAURANTS = {
  content_type: "application/json",
  path: "restaurants",
  has_token: false,
};

// Chat bot
export const API_CHAT = {
  content_type: "application/json",
  path: "ai/chat",
  has_token: false,
};

export const API_WHITE_LABEL = {
  content_type: "application/json",
  path: "white-label",
  has_token: false,
};

export const API_TABLES = {
  content_type: "application/json",
  path: "resto-tables",
  has_token: false,
};

export const API_RETAIL = {
  content_type: "application/json",
  path: "retail",
  has_token: false,
};

export const API_ACCOMMODATION = {
  content_type: "application/json",
  path: "accommodation/rental-unit",
  has_token: false,
};

// Affiliates
export const API_AFFILIATES = {
  content_type: "application/json",
  path: "affiliate",
  has_token: false,
};

// Auth
export const API_Auth = {
  content_type: "application/json",
  path: "auth",
  has_token: false,
};
export const API_AI = {
  content_type: "application/json",
  path: "ai",
  has_token: true,
};
export const WEB_AI = {
  content_type: "application/json",
  path: "web",
  has_token: true,
};
export const API_QUIZ = {
  content_type: "application/json",
  path: "ai/store-quiz-answers",
  has_token: true,
};
