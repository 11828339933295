import React from "react";

import FormText from "../../../components/FormText";

import mapImg from "../../../assets/images/services/map.png";
import homeIcon from "../../../assets/svgs/home.svg";
import gymIcon from "../../../assets/svgs/gym.svg";
import equipmentIcon from "../../../assets/svgs/equipment.svg";
import paymentIcon from "../../../assets/svgs/payment.svg";
import parkIcon from "../../../assets/svgs/park.svg";
import additionIcon from "../../../assets/svgs/addition.svg";

const GymInfo = ({ data, overview }) => {
  const resultString = data?.payment_options?.join(", ");

  let full_address = null;
  if (overview?.address) {
    full_address = `${overview?.address?.address_line1 ?? "No Address"}, ${
      overview?.address?.city ?? "No City"
    }, ${overview?.address?.state ?? "No State"}, ${
      overview?.address?.country ?? ""
    }, ${overview?.address?.postcode ?? "No Postal Code"}`;
  }

  return (
    <div className="md:col-span-2 flex flex-col pt-8 pb-8">
      <FormText title="Additional Information" type="itemtitle-sub" />
      <div className="flex flex-col border rounded-xl mt-4 h-full">
        <div className="flex flex-col p-5 pb-3 border border-x-0 border-t-0 border-b">
          {full_address && (
            <div className="flex items-center pb-1">
              <FormText
                title={full_address}
                type="subtitle-based"
                customClass="!font-bold"
              />
            </div>
          )}
        </div>
        <div className="px-5 py-2">
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={homeIcon} alt="icon" width={24} />
              <FormText
                title="Neighborhood"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={data?.neighborhood}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={gymIcon} alt="icon" width={24} />
              <FormText
                title="Amenities"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title="Classes, Yoga space, SPA"
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={equipmentIcon} alt="icon" />
              <FormText
                title="Equipment"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={"Treadmills, All other tools"}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={paymentIcon} alt="icon" />
              <FormText
                title="Payment options"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={resultString}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={parkIcon} alt="icon" />
              <FormText
                title="Parking details"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={data?.parking_details}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={additionIcon} alt="icon" />
              <FormText
                title="Additional"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={"Gym, Free Trial"}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GymInfo;
