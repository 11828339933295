import React, { useEffect } from "react";
import "./index.css";
import Ring from "../../assets/images/retail/ring-1.png";
import { arrowRightIcon, shopLeft, shopRight, viewIcon } from "./icons";
import ProductItems from "./retail-shop/product-items";
import { categoryData, dummyData } from "../cart/checkout/data";
import DealsOfMonth from "./retail-shop/deals-month";
import FreeShipping from "../../components/white-label-components/free-shipping";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";
import { NotificationManager } from "react-notifications";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WhiteLabelService from "../../services/whiteLabelService";
import { SET_VENUE_DATA } from "../../redux/actions/types";
import { useDispatch } from "react-redux";
import SocialMedia from "./retail-shop/social-media";
import RetailFooter from "./footer";

const VenueRetailHome = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const { app_key: retail_id } = useParams();
  const [email, setEmail] = useState("");

  useEffect(() => {
    getDetails();
  }, [retail_id]);

  const getDetails = () => {
    localStorage.removeItem("venueData");
    WhiteLabelService.getDetails(retail_id).then(
      (res) => {
        if (res.allowed_only_from !== "retail") {
          navigate("/");
        } else {
          dispatch({ type: SET_VENUE_DATA, payload: res.venue });
          setDetails(res.venue);

          identifyVenue(retail_id);
          trackEvent("White Label - Retail", {
            action_category: "Page Load",
            action_outcome: "success",
            interaction_element: "screen",
            industry: "retail_management",
            source: "vb_web",
          });
        }
      },
      (error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );

        identifyVenue(retail_id);
        trackEvent("White Label - Retail", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "retail_management",
          source: "vb_web",
        });
      }
    );
  };

  return (
    <div className="venue-home mt-[5.25rem] flex flex-col pb-5 mb-10 pt-[-100px]">
      <div className="p-7 md:p-0">
        <div
          className={`flex items-center justify-between h-[190px] rounded-xl md:rounded-none md:h-[510px] large-width !px-5 md:pr-0 bg-[#131118] bg-opacity-5`}
        >
          <div className="flex flex-col gap-3 md:gap-[22px] z-10">
            <div className="flex flex-col gap-0 md:gap-[10px]">
              {/* <p></p> */}
              <div className="text-24 md:text-60 leading-9 md:leading-[82px] text-[#131118] font-bold md:font-extrabold">
                Matrix Tennis Bracelet
              </div>
              <span className="text-12 md:text-30 font-normal text-[#131118] leading-[24px] md:leading-[40px]">
                UP TO 40% OFF
              </span>
            </div>
            <button className=" py-1 px-4 md:p-[20px] rounded md:rounded-[10px] bg-[#131118] flex text-white items-center justify-center gap-[10px] text-12 md:text-16 leading-6 md:leading-[22px] h-8 md:h-14 w-[90px] md:w-[156px]">
              <span>Shop Now</span>
              <span className="hidden md:block">{arrowRightIcon}</span>
            </button>
          </div>
          <img
            src={Ring}
            alt="ring"
            className="w-[230px] h-[200px] md:w-[470px] md:h-[400px] right-4 absolute md:right-[106px]"
          />
        </div>
      </div>

      <div className={`shop-category mt-[60px] large-width !px-5`}>
        <div className="first-line !items-center justify-between content-center flex w-full mb-10 md:mb-0">
          <p className="text-20 font-semibold md:text-[32px] md:font-medium text-[#131118] flex-grow">
            Shop by Category
          </p>
          <div className="icons justify-end md:!flex items-center gap-2 col-span-1 !hidden">
            <span className="cursor-pointer">{shopLeft}</span>
            <span className="cursor-pointer">{shopRight}</span>
          </div>
          <div className="cursor-pointer !block md:!hidden text-[#848FAC] text-12 font-normal text-right flex-1 whitespace-nowrap">
            See All
          </div>
        </div>

        <ProductItems
          data={categoryData}
          className="md:grid-cols-4 overflow-x-auto  whitespace-nowrap"
          showBottom={true}
          cartProp={false}
          showDescription={false}
        />
      </div>

      <div className={`shop-category mt-[80px] large-width !px-5`}>
        <div className="first-line !items-center justify-between content-center flex w-full mb-10 md:mb-0">
          <p className="text-20 font-semibold md:text-[32px] md:font-medium text-[#131118] flex-grow">
            Our Bestseller
          </p>
          <div className="cursor-pointer text-[#131118] text-12 md:text-16 font-normal md:font-medium text-right flex-1 whitespace-nowrap">{`Check all products>`}</div>
        </div>

        <ProductItems
          data={dummyData.reverse()}
          className="md:grid-cols-4 gap-[30px] overflow-x-auto  whitespace-nowrap"
          buttonLabel="Add to Cart"
          topIcon={viewIcon}
        />
      </div>

      <DealsOfMonth />

      <div
        className={`grid grid-cols-1 md:grid-cols-2 gap-[28px] mt-[50px] large-width !px-5`}
      >
        <div
          className={`rounded-lg border-none md:border-solid border border-[#DEE2E6] md:px-10 md:py-9 flex flex-col gap-4`}
        >
          <div className=" text-[#222222] font-bold text-20 text-center md:text-left">
            SIGN UP FOR NEWS & UPDATES
          </div>
          <div className="border border-[#DEE2E6] rounded-lg pl-6 flex flex-row justify-between">
            <input
              type="email"
              className="border-none font-semibold text-14 w-full focus:border-none focus:outline-none h-[44px]"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <input
              type="button"
              style={{
                backgroundColor: "#1A1818",
                color: "white",
              }}
              className="bg-[#1A1818] cursor-pointer rounded-lg font-semibold text-14 px-5 py-3"
              value={"Subscribe"}
              // onClick={onSubscribe}
            />
          </div>
        </div>

        <div className="rounded-lg border border-[#DEE2E6] px-10 py-9 gap-10 grid grid-cols-1 lg:grid-cols-5">
          <div className="flex flex-col gap-4 lg:col-span-3">
            <div className=" text-[#222222] font-bold text-20">
              Call Us 24/7
            </div>

            <div className="border border-[#DEE2E6] rounded-lg flex flex-row justify-between bg-[#EBEBEB] items-center pl-4 gap-2">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.8235 20.9996C15.9085 20.9996 14.6232 20.6686 12.6985 19.5933C10.3581 18.2808 8.54774 17.0691 6.21993 14.7474C3.97555 12.5044 2.88337 11.0522 1.35477 8.27066C-0.372103 5.13003 -0.0777282 3.48378 0.251334 2.78019C0.643209 1.93925 1.22165 1.43628 1.9693 0.937065C2.39397 0.658832 2.84337 0.420323 3.3118 0.224565C3.35868 0.204409 3.40227 0.18519 3.44118 0.167846C3.67321 0.0633151 4.02477 -0.0946537 4.47008 0.0740963C4.76727 0.185659 5.03258 0.41394 5.4479 0.824096C6.29962 1.6641 7.46352 3.53488 7.8929 4.45363C8.18118 5.07285 8.37196 5.4816 8.37243 5.94003C8.37243 6.47675 8.10243 6.89066 7.77477 7.33738C7.71337 7.42128 7.65243 7.50144 7.59337 7.57925C7.23665 8.048 7.15837 8.18347 7.20993 8.42535C7.31446 8.91144 8.09399 10.3585 9.37508 11.6368C10.6562 12.915 12.0615 13.6453 12.5495 13.7494C12.8016 13.8033 12.9399 13.7218 13.4237 13.3524C13.4931 13.2994 13.5643 13.2446 13.6388 13.1897C14.1385 12.818 14.5332 12.555 15.0573 12.555H15.0601C15.5162 12.555 15.9066 12.7528 16.5535 13.0791C17.3973 13.5047 19.3243 14.6536 20.1695 15.5063C20.5806 15.9207 20.8098 16.185 20.9218 16.4818C21.0906 16.9285 20.9316 17.2786 20.8281 17.513C20.8107 17.5519 20.7915 17.5946 20.7713 17.6419C20.574 18.1095 20.3341 18.558 20.0546 18.9816C19.5563 19.7269 19.0515 20.3039 18.2087 20.6963C17.7759 20.901 17.3023 21.0047 16.8235 20.9996Z"
                  fill="#240B3B"
                />
              </svg>
              <input
                className="border-none font-semibold text-14 w-full focus:border-none focus:outline-none h-[44px] disabled:bg-[#EBEBEB] rounded-lg text-[#595959]"
                placeholder="Your email"
                value={"355689079797"}
                disabled
              ></input>
            </div>
          </div>

          <SocialMedia details={details} isRetail={true} />
        </div>
      </div>

      <div className="mt-[100px] large-width hidden md:block">
        <FreeShipping />
      </div>

      <div className="large-width hidden md:block">
        <RetailFooter />
      </div>
    </div>
  );
};

export default VenueRetailHome;
