import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestClub = () => {
  const { app_key } = useParams();
  const navigate = useNavigate();
  const benefits = [
    {
      id: 1,
      img: "https://bybest.shop/assets/img/discount.svg",
      title: "Promocione ekskluzive",
    },
    {
      id: 2,
      img: "https://bybest.shop/assets/img/gift.svg",
      title: "Dhurata per ditelindje",
    },
    {
      id: 3,
      img: "https://bybest.shop/assets/img/points.svg",
      title: "Mblidhni pikë",
    },
    {
      id: 4,
      img: "https://bybest.shop/assets/img/star.svg",
      title: "Novelty Sneak Preview",
    },
    {
      id: 5,
      img: "https://bybest.shop/assets/img/heart.svg",
      title: "Lista e dëshirave",
    },
  ];

  const onSubmit = () => {
    navigate(`/shop/${app_key}/cart/payment-method`);
  };

  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] uppercase ">
            BYBEST {`>`} MY CLUB
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="mt-5 w-full px-4 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6">
            <div class="w-full p-4 flex flex-col justify-center items-center">
              <img
                alt=""
                src={"https://bybest.shop/assets/img/my-club.svg"}
                className="w-[60%] object-contain"
              />
            </div>
            <div class="  ">
              <p className="text-[16px] text-[#000] leading-[24px] font-medium">
                Një vend për të gjithë ata që gëzojnë përfitime ekskluzive, që
                dizajnojnë shtëpitë e tyre sipas dëshirës dhe që duan të
                frymëzohen. Ju ftojmë të zbuloni përfitimet e Klubit tonë të
                klientëve My Club!
              </p>
              <p className=" mt-4 text-[16px] text-[#000] leading-[24px] font-medium">
                Regjistrohu falas tani dhe krijo momente dhe hapësira të
                këndshme!
              </p>
              <div className=" mt-4 w-full grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    Emri
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder="Emri"
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    Mbiemri
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder="Mbiemri"
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    Email
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    Numri i Telefonit
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder="Numri i Telefonit"
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    Ditëlindja
                  </p>
                  <input
                    type="date"
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder="Ditëlindja"
                  />
                </div>
                <div>
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    Qyteti
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder="Qyteti"
                  />
                </div>
                <div className="md:col-span-2">
                  <p className="mb-1 text-[12px] text-[#000] leading-[18px]">
                    Adresa
                  </p>
                  <input
                    className="w-full h-[36px] px-2 rounded-[5px] border border-[#ced4da]"
                    placeholder="Emri"
                  />
                </div>
              </div>
              <button
                className=" mt-4 rounded bg-[#3b3b3a]  h-[40px]  font-bold text-[14px] text-white uppercase"
                onClick={onSubmit}
              >
                Regjistrohuni Tani
              </button>
            </div>
          </div>
          <hr className="mt-7" />
          <h3 className="mt-7 text-center text-[20px] text-[#3b3b3a] font-bold">
            Përfitimet tuaja më të rëndësishme.
          </h3>
          <div className="mt-7 w-full px-4 grid grid-cols-1 md:grid-cols-5 gap-2 md:gap-6">
            {benefits.map((b) => (
              <div className="flex flex-col justify-center items-center">
                <div className="flex justify-center items-center bg-[#f6f3f1] w-[200px] rounded-[5px] p-[50px]">
                  <img src={b.img} className="w-full object-contain" alt="" />
                </div>
                <p className="mt-3 text-center text-[16px] text-[#000] leading-[24px] font-medium">
                  {b.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestClub;
