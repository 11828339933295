import React from "react";
import ByBestSidebar from "./sideBar";
import useSeoData from "../../services/hooks/use-seo";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";

const ByBestPaymentMethod = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
            BYBEST {`>`} FAQE {`>`} NA KONTATONI
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                Format e mëposhtme të pagesës pranohen nga BY BEST:
              </p>
              <h3 className="mt-8 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                PAYPAL
              </h3>
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                Duke zgjedhur mënyrën e pagesës Paypal, klienti mund të paguajë
                drejtpërdrejt përmes llogarisë së tij Paypal.
              </p>
              <h3 className="mt-8 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                PAGUANI NE DORËZIM
              </h3>
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                Pagesa me dorëzim është e disponueshme si një mënyrë pagese për
                të gjithë artikujt që përmbushen nga Best.
              </p>
              <h3 className="mt-8 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                KARTË KREDITI
              </h3>
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                Nëse fondet ekzistuese janë të mjaftueshme për blerjen dhe të
                dhënat bankare të dhëna janë të vlefshme, transaksioni do të
                përpunohet menjëherë. Karta e kreditit e përdorur do të
                tarifohet në momentin e blerjes edhe në rastin e një porosie
                paraprake edhe pse dërgesa e këtyre artikujve bëhet më vonë
                gjatë sezonit.
              </p>
              <h3 className="mt-8 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                TRANSFERTA BANKARE
              </h3>
              <p className="text-[13px] leading-[20px] text-[#000]  ">
                Duke zgjedhur pagesën me transfertë bankare, në fund të porosisë
                klienti merr automatikisht një email konfirmimi që përmban të
                dhënat bankare të BY BEST, pas së cilës mallrat lihen mënjanë në
                pritje të mbërritjes së transfertës në llogari. Klientit i
                kërkohet të dërgojë një kopje të pagesës me e-mail brenda 48
                orëve nga marrja e konfirmimit të porosisë. Nëse brenda kësaj
                periudhe BY BEST nuk merr një kopje të pagesës, ajo do ta
                anulojë porosinë. Ne do ta dërgojmë porosinë kur transferimi të
                ketë arritur në llogarinë tonë bankare dhe sapo mallrat të jenë
                të disponueshme në magazinë tonë. Kjo mënyrë pagese nuk është
                aktive për të gjitha destinacionet, ju lutemi kontrolloni
                disponueshmërinë në karrocë në momentin e blerjes
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestPaymentMethod;
