import React from "react";
import FormText from "../../../components/FormText";

const RetailHeader = ({ TagsButtonData, data }) => {
  return (
    <div className="md:bg-gray2 bg-white">
      <div className="block md:hidden h-68 w-full">
        <img src={data?.overview?.cover} alt="retail" className="" />
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-10 md:py-10 max-w-screen-main mx-auto px-0 sm:px-6 md:px-8 lg:px-16 w-full">
        <div className="flex flex-col justify-center">
          <FormText
            title={data?.overview?.venue_name}
            type="title-whitelabel"
            customClass="mt-5"
          />
          <FormText
            title={
              data?.additional_information?.description &&
              data?.additional_information?.description.length > 700
                ? `${data?.additional_information?.description.substring(
                    0,
                    697
                  )}...`
                : data?.additional_information?.description
            }
            type="subtitle-dark"
            customClass="mt-4"
            customStyle={{ wordWrap: "break-word" }}
          />

          <div
            className="hidden md:flex flex-row flew-wrap mt-4"
            style={{ overflowY: "auto", maxWidth: "100%" }}
          >
            {data?.additional_information?.main_tag && (
              <div
                className={`bg-white cursor-point px-3 md:px-4 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{data?.additional_information?.main_tag}</b>
                </span>
              </div>
            )}
            {data?.additional_information?.tags?.map((item, index) => (
              <div
                className={`bg-white cursor-point px-3 md:px-4 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{item}</b>
                </span>
              </div>
            ))}
          </div>

          <div
            className="md:hidden flex flex-row flew-wrap"
            style={{ overflowY: "auto", maxWidth: "100%" }}
          >
            {data?.additional_information?.main_tag && (
              <div
                className={`bg-white cursor-point px-3 md:px-3 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{data?.additional_information?.main_tag}</b>
                </span>
              </div>
            )}
            {data?.additional_information?.tags?.map((item, index) => (
              <div
                className={`bg-white cursor-point px-3 md:px-3 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{item}</b>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="hidden md:block h-96">
          <img
            src={data?.overview?.cover}
            alt="retail"
            className="rounded-xl w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default RetailHeader;
