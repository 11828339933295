import React from "react";
import { isEmpty } from "../../../utils/common";

const Products = ({data}) => {

  return data.map((item) => (
    <div className="menu-wrapper">
      <div className="menu-item">
        <span className="manrope-font font-bold text-14 leading-[22px] text-primary1">{item.title}</span>
        <span className="sub-description">{item.description}</span>
      </div>
      {!isEmpty(item.image_path) && (
        <img
          className="object-cover object-center rounded-full w-[60px] h-[60px] min-w-[60px] min-h-[60px]"
          src={item.image_path}
          alt="Product"
        />
      )}
    </div>
  ));
};

export default Products;
