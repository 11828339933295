import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BBLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import ImgBrand1 from "../../assets/images/bybest/brands/brand1.png";
import ImgBrand2 from "../../assets/images/bybest/brands/brand2.png";
import ImgBrand3 from "../../assets/images/bybest/brands/brand3.png";
import ImgBrand4 from "../../assets/images/bybest/brands/brand4.png";
import ImgBrand5 from "../../assets/images/bybest/brands/brand5.png";
import ImgBrand6 from "../../assets/images/bybest/brands/brand6.png";
import ImgBrand7 from "../../assets/images/bybest/brands/brand7.png";
import ImgBrand8 from "../../assets/images/bybest/brands/brand8.png";
import ImgBrand9 from "../../assets/images/bybest/brands/brand9.png";
import BybestHomeCarousel from "../../components/BybestHomeCarousel";
import { home_products } from "./data";
import BybestProductItem from "../../components/BybestProductItem";
import BybestHomeNotiModal from "../../components/BybestHomeNotiModal";
import useSeoData from "../../services/hooks/use-seo";

const brands = [
  ImgBrand1,
  ImgBrand2,
  ImgBrand3,
  ImgBrand4,
  ImgBrand5,
  ImgBrand6,
  ImgBrand7,
  ImgBrand8,
  ImgBrand9,
];

const ByBestHome = () => {
  const { app_key } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, [30 * 1000]);
  }, []);

  const onGoProductDetails = (p) => {
    navigate(`/shop/${app_key}/products/${p.id}/akeasf`);
  };

  const { pageSEO } = useSeoData();

  return (
    <BBLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#3B3B3A] py-4 px-4">
          <div
            className={`w-full max-w-screen-main flex justify-between items-center`}
          >
            {brands.map((b, index) => (
              <img
                alt=""
                key={index}
                src={b}
                className="max-h-[19px] md:max-h-[32px] w-[10%] md:w-auto object-contain"
              />
            ))}
          </div>
        </div>
        <div className={`w-full max-w-screen-main md:mt-7 mb-5`}>
          <BybestHomeCarousel />
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-8 px-5 md:px-0">
            {home_products.map((p, index) => (
              <React.Fragment key={index}>
                <div>
                  <img
                    alt=""
                    src={p.cat_img}
                    className="w-full h-[120px] md:h-full object-cover"
                  />
                </div>
                <div className="col-span-3 grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-8">
                  {p.products.map((pp, i) => (
                    <BybestProductItem
                      key={i}
                      data={pp}
                      type={"home"}
                      onClick={() => onGoProductDetails(pp)}
                    />
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        {showModal && (
          <BybestHomeNotiModal
            showModal={showModal}
            onClose={() => {
              setShowModal(false);
            }}
          />
        )}
      </div>
    </BBLayout>
  );
};

export default ByBestHome;
