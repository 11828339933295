import React from "react";
import { useSelector } from "react-redux";
import "../index.css";

const Photos = () => {
  const venueData = useSelector((state) => state.venue);
  return (
    <div className="mb-10 p-1 my-2 flex flex-col gap-2">
      <span className="description-title">{venueData?.photos?.length} Photos</span>
      <div className="grid grid-cols-3 gap-2">
        {venueData?.photos?.map((item, index) => (
          <img
            key={index}
            className=" object-cover object-center w-full h-[112px] rounded-xl"
            src={item.photo_path}
            alt="Gallary"
          />
        ))}
      </div>
    </div>
  );
};

export default Photos;
