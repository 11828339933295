import { useDispatch, useSelector } from "react-redux";
import { dummyData } from "../../cart/checkout/data";
import { arrowDown, barIcon, filterIcon, viewIcon, X12 } from "../icons";
import ProductItems from "./product-items";
import { useMemo, useState } from "react";
import { SET_SEARCH } from "../../../redux/actions/types";
import "../index.css";
import { FilterLayoutMobile } from "./filter-layout-mobile";

const ProductLayout = () => {
  const dispatch = useDispatch();
  const searchSelector = useSelector((state) => state.search);

  const data = useMemo(() => {
    if (searchSelector.length > 0) {
      return dummyData.filter((item, index) => index <= 1);
    }
    return dummyData.filter((item, index) => index <= 5);
  }, [searchSelector]);

  const [ display, setDisplay ] = useState(1);

  return (
    <div className="md:col-span-4 col-span-5 flex flex-col gap-y-4 py-6 md:py-0">
      <div className="justify-between hidden md:flex">
        <div className="flex items-center gap-[20px]">
          {barIcon}
          {filterIcon}
          <div className="flex items-center gap-1">
            <span className="filter-title">Showing 1–16 of 72 results</span>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <label className="filter-title">Show by latest</label>
          <span>{arrowDown}</span>
        </div>
      </div>

      <div className="flex lg:hidden justify-between h-[50px] items-center">
        <div className="text-[#131118] text-20 font-semibold leading-7">All products</div>
        <div className="flex gap-3 items-center">
            <div className="flex p-1 rounded-[20px] border-[#DFE8F6] border">
              <a className="" onClick={() => setDisplay(1)}>
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.655518" width="30" height="30" rx="15" fill={`${display === 1 && "#131118"}`} />
                  <g opacity={`${display === 1 ? '1': '0.6'}`}>
                    <path d="M19.5356 14.2748V14.2748H19.5325H17.1758C16.7493 14.2748 16.5787 14.1952 16.509 14.1335C16.4549 14.0856 16.375 13.9706 16.375 13.6198V10.9773C16.375 10.6304 16.4545 10.5142 16.5097 10.4651C16.58 10.4026 16.7511 10.3223 17.1758 10.3223H19.5325C19.9572 10.3223 20.1283 10.4026 20.1986 10.4651C20.2539 10.5142 20.3333 10.6304 20.3333 10.9773V13.6256C20.3333 13.9729 20.2537 14.0883 20.1994 14.1364C20.1301 14.1977 19.9601 14.2774 19.5356 14.2748Z" fill={`${display === 1 ? "white": "#848FAC"}`} stroke={`${display === 1 ? "white": "#848FAC"}`}/>
                    <path d="M20.3333 17.8314V20.188C20.3333 20.6017 20.2471 20.7627 20.1772 20.8327C20.1072 20.9026 19.9462 20.9889 19.5325 20.9889H17.1758C16.7622 20.9889 16.6011 20.9026 16.5312 20.8327C16.4612 20.7627 16.375 20.6017 16.375 20.188V17.8314C16.375 17.4177 16.4612 17.2566 16.5312 17.1867C16.6011 17.1167 16.7622 17.0305 17.1758 17.0305H19.5325C19.9462 17.0305 20.1072 17.1167 20.1772 17.1867C20.2471 17.2566 20.3333 17.4177 20.3333 17.8314Z" fill={`${display === 1 ? "white": "#848FAC"}`} stroke={`${display === 1 ? "white": "#848FAC"}`}/>
                    <path d="M12.8267 14.2748V14.2748H12.8235H10.4668C10.0404 14.2748 9.86975 14.1952 9.79999 14.1335C9.74596 14.0856 9.66602 13.9706 9.66602 13.6198V10.9773C9.66602 10.6304 9.74547 10.5142 9.80072 10.4651C9.87104 10.4026 10.0421 10.3223 10.4668 10.3223H12.8235C13.2482 10.3223 13.4193 10.4026 13.4896 10.4651C13.5449 10.5142 13.6243 10.6304 13.6243 10.9773V13.6256C13.6243 13.9729 13.5447 14.0883 13.4904 14.1364C13.4211 14.1977 13.2511 14.2774 12.8267 14.2748Z" fill={`${display === 1 ? "white": "#848FAC"}`} stroke={`${display === 1 ? "white": "#848FAC"}`}/>
                    <path d="M14.1243 20.188V17.8314C14.1243 16.9039 13.751 16.5305 12.8235 16.5305H10.4668C9.53935 16.5305 9.16602 16.9039 9.16602 17.8314V20.188C9.16602 21.1155 9.53935 21.4889 10.4668 21.4889H12.8235C13.751 21.4889 14.1243 21.1155 14.1243 20.188Z" fill={`${display === 1 ? "white": "#848FAC"}`} />
                  </g>
                </svg>
              </a>

              <a onClick={() => setDisplay(2)}>
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.655518" width="30" height="30" rx="15" fill={`${display === 2 && "#131118"}`} />
                  <g opacity={`${display === 2 ? '1': '0.6'}`}>
                    <path d="M10.391 17.0305H19.6077C19.9886 17.0305 20.1259 17.1138 20.1841 17.1757C20.2496 17.2453 20.3327 17.4111 20.3327 17.8314V20.188C20.3327 20.6082 20.2496 20.774 20.1841 20.8436C20.1259 20.9056 19.9886 20.9889 19.6077 20.9889H10.391C10.0101 20.9889 9.87284 20.9056 9.81457 20.8436C9.74908 20.774 9.66602 20.6082 9.66602 20.188V17.8314C9.66602 17.4111 9.74908 17.2453 9.81457 17.1757C9.87284 17.1138 10.0101 17.0305 10.391 17.0305Z" fill={`${display === 2 ? "white": "#848FAC"}`} stroke={`${display === 2 ? "white": "#848FAC"}`}/>
                    <path d="M10.391 10.3223H19.6077C19.9886 10.3223 20.1259 10.4055 20.1841 10.4675C20.2496 10.5371 20.3327 10.7029 20.3327 11.1231V13.4798C20.3327 13.9 20.2496 14.0658 20.1841 14.1354C20.1259 14.1973 19.9886 14.2806 19.6077 14.2806H10.391C10.0101 14.2806 9.87284 14.1973 9.81457 14.1354C9.74908 14.0658 9.66602 13.9 9.66602 13.4798V11.1231C9.66602 10.7029 9.74908 10.5371 9.81457 10.4675C9.87284 10.4055 10.0101 10.3223 10.391 10.3223Z" fill={`${display === 2 ? "white": "#848FAC"}`} stroke={`${display === 2 ? "white": "#848FAC"}`}/>
                  </g>
                </svg>
              </a>
            </div>

            <FilterLayoutMobile />
        </div>
      </div>

      {searchSelector.length > 0 && (
        <div className="search-badge w-fit">
          <span>{searchSelector}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              dispatch({
                type: SET_SEARCH,
                payload: "",
              });
            }}
          >
            {X12}
          </span>
        </div>
      )}

      <ProductItems
        topIcon={viewIcon}
        showPagination={true}
        data={data}
        buttonLabel="Add to Cart"
        className="md:grid-cols-3 flex justify-between flex-wrap gap-y-9"
      />
    </div>
  );
};

export default ProductLayout;
