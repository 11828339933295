import React from 'react';
import BBHead from './BBHead';
import BBBodyStart from './BBBodyStart';
import BBBodyEnd from './BBBodyEnd';

const BBMainLayout = ({ children, clientKey = 'bybest', seo = {} }) => {
    return (
        <>
            <BBHead clientKey={clientKey} seo={seo} />
            <body>
            <BBBodyStart clientKey={clientKey} />
            {children}
            <BBBodyEnd clientKey={clientKey} />
            </body>
        </>
    );
};

export default BBMainLayout;