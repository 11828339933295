import BlogImg1 from "../../assets/images/blog/vb-blog.webp";
import BlogImg2 from "../../assets/images/blog/vb-blog-2.webp";
import BlogImg4 from "../../assets/images/blog/charting-success-exploring-venueboost-s-cost-free-journey.webp";
import BlogImg5 from "../../assets/images/blog/join-our-exclusive-soft-launch-and-be-part-of-the-community.webp";
import BlogImg62 from "../../assets/images/blog/blog-6-january-3.webp";
import BlogImg7 from "../../assets/images/blog/blog7-primary.webp";
import BlogImg8 from "../../assets/images/blog/blog8-primary.webp";
import BlogImg9 from "../../assets/images/blog/blog-9.webp";
import BlogImg10 from "../../assets/images/blog/blog-10.webp";
import BlogImg11 from "../../assets/images/blog/blog-11.webp";
import BlogImg12 from "../../assets/images/blog/blog-12.webp";
import BlogImg13 from "../../assets/images/blog/blog-13.webp";
import BlogImg14 from "../../assets/images/blog/blog-14.webp";
import BlogImg15 from "../../assets/images/blog/blog-15.webp";
import BlogImg16 from "../../assets/images/blog/blog-16.webp";
import BlogImg17 from "../../assets/images/blog/blog-17.webp";
import BlogImg18 from "../../assets/images/blog/blog-18.webp";
import BlogImg19 from "../../assets/images/blog/blog-19.webp";
import BlogImg20 from "../../assets/images/blog/blog-20.webp";
import BlogImg21 from "../../assets/images/blog/blog-21.webp";
import BlogImg22 from "../../assets/images/blog/blog-22.webp";
import BlogImg23 from "../../assets/images/blog/blog-23.webp";
import BlogImg24 from "../../assets/images/blog/blog-24.webp";
import BlogImg25 from "../../assets/images/blog/blog-25.webp";
import BlogImg26 from "../../assets/images/blog/blog-26.webp";
import BlogImg27 from "../../assets/images/blog/blog-27.webp";
import BlogImg28 from "../../assets/images/blog/blog-28.webp";
import BlogImg29 from "../../assets/images/blog/blog-29.webp";
import BlogImg30 from "../../assets/images/blog/blog-30.webp";
import BlogImg31 from "../../assets/images/blog/blog-31.webp";
import BlogImg32 from "../../assets/images/blog/blog-32.webp";
import BlogImg33 from "../../assets/images/blog/blog-33.webp";
import BlogImg34 from "../../assets/images/blog/blog-34.webp";
import BlogImg35 from "../../assets/images/blog/blog-35.webp";
import BlogImg36 from "../../assets/images/blog/blog-36.webp";
import BlogImg37 from "../../assets/images/blog/blog-37.webp";
import BlogImg38 from "../../assets/images/blog/blog-38.webp";
import BlogImg39 from "../../assets/images/blog/blog-39.webp";
import BlogImg40 from "../../assets/images/blog/blog-40.webp";

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 2050 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 2050, min: 1240 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1240, min: 768 },
    items: 1,
  },
  mobile1: {
    breakpoint: { max: 768, min: 425 },
    items: 1,
  },
  mobile2: {
    breakpoint: { max: 425, min: 0 },
    items: 1,
  },
};

export const tags = [
  // { value: 'All', label: 'All', title: "All", url: "#" },
  {
    value: "News",
    label: "News and trends",
    title: "News and trends",
    url: "#",
  },
  {
    value: "Venue",
    label: "Venue management",
    title: "Venue management",
    url: "#",
  },
  {
    value: "Pro",
    label: "Pro Tips and Best Practices",
    title: "Pro Tips and Best Practices",
    url: "#",
  },
  {
    value: "Special",
    label: "Special Announcements",
    title: "Special Announcements",
    url: "#",
  },
  {
    value: "Feature",
    label: "Feature Showcase",
    title: "Feature Showcase",
    url: "#",
  },
  {
    value: "Industry",
    label: "Industry insights",
    title: "Industry insights",
    url: "#",
  },
];

export const blogData = [
  {
    id: 1,
    title:
      "VenueBoost: Simplify, Optimize, and Grow Your Venue Management Efforts",
    category: "News and trends",
    slug: "venueboost-simplify-optimize-grow-your-venue-management-efforts",
    date: "December 22, 2023",
    image: BlogImg1,
  },
  {
    id: 2,
    title:
      "Revolutionizing Venue Management by Overcoming the Staff Scheduling Challenge",
    category: "Venue management",
    slug: "revolutionizing-venue-management-overcoming-staff-scheduling",
    date: "December 26, 2023",
    image: BlogImg2,
  },
  // {
  //   title: "Untangling the Knots: A Dive into the Challenges Faced by Venue Owners and Managers",
  //   category: "Venue management",
  //   slug: "untangling-the-knots-venue-owners-managers-challenges",
  //   date: "December 30, 2023",
  //   image: BlogImg2
  // },
  {
    id: 3,
    title: "Charting Success: Exploring VenueBoost's Cost-Free Journey",
    category: "Pro Tips and Best Practices",
    slug: "charting-success-exploring-venueboost-s-cost-free-journey",
    date: "January 2, 2024",
    image: BlogImg4,
  },
  {
    id: 4,
    title: "Join our exclusive soft launch and be part of the community",
    category: "Special Announcements",
    slug: "join-our-exclusive-soft-launch-and-be-part-of-the-community",
    date: "January 5, 2024",
    image: BlogImg5,
  },
  {
    id: 5,
    title:
      "Crafting Unforgettable Experiences: How VenueBoost Elevates Customer Engagement",
    category: "Feature Showcase",
    slug: "crafting-unforgettable-experiences-how-venueboost-elevates-customer-engagement",
    date: "January 6, 2024",
    image: BlogImg62,
  },
  {
    id: 6,
    title: "VenueBoost Mastery: Enhancing Your Accommodation Business",
    category: "Feature Showcase",
    slug: "venueboost-mastery-enhancing-accommodation-business",
    date: "January 10, 2024",
    image: BlogImg7,
  },
  {
    id: 7,
    title:
      "Confronting the Dread of Low Stock: Understanding the Retailer's Biggest Challenge",
    category: "Feature Showcase",
    slug: "confronting-dread-low-stock-retailers-challenge",
    date: "January 13, 2024",
    image: BlogImg8,
  },
  {
    id: 8,
    title: "Solving the Puzzle of Entertainment Venue Scheduling and Booking",
    category: "Feature Showcase",
    slug: "solving-the-puzzle-of-entertainment-venue-scheduling-and-booking",
    date: "January 15, 2024",
    image: BlogImg9,
  },
  {
    id: 9,
    title: "Top 10 Strategies to Eliminate Waiting Times for Hotel Rooms",
    category: "Industry insights",
    slug: "top-10-strategies-eliminate-hotel-waiting-times",
    date: "January 17, 2024",
    image: BlogImg10,
  },
  {
    id: 10,
    title: "The Dreaded POS System Crash – A Familiar Tale?\n",
    category: "Feature Showcase",
    slug: "the-dreaded-pos-system-crash-a-familiar-tale",
    date: "January 19, 2024",
    image: BlogImg11,
  },
  {
    id: 11,
    title: "Multi-Venue Management: Mastering Challenges with VenueBoost\n",
    category: "Industry insights\n",
    slug: "multi-venue-management-mastering-challenges-with-venueboost",
    date: "January 21, 2024",
    image: BlogImg12,
  },
  {
    id: 12,
    title: "Menu Management: Pro Tips for Elevating Your Restaurant Business",
    category: "Feature Showcase\n",
    slug: "menu-management-pro-tips-elevating-your-restaurant-business",
    date: "January 23, 2024",
    image: BlogImg13,
  },
  {
    id: 13,
    title: "The Importance of Personalized Marketing and How to Get It Right\n",
    category: "Industry insights\n",
    slug: "importance-of-personalized-marketing-and-how-to-get-it-right",
    date: "January 26, 2024",
    image: BlogImg14,
  },
  {
    id: 14,
    title:
      "Leveraging Technology\n" +
      "to Minimize Waste in the Hospitality Industry\n",
    category: "Pro Tips and Best Practices\n",
    slug: "leveraging-technology-to-minimize-waste-in-the-hospitality-industry",
    date: "January 30, 2024",
    image: BlogImg15,
  },
  {
    id: 15,
    title: "5 Essential Tips for Small Business Owners to Grow Their Business",
    category: "Pro Tips and Best Practices\n",
    slug: "5-essential-tips-small-business-owners-grow-their-business",
    date: "February 3, 2024",
    image: BlogImg16,
  },
  {
    id: 16,
    title: "The Ultimate Hospitality Guide to Valentine’s Day",
    category: "Pro Tips and Best Practices\n",
    slug: "ultimate-hospitality-guide-valentines-day-venueboost",
    date: "February 6, 2024",
    image: BlogImg17,
  },
  {
    id: 17,
    title: "What Is Order Management — And Why Do You Need It",
    category: "Industry Insights\n",
    slug: "what-order-management-why-do-you-need",
    date: "February 20, 2024",
    image: BlogImg18,
  },
  {
    id: 18,
    title:
      "Starting a New Business? Simplify\n" +
      "Bookkeeping with VenueBoost and\n" +
      "QuickBooks Online",
    category: "Feature Showcase",
    slug: "venueboost-quickbooks-online-bookkeeping",
    date: "February 23, 2024",
    image: BlogImg19,
  },

  {
    id: 19,
    title: "How To Choose The Best Theatre Management Software",
    category: "Pro Tips and Best Practices",
    slug: "how-choose-best-theatre-management-software",
    date: "February 27, 2024",
    image: BlogImg20,
  },
  {
    id: 20,
    title: "Tips To Run a Successful Food Truck Business",
    category: "Pro Tips and Best Practices",
    slug: "tips-run-successful-food-truck-business",
    date: "March 1, 2024",
    image: BlogImg21,
  },
  {
    id: 21,
    title: "Dodging Retail Profit Disasters: The Missteps That Sink Businesses",
    category: "Industry insights",
    slug: "dodging-retail-profit-disasters-missteps-sink-businesses",
    date: "March 3, 2024",
    image: BlogImg22,
  },
  {
    id: 22,
    title: "Local Tourism and Your Business: Building a Community Connection",
    category: "Industry insights",
    slug: "local-tourism-and-your-business-building-a-community-connection",
    date: "March 5, 2024",
    image: BlogImg23,
  },
  {
    id: 23,
    title:
      "Sustainability in the Food Industry: A Guide to Eco-Friendly Practices",
    category: "Pro Tips and Best Practices",
    slug: "sustainability-food-industry-guide-eco-friendly-practices",
    date: "March 9, 2024",
    image: BlogImg24,
  },
  {
    id: 24,
    title:
      "The Emotional Journey of Running a Small Business: Staying Motivated",
    category: "Pro Tips and Best Practices",
    slug: "emotional-journey-running-small-business-staying-motivated",
    date: "March 12, 2024",
    image: BlogImg25,
  },
  {
    id: 25,
    title: "VenueBoost vs. Toast: Choosing the Right Venue Management Platform",
    category: "Venue Management",
    slug: "venueboost-vs-toast-choosing-right-venue-management-platform",
    date: "March 17, 2024",
    image: BlogImg26,
  },
  {
    id: 26,
    title:
      "Discover the Power of Personalization with VenueBoost's White Label Solution!",
    category: "Feature Showcase\n",
    slug: "discover-power-personalization-venueboosts-white-label-solution",
    date: "March 21, 2024",
    image: BlogImg27,
  },
  {
    id: 27,
    title: "5 essential tips for improving your event management skills",
    category: "Pro Tips and Best Practices",
    slug: "5-essential-tips-improving-event-management-skills",
    date: "March 24, 2024",
    image: BlogImg28,
  },
  {
    id: 28,
    title: "The 6 Common Problems That Can Hurt Your Bowling Alley Business",
    category: "Pro Tips and Best Practices",
    slug: "6-common-problems-can-hurt-your-bowling-alley-business",
    date: "March 27, 2024",
    image: BlogImg29,
  },
  {
    id: 29,
    title:
      "10 Essential Features Every Food & Beverage Venue Needs (And How VenueBoost Delivers\n" +
      "Them All)",
    category: "Pro Tips and Best Practices",
    slug: "10-essential-features-food-beverage-venue-needs-venueboost-delivers",
    date: "April 8, 2024",
    image: BlogImg30,
  },
  {
    id: 30,
    title:
      "Expanded Guide to Marketing Automation for Retail Outlets with VenueBoost",
    category: "Pro Tips and Best Practices",
    slug: "marketing-automation-retail-outlets-venueboost-guide",
    date: "April 14, 2024",
    image: BlogImg31,
  },
  {
    id: 31,
    title: "5 Strategic Approaches to Boost Your Restaurant's Online Presence",
    category: "Pro Tips and Best Practices",
    slug: "5-strategic-approaches-boost-restaurant-online-presence",
    date: "April 17, 2024",
    image: BlogImg32,
  },
  {
    id: 32,
    title: "The Future of Accommodation: Emerging\n" + "Trends for 2024",
    category: "Pro Tips and Best Practices",
    slug: "future-accommodation-emerging-trends-2024",
    date: "April 28, 2024",
    image: BlogImg33,
  },
  {
    id: 33,
    title:
      "Revolutionizing Menu Management with the VenueBoost AI Assistant\n\n" +
      "\n",
    category: "Pro Tips and Best Practices",
    slug: "revolutionizing-menu-management-with-ai-assistant-venueboost",
    date: "May 2, 2024",
    image: BlogImg34,
  },
  {
    id: 34,
    title: "How AI is Changing the Way We Make Content" + "\n",
    category: "Pro Tips and Best Practices",
    slug: "how-ai-changing-make-content",
    date: "May 7, 2024",
    image: BlogImg35,
  },
  {
    id: 35,
    title:
      "5 Tips for Creating Engaging Product Descriptions That Boost Sales" +
      "\n",
    category: "Pro Tips and Best Practices",
    slug: "5-tips-for-creating-engaging-product-descriptions",
    date: "May 17, 2024",
    image: BlogImg36,
  },
  {
    id: 36,
    title: "The Importance of Quality Content in Modern Marketing" + "\n",
    category: "Pro Tips and Best Practices",
    slug: "the-importance-of-quality-content-in-modern-marketing",
    date: "May 23, 2024",
    image: BlogImg37,
  },
  {
    id: 37,
    title: "10 Time-Saving Tools Every\n" + "Business Should Be Using" + "\n",
    category: "Pro Tips and Best Practices",
    slug:
      "10-time-saving-tools-every-business-should-be-using\n" +
      "\n" +
      "\n" +
      "\n" +
      "\n" +
      "\n",
    date: "May 29, 2024",
    image: BlogImg38,
  },
  {
    id: 38,
    title: "Ignite Your Venue's Promotion Strategy with\n" +
        "VenueBoost AI" + "\n",
    category: "Pro Tips and Best Practices",
    slug:
        "ignite-your-venue-promotion-strategy-with-venueboost-ai\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n",
    date: "June 6, 2024",
    image: BlogImg39,
  },
  {
    id: 39,
    title: "Embracing the Future: QR Code Payments Transforming Retail and Hospitality" + "\n",
    category: "Pro Tips and Best Practices",
    slug:
        "embracing-the-future-qr-code-payments-transforming-retail-and-hospitality\\" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n",
    date: "June 6, 2024",
    image: BlogImg40,
  },
].sort((a, b) => b.id - a.id);
