import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, logoutAffiliate } from "../../redux/actions/auth";
import { Icon } from "react-icons-kit";
import { user_circle } from "react-icons-kit/ikons/user_circle";
import { chevronDown } from "react-icons-kit/feather/chevronDown";
import { chevronUp } from "react-icons-kit/feather/chevronUp";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import NewMenuFeatures from "../../assets/svgs/new-menu-features.svg";
import NewMenuBlog from "../../assets/svgs/new-menu-blog.svg";
import NewMenuCaseStudies from "../../assets/svgs/new-menu-case-studies.svg";
import NewMenuWhyUs from "../../assets/svgs/new-menu-why.svg";
import NewMenuAbout from "../../assets/svgs/new-menu-about.svg";
import FoodIcon from "../../assets/svgs/food.svg";
import SportIcon from "../../assets/svgs/sport.svg";
import AccommodationIcon from "../../assets/svgs/accommodation.svg";
import RetailIcon from "../../assets/svgs/retail-2.svg";
import OverviewIcon from "../../assets/svgs/food-overview-1.svg";
import FoodRestaurant from "../../assets/svgs/serve_rest.svg";
import FoodCateringServices from "../../assets/svgs/serve_caterer.svg";
import NewMenuCoffeShops from "../../assets/svgs/serve_coffee_shops.svg";
import FoodJuiceBars from "../../assets/svgs/serve_juice_bars.svg";
import FoodFoodHalls from "../../assets/svgs/serve_food_halls.svg";
import FoodPopUpRestaurants from "../../assets/svgs/serve_pop_up_dining.svg";
import FoodFoodStalls from "../../assets/svgs/serve_food_stalls.svg";
import FoodBistro from "../../assets/svgs/serve_bistro.svg";
import FoodIceCreamParlors from "../../assets/svgs/serve_Ice_cream_parlors.svg";
import FoodFastFoodJoints from "../../assets/svgs/serve_fast_food.svg";
import NewMenuFoodCourts from "../../assets/svgs/serve_food_courts.svg";
import FoodCafe from "../../assets/svgs/serve_cafe.svg";
import FoodBakeries from "../../assets/svgs/serve_bakeries.svg";
import FoodTruck from "../../assets/svgs/serve_truck.svg";
import FoodFineDining from "../../assets/svgs/serve_dining.svg";
import FoodBar from "../../assets/svgs/serve_bar.svg";
import FoodPub from "../../assets/svgs/serve_pubs.svg";
import NewMenuPizza from "../../assets/svgs/serve_pizzerias.svg";
import NewMenuDelis from "../../assets/svgs/serve_delis.svg";
import FoodSupperClubs from "../../assets/svgs/serve_supper_clubs.svg";
import FoodBuffets from "../../assets/svgs/serve_buffets.svg";

import SportAmusementParks from "../../assets/svgs/new_for_industries/serve_amusement_parks.svg";
import NewMenuCinema from "../../assets/svgs/new_for_industries/serve_cinemas.svg";
import SportConcertVenuesAndTheaters from "../../assets/svgs/new_for_industries/serve_concent.svg";
import SportArcadesAndGameCenters from "../../assets/svgs/new_for_industries/serve_game_centers.svg";
import NewMenuGolf from "../../assets/svgs/new_for_industries/serve_golf_courses.svg";
import SportSkiResorts from "../../assets/svgs/new_for_industries/serve_ski_resorts.svg";
import SportSportsComplexes from "../../assets/svgs/new_for_industries/serve_sports_arenas.svg";
import SportBowling from "../../assets/svgs/new_for_industries/serve_bowling_alleys.svg";
import SportGym from "../../assets/svgs/new_for_industries/serve_gyms_and_fitness.svg";
import NewMenuMuseum from "../../assets/svgs/new_for_industries/serve_museum.svg";
import NewMenuBeachBar from "../../assets/svgs/new_for_industries/ent_beachbar.svg";
import SportOverview from "../../assets/svgs/sport-overview.svg";
import AccommodationOverview from "../../assets/svgs/accommodation-overview.svg";
import NewMenuHotel from "../../assets/svgs/new_for_industries/accom_hotel.svg";
import AccommodationCozy from "../../assets/svgs/new_for_industries/accom_cozy.svg";
import AccommodationHostel from "../../assets/svgs/new_for_industries/accom_hostel.svg";
import AccommodationHotelchain from "../../assets/svgs/new_for_industries/accom_hotelchain.svg";
import AccommodationVacation from "../../assets/svgs/accommodation-vacation-1.svg";
import AccommodationLuxuryResorts from "../../assets/svgs/new_for_industries/accom_luxury.svg";
import AccommodationHotel from "../../assets/svgs/new_for_industries/accom_family.svg";
import NewMenuApt from "../../assets/svgs/new_for_industries/accom_service.svg";
import NewMenuBedAndBreakfast from "../../assets/svgs/new_for_industries/accom_bed.svg";
import NewMenuMotel from "../../assets/svgs/new_for_industries/accom_motel.svg";
import NewMenuCapsuleHotels from "../../assets/svgs/new_for_industries/accom_capsule.svg";
import NewMenuHostel from "../../assets/svgs/new_for_industries/accom_youth.svg";
import NewMenuCampGround from "../../assets/svgs/new_for_industries/accom_campground.svg";
import NewMenuRVParks from "../../assets/svgs/new_for_industries/accom_rv.svg";
import RetailOverview from "../../assets/svgs/retail-overview.svg";
import RetailSport from "../../assets/svgs/new_for_industries/retail_sport.svg";
import RetailHome from "../../assets/svgs/new_for_industries/retail_home.svg";
import RetailFashion from "../../assets/svgs/new_for_industries/retail_fashion.svg";
import RetailRetailChains from "../../assets/svgs/new_for_industries/retail_chain.svg";
import RetailBoutiquesAndSpecialtyStores from "../../assets/svgs/new_for_industries/retail_specialty.svg";
import RetailOnlineRetailers from "../../assets/svgs/new_for_industries/retail_online.svg";
import RetailGroceryStores from "../../assets/svgs/new_for_industries/retail_grocery.svg";
import RetailElectronicsStores from "../../assets/svgs/new_for_industries/retail_electron.svg";
import RetailPharmacies from "../../assets/svgs/new_for_industries/retail_pharmacy.svg";
import RetailAutoPartsStores from "../../assets/svgs/new_for_industries/retail_auto.svg";
import RetailLiquorStores from "../../assets/svgs/new_for_industries/retail_liquor.svg";
import RetailPetSupplyStores from "../../assets/svgs/new_for_industries/retail_pet.svg";
import DropdownMenu from "../../components/DropdownMenu";
import DropdownMenuMobile from "../../components/DropdownMenuMobile";
import DropdownMenuMobile2 from "../../components/DropdownMenuMobile2";
import LangSelectorMobile from "../../components/LangSelectorMobile";
import LangSelector from "../../components/LangSelector";
import DropdownsNewMenuMobile from "../../components/DropdownsNewMenuMobile";
import { useTranslation } from "react-i18next";

import Avatar from "../../assets/svgs/alex_r_avatar.svg";
import DashboardIcon from "../../assets/svgs/dashboard_icon.svg";
import PersonalIcon from "../../assets/svgs/personalinfo_icon.svg";
import ReservationIcon from "../../assets/svgs/reservation_icon.svg";
import PromotionIcon from "../../assets/svgs/promotions_icon.svg";
import PaymentIcon from "../../assets/svgs/payment_icon.svg";
import WalletIcon from "../../assets/svgs/wallet_icon.svg";
import SecurityIcon from "../../assets/svgs/security_icon.svg";
import LogoutIcon from "../../assets/svgs/logout_icon.svg";
import GetStartedIcon from "../../assets/svgs/resources/getstarted.svg";
import GuidesIcon from "../../assets/svgs/resources/guides.svg";
import PracticeIcon from "../../assets/svgs/resources/practice.svg";
import TutorialsIcon from "../../assets/svgs/resources/tutorials.svg";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import RouteNames from "../../constants/route_names";
import { MdShoppingCart } from "react-icons/md";
import { getStorageKey, KEYS } from "../../utils/storage";
import Logo from "../../assets/svgs/logo-png-header.png";
import HubFaq from "../../assets/svgs/hub_faq.svg";

const Header = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPromotionOpen, setIsPromotionOpen] = useState(false);
  const [affiliatesHeader, setAffiliatesHeader] = useState(false);
  const { products } = useSelector((state) => state.cart);

  useEffect(() => {
    pathname === "/affiliates/dashboard"
      ? setAffiliatesHeader(true)
      : setAffiliatesHeader(false);
  }, [pathname]);

  // Determine if the current route is the pricing route
  const isPricingRoute = pathname === "/pricing-plans";

  const isVenue =
    pathname.startsWith("/venue") || pathname.startsWith("/retail");

  const hideHeader =
    pathname.includes("/beach-bar-order") ||
    pathname.includes("/venue/restaurant");

  const onLogoutAffilate = () => {
    dispatch(logoutAffiliate())
      .then((data) => {
        navigate(RouteNames.affiliates_login);
        NotificationManager.success(
          data?.message || "Logout Success",
          "Success",
          5000
        );
      })
      .catch((error) => {
        NotificationManager.error(
          error.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error("Error with logout affiliate");
      });
  };

  const onLogout = () => {
    dispatch(logout())
      .then((data) => {
        NotificationManager.success(
          data?.message || "Logout Success",
          "Success",
          5000
        );
        localStorage.removeItem("vbToken");
      })
      .catch((error) => {
        NotificationManager.error(
          error.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error("Error with logout");
      });
  };

  const resource_menus = [
    {
      label: t("header.resourceMenu.get_started_label"),
      url: "/resources/getting-started",
      icon: GetStartedIcon,
      subtitle: t("header.resourceMenu.get_started_subtitle"),
    },
    {
      label: t("header.resourceMenu.feature_tutorial_label"),
      url: "/resources/feature-tutorials",
      icon: TutorialsIcon,
      subtitle: t("header.resourceMenu.feature_tutorial_subtitle"),
    },
    {
      label: t("header.resourceMenu.best_practice_label"),
      url: "/resources/best-practices",
      icon: PracticeIcon,
      subtitle: t("header.resourceMenu.best_practice_subtitle"),
    },
    {
      label: t("header.resourceMenu.case_studies_label"),
      url: "/resources/success-stories",
      icon: NewMenuCaseStudies,
      subtitle: t("header.resourceMenu.case_studies_subtitle"),
    },
    {
      label: t("header.resourceMenu.guide_label"),
      url: "/resources/guides-and-templates",
      icon: GuidesIcon,
      subtitle: t("header.resourceMenu.guide_subtitle"),
    },

    {
      label: t("header.resourceMenu.blog_label"),
      url: "/blog",
      icon: NewMenuBlog,
      subtitle: t("header.resourceMenu.blog_subtitle"),
    },
    {
      label: t("header.resourceMenu.faq_label"),
      url: "/resources/faqs",
      icon: HubFaq,
      subtitle: t("header.resourceMenu.faq_subtitle"),
    },
  ];

  const why_venueboost_menu = [
    {
      label: t("header.whyVenueBoost.label"),
      url: "/why-venueboost",
      subtitle: t("header.whyVenueBoost.subtitle"),
      icon: NewMenuWhyUs,
    },
    {
      label: t("header.aboutUs.label"),
      url: "/about",
      subtitle: t("header.aboutUs.subtitle"),
      icon: NewMenuAbout,
    },
    {
      label: t("header.resourceMenu.menu_1_label"),
      url: "/features",
      icon: NewMenuFeatures,
      subtitle: t("header.resourceMenu.menu_1_subtitle"),
    },
  ];

  const whoweserve_new_menu = [
    {
      label: t("header.food.label"),
      description: t("header.food.description"),
      icon: FoodIcon,
      //hoverIcon: FoodHoverIcon,
      hoverIcon: FoodIcon,
      children: [
        {
          label: t("header.food.overview.label"),
          url: "/who-we-serve/food-and-beverage",
          // url: "#",
          subtitle: t("header.food.overview.subtitle"),
          icon: OverviewIcon,
          className: "food-overview",
        },
        {
          label: t("header.food.restaurants.label"),
          url: "/who-we-serve/food-and-beverage/restaurants",
          // url: "#",
          subtitle: t("header.food.restaurants.subtitle"),
          icon: FoodRestaurant,
        },
        {
          label: t("header.food.bistros.label"),
          url: "/who-we-serve/food-and-beverage/bistros",
          // url: "#",
          subtitle: t("header.food.bistros.subtitle"),
          icon: FoodBistro,
        },
        {
          label: t("header.food.cafes.label"),
          url: "/who-we-serve/food-and-beverage/cafes",
          // url: "#",
          subtitle: t("header.food.cafes.subtitle"),
          icon: FoodCafe,
        },
        {
          label: t("header.food.bars.label"),
          url: "/who-we-serve/food-and-beverage/bars",
          // url: "#",
          subtitle: t("header.food.bars.subtitle"),
          icon: FoodBar,
        },
        {
          label: t("header.food.pubclubs.label"),
          url: "/who-we-serve/food-and-beverage/pub-and-clubs",
          // url: "#",
          subtitle: t("header.food.pubclubs.subtitle"),
          icon: FoodPub,
        },
        {
          label: t("header.food.foodtrucks.label"),
          url: "/who-we-serve/food-and-beverage/food-trucks",
          // url: "#",
          subtitle: t("header.food.foodtrucks.subtitle"),
          icon: FoodTruck,
        },
        {
          label: t("header.food.finedining.label"),
          url: "/who-we-serve/food-and-beverage/fine-dining-restaurants",
          // url: "#",
          subtitle: t("header.food.finedining.subtitle"),
          icon: FoodFineDining,
        },
        {
          label: t("header.food.bakeries.label"),
          url: "/who-we-serve/food-and-beverage/bakeries",
          // url: "#",
          subtitle: t("header.food.bakeries.subtitle"),
          icon: FoodBakeries,
        },
        {
          label: t("header.food.catering.label"),
          url: "/who-we-serve/food-and-beverage/catering-services",
          // url: "#",
          subtitle: t("header.food.catering.subtitle"),
          icon: FoodCateringServices,
        },
        {
          label: t("header.food.fastfood.label"),
          url: "/who-we-serve/food-and-beverage/fast-food-joints",
          // url: "#",
          subtitle: t("header.food.fastfood.subtitle"),
          icon: FoodFastFoodJoints,
        },
        {
          label: t("header.food.icecream.label"),
          url: "/who-we-serve/food-and-beverage/ice-cream-parlors",
          // url: "#",
          subtitle: t("header.food.icecream.subtitle"),
          icon: FoodIceCreamParlors,
        },
        {
          label: t("header.food.foodstalls.label"),
          url: "/who-we-serve/food-and-beverage/food-stalls",
          // url: "#",
          subtitle: t("header.food.foodstalls.subtitle"),
          icon: FoodFoodStalls,
        },
        {
          label: t("header.food.buffets.label"),
          url: "/who-we-serve/food-and-beverage/buffets",
          // url: "#",
          subtitle: t("header.food.buffets.subtitle"),
          icon: FoodBuffets,
        },
        {
          label: t("header.food.popups.label"),
          url: "/who-we-serve/food-and-beverage/pop-up-restaurants",
          // url: "#",
          subtitle: t("header.food.popups.subtitle"),
          icon: FoodPopUpRestaurants,
        },
        {
          label: t("header.food.juicebars.label"),
          url: "/who-we-serve/food-and-beverage/juice-bars",
          subtitle: t("header.food.juicebars.subtitle"),
          // url: "#",
          icon: FoodJuiceBars,
        },
        {
          label: t("header.food.foodhalls.label"),
          url: "/who-we-serve/food-and-beverage/food-halls",
          // url: "#",
          subtitle: t("header.food.foodhalls.subtitle"),
          icon: FoodFoodHalls,
        },
        {
          label: t("header.food.supperclubs.label"),
          url: "/who-we-serve/food-and-beverage/supper-clubs",
          // url: "#",
          subtitle: t("header.food.supperclubs.subtitle"),
          icon: FoodSupperClubs,
        },
        {
          label: "Pizzerias",
          url: "/who-we-serve/food-and-beverage/pizzeria",
          // url: "#",
          subtitle: "Streamline ordering, delivery and dine-in",
          icon: NewMenuPizza,
        },
        {
          label: "Coffee Shops",
          url: "/who-we-serve/food-and-beverage/coffe-shop",
          // url: "#",
          subtitle: "Build loyalty and showcase specialty brews",
          icon: NewMenuCoffeShops,
        },
        {
          label: "Delis",
          url: "/who-we-serve/food-and-beverage/delis",
          // url: "#",
          subtitle: "Manage made-to-order sandwiches and salads",
          icon: NewMenuDelis,
        },
        {
          label: "Food Courts",
          url: "/who-we-serve/food-and-beverage/food-court",
          // url: "#",
          subtitle: "Unify options for malls, airports and more",
          icon: NewMenuFoodCourts,
        },
      ],
    },
    {
      label: t("header.new_menu.sport_entertainment.label"),
      description: t("header.new_menu.sport_entertainment.desc"),
      icon: SportIcon,
      hoverIcon: SportIcon,
      children: [
        {
          label: t("header.new_menu.sport_entertainment.content.label_1"),
          url: "/who-we-serve/entertainment-venues",
          subtitle: t("header.new_menu.sport_entertainment.content.subtitle_1"),
          icon: SportOverview,
          className: "entertainment-overview",
        },
        {
          label: t("header.new_menu.sport_entertainment.content.label_2"),
          url: "/who-we-serve/entertainment-venues/golf-courses",
          // url: "#",
          subtitle: t("header.new_menu.sport_entertainment.content.subtitle_2"),
          icon: NewMenuGolf,
        },
        {
          label: t("header.new_menu.sport_entertainment.content.label_3"),
          url: "/who-we-serve/entertainment-venues/bowling",
          // url: "#",
          subtitle: t("header.new_menu.sport_entertainment.content.subtitle_3"),
          icon: SportBowling,
        },
        {
          label: t("header.new_menu.sport_entertainment.content.label_4"),
          url: "/who-we-serve/entertainment-venues/gyms-and-fitness-centers",
          // url: "#",
          subtitle: t("header.new_menu.sport_entertainment.content.subtitle_4"),
          icon: SportGym,
        },
        {
          label: t("header.new_menu.sport_entertainment.content.label_5"),
          url: "/who-we-serve/entertainment-venues/arcades-and-game-centers",
          // url: "#",
          subtitle: t("header.new_menu.sport_entertainment.content.subtitle_5"),
          icon: SportArcadesAndGameCenters,
        },
        {
          label: t("header.new_menu.sport_entertainment.content.label_6"),
          url: "/who-we-serve/entertainment-venues/sports-arenas",
          // url: "#",
          subtitle: t("header.new_menu.sport_entertainment.content.subtitle_6"),
          icon: SportSportsComplexes,
        },
        {
          label: t("header.new_menu.sport_entertainment.content.label_7"),
          url: "/who-we-serve/entertainment-venues/concert-halls-and-theaters",
          // url: "#",
          subtitle: t("header.new_menu.sport_entertainment.content.subtitle_7"),
          icon: SportConcertVenuesAndTheaters,
        },
        {
          label: t("header.new_menu.sport_entertainment.content.label_8"),
          url: "/who-we-serve/entertainment-venues/amusement-parks",
          // url: "#",
          subtitle: t("header.new_menu.sport_entertainment.content.subtitle_8"),
          icon: SportAmusementParks,
        },
        {
          label: t("header.new_menu.sport_entertainment.content.label_9"),
          url: "/who-we-serve/entertainment-venues/ski-resorts",
          // url: "#",
          subtitle: t("header.new_menu.sport_entertainment.content.subtitle_9"),
          icon: SportSkiResorts,
        },
        {
          label: "Museums",
          url: "/who-we-serve/entertainment-venues/museum",
          // url: "#",
          subtitle: "Curate engaging exhibits and streamline ticketing",
          icon: NewMenuMuseum,
        },
        {
          label: "Cinemas",
          url: "/who-we-serve/entertainment-venues/cinema",
          // url: "#",
          subtitle: "Enhance movie experiences end-to-end",
          icon: NewMenuCinema,
        },
        {
          label: "Beach Bars",
          url: "/who-we-serve/entertainment-venues/beach-bars",
          // url: "#",
          subtitle: "Efficiently Manage Beach Bar Stock Levels\n",
          icon: NewMenuBeachBar,
        },
      ],
    },
    {
      label: t("header.new_menu.accommodation.label"),
      description: t("header.new_menu.accommodation.desc"),
      icon: AccommodationIcon,
      hoverIcon: AccommodationIcon,

      children: [
        {
          label: t("header.new_menu.accommodation.content.label_1"),
          url: "/who-we-serve/accommodation",
          subtitle: t("header.new_menu.accommodation.content.subtitle_1"),
          icon: AccommodationOverview,
          className: "accommodation-overview",
        },
        {
          label: t("header.new_menu.accommodation.content.label_2"),
          url: "/who-we-serve/accommodation/hotels",
          // url: "#",
          subtitle: t("header.new_menu.accommodation.content.subtitle_2"),
          icon: NewMenuHotel,
        },
        {
          label: t("header.new_menu.accommodation.content.label_3"),
          url: "/who-we-serve/accommodation/cozy-retreats",
          // url: "#",
          subtitle: t("header.new_menu.accommodation.content.subtitle_3"),
          icon: AccommodationCozy,
        },
        {
          label: t("header.new_menu.accommodation.content.label_4"),
          url: "/who-we-serve/accommodation/hostels",
          // url: "#",
          subtitle: t("header.new_menu.accommodation.content.subtitle_4"),
          icon: AccommodationHostel,
        },
        {
          label: t("header.new_menu.accommodation.content.label_5"),
          url: "/who-we-serve/accommodation/hotel-chains",
          // url: "#",
          subtitle: t("header.new_menu.accommodation.content.subtitle_5"),
          icon: AccommodationHotelchain,
        },
        {
          label: t("header.new_menu.accommodation.content.label_6"),
          url: "/who-we-serve/accommodation/vacation-rentals",
          // url: "#",
          subtitle: t("header.new_menu.accommodation.content.subtitle_6"),
          icon: AccommodationVacation,
        },
        {
          label: t("header.new_menu.accommodation.content.label_7"),
          url: "/who-we-serve/accommodation/luxury-resorts",
          // url: "#",
          subtitle: t("header.new_menu.accommodation.content.subtitle_7"),
          icon: AccommodationLuxuryResorts,
        },
        {
          label: t("header.new_menu.accommodation.content.label_8"),
          url: "/who-we-serve/accommodation/family-resorts",
          // url: "#",
          subtitle: t("header.new_menu.accommodation.content.subtitle_8"),
          icon: AccommodationHotel,
        },
        {
          label: t("header.new_menu.accommodation.content.label_9"),
          url: "/who-we-serve/accommodation/service-apartments",
          // url: "#",
          subtitle: t("header.new_menu.accommodation.content.subtitle_9"),
          icon: NewMenuApt,
        },
        {
          label: "Bed and Breakfasts",
          url: "/who-we-serve/accommodation/bed-and-breakfast",
          // url: "#",
          subtitle: "Create charming, personalized stays",
          icon: NewMenuBedAndBreakfast,
        },
        {
          label: "Motels",
          url: "/who-we-serve/accommodation/motel",
          // url: "#",
          subtitle: "Drive efficiency for budget-friendly roadside stays",
          icon: NewMenuMotel,
        },
        {
          label: "Capsule Hotels",
          url: "/who-we-serve/accommodation/capsule-hotel",
          // url: "#",
          subtitle: "Tech for compact hotels for solo travelers",
          icon: NewMenuCapsuleHotels,
        },
        {
          label: "Youth Hostels",
          url: "/who-we-serve/accommodation/youth-hostel",
          // url: "#",
          subtitle: "Engage student and backpacker customers",
          icon: NewMenuHostel,
        },
        {
          label: "Campgrounds",
          url: "/who-we-serve/accommodation/campground",
          // url: "#",
          subtitle: "Streamline reservations & optimize seasonal operations",
          icon: NewMenuCampGround,
        },
        {
          label: "RV Parks",
          url: "/who-we-serve/accommodation/rv-park",
          // url: "#",
          subtitle: "Manage spaces and amenities for mobile stays",
          icon: NewMenuRVParks,
        },
      ],
    },
    {
      label: t("header.new_menu.retail.label"),
      description: t("header.new_menu.retail.desc"),
      icon: RetailIcon,
      hoverIcon: RetailIcon,

      children: [
        {
          label: t("header.new_menu.retail.content.label_1"),
          url: "/who-we-serve/retail-management",
          subtitle: t("header.new_menu.retail.content.subtitle_1"),
          icon: RetailOverview,
          className: "retail-overview",
        },
        {
          label: t("header.new_menu.retail.content.label_2"),
          url: "/who-we-serve/retail-management/sporting-goods-stores",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_2"),
          icon: RetailSport,
        },
        {
          label: t("header.new_menu.retail.content.label_3"),
          url: "/who-we-serve/retail-management/home-decor",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_3"),
          icon: RetailHome,
        },
        {
          label: t("header.new_menu.retail.content.label_4"),
          url: "/who-we-serve/retail-management/fashion-boutiques",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_4"),
          icon: RetailFashion,
        },
        {
          label: t("header.new_menu.retail.content.label_5"),
          url: "/who-we-serve/retail-management/retail-chains",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_5"),
          icon: RetailRetailChains,
        },
        {
          label: t("header.new_menu.retail.content.label_6"),
          url: "/who-we-serve/retail-management/boutiques-and-specialty-stores",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_6"),
          icon: RetailBoutiquesAndSpecialtyStores,
        },
        {
          label: t("header.new_menu.retail.content.label_7"),
          url: "/who-we-serve/retail-management/online-shops",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_7"),
          icon: RetailOnlineRetailers,
        },
        {
          label: t("header.new_menu.retail.content.label_8"),
          url: "/who-we-serve/retail-management/grocery-stores",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_8"),
          icon: RetailGroceryStores,
        },
        {
          label: t("header.new_menu.retail.content.label_9"),
          url: "/who-we-serve/retail-management/electronics-stores",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_9"),
          icon: RetailElectronicsStores,
        },
        {
          label: t("header.new_menu.retail.content.label_10"),
          url: "/who-we-serve/retail-management/pharmacies",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_10"),
          icon: RetailPharmacies,
        },
        {
          label: t("header.new_menu.retail.content.label_11"),
          url: "/who-we-serve/retail-management/auto-parts-stores",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_11"),
          icon: RetailAutoPartsStores,
        },
        {
          label: t("header.new_menu.retail.content.label_12"),
          url: "/who-we-serve/retail-management/liquor-stores",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_12"),
          icon: RetailLiquorStores,
        },
        {
          label: t("header.new_menu.retail.content.label_13"),
          url: "/who-we-serve/retail-management/pet-supply-stores",
          // url: "#",
          subtitle: t("header.new_menu.retail.content.subtitle_13"),
          icon: RetailPetSupplyStores,
        },
      ],
    },

    // Note: healthcare will be separated, will be a new web, just keep these here for refernece
    // {
    //   label: "Healthcare",
    //   description: "All-in-one platform for appointments, scheduling and more",
    //   icon: HealthcareIcon,
    //   hoverIcon: HealthCareHoverIcon,
    //
    //   children: [
    //     {
    //       label: "Overview",
    //       url: "/who-we-serve/healthcare",
    //       subtitle:
    //         "A better and more efficient way to manage your appointments",
    //       icon: OverviewIcon,
    //     },
    //     {
    //       label: "Hospital",
    //       url: "#",
    //       subtitle: "Revolutionizing appointment management for hospitals",
    //       icon: HealthcareHospital,
    //     },
    //     {
    //       label: "Dental Clinic",
    //       url: "#",
    //       subtitle:
    //         "Comprehensive technology for appointments, marketing, and payments",
    //       icon: HealthcareDental,
    //     },
    //   ],
    // },
  ];

  const [isNavOpen, setIsNavOpen] = useState(false);

  const goToCart = () => {
    let hospitalRoomKey = getStorageKey(KEYS.HOSPITAL_ROOM_KEY);
    if (hospitalRoomKey) {
      let route = `/food-checkout`;
      navigate(route);
    }
    let venueKey = getStorageKey(KEYS.CART_VENUE_KEY);
    if (venueKey?.venue_app_key) {
      let route = `/venue/retail/${venueKey?.venue_app_key}/cart`;
      navigate(route);
    }
  };

  const isRestaurantView = pathname.includes("/venue/restaurant");

  const isLoggedInUser = pathname.includes("/customer/");
 
  return  isLoggedInUser ? (
    <div className={"h-5 border"}></div>
  ) : (
    <>
    <header
      aria-label="Site Header"
      className={`bg-white header ${hideHeader ? "hidden" : ""}`}
      style={{ boxShadow: "0px 1px 2px 0px #00000040" }}
    >
      <div className="hidden lg:block" style={{ backgroundColor: "#d9cde4" }}>
        <div className="flex h-8 items-center justify-end mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
          <ul className="flex items-center gap-6 text-sm">
            {!props?.fromWaitlist && (
              <li>
                <a
                  className={
                    "font-medium text-[#666A73] text-14 transition hover:text-black1/75"
                  }
                  href="/contact-us"
                >
                  {t("topbar.contact_us")}
                </a>
              </li>
            )}
            {products && products.length > 0 && (
              <div
                onClick={() => goToCart()}
                style={{ position: "relative", cursor: "pointer" }}
              >
                <MdShoppingCart color="#666A73" />
                <div
                  style={{
                    position: "absolute",
                    top: "-5px", // Adjust this value to position the badge properly
                    right: "-10px", // Adjust this value to position the badge properly
                    backgroundColor: "#240b3b", // Customize badge background color
                    color: "white", // Customize badge text color
                    borderRadius: "50%", // Create a circular badge
                    fontSize: "8px", // Adjust font size as needed
                    height: "14px",
                    lineHeight: "14px",
                    textAlign: "center",
                    width: "14px",
                  }}
                >
                  {products.length}
                </div>
              </div>
            )}
            <li>
              <LangSelector />
            </li>
          </ul>
        </div>
      </div>
      {!affiliatesHeader ? (
        <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
          <div
            className={`${
              isRestaurantView ? "hidden" : "flex"
            } h-20 items-center justify-between`}
          >
            <div className="flex md:items-center md:gap-12">
              <a
                className="text-teal-600 flex items-center  gap-4 logo"
                href="/"
                style={{ marginLeft: "15px" }}
              >
                <img src={Logo} alt="VB Logo" style={{ width: "225px" }} />
              </a>
            </div>

            {!props?.fromWaitlist && (
              <div className={`hidden ${isVenue ? "" : "lg:block"}`}>
                <div className="nav flex flex-col lg:flex-row   gap-12 w-full">
                  <nav aria-label="Site Nav">
                    <ul className="flex flex-col justify-start lg:flex-row text-sm">
                      <li>
                        <DropdownsNewMenuMobile
                          menu={whoweserve_new_menu}
                          title="Who we serve"
                        />
                      </li>
                      <li className="flex w-full text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-1 cursor-pointer px-4 hover:text-black1/75">
                        <a
                          className={`text-16 h-0 font-medium align-middle ${
                            isPricingRoute ? "text-[#8b1dbf]" : ""
                          } nav-link transition hover:text-black1/75`}
                          href="/pricing-plans"
                        >
                          {t("header.pricing")}
                        </a>
                      </li>

                      <li>
                        <DropdownMenu
                          menu={why_venueboost_menu}
                          title="Why VenueBoost"
                        />
                      </li>

                      <li>
                        <DropdownMenu menu={resource_menus} title="Resources" />
                      </li>
                    </ul>
                  </nav>

                  {user && !user.affiliate_code ? (
                    <div className="flex items-center gap-4 w-full justify-center">
                      <div
                        className="relative flex flex-col xs:flex-row gap-4 justify-center"
                        onMouseOver={() => setIsDropdownOpen(true)}
                        onMouseLeave={() => setIsDropdownOpen(false)}
                      >
                        <div className="flex ">
                          <a
                            className="w-full rounded-md bg-primary1 px-2 lg:px-4 py-2 text-center text-16 font-medium text-white shadow gap-2 flex"
                            style={{ lineHeight: "190%" }}
                          >
                            <Icon
                              icon={user_circle}
                              style={{ color: "#DDDDDD" }}
                              size={20}
                            />
                            {user?.name}
                            <Icon
                              icon={chevronDown}
                              style={{ color: "#DDDDDD" }}
                              size={20}
                            />
                          </a>
                        </div>
                        {isDropdownOpen && (
                          <div className="absolute top-[48px] right-0 bg-white boxshadow border-[1px] border-[#DBDBDB] rounded-[4px] p-6 w-[300px] flex flex-col gap-5">
                            <a
                              className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                              href="/dashboard"
                            >
                              <img
                                alt=""
                                src={DashboardIcon}
                                className="w-[24px] h-[24px]"
                              />
                              <p className="text-[#191D23] text-16 font-semibold">
                                Dashboard
                              </p>
                            </a>
                            <a
                              className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                              href="/personal-information"
                            >
                              <img
                                alt=""
                                src={PersonalIcon}
                                className="w-[24px] h-[24px]"
                              />
                              <p className="text-[#191D23] text-16 font-semibold">
                                Personal Information
                              </p>
                            </a>
                            <a
                              className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                              href="/reservations"
                            >
                              <img
                                alt=""
                                src={ReservationIcon}
                                className="w-[24px] h-[24px]"
                              />
                              <p className="text-[#191D23] text-16 flex-1 font-semibold">
                                Reservations
                              </p>
                              <p className="bg-[#240b3b] text-16 text-white px-[10px] rounded-[4px]">
                                32
                              </p>
                            </a>
                            <a
                              className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                              onClick={() =>
                                setIsPromotionOpen(!isPromotionOpen)
                              }
                            >
                              <img
                                alt=""
                                src={PromotionIcon}
                                className="w-[24px] h-[24px]"
                              />
                              <p className="text-[#191D23] flex-1 text-16 font-semibold">
                                Promotions
                              </p>
                              {isPromotionOpen ? (
                                <Icon
                                  icon={chevronUp}
                                  style={{ color: "#64748B" }}
                                  size={20}
                                />
                              ) : (
                                <Icon
                                  icon={chevronDown}
                                  style={{ color: "#64748B" }}
                                  size={20}
                                />
                              )}
                            </a>
                            {isPromotionOpen && (
                              <div className="ml-6 border-l-[1px] pl-4 mt-[-12px]">
                                <a className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2">
                                  <p className="text-[#191D23] text-16 font-semibold">
                                    Yours
                                  </p>
                                </a>
                                <a className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2">
                                  <p className="text-[#191D23] flex-1 text-16 font-semibold">
                                    Calendar
                                  </p>
                                  <Icon
                                    icon={chevronRight}
                                    style={{ color: "#64748B" }}
                                    size={20}
                                  />
                                </a>
                              </div>
                            )}
                            <a
                              className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                              href="/payments"
                            >
                              <img
                                alt=""
                                src={PaymentIcon}
                                className="w-[24px] h-[24px]"
                              />
                              <p className="text-[#191D23] text-16 font-semibold">
                                Payments
                              </p>
                            </a>
                            <a
                              className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                              href="/wallet"
                            >
                              <img
                                alt=""
                                src={WalletIcon}
                                className="w-[24px] h-[24px]"
                              />
                              <p className="text-[#191D23] text-16 font-semibold">
                                Wallet
                              </p>
                            </a>
                            <a
                              className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                              href="/login-security"
                            >
                              <img
                                alt=""
                                src={SecurityIcon}
                                className="w-[24px] h-[24px]"
                              />
                              <p className="text-[#191D23] text-16 font-semibold">
                                Login and Security
                              </p>
                            </a>
                            <hr className="border-[#D0D5DD] border-t-[1px] " />
                            <p className="text-16 text-[#64748B] font-semibold">
                              Profile
                            </p>
                            <div className="flex  gap-2">
                              <img
                                alt=""
                                src={Avatar}
                                className="rounded-100 w-[42px] h-[42px]"
                              />
                              <div className="justify-between">
                                <p className="text-16 text-[#191D23] font-semibold">
                                  {user?.name}
                                </p>
                                <p className="text-16 text-[#A0ABBB] font-semibold">
                                  {user.email}
                                </p>
                              </div>
                            </div>
                            <div
                              className="rounded-[4px] p-3 cursor-pointer bg-[#F7F8F9] flex-1 flex gap-1 align-middle"
                              onClick={onLogout}
                            >
                              <img
                                alt=""
                                src={LogoutIcon}
                                className="w-[24px] h-[24px]"
                              />
                              <p className="text-[#191D23]  text-16 font-semibold">
                                Log out
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center gap-4 w-full justify-center">
                      <div className="flex flex-col xs:flex-row gap-4 justify-center">
                        <div className="flex">
                          <a
                            className="w-full rounded-md bg-primary1 px-2 lg:px-4 py-2 text-center text-16 font-medium text-white shadow"
                            href="/get-started"
                            style={{
                              lineHeight: "190%",
                              background:
                                "linear-gradient(100.8deg, #240B3B 3.95%, #3D1E59 34%, #BC14FE 94.28%)",
                            }}
                          >
                            {t("header.get_started")}
                          </a>
                        </div>
                        <div className="flex">
                          <a
                            className="w-full rounded-md border-2 border-primary1 bg-white text-center px-2 lg:px-4 py-2 text-16 font-medium  text-primary1"
                            href="https://admin.venueboost.io/"
                            style={{ lineHeight: "160%" }}
                            // onClick={() => handleLogin()}
                          >
                            {t("header.login")}
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {isNavOpen && (
              <div className="flex flex-col gap-12 w-full header-menu-mobile p-0 m-0 bg-gray-100">
                <nav aria-label="Site Nav">
                  <ul className="flex flex-col gap-4 text-sm">
                    <li>
                      <DropdownMenuMobile2
                        menu={whoweserve_new_menu}
                        title={t("header.dropdown_serve")}
                      />
                    </li>
                    <li>
                      <a
                        className={
                          "text-18 m-4 mx-6 font-medium align-start text-black1 transition hover:text-black1/75"
                        }
                        href="/pricing-plans"
                      >
                        {t("topbar.pricing")}
                      </a>
                    </li>

                    <li>
                      <DropdownMenuMobile
                        menu={why_venueboost_menu}
                        title={t("header.dropdown_venueboost")}
                      />
                    </li>

                    <li>
                      <DropdownMenuMobile
                        menu={resource_menus}
                        title={t("header.dropdown_resource")}
                      />
                    </li>

                    <li>
                      <LangSelectorMobile
                        title={t("header.dropdown_language")}
                        closeNav={setIsNavOpen}
                      />
                    </li>
                  </ul>
                </nav>

                <div className="flex flex-col xs:flex-row gap-4 justify-center">
                  <a
                    className=" mx-2 rounded-md bg-primary1 px-4 py-2 text-center text-16 font-medium text-white shadow"
                    href="/get-started"
                    style={{
                      lineHeight: "190%",
                      background:
                        "linear-gradient(100.8deg, #240B3B 3.95%, #3D1E59 34%, #BC14FE 94.28%)",
                    }}
                  >
                    {t("topbar.get_started")}
                  </a>
                  <a
                    className=" mx-2 rounded-md border-2 border-primary1 bg-white text-center px-4 py-2 text-16 font-medium  text-primary1"
                    href="https://admin.venueboost.io/"
                    style={{ lineHeight: "160%" }}
                    // onClick={() => handleLogin()}
                  >
                    {t("topbar.login")}
                  </a>
                </div>
              </div>
            )}

            {!props?.fromWaitlist && (
              <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="inline-flex items-center p-2 ml-3 text-sm text-primary1 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-default"
                onClick={() => setIsNavOpen((prev) => !prev)}
              >
                <span className="sr-only">{t("topbar.open_menu")}</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
          <div
            className={`${
              isRestaurantView ? "hidden" : "flex"
            } h-20 items-center justify-between`}
          >
            <div className="flex md:items-center md:gap-12">
              <a
                className="text-teal-600 flex items-center  gap-4 logo"
                href="/"
              >
                <img src={Logo} alt="VB Logo" style={{ width: "225px" }} />
              </a>
            </div>

            <div className={`hidden ${isVenue ? "" : "lg:block"}`}>
              <div className="nav flex flex-col lg:flex-row   gap-12 w-full">
                <nav aria-label="Site Nav">
                  <ul className="flex flex-col justify-start lg:flex-row text-sm">
                    <li className="flex w-full text-16 lg:h-20 align-middle font-medium text-[#666A73] transition gap-1 cursor-pointer px-4 hover:text-black1/75">
                      <a
                        className={
                          "text-16 h-20 font-medium align-middle text-[#666A73] transition hover:text-black1/75"
                        }
                        href="/affiliates/dashboard"
                      >
                        Dashboard
                      </a>
                    </li>

                    <li className="flex w-full text-16 lg:h-20 align-middle font-medium text-[#666A73] transition gap-1 cursor-pointer px-4 hover:text-black1/75">
                      <a
                        className={
                          "text-16 h-20 font-medium align-middle text-[#666A73] transition hover:text-black1/75 whitespace-nowrap"
                        }
                      >
                        {user?.name}
                      </a>
                    </li>
                  </ul>
                </nav>

                <div className="flex items-center gap-4 w-full justify-center">
                  <div className="flex flex-col xs:flex-row gap-4 justify-center">
                    <div className="flex">
                      <a
                        className="cursor-pointer w-full rounded-md border-2 border-primary1 bg-white text-center px-2 lg:px-4 py-2 text-16 font-medium  text-primary1"
                        style={{ lineHeight: "160%" }}
                        onClick={onLogoutAffilate}
                      >
                        Log out
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center p-2 ml-3 text-sm text-primary1 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-default"
              onClick={() => setIsNavOpen((prev) => !prev)}
            >
              <span className="sr-only">{t("topbar.open_menu")}</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
    </header>
    <div className=" h-[80px] lg:h-[112px]"/>
    </>
  );
};

export default Header;
