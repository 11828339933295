import React, { useState } from "react";
import { Table, Pagination } from "antd";
import { Icon } from "react-icons-kit";
import { home } from "react-icons-kit/typicons/home";
import Sidebar from "../../../components/Sidebar";
import OliviaIcon from "../../../assets/images/icons/olivia_icon.png";

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    icon: OliviaIcon,
    name: `Olivia ${i}`,
    time: "15/10/2023 16:00 - 19:00",
    guests: `${i % 5} Guests`,
    total: `$ 100`,
    status: i % 3 === 0 ? "Completed" : i % 3 === 1 ? "Cancelled" : "Pending",
  });
}

const Reservations = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(4);
  const [numEachPage, setNumEachPage] = useState(4);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleChange = (value) => {
    setMinValue((value - 1) * numEachPage);
    setMaxValue(value * numEachPage);
  };

  return (
    <div className="flex flex-col align-col-middle view-terms ">
      <div className="flex w-[100vw] bg-[#FCFCFD]">
        <Sidebar selected={"reservations"} />
        <div className="flex flex-1 flex-col px-[20px] py-[9px] md:px-[42px] md:py-[32px] gap-6">
          <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
            <div className="p-6 gap-2 flex flex-col">
              <div className="flex items-center gap-4">
                <Icon
                  icon={home}
                  style={{
                    color: "#6B7280",
                    display: "flex",
                  }}
                  size={20}
                />
                <p className="text-[#6B7280] text-20 font-medium leading-5 ">{`>`}</p>
                <p className="text-[#6B7280] text-14 font-medium leading-5 ">
                  Reservations
                </p>
              </div>
              <div className="text-[#101828] text-18 font-semibold leading-7">
                Reservations
                <span className="ml-2 text-14 leading-[18px] text-[#240b3b] bg-[#F3F1F4] rounded-[16px] px-2 py-[2px] ">
                  20 reservations
                </span>
              </div>
              <p className="text-[#667085] text-14 font-medium leading-5">
                Keep track of reservations and much more...
              </p>
            </div>
            <Table
              rowSelection={rowSelection}
              dataSource={data}
              className="w-full hidden md:block"
            >
              <Table.Column
                title="Restaurants"
                dataIndex="name"
                key="name"
                render={(icon) => (
                  <div className="flex-row justify-start flex items-center  ">
                    <img
                      alt=""
                      src={OliviaIcon}
                      className="w-[40px] h-[40px]  rounded-[100px]"
                    />
                    <div className="justify-between ml-3">
                      <p className="text-14 font-bold leading-5 text-[#240b3b] ">
                        Olivia Cooks
                      </p>
                      <p className="text-[#6B7280] font-normal text-14 leading-5">
                        Restaurant
                      </p>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                title="Time"
                dataIndex="time"
                key="time"
                render={(time) => (
                  <p className="text-14 text-[#667085] leading-5 font-medium">
                    {time}
                  </p>
                )}
              />
              <Table.Column
                title="Guests"
                dataIndex="guests"
                key="guests"
                render={(guests) => (
                  <p className="text-14 text-[#667085] leading-5 font-medium">
                    {guests}
                  </p>
                )}
              />
              <Table.Column
                title="Total"
                dataIndex="total"
                key="total"
                render={(total) => (
                  <p className="text-14 text-[#240b3b] leading-5 font-bold">
                    {total}
                  </p>
                )}
              />
              <Table.Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(status) => (
                  <div className="align-start">
                    {status === "Completed" ? (
                      <div className="align-middle flex text-[#027A48] px-2 py-[2px] rounded-[16px] text-12 font-medium leading-[18px] bg-[#ECFDF3]">
                        <span className="text-[20px] content-center mr-1">
                          •
                        </span>
                        Completed
                      </div>
                    ) : status === "Cancelled" ? (
                      <div className="align-middle flex text-[#FF3E3E] px-2 py-[2px] rounded-[16px] text-12 font-medium leading-[18px] bg-[#FDEFEC]">
                        <span className="text-[20px] content-center mr-1">
                          •
                        </span>
                        Cancelled
                      </div>
                    ) : (
                      <div className="align-middle text-[#344054] px-2 py-[2px] rounded-[16px] text-12 font-medium leading-[18px] bg-[#F2F4F7]">
                        <span className="text-[20px] content-center mr-1">
                          •
                        </span>
                        Pending
                      </div>
                    )}
                  </div>
                )}
              />
            </Table>
          </div>
          <div className="flex flex-col md:hidden gap-[9px] justify-center flex-1">
            {data &&
              data.length > 0 &&
              data.slice(minValue, maxValue).map((val) => (
                <div className="boxshadow flex-1 border-[1px] border-[#E3e3e3] rounded-[8px] p-3 md:p-4 flex flex-col md:flex-row ">
                  <div className="flex flex-1 align-middle">
                    <div className="flex align-middle flex-1">
                      <img
                        alt=""
                        src={val.icon}
                        className="w-[48px] h-[48px] rounded-[100px] border-[1px] border-[#EAECF0] mr-[16px]"
                      />
                      <div className="flex flex-col justify-between flex-1">
                        <p className="text-[#240b3b] text-16 font-bold leading-5">
                          {val.name}
                        </p>
                        <p className="text-[#6B7280] text-14 font-normal leading-5">
                          Restaurant
                        </p>
                      </div>
                    </div>
                    <div className="">
                      {val.status === "Completed" ? (
                        <div className="align-middle flex text-[#027A48] px-2 py-[2px] rounded-[16px] text-12 font-medium leading-[18px] bg-[#ECFDF3]">
                          <span className="text-[20px] content-center mr-1">
                            •
                          </span>
                          Completed
                        </div>
                      ) : val.status === "Cancelled" ? (
                        <div className="align-middle flex text-[#FF3E3E] px-2 py-[2px] rounded-[16px] text-12 font-medium leading-[18px] bg-[#FDEFEC]">
                          <span className="text-[20px] content-center mr-1">
                            •
                          </span>
                          Cancelled
                        </div>
                      ) : (
                        <div className="align-middle text-[#344054] px-2 py-[2px] rounded-[16px] text-12 font-medium leading-[18px] bg-[#F2F4F7]">
                          <span className="text-[20px] content-center mr-1">
                            •
                          </span>
                          Pending
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 gap-[11px] mt-[13px]">
                    <div className="flex  justify-between flex-1">
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        Time
                      </p>
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        {val.time}
                      </p>
                    </div>
                    <div className="flex  justify-between flex-1">
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        Guests
                      </p>
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        {val.guests}
                      </p>
                    </div>
                    <div className="flex  justify-between flex-1">
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        Total
                      </p>
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        {val.total}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            <Pagination
              defaultCurrent={1}
              defaultPageSize={numEachPage} //default size of page
              onChange={handleChange}
              className="flex-1 justify-center flex "
              total={data.length} //total number of card data available
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reservations;
