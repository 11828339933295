import FreeShipping from "../../../components/white-label-components/free-shipping";
import "../index.css";
import { FilterLayout } from "./filter-layout";
import { FilterLayoutMobile } from "./filter-layout-mobile";
import ProductLayout from "./product-layout";

const VenueRetailShop = () => {
  return (
    <>
      <div className=" bg-opacity-5 h-[50px] w-full bg-[#000000] absolute left-0 top-[82px] hidden lg:flex items-center">
        <div className="large-width mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full flex flex-row items-center">
          <div>{ `Shop` }</div>
          <span className="w-[20px] h-[20px] flex justify-center items-center">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.943573 0.345232C0.674034 0.560863 0.630333 0.954171 0.845964 1.22371L3.86695 4.99994L0.845964 8.77617C0.630333 9.04571 0.674034 9.43902 0.943573 9.65465C1.21311 9.87028 1.60642 9.82658 1.82205 9.55704L5.15538 5.39038C5.33799 5.16211 5.33799 4.83777 5.15538 4.60951L1.82205 0.442841C1.60642 0.173302 1.21311 0.129601 0.943573 0.345232Z" fill="#131118"/>
            </svg>
          </span>
          <div>{ `Swarovski` }</div>
          <span className="w-[20px] h-[20px] flex justify-center items-center">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.943573 0.345232C0.674034 0.560863 0.630333 0.954171 0.845964 1.22371L3.86695 4.99994L0.845964 8.77617C0.630333 9.04571 0.674034 9.43902 0.943573 9.65465C1.21311 9.87028 1.60642 9.82658 1.82205 9.55704L5.15538 5.39038C5.33799 5.16211 5.33799 4.83777 5.15538 4.60951L1.82205 0.442841C1.60642 0.173302 1.21311 0.129601 0.943573 0.345232Z" fill="#131118"/>
            </svg>
          </span>
          <div>{ `All Products` }</div>
        </div>
      </div>

      <div className="grid grid-cols-5 gap-10">
        <FilterLayout />
        <ProductLayout />
        <div className="md:mt-16 mt-5 col-span-5 hidden md:block">
          <FreeShipping />
        </div>
      </div>
    </>
  );
};

export default VenueRetailShop;
