import React, { useState, useRef, useMemo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./index.css";
import SvgRest from "../../assets/svgs/serve_rest.svg";
import SvgCaterer from "../../assets/svgs/serve_caterer.svg";
import SvgCoffeShop from "../../assets/svgs/serve_coffee_shops.svg";
import SvgJuiceBars from "../../assets/svgs/serve_juice_bars.svg";
import SvgFoodHalls from "../../assets/svgs/serve_food_halls.svg";
import SvgPopUp from "../../assets/svgs/serve_pop_up_dining.svg";
import SvgFoodStalls from "../../assets/svgs/serve_food_stalls.svg";
import SvgBistro from "../../assets/svgs/serve_bistro.svg";
import SvgIceCream from "../../assets/svgs/serve_Ice_cream_parlors.svg";
import SvgFastFood from "../../assets/svgs/serve_fast_food.svg";
import SvgFoodCourts from "../../assets/svgs/serve_food_courts.svg";
import SvgCafe from "../../assets/svgs/serve_cafe.svg";
import SvgBakeries from "../../assets/svgs/serve_bakeries.svg";
import SvgTruck from "../../assets/svgs/serve_truck.svg";
import SvgDinning from "../../assets/svgs/serve_dining.svg";
import SvgBar from "../../assets/svgs/serve_bar.svg";
import SvgPubs from "../../assets/svgs/serve_pubs.svg";
import SvgPizzeria from "../../assets/svgs/serve_pizzerias.svg";
import SvgDelis from "../../assets/svgs/serve_delis.svg";
import SvgSupperClubs from "../../assets/svgs/serve_supper_clubs.svg";
import SvgBuffets from "../../assets/svgs/serve_buffets.svg";
import SvgAmusementPark from "../../assets/svgs/new_for_industries/serve_amusement_parks.svg";
import SvgCinema from "../../assets/svgs/new_for_industries/serve_cinemas.svg";
import SvgConcertHall from "../../assets/svgs/new_for_industries/serve_concent.svg";
import SvgGameCenter from "../../assets/svgs/new_for_industries/serve_game_centers.svg";
import SvgGolfCourses from "../../assets/svgs/new_for_industries/serve_golf_courses.svg";
import SvgSkiResorts from "../../assets/svgs/new_for_industries/serve_ski_resorts.svg";
import SvgSportsArena from "../../assets/svgs/new_for_industries/serve_sports_arenas.svg";
import SvgBowlingAlleys from "../../assets/svgs/new_for_industries/serve_bowling_alleys.svg";
import SvgGyms from "../../assets/svgs/new_for_industries/serve_gyms_and_fitness.svg";
import SvgMuseum from "../../assets/svgs/new_for_industries/serve_museum.svg";
import SvgBeachBar from "../../assets/svgs/new_for_industries/ent_beachbar.svg";
import { t } from "i18next";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import BusinessServiceCard from "../BusinessServiceCard";
import SvgHotel from "../../assets/svgs/new_for_industries/accom_hotel.svg";
import SvgCozy from "../../assets/svgs/new_for_industries/accom_cozy.svg";
import SvgHostel from "../../assets/svgs/new_for_industries/accom_hostel.svg";
import SvgHotelChain from "../../assets/svgs/new_for_industries/accom_hotelchain.svg";
import SvgVacation from "../../assets/svgs/accommodation-vacation-1.svg";
import SvgLuxury from "../../assets/svgs/new_for_industries/accom_luxury.svg";
import SvgFamily from "../../assets/svgs/new_for_industries/accom_family.svg";
import SvgService from "../../assets/svgs/new_for_industries/accom_service.svg";
import SvgBed from "../../assets/svgs/new_for_industries/accom_bed.svg";
import SvgMotel from "../../assets/svgs/new_for_industries/accom_motel.svg";
import SvgCapsule from "../../assets/svgs/new_for_industries/accom_capsule.svg";
import SvgYouth from "../../assets/svgs/new_for_industries/accom_youth.svg";
import SvgCamp from "../../assets/svgs/new_for_industries/accom_campground.svg";
import SvgRv from "../../assets/svgs/new_for_industries/accom_rv.svg";

import SvgSport from "../../assets/svgs/new_for_industries/retail_sport.svg";
import SvgHome from "../../assets/svgs/new_for_industries/retail_home.svg";
import SvgFashion from "../../assets/svgs/new_for_industries/retail_fashion.svg";
import SvgChain from "../../assets/svgs/new_for_industries/retail_chain.svg";
import SvgSpecialty from "../../assets/svgs/new_for_industries/retail_specialty.svg";
import SvgOnline from "../../assets/svgs/new_for_industries/retail_online.svg";
import SvgGrocery from "../../assets/svgs/new_for_industries/retail_grocery.svg";
import SvgElectron from "../../assets/svgs/new_for_industries/retail_electron.svg";
import SvgPharmacy from "../../assets/svgs/new_for_industries/retail_pharmacy.svg";
import SvgAuto from "../../assets/svgs/new_for_industries/retail_auto.svg";
import SvgLiquor from "../../assets/svgs/new_for_industries/retail_liquor.svg";
import SvgPet from "../../assets/svgs/new_for_industries/retail_pet.svg";


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 2050 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 2050, min: 1240 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1240, min: 768 },
    items: 1,
  },
  mobile1: {
    breakpoint: { max: 768, min: 414 },
    items: 1,
  },
  mobile2: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

const dataET = [
  [
    {
      icon: SvgGolfCourses,
      label: t("components.serve_carousel.ev_1.label"),
      url: "/who-we-serve/entertainment-venues/golf-courses",
    },
    {
      icon: SvgBowlingAlleys,
      label: t("components.serve_carousel.ev_2.label"),
      url: "/who-we-serve/entertainment-venues/bowling",
    },

  ],
  [
    {
      icon: SvgGyms,
      label: t("components.serve_carousel.ev_3.label"),
      url: "/who-we-serve/entertainment-venues/gyms-and-fitness-centers",
    },
    {
      icon: SvgGameCenter,
      label: t("components.serve_carousel.ev_4.label"),
      url: "/who-we-serve/entertainment-venues/arcades-and-game-centers",
    },
  ],
  [
    {
      icon: SvgSportsArena,
      label: t("components.serve_carousel.ev_5.label"),
      url: "/who-we-serve/entertainment-venues/sports-arenas",
    },
    {
      icon: SvgConcertHall,
      label: t("components.serve_carousel.ev_6.label"),
      url: "/who-we-serve/entertainment-venues/concert-halls-and-theaters",
    },
  ],
  [
    {
      icon: SvgAmusementPark,
      label: t("components.serve_carousel.ev_7.label"),
      url: "/who-we-serve/entertainment-venues/amusement-parks",
    },
    {
      icon: SvgSkiResorts,
      label: t("components.serve_carousel.ev_8.label"),
      url: "/who-we-serve/entertainment-venues/ski-resorts",
    },
  ],
  [
    {
      icon: SvgMuseum,
      label: t("components.serve_carousel.ev_9.label"),
      url: "/who-we-serve/entertainment-venues/museum",
    },
    {
      icon: SvgCinema,
      label: t("components.serve_carousel.ev_10.label"),
      url: "/who-we-serve/entertainment-venues/cinema",
    },
  ],
  [
    {
      icon: SvgBeachBar,
      label: t("components.serve_carousel.beachbar.label"),
      url: "/who-we-serve/entertainment-venues/beach-bars",
    },
  ]
];

const dataFB = [
  [
    {
      icon: SvgRest,
      label: t("components.serve_carousel.restaurants.label"),
    },
    {
      icon: SvgBistro,
      label: t("components.serve_carousel.bistros.label"),
    },

  ],
  [
    {
      icon: SvgCafe,
      label: t("components.serve_carousel.cafes.label"),
    },
    {
      icon: SvgBakeries,
      label: t("components.serve_carousel.bakeries.label"),
    },
  ],
  [
    {
      icon: SvgBar,
      label: t("components.serve_carousel.bars.label"),
    },
    {
      icon: SvgPubs,
      label: t("components.serve_carousel.pubclubs.label"),
    },
  ],
  [
    {
      icon: SvgTruck,
      label: t("components.serve_carousel.foodtrucks.label"),
    },
    {
      icon: SvgDinning,
      label: t("components.serve_carousel.finedining.label"),
    },
  ],
  [
    {
      icon: SvgFastFood,
      label: t("components.serve_carousel.fastfood.label"),
    },
    {
      icon: SvgCaterer,
      label: t("components.serve_carousel.catering.label"),
    },


  ],
  [
    {
      icon: SvgIceCream,
      label: t("components.serve_carousel.icecream.label"),
    },
    {
      icon: SvgFoodStalls,
      label: t("components.serve_carousel.foodstalls.label"),
    },

  ],
  [
    {
      icon: SvgSupperClubs,
      label: t("components.serve_carousel.supperclubs.label"),
    },
    {
      icon: SvgPizzeria,
      label: t("components.serve_carousel.pizzerias.label"),
    },

  ],
  [
    {
      icon: SvgBuffets,
      label: t("components.serve_carousel.buffets.label"),
    },
    {
      icon: SvgPopUp,
      label: t("components.serve_carousel.popups.label"),
    },

  ],
  [
    {
      icon: SvgJuiceBars,
      label: t("components.serve_carousel.juicebars.label"),
    },
    {
      icon: SvgFoodHalls,
      label: t("components.serve_carousel.foodhalls.label"),
    },

  ],
  [
    {
      icon: SvgCoffeShop,
      label: t("components.serve_carousel.coffee_shops.label"),
    },
    {
      icon: SvgDelis,
      label: t("components.serve_carousel.delis.label"),
    },

  ],
    [
    {
      icon: SvgFoodCourts,
      label: t("components.serve_carousel.food_courts.label"),
    },
    ]
];
const dataACC = [
  [
    {
      icon: SvgHotel,
      label: t("components.serve_carousel.accom_hotel.label"),
      url: "/who-we-serve/accommodation/hotels",
    },
    {
      icon: SvgCozy,
      label: t("components.serve_carousel.accom_cozy.label"),
      url: "/who-we-serve/accommodation/cozy-retreats",
    },

  ],
  [
    {
      icon: SvgHostel,
      label: t("components.serve_carousel.accom_hostel.label"),
      url: "/who-we-serve/accommodation/hostels",
    },
    {
      icon: SvgHotelChain,
      label: t("components.serve_carousel.accom_hotelchain.label"),
      url: "/who-we-serve/accommodation/hotel-chains",
    },
  ],
  [
    {
      icon: SvgVacation,
      label: t("components.serve_carousel.accom_vacation.label"),
      url: "/who-we-serve/accommodation/vacation-rentals",
    },
    {
      icon: SvgLuxury,
      label: t("components.serve_carousel.accom_luxury.label"),
      url: "/who-we-serve/accommodation/luxury-resorts",
    },
  ],
  [
    {
      icon: SvgFamily,
      label: t("components.serve_carousel.accom_family.label"),
      url: "/who-we-serve/accommodation/family-resorts",
    },
    {
      icon: SvgService,
      label: t("components.serve_carousel.accom_service.label"),
      url: "/who-we-serve/accommodation/service-apartments",
    },
  ],
  [
    {
      icon: SvgBed,
      label: t("components.serve_carousel.accom_bed.label"),
      url: "/who-we-serve/accommodation/bed-and-breakfast",
    },
    {
      icon: SvgMotel,
      label: t("components.serve_carousel.accom_motel.label"),
      url: "/who-we-serve/accommodation/motel",
    },


  ],
  [
    {
      icon: SvgCapsule,
      label: t("components.serve_carousel.accom_capsule.label"),
      url: "/who-we-serve/accommodation/capsule-hotel",
    },
    {
      icon: SvgYouth,
      label: t("components.serve_carousel.accom_youth.label"),
      url: "/who-we-serve/accommodation/youth-hostel",
    },

  ],
  [
    {
      icon: SvgCamp,
      label: t("components.serve_carousel.accom_campground.label"),
      url: "/who-we-serve/accommodation/campground",
    },
    {
      icon: SvgRv,
      label: t("components.serve_carousel.accom_rv.label"),
      url: "/who-we-serve/accommodation/rv-park",
    },
  ],
];
const dataRT = [
  [
    {
      icon: SvgSport,
      label: t("components.serve_carousel.retail_sport.label"),
      url: "/who-we-serve/retail-management/sporting-goods-stores",
    },
    {
      icon: SvgHome,
      label: t("components.serve_carousel.retail_home.label"),
      url: "/who-we-serve/retail-management/home-decor",
    },

  ],
  [
    {
      icon: SvgFashion,
      label: t("components.serve_carousel.retail_fashion.label"),
      url: "/who-we-serve/retail-management/fashion-boutiques",
    },
    {
      icon: SvgChain,
      label: t("components.serve_carousel.retail_chain.label"),
      url: "/who-we-serve/retail-management/retail-chains",
    },
  ],
  [
    {
      icon: SvgSpecialty,
      label: t("components.serve_carousel.retail_specialty.label"),
      url: "/who-we-serve/retail-management/boutiques-and-specialty-stores",
    },
    {
      icon: SvgOnline,
      label: t("components.serve_carousel.retail_online.label"),
      url: "/who-we-serve/retail-management/online-shops",
    },
  ],
  [
    {
      icon: SvgGrocery,
      label: t("components.serve_carousel.retail_grocery.label"),
      url: "/who-we-serve/retail-management/grocery-stores",
    },
    {
      icon: SvgElectron,
      label: t("components.serve_carousel.retail_electron.label"),
      url: "/who-we-serve/retail-management/electronics-stores",
    },
  ],
  [
    {
      icon: SvgPharmacy,
      label: t("components.serve_carousel.retail_pharmacy.label"),
      url: "/who-we-serve/retail-management/pharmacies",
    },
    {
      icon: SvgAuto,
      label: t("components.serve_carousel.retail_auto.label"),
      url: "/who-we-serve/retail-management/auto-parts-stores"
    },
  ],
  [
    {
      icon: SvgLiquor,
      label: t("components.serve_carousel.retail_liquor.label"),
      url: "/who-we-serve/retail-management/liquor-stores",
    },
    {
      icon: SvgPet,
      label: t("components.serve_carousel.retail_pet.label"),
      url: "/who-we-serve/retail-management/pet-supply-stores",
    },
  ],
];

const ServicesCarousel = ({ type }) => {
  const carouselRef = useRef(null);
  const [curSlide, setCurSlide] = useState(0);

  const data = useMemo(()=>{
    if (type === 'entertainment_venues') {
      return dataET;
    }
    else if (type === 'food') {
      return dataFB;
    }
    else if (type === 'accommodation') {
      return dataACC;
    }
    else if (type === 'retail') {
      return dataRT;
    }
  }, [type])

  const handleNextSlide = () => {
    const nextSlide = curSlide + 1;
    if (nextSlide < data.length) {
      carouselRef.current?.next();
      setCurSlide(nextSlide);
    }
  };

  const handlePrevSlide = () => {
    if (curSlide > 0) {
      carouselRef.current?.previous();
      setCurSlide(curSlide - 1);
    }
  };


  return (
    <div className="flex flex-col justify-center w-full">
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        infinite={false}
        autoPlay={false}
        showDots={false}
        partialVisible={false}
        arrows={false}
        swipeable={false}
        draggable={false}
        keyBoardControl={false}
        containerClass={"pt-6  services-carousel w-full"}
      >
        {data.map((item, index) => (
          <div
            onClick={() => {}}
            className="flex flex-col align-middle "
            key={index}
          >
            <BusinessServiceCard type={type} data={item[0]} />
            {/* <div className="h-[8px]" /> */}
            <BusinessServiceCard type={type} data={item[1]} />
          </div>
        ))}
      </Carousel>
      <div className="flex flex-row justify-center items-center">
        <div className="flex flex-row justify-center items-center cursor-pointer px-3 py-3  rounded-full"
          // onClick={()=>{
          //   // const nextSlide = carouselRef.current?.state.currentSlide - 1;
          //   if (curSlide == 1) {
          //     carouselRef.current?.goToSlide(0)
          //     setCurSlide(0)
          //   }
          // }}
             onClick={handlePrevSlide}
        >
          <BsArrowLeft size={18} color={curSlide > 0 ? '#5C1C81' : '#C0C0C0'} />

        </div>
        <div className="w-[16px]" />
        <div className="flex flex-row justify-center items-center cursor-pointer px-3 py-3  rounded-full"
          // onClick={()=>{
          //   // const nextSlide = carouselRef.current?.state.currentSlide + 1;
          //   if (curSlide == 0) {
          //     carouselRef.current?.goToSlide(1)
          //     setCurSlide(1)
          //   }
          // }}
             onClick={handleNextSlide}
        >
          <BsArrowRight size={18} color={curSlide < data.length - 1 ? '#5C1C81' : '#C0C0C0'} />

        </div>
      </div>
    </div>
  );
};
export default ServicesCarousel;
