import { getStatus } from "../../../components/columns/get-status";

export const columns = [
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
    render: (brand) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{brand}</p>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{type}</p>
    ),
  },
  {
    title: "Expiry Date",
    dataIndex: "expiryDate",
    key: "expiryDate",
    render: (date) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{date}</p>
    ),
  },
  {
    title: "Promo",
    dataIndex: "promo",
    key: "promo",
    render: (promo) => (
      <p className="text-14 text-[#240b3b] leading-5 font-bold">{promo}</p>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: getStatus,
  },
];

export const dataSource = [
  {
    key: "1",
    brand: "Swatch",
    type: "Discount",
    expiryDate: "15/10/2024",
    promo: "SWATCH15",
    status: "Active",
  },
  {
    key: "2",
    brand: "Swarovski",
    type: "Discount",
    expiryDate: "15/10/2024",
    promo: "SW30OFF",
    status: "Active",
  },
  {
    key: "3",
    brand: "All Brands",
    type: "Coupon",
    expiryDate: "15/10/2024",
    promo: "BBVRTC752XL",
    status: "Active",
  },
];
