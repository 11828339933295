import React from "react";

import GenericAccommodationHeader from "./generic-acc-header";

const AccommodationHeader = ({ TagsButtonData, data }) => {
  return (
    <GenericAccommodationHeader
      {...{
        TagsButtonData,
        data,
        selectApartments: true,
        title: "Helping You Find The Most Comfortable Place",
      }}
    />
  );
};

export default AccommodationHeader;
