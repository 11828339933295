import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const ByBestSidebar = () => {
    const { app_key } = useParams();
    return (
        <div className="hidden md:flex flex-col items-start justify-start col-span-2">
            <h3 className="text-[14px] leading-[21px] font-bold text-[#000] uppercase">Shërbimi i Klientit</h3>
            <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/help`}>Ndihmë</Link>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/frequently-asked-questions`}>Pyetjet më të shpeshta</Link>
            <h3 className="mt-5 text-[14px] leading-[21px] font-bold text-[#000] uppercase">Karriera</h3>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/job-listing`}>Vende Vakante</Link>
            <h3 className="mt-5 text-[14px] leading-[21px] font-bold text-[#000] uppercase">BY BEST</h3>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/contact-us`}>Na kontaktoni</Link>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/about-us`}>Rreth Nesh</Link>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/store-and-hours`}>Dyqanet dhe Oraret</Link>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/privacy-policy`}>Politikat e Privatësisë</Link>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/cookies`}>Politikat e Cookies</Link>
            <h3 className="mt-5 text-[14px] leading-[21px] font-bold text-[#000] uppercase">Blerjet Online</h3>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/prices-and-currency`}>Çmimet dhe monedha</Link>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/payment-methods`}>Menyrat e Pagesës</Link>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/shipping-methods`}>Dergesat & Kostot</Link>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/return-policy`}>Shkëmbimi i Produkteve</Link>
             <Link className="mt-2 text-[13px] leading-[20px] text-[#000] uppercase" to={`/shop/${app_key}/page/virtual-gift-card`}>Karta virtuale dhuratë</Link>
        </div>
    );
};

export default ByBestSidebar;
