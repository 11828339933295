import React, { useState } from "react";
import "./index.css";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useEffect } from "react";
import { t } from "i18next";

const BybestDropdownMenu = ({ menu }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { app_key } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setIsCollapsed(false);
  }, [pathname]);

  const goCategory = (category, item) => {
    navigate(
      `/shop/${app_key}/category/${item.replace(/\s+/g, "-").toLowerCase()}`,
      {
        state: {
          category: category,
          item: item,
        },
      }
    );
  };

  return (
    <div className="relative">
      <span
        onMouseEnter={() => setIsCollapsed(true)}
        onMouseLeave={() => setIsCollapsed(false)}
        className={` uppercase text-[13px] leading-[20px] font-bold text-[#000] whitespace-nowrap cursor-pointer py-[12px] px-[20px] hover:!text-[#E14848] ${
          isCollapsed ? "bg-[#f4f4f4]" : ""
        }`}
      >
        {menu.label}
      </span>
      {isCollapsed && (
        <>
          {menu.menu_image == null && (
            <div
              className={` absolute overflow-hidden top-[24px] left-[-50%]     bg-[#f4f4f4] px-5  transition-all duration-100 delay-100 `}
              onMouseLeave={() => setIsCollapsed(false)}
              onMouseEnter={() => setIsCollapsed(true)}
            >
              <ul className={`flex flex-col text-18`}>
                {menu.menus.map((item, i) => (
                  <li
                    key={i}
                    className=" py-1 lg:py-2 text-[14px] text-[#666] hover:text-[#eab600] whitespace-nowrap uppercase"
                  >
                    <Link to={`/shop/${app_key}/${item.link}`}>
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {menu.menu_image != null && (
            <div
              className={`w-[100vw] fixed top-[155px] left-0 right-0 flex justify-center items-center  overflow-hidden  transition-all duration-100 delay-100 `}
            >
              <div
                className={`w-full max-w-screen-main bg-[#f4f4f4] p-5 grid grid-cols-6 gap-8`}
                onMouseLeave={() => setIsCollapsed(false)}
                onMouseEnter={() => setIsCollapsed(true)}
              >
                <div>
                  <img
                    src={menu.menu_image}
                    className={"w-full h-full object-cover"}
                    alt="icon"
                  />
                </div>
                {menu.menus.map((item, i) => (
                  <div className="flex flex-col justify-start items-start">
                    <p className="text-[13px] font-bold text-[#000] uppercase">
                      {item.category}
                    </p>
                    {item.items.map((_item, i) => (
                      <div
                        key={i}
                        className="flex justify-start items-center cursor-pointer py-1 text-[13px] text-[#666] hover:text-[#eab600] uppercase"
                        onClick={() => goCategory(item.category, _item)}
                      >
                        {_item}
                        {(_item == "Flik flak" || _item == "SWATCH") && (
                          <img
                            src="https://bybest.shop/assets/img/icons/swiss.png"
                            className="ml-1 h-[13px] object-contain"
                          />
                        )}
                      </div>
                    ))}
                    <p className="text-[13px] font-bold text-[#666] uppercase">
                      {t("bybest.header.see_all")}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BybestDropdownMenu;
