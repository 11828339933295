import React from "react";
import { Slider } from "@mui/material";

const PriceRange = ({
  priceRange,
  setPriceRange,
  minRate = 0,
  maxRate = 2000,
}) => {
  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  return (
    <Slider
      value={priceRange}
      onChange={handlePriceChange}
      valueLabelDisplay="auto"
      min={minRate}
      max={maxRate}
      sx={{
        color: "#131118",
        height: 2,
        "& .MuiSlider-thumb": {
          backgroundColor: "#131118",
          height: 12,
          width: 12,
          color: "#131118",
        },
        "& .MuiSlider-track": {
          backgroundColor: "#131118",
          height: 2,
        },
      }}
    />
  );
};

export default PriceRange;
