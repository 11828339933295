import { createUUID } from './common';

export const KEYS = {
  USER_DATA: 'USER_DATA',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  CHAT_HISTORY: 'CHAT_HISTORY',
  CHAT_LAST_MSG: 'CHAT_LAST_MSG',
  LANGUAGE: 'LANGUAGE',
  CHAT_PREDEFINED: 'CHAT_PREDEFINED',
  CART_PRODUCTS: 'CART_PRODUCTS',
  CART_VENUE_KEY: 'CART_VENUE_KEY',
  CART_COUPON: 'CART_COUPON',
  HOSPITAL_ROOM_KEY: 'HOSPITAL_ROOM_KEY',
  ORDER_SUCCESS_DATA: 'ORDER_SUCCESS_DATA',
  COUNTRY_CODE: 'COUNTRY_CODE',
  REFERRAL_CODE: 'REFERRAL_CODE',
  AFFILIATE_CODE: 'AFFILIATE_CODE',
  WAITLIST_AUTH_KEY: "WAITLIST_AUTH_KEY",
  DEVICE_UNIQUE_ID: 'DEVICE_UNIQUE_ID',
  RESERVATION_DATA: 'RESERVATION_DATA'
};

export const getStorageKey = (key) => {
  let data = localStorage.getItem(key);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      // Handle the case where data is not valid JSON
      // You can return 'null' or some default value
      console.error("Error parsing JSON for key:", key, e);
      return null;
    }
  }
  return null;
};


export const setStorageKey = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeStorageKey = (key) => {
  localStorage.removeItem(key);
};

export const getDeviceUniqueId = () => {
  let uniqueId = localStorage.getItem(KEYS.DEVICE_UNIQUE_ID);
  if (uniqueId == null) {
    uniqueId = createUUID();
    localStorage.setItem(KEYS.DEVICE_UNIQUE_ID, uniqueId);
  }
  return uniqueId;
};