import React, { useEffect } from "react";
import "./index.css";
import ScrollToUpIcon from "../../assets/svgs/scroll-up.svg";
import { useDispatch, useSelector } from "react-redux";
import { setShowTopBtn } from "../../redux/actions/app";

const ScrollToTopButton = () => {
  const dispatch = useDispatch();
  const showTopBtn = useSelector((state) => state.app.showTopBtn);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        dispatch(setShowTopBtn(true));
      } else {
        dispatch(setShowTopBtn(false));
      }
    });
    // eslint-disable-next-line
  }, []);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className=" sm:hidden relative z-999">
      {showTopBtn === true && (
        <div
          className="rounded-[50%] fixed bottom-[85px] right-8 z-[1000]"
          onClick={() => goToTop()}
        >
          <img src={ScrollToUpIcon} className="w-[58px] h-[58px]" alt="icon" />
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
