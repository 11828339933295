import React from "react";
import BybestBlogItem from "../../components/BybestBlogItem";
import { blogs } from "./data";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestBlogs = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[16px] leading-[24px] text-[#000] ">
            BYBEST {`>`} BLOG {`>`} BLOG{" "}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6">
            {blogs.map((item) => (
              <BybestBlogItem key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestBlogs;
