import React from "react";
import "./index.css";
import { arrowDownIcon } from "./icons";

const DropdownFilter = ({
  title = "Filter",
  onClick,
  showIcon = true,
  className = "",
}) => {
  return (
    <div
      className={`dropdown-layout items-center gap-2 border p-2 cursor-pointer ${className}`}
      onClick={onClick}
    >
      <p>{title}</p>
      {showIcon && <span>{arrowDownIcon}</span>}
    </div>
  );
};

export default DropdownFilter;
