import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import {useNavigate} from "react-router-dom";

const learnList = [
  {
    title: 'Instant Impact Solutions',
    content: 'Transform Your Venue Today: Jumpstart your venue’s transformation with VenueBoost’s comprehensive suite. From instant booking enhancements to streamlined payments, see immediate improvements in efficiency and customer satisfaction.'
  },
  {
    title: 'Unified Platform Advantage',
    content: 'All-in-One Management Mastery: Unify all aspects of venue management with one powerful platform. VenueBoost brings together bookings, staff scheduling, and inventory management, simplifying your operations and freeing up your time.',
  },
  {
    title: 'Adaptive Growth Strategies',
    content: 'Grow Smarter, Not Harder: With VenueBoost, leverage data-driven insights and adaptive marketing tools to not only grow your business but also smartly adapt to ever-changing market dynamics and customer preferences.',
  },
]
const Learning = () => {

  const navigate = useNavigate();
  const navigateToUrl = (url) => {
    navigate(url);
  };

  return (
    <div className={"align-col-middle view-terms"}>
      <div className="w-[100vw] relative bg-transparent">
        <div className="mx-auto max-w-screen-main px-7 md:px-8 lg:px-16 pt-4 pb-8">
          <div className='flex flex-col md:flex-row md:justify-between gap-6'>
            {learnList.map((item, index) => (
              <div key={index} className='flex flex-col rounded-lg px-8 py-8 bg-white max-w-[100%] md:max-w-[340px] gap-2'>
                <span className='text-pink1 text-2xl md:text-3xl font-bold leading-9'>{item.title}</span>
                <span className='text-black2 text-sm md:text-base font-medium'>{item.content}</span>
              </div>
            ))}
          </div>
          <div className='flex flex-row justify-center items-center gap-4 mt-10'>
            <span onClick={() => {
              navigateToUrl('/get-started')
            }} className='cursor-pointer text-primary1 text-lg font-bold'>Learn more about us</span>
            <BsArrowRight className='text-pink1 text-xl' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Learning;