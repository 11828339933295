import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestPrivacyPolicy = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
            BYBEST {`>`} FAQE {`>`} NA KONTATONI
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Kjo politikë e privatësisë do të shpjegojë se si organizata jonë
                përdor të dhënat personale që mbledhim nga ju kur përdorni faqen
                tonë të internetit.
              </p>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Temat:
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Çfarë të dhënash mbledhim?
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Si i mbledhim të dhënat tuaja?
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Si do t'i përdorim të dhënat tuaja?
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Si i ruajmë të dhënat tuaja?
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Marketingu
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Cilat janë të drejtat tuaja për mbrojtjen e të dhënave?
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Çfarë janë cookies?
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Si i përdorim cookies?
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  What types of cookies do we use?
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Çfarë lloje të cookie-ve përdorim?
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Politikat e privatësisë së faqeve të tjera të internetit
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Ndryshime në politikën tonë të privatësisë
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Si të na kontaktoni
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Si të kontaktoni autoritetet përkatëse
                </li>
              </ul>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                ÇFARË TË DHËNASH MBLEDHIM?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                BYBEST.SHOP mbledh të dhënat e mëposhtme:
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Informacion personal identifikues (Emri, adresa e emailit,
                  numri i telefonit, adresa e transportit.)
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Numri i porosive
                </li>
              </ul>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                SI I MBLIDHIM TË DHËNAT TUAJA?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                BYBEST.SHOP ruan shumicën e të dhënave që ju ndani gjatë kohës
                që:
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Regjistroheni në faqen BYBEST.SHOP ose bëni një porosi për
                  ndonjë nga produktet ose shërbimet tona.
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Plotësoni vullnetarisht një anketë me klientët ose jepni
                  komente për cilindo nga tabelat tona të mesazheve ose me
                  email.
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Përdorni ose shikoni faqen tonë të internetit nëpërmjet
                  cookies të shfletuesit (browser) tuaj.
                </li>
              </ul>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                SI DO TË PËRDORIM TË DHËNAT TUAJA?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Kompania jonë mbledh të dhënat tuaja në mënyrë që ne të mund të:
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Procesojë porosinë tuaj ose menaxhojë llogarinë tuaj.
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Ju dërgon email me oferta speciale për produkte dhe shërbime
                  të tjera që mendojmë se mund t'ju pëlqejnë.
                </li>
              </ul>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Nëse jeni dakord, kompania jonë do të ndajë të dhënat tuaja me
                kompanitë tona partnere në mënyrë që ata t'ju ofrojnë produktet
                dhe shërbimet e tyre.
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                SI I RUAJMË TË DHËNAT TUAJA?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                BYBEST.SHOP ruan në mënyrë të sigurt të dhënat tuaja në Amazon
                Web Services.
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                MARKETINGU
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Kompania jonë dëshiron t'ju dërgojë informacione rreth
                produkteve dhe shërbimeve tona që ne mendojmë se mund t'ju
                pëlqejnë. Nëse keni rënë dakord të merrni emaile të tilla,
                gjithmonë mund të tërhiqeni në një datë të mëvonshme. Ju keni të
                drejtë në çdo kohë të ndaloni BYBEST.SHOP të kontaktojë me ju
                për qëllime marketingu. Nëse nuk dëshironi më të kontaktoheni
                për qëllime marketingu, ju lutemi klikoni këtu.
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                CILAT JANË TË DREJTAT TUAJA PËR MBROJTJEN E TË DHËNAVE?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                BYBEST.SHOP dëshiron të sigurohet që ju jeni plotësisht të
                vetëdijshëm për të gjitha të drejtat tuaja për mbrojtjen e të
                dhënave. Çdo përdorues ka të drejtë për sa vijon:
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  E drejta për korrigjim – Ju keni të drejtën të kërkoni që
                  Kompania jonë të korrigjojë çdo informacion që mendoni se
                  është i pasaktë. Ju gjithashtu keni të drejtë të kërkoni nga
                  Kompania jonë të plotësojë informacionin që ju besoni se është
                  i paplotë.
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  E drejta për të kufizuar përpunimin – Ju keni të drejtë të
                  kërkoni që Kompania jonë të kufizojë përpunimin e të dhënave
                  tuaja personale, në kushte të caktuara.
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  E drejta për të kundërshtuar përpunimin – Ju keni të drejtë të
                  kundërshtoni përpunimin e të dhënave tuaja personale nga
                  Kompania jonë, në kushte të caktuara.
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  E drejta për transportueshmëri të të dhënave – Ju keni të
                  drejtë të kërkoni që kompania jonë t'i transferojë të dhënat
                  që kemi mbledhur te një organizatë tjetër, ose drejtpërdrejt
                  tek ju, në kushte të caktuara.
                </li>
              </ul>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Nëse bëni një kërkesë, ne kemi një muaj kohë për t'ju
                përgjigjur. Nëse dëshironi të ushtroni ndonjë nga këto të
                drejta, ju lutemi na kontaktoni në emailin tonë:
                <br />
                Na dërgoni email në: customerservice@bybest.shop
                <br />
                Na telefononi: +355693561111
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                COOKIES
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Cookies janë skedarë teksti të vendosura në kompjuterin tuaj për
                të mbledhur informacionin standard të regjistrit të internetit
                dhe informacionin e sjelljes së vizitorëve. Kur vizitoni faqet
                tona të internetit, ne mund të mbledhim informacion nga ju
                automatikisht përmes cookies ose teknologjive të ngjashme Për më
                shumë informacion, vizitoni allaboutcookies.org.
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                SI TË PËRDORIM COOKIES?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Kompania jonë përdor cookies në një sërë mënyrash për të
                përmirësuar përvojën tuaj në faqen tonë të internetit, duke
                përfshirë:
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Duke ju mbajtur të loguar/kycur
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Të kuptojmë se si e përdorni faqen tonë të internetit
                </li>
              </ul>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                CILAT LLOJ TË COOKIE NE PËRDORIM?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Ekzistojnë një numër i llojeve të ndryshme të cookie-ve,
                megjithatë, faqja jonë e internetit përdor:
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  (Functionality) Funksionale – Kompania jonë i përdor këto
                  skedarë cookie në mënyrë që ne t'ju njohim në faqen tonë të
                  internetit dhe të kujtojmë preferencat tuaja të zgjedhura më
                  parë. Këto mund të përfshijnë gjuhën që preferoni dhe
                  vendndodhjen në të cilën jeni. Përdoret një përzierje e kukit
                  të palës së parë dhe të palës së tretë.
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  (Advertising) Reklamimi – Kompania jonë i përdor këto cookie
                  për të mbledhur informacion në lidhje me vizitën tuaj në faqen
                  tonë të internetit, përmbajtjen që keni parë, lidhjet që keni
                  ndjekur dhe informacione në lidhje me shfletuesin, pajisjen
                  dhe adresën tuaj IP. Kompania jonë ndonjëherë ndan disa
                  aspekte të kufizuara të këtyre të dhënave me palët e treta për
                  qëllime reklamimi. Ne gjithashtu mund të ndajmë të dhëna në
                  internet të mbledhura përmes cookies me partnerët tanë
                  reklamues. Kjo do të thotë që kur vizitoni një uebsajt tjetër,
                  mund t'ju shfaqen reklama bazuar në modelet tuaja të
                  shfletimit në faqen tonë të internetit.
                </li>
              </ul>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                SI TË MENAXHONI COOKIES?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Mund ta vendosni shfletuesin (browser-in) tuaj që të mos pranojë
                skedarë skedarësh dhe faqja e mësipërme e internetit ju tregon
                se si të hiqni skedarët e skedarëve nga shfletuesi juaj.
                Megjithatë, në disa raste, disa nga veçoritë e faqes sonë të
                internetit mund të mos funksionojnë si rezultat.
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                PRIVATËSIA POLITIKAT E FAQEVE TË TJERA
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Faqja e internetit BYBEST.SHOP përmban lidhje me faqet e tjera
                të internetit. Politika jonë e privatësisë zbatohet vetëm për
                faqen tonë të internetit, kështu që nëse klikoni në një lidhje
                në një uebsajt tjetër, duhet të lexoni politikën e tyre të
                privatësisë.
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                NDRYSHIMET NË POLITIKËN TONË TË PRIVATËSISË
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                BYBEST.SHOP e mban politikën e saj të privatësisë nën shqyrtim
                të rregullt dhe vendos çdo përditësim në këtë faqe interneti.
                Kjo politikë e privatësisë u përditësua për herë të fundit më 16
                shkurt 2022.
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                SI TË KONTAKTONI?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Nëse keni ndonjë pyetje në lidhje me politikën e privatësisë së
                BYBEST.SHOP, të dhënat që ne mbajmë për ju, ose nëse dëshironi
                të ushtroni një nga të drejtat tuaja për mbrojtjen e të dhënave,
                ju lutemi mos hezitoni të na kontaktoni.
                <br />
                Na dërgoni email në: customerservice@bybest.shop
                <br />
                Na telefononi: +355693561111
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                SI TË KONTAKTOJMË AUTORITETIN E PËRSHTATSHËM?
              </h3>
              <p className="mt-[13px] text-[13px] leading-[25px] text-[#000]  ">
                Nëse dëshironi të raportoni një ankesë ose nëse mendoni se
                BYBEST.SHOP nuk e ka trajtuar shqetësimin tuaj në mënyrë të
                kënaqshme, mund të kontaktoni Zyrën e Komisionerit të
                Informacionit.
                <br />
                <br />
                Email: customerservice@bybest.shop
                <br />
                Tel: +355693561111
                <br />
                Address: Metropol - Qendra Tregtare, Rruga "Sami Frashëri",
                Tiranë, Shqipëri
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestPrivacyPolicy;
