import React from "react";
import "./index.css";
import { xIcon } from "./icons";

const WishlistItems = ({ items = [] }) => {
  return items.map((item, index) => (
    <div
      key={index}
      className="wishlist-item flex lg:flex-row flex-col items-start gap-2 relative"
    >
      <div className="image h-full">
        <img src={item.image} alt={item.title} />
      </div>

      <div className=" h-full flex flex-col gap-2 w-full justify-between description">
        <div className="w-full">
          <div className="absolute right-2 top-2 cursor-pointer">
            <span>{xIcon}</span>
          </div>
          <h3>{item.title}</h3>
          <p>Brand: {item.brand}</p>
          {item.collection && <p>Collection: {item.collection}</p>}
          {item.diameter && <p>Diameter: {item.diameter}</p>}
          {item.gender && <p>Gender: {item.gender}</p>}
          {item.mechanism && <p>Mechanism: {item.mechanism}</p>}
          <p>Price: {item.price} LEKE</p>
        </div>

        <div className="button">
          <button>
            <span>Add to Cart</span>
          </button>
        </div>
      </div>
    </div>
  ));
};

export default WishlistItems;
