import { useState } from "react";
import HotelHeader from "./hotel-header";
import HotelInfo from "./hotel-info";
import HotelPhotos from "./hotel-photos";
import HotelReservation from "./hotel-reservation";
import HotelTab from "./hotel-tab";
import HotelFacilities from "./hotel_facilities";
import ReservationForm from "../../components/ReservationForm";
import { useNavigate, useParams } from "react-router-dom";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import moment from "moment";
import Enroll from "../../components/venue/enroll";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";

const VenueHotel = () => {
  const { app_key } = useParams();
  const [details, setDetails] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [canEnroll, setCanEnroll] = useState(false);
  const [success, setSuccess] = useState(false);
  const { app_key: hotel_id } = useParams();
  const [reservation, setReservation] = useState({});
  const [enrollEmail, setEnrollEmail] = useState(null);
  const [enrolledCompleted, setEnrolledCompleted] = useState(false);
  const navigate = useNavigate();

  const setFormSubmited = (data) => {
    setReservation(data);
    setSubmitted(true);
  };
  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    localStorage.removeItem("venueData");
    WhiteLabelService.getDetails(hotel_id)
      .then((res) => {
        if (res.allowed_only_from !== "hotel") {
          navigate("/");
        } else {
          setDetails(res.venue);
        }
        identifyVenue(app_key);
        trackEvent("White Label - Hotel", {
          action_category: "Book",
          action_outcome: "success",
          interaction_element: "screen",
          source: "vb_web",
        });
        // golf_venue
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        identifyVenue(app_key);
        trackEvent("White Label - Hotel", {
          action_category: "Book",
          action_outcome: "fail",
          interaction_element: "screen",
          source: "vb_web",
        });
      });
  };
  const handleSubmit = (data) => {
    const payload = {
      start_time: moment(reservation?.date + " " + reservation?.time).format(
        "YYYY-MM-DD hh:mm"
      ),
      is_for_main: false,
      guest_count: reservation?.people,
      facility_type: reservation?.reservation,
      main_guest: {
        first_name: data.name,
        last_name: data?.lastName,
        email: data?.email,
        phone: data?.phone,
      },
      notes: data?.additional,
    };
    setEnrollEmail(data?.email);
    WhiteLabelService.reservation(payload, hotel_id)
      .then((res) => {
        setSuccess(true);
        if (res.data?.can_enroll) {
          setCanEnroll(true);
        }
        identifyVenue(app_key);
        trackEvent("White Label - Booking", {
          action_category: "Book",
          action_outcome: "success",
          interaction_element: "button",
          industry: "entertainment_venues",
          source: "vb_web",
        });
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong!");
        identifyVenue(app_key);
        trackEvent("White Label - Booking", {
          action_category: "Book",
          action_outcome: "fail",
          interaction_element: "button",
          source: "vb_web",
        });
      });
  };

  const onEnrollSubmit = () => {
    const payload = {
      email: enrollEmail,
    };
    WhiteLabelService.guestEnroll(payload, hotel_id)
      .then((res) => {
        NotificationManager.success(
          res?.message ?? "Guest enrolled successfully"
        );
        identifyVenue(app_key);
        trackEvent("White Label - Hotel", {
          action_category: "Guest Enroll",
          action_outcome: "success",
          interaction_element: "screen",
          source: "vb_web",
        });
        setEnrolledCompleted(true);
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong!");
        identifyVenue(app_key);
        trackEvent("White Label - Hotel", {
          action_category: "Guest Enroll",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "entertainment_venues",
          source: "vb_web",
        });
      });
  };

  return (
    <div className="flex flex-col">
      <HotelHeader data={details} />
      {submitted ? (
        <div>
          <ReservationForm
            success={success}
            data={reservation}
            onBack={() => setSubmitted(false)}
            onSubmit={(data) => handleSubmit(data)}
          />
          {canEnroll ? (
            <Enroll onSubmit={onEnrollSubmit} completed={enrolledCompleted} />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="bg-white">
          <HotelTab photos={details?.photos} />
          <div className="grid md:grid-cols-5 grid-cols-1 gap-10 max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full">
            <div className="md:col-span-3 flex flex-col">
              <HotelPhotos photos={details?.photos ?? []} />
              <HotelFacilities
                facilities={details?.facilities}
                menu={details?.menu ?? {}}
                venueKey={hotel_id}
              />
            </div>
            <div className="md:col-span-2 flex flex-col">
              <HotelReservation onAction={(data) => setFormSubmited(data)} />
              <HotelInfo data={details} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VenueHotel;
