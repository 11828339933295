import React from 'react';
import {isMobile} from "react-device-detect";

import FormText from '../../../components/FormText';
import bowling1Img from '../../../assets/images/services/bowling1.png';
import bowling2Img from '../../../assets/images/services/bowling2.png';
import bowling3Img from '../../../assets/images/services/bowling3.png';
import bowling4Img from '../../../assets/images/services/bowling4.png';

const BowlingPhotos = ({ photos }) => {

  return (
    <div className='md:col-span-3 flex flex-col pt-4 md:pt-8'>
      <FormText title='Photos' type='itemtitle-sub' />
      {
        isMobile ?
          <div className="flex flex-row justify-between w-full h-[200px] md:h-[400px] mt-4">
            <div style={{width: '100%', height: '100%', position: 'relative'}}>
              <img style={{width: 143.18, height: 200.20, left: 0, top: 0, position: 'absolute', background: 'linear-gradient(0deg, #F9F7F7 0%, #F9F7F7 100%)', borderRadius: 6.43, border: '0.49px #E0E0E0 solid'}} src={bowling1Img ??  "https://via.placeholder.com/288x405"} alt='img'/>
              <img style={{width: 97.94, height: 97.38, left: 148.66, top: -0, position: 'absolute', background: 'linear-gradient(0deg, #F9F7F7 0%, #F9F7F7 100%)', borderRadius: 6.43, border: '0.49px #E0E0E0 solid'}} src={bowling2Img ?? "https://via.placeholder.com/197x197"}  alt='img'/>
              <img style={{width: 97.94, height: 97.38, left: 148.66, top: 102.82, position: 'absolute', background: 'linear-gradient(0deg, #F9F7F7 0%, #F9F7F7 100%)', borderRadius: 6.43, border: '0.49px #E0E0E0 solid'}} src={bowling3Img ?? "https://via.placeholder.com/197x197"}  alt='img'/>
              <img style={{width: 97.94, height: 97.38, left: 252.05, top: -0, position: 'absolute', background: 'linear-gradient(0deg, #F9F7F7 0%, #F9F7F7 100%)', borderRadius: 6.43, border: '0.49px #E0E0E0 solid'}} src={bowling4Img ?? "https://via.placeholder.com/197x197"} alt='img'/>
              <img style={{width: 97.94, height: 97.38, left: 252, top: 103, position: 'absolute', background: '#F9F7F7', borderRadius: 6.43, border: '0.49px #E0E0E0 solid'}}  src={"https://via.placeholder.com/197x197"}    alt='img'/>
            </div>
          </div>
        :
        <div className="flex flex-row justify-between w-full h-[200px] md:h-[400px] mt-4">
          <div style={{width: '100%', height: '100%', position: 'relative'}}>
            <img style={{width: 288, height: 405, left: 0, top: 0, position: 'absolute', background: 'linear-gradient(0deg, #F9F7F7 0%, #F9F7F7 100%)', borderRadius: 13, border: '1px #E0E0E0 solid'}} src={bowling1Img ?? "https://via.placeholder.com/143x200"}  alt='img'/>
            <img style={{width: 197, height: 197, left: 299, top: 0, position: 'absolute', background: 'linear-gradient(0deg, #F9F7F7 0%, #F9F7F7 100%)', borderRadius: 13, border: '1px #E0E0E0 solid'}} src={bowling2Img ?? "https://via.placeholder.com/98x97"} alt='img'/>
            <img style={{width: 197, height: 197, left: 299, top: 208, position: 'absolute', background: 'linear-gradient(0deg, #F9F7F7 0%, #F9F7F7 100%)', borderRadius: 13, border: '1px #E0E0E0 solid'}} src={bowling3Img ?? "https://via.placeholder.com/98x97"}  alt='img'/>
            <img style={{width: 197, height: 197, left: 507, top: 0, position: 'absolute', background: 'linear-gradient(0deg, #F9F7F7 0%, #F9F7F7 100%)', borderRadius: 13, border: '1px #E0E0E0 solid'}} src={bowling4Img ?? "https://via.placeholder.com/98x97"}  alt='img'/>
            <img style={{width: 197, height: 197, left: 507, top: 208, position: 'absolute', background: 'white', borderRadius: 13, border: '1px #E0E0E0 solid'}}  src={"https://via.placeholder.com/98x97"} alt='img'/>
          </div>
        </div>
      }
    </div>
  )
}

export default BowlingPhotos;
