import React, { useState, useEffect } from "react";
import { Select } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import RestaurantHeader from "../restaurant-header";
import '../index.css'
import SvgRaise from '../../../assets/svgs/resto-profile/raise.svg';
import SvgClock from '../../../assets/svgs/resto-profile/clock.svg';
import { generateDateArray, generateTimeArray, getUtcTimeIntervalsWithDuration } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { loadWhiteLabel } from "../../../redux/actions/venue";
import ReservationService from "../../../services/reservationService";

const MakeReservation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { app_key } = useParams();
  const venueData = useSelector(state => state.venue);

  const [people, setPeople] = useState(1);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [listDates, setListDates] = useState([]);
  const [listTimes, setListTimes] = useState([]);
  const [otherTimes, setOtherTimes] = useState([]);
  const [bookTimes, setBookTimes] = useState(0);

  useEffect(() => {
    dispatch(loadWhiteLabel(app_key));
    getTotalBookTimes(app_key);
  }, [app_key]);

  useEffect(() => {
    let dates = generateDateArray();
    setListDates(dates);

    const times = getUtcTimeIntervalsWithDuration(venueData?.overview?.reservation_start_time, venueData?.overview?.reservation_end_time, 30, 2);
    setListTimes(times);
    
    setPeople(1)
    setDate(dates[0]?.value)
    setTime(times[0]?.value)
  }, [venueData]);

  useEffect(() => {
    const index = listTimes.findIndex(t => t.value == time);
    let tmp = [];
    let cnt = 0;
    for (let i = index + 1; i < listTimes.length; i ++) {
      if (cnt < 4) {
        tmp.push(listTimes[i])
        cnt ++;
      }
      else {
        break;
      }
    }
    setOtherTimes(tmp);
  }, [listTimes, time])

  const getTotalBookTimes = (app_key) => {
    ReservationService.getBookTimes(app_key)
      .then((res) => {
        setBookTimes(res.data)
      })
      .catch((err) => {
        console.log('getTotalBookTimes ', err)
      });
  }

  const onGoReserve = (selected_time) => {
    navigate(`/venue/restaurant/${app_key}/reserve`, {state: {
      people,
      date,
      time: selected_time
    }})
  }

  return (
    <div className="w-full make-reservation">
      <RestaurantHeader icon={venueData?.overview?.logo}/>
      <div className="w-full bg-[#fdfdfd] px-7 py-8">
        <div className="w-full rounded-24 bg-white p-4 shadow-md">
          <h4 className="manrope-font font-bold text-20 text-primary1">Make a reservation</h4>
          <p className="manrope-font font-medium text-[15px] leading-[17px] text-[#747474] mt-2">Define numbr of guests and your timeslot and find the suitable table for your reservation</p>
          <div className="manrope-font font-medium text-[15px] text-[#8F8F8F] mt-5 mb-[5px]">How many people?</div>
          <Select
            optionFilterProp="label"
            value={people}
            onChange={setPeople}
            className={'w-full h-[48px] rounded-[10px] reserve-select'}
            options={Array(50).fill().map((_, index) => ({
              value: index + 1,
              label: `${index + 1} person`,
            }))}
          />
          <div className="grid grid-cols-2 gap-2 mt-2">
            <div >
              <div className="manrope-font font-medium text-[15px] text-[#8F8F8F] mb-[5px]">Date</div>
              <Select
                optionFilterProp="label"
                value={date}
                onChange={setDate}
                className={'w-full h-[48px] rounded-[10px] reserve-select'}
                options={listDates}
              />
            </div>
            <div >
              <div className="manrope-font font-medium text-[15px] text-[#8F8F8F] mb-[5px]">Time</div>
              <Select
                optionFilterProp="label"
                value={time}
                onChange={setTime}
                className={'w-full h-[48px] rounded-[10px] reserve-select'}
                options={listTimes}
              />
            </div>
          </div>
          <button className="mt-3 h-10 w-full rounded-[10px] bg-green1 manrope-font text-white font-bold text-[16px]"
            onClick={() => onGoReserve(time)}
          >Find a time</button>
          <div className="flex w-full mt-4">
            <img src={SvgRaise} />
            <div className=" ml-1 manrope-font font-medium text-[13px] text-primary1">Booked {bookTimes} {bookTimes > 1 ? 'times' : 'time'} today</div>
          </div>
          <div className="flex w-full mt-1">
            <img src={SvgClock} />
            <div className=" ml-1 manrope-font font-medium text-[13px] text-primary1">You're in luck! We still have {listTimes.length} timeslots left</div>
          </div>
          <p className="manrope-font font-bold text-16 text-primary1 mt-4">Select a time</p>
          <div className="grid grid-cols-2 gap-2 mt-[10px]">
            {
              otherTimes.map(t =>
                <div key={t.value}>
                  <button className="h-10 w-full rounded-[10px] bg-green1 manrope-font text-white font-bold text-[15px]"
                    onClick={() => onGoReserve(t.value)}>{t.label}</button>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="text-[#1A1818] text-center text-16 font-medium mb-2 px-4">
        ©2024 - All Rights Reserved️ - Powered by VenueBoost
      </div>
    </div>
  );
};

export default MakeReservation;
