import React from "react";
import "./index.css";

const Subtotal = () => {
  const data = [
    { label: "Subtotal", value: "8150.00 LEK" },
    { label: "Shipping Cost", value: "8150.00 LEK" },
    { label: "Discount", value: "8150.00 LEK" },
  ];
  return (
    <div className="flex flex-col gap-2 bg-white">
      {data.map((item, index) => (
        <div className="subtotal-wrapper" key={index}>
          <span>{item.label}</span>
          <p>{item.value}</p>
        </div>
      ))}
    </div>
  );
};

export default Subtotal;
