import React from "react";

import "./index.css";

const FormSelect = (props) => {
  const {
    name = "",
    dataList = [],
    value = "",
    enableLabel=true,
    placeholder = "",
    classes = '',
    isFromHome=false,
    className = "",
    onChanage = () => {},
    required = false
  } = props;

  return (
    <div className={`w-full ${classes}`}>
      { enableLabel &&
          <div className={"flex"}>
            <p className="text-16 text-[#232323]">{placeholder}</p>
            {required &&
                <span style={{color: "#F44336", marginLeft: "2px"}}>*</span>
            }
          </div>
      }
      <select
        name={name}
        value={value}
        onChange={onChanage}
        placeholder={placeholder}
        style={isFromHome ? { height: 42, marginTop: 15} : {}}
        className={`w-full bg-white h-[48px] border-[1px] mt-2 rounded-[5px] border-[#CBD5E1] px-2 focus:border-[1px] outline-none focus:border-blue-400 ${className}`}
      >
        {dataList.map((item, index) => (
          <option key={index} value={item.value}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormSelect;
