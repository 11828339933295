import React from "react";
import { Pagination } from "antd";
import "./index.css";
import DropdownFilter from "../Dropdown/dropdown-filter";

const GenericPagination = ({
  current = 1,
  total = 1,
  onChange,
  pageSize = 10,
}) => {
  const numberOfPages = Math.ceil(total / pageSize);

  const handlePrevious = () => {
    if (current > 1) {
      onChange(current - 1);
    }
  };

  const handleNext = () => {
    if (current < numberOfPages) {
      onChange(current + 1);
    }
  };

  return (
    <div className="flex justify-between gap-2 p-6">
      <div className="pagination-text">
        Page {current} of {numberOfPages}
      </div>
      <Pagination
        current={current}
        total={total}
        onChange={onChange}
        pageSize={pageSize}
        showSizeChanger={false}
        hideOnSinglePage={false}
      />
      <div className="flex items-center gap-2">
        <DropdownFilter
          showIcon={false}
          title="Previous"
          onClick={handlePrevious}
        />
        <DropdownFilter showIcon={false} title="Next" onClick={handleNext} />
      </div>
    </div>
  );
};

export default GenericPagination;
