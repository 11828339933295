import React from 'react';
import { Helmet } from 'react-helmet-async';

const MainHomeHead = ({ seo = {} }) => {
    const defaultSEO = {
        title: "VenueBoost | Simplify Venue Management with Streamlined Solutions",
        description: "VenueBoost | Simplify venue management with streamlined solutions for reservations, operations, and guest experiences.",
        ogImage: "https://venueboost.io/venueboost-saas.png",
        ogUrl: "https://venueboost.io/",
    };

    const mergedSEO = { ...defaultSEO, ...seo };

    return (
        <Helmet>
            <title>{mergedSEO.title}</title>
            <meta name="description" content={mergedSEO.description} />
            <meta property="og:title" content={mergedSEO.title} />
            <meta property="og:description" content={mergedSEO.description} />
            <meta property="og:image" content={mergedSEO.ogImage} />
            <meta property="og:url" content={mergedSEO.ogUrl} />

            {/* Insighto.ai chat widget */}
            <script>
                {`const insighto_ai_widget_id = "019028be-40be-784d-8c54-8079bd472d8b"`}
            </script>
            <script defer src="https://cdn.insighto.ai/assets/insighto.min.js"></script>
            <link rel="stylesheet" href="https://cdn.insighto.ai/assets/insighto.min.css" />
        </Helmet>
    );
};

export default MainHomeHead;