import React from "react";
import "./index.css";
import CustomerHeader from "../customer-header";
import { loginLabels } from "./data";

const LoginSecurityActivities = () => {
  return (
    <div className="login-security">
      <CustomerHeader
        title="Login Security and Activities"
        description="Last login: now"
        showItems={false}
        showHome={false}
      />
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] w-full " />
      <span className="activities">Activities</span>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] w-full " />

      {loginLabels.map((item, index) => (
        <>
          <span className="login-item">{item}</span>
          {index !== loginLabels.length - 1 && (
            <hr className="border-[1px] hidden md:block border-[#E5E7EB] w-full " />
          )}
        </>
      ))}
    </div>
  );
};

export default LoginSecurityActivities;
