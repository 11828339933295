import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ImgPlaceholder from "../../assets/images/img_placeholder.png";
import LineImg from '../../assets/images/line.png';

import {
  addToCart,
  removeFromCart,
  setCartShippingMethod,
  verifyCoupon,
} from "../../redux/actions/cart";
import "./index.css";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";
import { TableCart } from "./layout/table-cart";
import { Cart } from "./layout/cart";
import { productData } from "./checkout/data";

const CartScreen = () => {
  const cartProducts = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const { app_key } = useParams();
  const navigate = useNavigate();

  const {
    products,
    coupon_detail,
    currency,
    venueName,
    shippingMethod,
    venueLogo,
  } = useSelector((state) => state.cart);
  const [discount, setDiscount] = useState(0);
  const [tableProducts, setTableProducts] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState(0);
  const [quantities, setQuantities] = useState([]);
  const [shippingMethodListObject, setShippingMethodListObject] = useState([]);
  const [shippingMethodList, setShippingMethodList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    GetPageConfiguration();
  }, []);

  useEffect(() => {
    const initialQuantities = productData.map((element, index) => {
      if (index < quantities.length) {
        return quantities[index] < 1 ? 1 : quantities[index] ?? 1;
      } else {
        return element.quantity < 1 ? 1 : element.quantity ?? 1;
      }
    });

    setQuantities(initialQuantities);
  }, [productData]);

  const GetPageConfiguration = () => {
    WhiteLabelService.getShippingMethods(app_key)
      .then((res) => {
        const shippingMethods = res.shipping_methods.map((method) => ({
          value: method.method_id, // Assuming method_id is a string
          title: method.method_name,
        }));

        setShippingMethodListObject(shippingMethods);
        calculateShippingMethod(res.shipping_methods);
        identifyVenue(app_key);
        trackEvent("Cart Screen", {
          action_category: "Get Shipping Methods",
          action_outcome: "success",
          interaction_element: "screen",
          source: "vb_web",
        });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        identifyVenue(app_key);
        trackEvent("Cart Screen", {
          action_category: "Get Shipping Methods",
          action_outcome: "fail",
          interaction_element: "screen",
          source: "vb_web",
        });
      });
  };

  useEffect(() => {
    if (products) {
      let discount = 0;
      let shipping = 0;
      setTableProducts(products);
      if (coupon_detail) {
        dispatch(verifyCoupon(null));
        if (coupon_detail?.discount_value) {
          discount = coupon_detail?.discount_value;
        }
      }
      if (shippingMethod) {
        shipping = shippingMethod;
      }
      setSelectedShipping(shipping);
      setDiscount(discount);
      calculateShippingMethod(shippingMethodListObject);
    }
  }, [products, coupon_detail, shippingMethod]);

  const calculateShippingMethod = (shippings) => {
    const tmp = [];
    let productsTotal = 0;
    if (products) {
      if (products.length > 0) {
        productsTotal = products
          .map((item) => parseFloat(item.price * item?.quantity))
          .reduce((prev, next) => prev + next);
      }
    }
    if (shippings) {
      shippings.forEach((item) => {
        if (item.method_type == "flat_rate") {
          if (item.flat_rate_cost) {
            tmp.push({
              title: item.method_name,
              value: parseFloat(item.flat_rate_cost),
            });
          }
        } else if (item.method_type == "free_shipping") {
          if (item.minimum_order_amount) {
            const minimumOrderAmmount = parseFloat(item.minimum_order_amount);
            if (productsTotal > minimumOrderAmmount) {
              tmp.push({ title: item.method_name, value: 0 });
            }
          } else {
            tmp.push({ title: item.method_name, value: 0 });
          }
        }
      });
      setShippingMethodList(tmp);
      if (tmp.length === 1) {
        onSelectShippingMethod(tmp[0].value); // if shipping method has only one value, it will be bindet first
      }
    }
  };

  const onRemoveProduct = (product) => {
    setTableProducts(productData.filter((item) => item.id !== product.id));
  };

  const updateQuantityAtIndex = (product, index, increment = true) => {
    setQuantities((prevQuantities) => {
      const updatedQuantities = [...prevQuantities];
      if (increment) {
        const cartQuantity = getCartQuantity(product.id) ?? 1;

        updatedQuantities[index] += 1;
        if (cartQuantity + 1 <= product.stock_quantity) {
          const newProduct = { ...product, quantity: 1 };
          dispatch(
            addToCart(newProduct, app_key, venueName, currency, venueLogo)
          );
          NotificationManager.success("Product added to cart", "Success", 3000);
        } else {
          // Show a warning alert if the cart quantity would exceed the stock
          NotificationManager.warning(
            "Cannot add more. Stock limit reached",
            "Warning",
            3000
          );
        }
      } else {
        updatedQuantities[index] === 1
          ? (updatedQuantities[index] = 1)
          : (updatedQuantities[index] -= 1);
        if (product.quantity === 1) {
          dispatch(removeFromCart(product));
        } else {
          const newPrroduct = { ...product, quantity: -1 };
          dispatch(
            addToCart(newPrroduct, app_key, venueName, currency, venueLogo)
          );
        }
      }
      return updatedQuantities;
    });
  };

  const getCartQuantity = (productId) => {
    // Find the product in the cart by its ID and return its quantity
    const cartProduct = cartProducts.find(
      (product) => product.id === productId
    );
    return cartProduct ? cartProduct.quantity : 0;
  };

  const onSelectShippingMethod = (value) => {
    setSelectedShipping(value);
    dispatch(setCartShippingMethod(value));
  };

  return (
    <>
      <div className=" bg-opacity-5 h-[50px] w-full bg-[#000000] absolute left-0 top-[82px] items-center hidden md:flex ">
        <div className="large-width mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full">
          <div className="w-full">{ `Checkout >` }</div>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-4 w-full gap-5 mb-32 venue-home">
        <div className="col-span-3">
          <TableCart
            ImgPlaceholder={ImgPlaceholder}
            updateQuantityAtIndex={updateQuantityAtIndex}
            tableProducts={productData}
            quantities={quantities}
            onRemoveProduct={onRemoveProduct}
          />
        </div>
        <Cart
          tableProducts={productData}
          quantities={quantities}
          discount={discount}
          selectedShipping={selectedShipping}
          onClick={() => navigate(`/checkout`)}
        />
      </div>
    </>
  );
};

export default CartScreen;
