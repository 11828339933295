import hotelImg1 from '../../../assets/images/services/hotel_1.png';
import hotelImg2 from '../../../assets/images/services/hotel_2.png';
import hotelImg3 from '../../../assets/images/services/hotel_3.png';
import hotelImg4 from '../../../assets/images/services/hotel_4.png';
import FormText from '../../../components/FormText';

const HotelPhotos = ({ photos }) => {

  return (
    <div className='md:col-span-3 flex flex-col pt-8'>
      <FormText title='Photos' type='itemtitle-sub' />
      <div className="flex flex-row justify-between w-full h-[200px] md:h-[400px] mt-4">
        {photos.length > 1 ? <div className='w-[44%]'>
          <img src={photos[0]?.photo_path} alt='hotel1' className='h-full w-full' />
        </div> : null}
        {photos.length > 2 ? <div className='w-[26.5%] flex flex-col justify-between'>
          <img src={photos[1]?.photo_path} alt='hotel2' className='h-[48.5%]' />
          <img src={photos[2]?.photo_path} alt='hotel3' className='h-[48.5%]' />
        </div> : null}
        {photos.map((item, index) => {
          if (index > 3 && index < 7) {
            return (
              <div className='w-[26.5%] flex flex-col justify-between'>
                <img src={item?.photo_path} alt='hotel4' className='h-[48.5%]' />
              </div>
            );
          }
        })}
        {photos.length > 5 ? <div className='h-[48.5%] border rounded-xl flex items-center justify-center'>
          <FormText title={`+${photos.length - 5} more`} />
        </div> : null}
      </div>
    </div>
  )
}

export default HotelPhotos;
