import React from "react";
import RestaurantHeader from "../restaurant-header";
import Phone from "../../../assets/images/restaurants/phone.png";
import "./complete.css";
import { completeReservationOverviewData } from "../data";
import RestaurantButton from "../restaurant-button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  calendarIcon,
  clockIcon,
  noteIcon,
  phoneIcon,
  tableIcon,
  userIcon,
  mailIcon
} from "../reserve-complete/reserve-icons";
import { useMemo } from "react";
import { isEmpty } from "../../../utils/common";
import moment from "moment";

const ReserveComplete = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { app_key } = useParams();
  const venueData = useSelector((state) => state.venue);

  const completeReservationOverviewData = useMemo(() => {
    return [
      {
        title: (state?.main_guest?.is_phone == true ? state?.main_guest?.phone : state?.main_guest?.email),
        icon: (state?.main_guest?.is_phone == true ? phoneIcon : mailIcon),
      },
      {
        title: moment(state.date).format("ddd, MMM DD"),
        icon: calendarIcon,
      },
      {
        title: state.time,
        icon: clockIcon,
      },
      {
        title: state?.table?.name,
        icon: tableIcon,
      },
      {
        title: `${state?.guest_count} people`,
        icon: userIcon,
      },
      {
        title: isEmpty(state?.notes) ? "No note" : state?.notes,
        icon: noteIcon,
      },
    ];
  }, [state])

  const onGoProfile=() => {
    navigate(`/venue/restaurant/${app_key}/`);
  }

  return (
    <div className="bg-white flex flex-col gap-4">
      <RestaurantHeader icon={venueData?.overview?.logo}/>
      <div className="bg-[#fdfdfd] flex flex-col gap-4 py-2 px-4">
        <div className="flex justify-center items-center">
          <img alt="phone" src={Phone} />
        </div>
        <div className="flex flex-col gap-2 items-center">
          <h1 className="complete-text">
            Your Table was Reserved Successfully!
          </h1>
          <span className="complete-description w-4/5">
            Please respect our policies and do come to your reserved Table
            within the specified time.
          </span>
        </div>

        <div className="complete-reservation-wrapper">
          <div>
            <span className="complete-text-title">Reservation Overview</span>
            <div className="grid custom_380:grid-cols-3 grid-cols-2 gap-2">
              {completeReservationOverviewData.map((item, index) => (
                <div className="flex items-center gap-2" key={index}>
                  <span>{item.icon}</span>
                  <span className="complete-info-text">{item.title}</span>
                </div>
              ))}
            </div>
          </div>
          <span className="complete-text-title">
            Important dining information
          </span>

          <span className="complete-dining-information">
            We have a 15-30 minute grace period. Please call us if you are
            running later than 15-30 minutes after your booked time.
          </span>
          <span className="complete-dining-information">
            We may contact you about this booking, so please ensure your have
            your phone/email up to date
          </span>
        </div>

        <RestaurantButton title="Perfect, thank you" onClick={onGoProfile} />
      </div>
      <div className="text-[#1A1818] text-center text-16 font-medium mb-2 px-4">
        ©2024 - All Rights Reserved️ - Powered by VenueBoost
      </div>
    </div>
  );
};

export default ReserveComplete;
