import React from "react";
import Avatar from "../../assets/svgs/alex_r_avatar.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { NotificationManager } from "react-notifications";
import "./index.css";
import { logoutIcon } from "./icons";
import { SidebarLink } from "./sidebar-link";
import { appKey, links } from "./data";
import { useNavigate } from "react-router-dom";
import TierLevel from "./tier-level";

const Sidebar = ({ selected }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout())
      .then((data) => {
        NotificationManager.success(
          data?.message || "Logout Success",
          "Success",
          5000
        );
        navigate(`/shop/${appKey}/login`);
      })
      .catch((error) => {
        if (error?.message?.includes("Token has expired")) {
          navigate(`/shop/${appKey}/login`);
        }

        NotificationManager.error(
          error.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error("Error with logout");
      });

    localStorage.removeItem("vbToken");
  };

  return (
    <div className="sidebar hidden lg:w-[280px] bg-white md:flex flex-col border-[#0000001a] border-r-[1px]">
      <div className="user-profile">
        <img alt="" src={Avatar} className="w-16 h-16" />
        <div className="user-name">
          <h1>Ronald Richards</h1>
          <p className="text-[#666A73] text-14 ">BB Points: 200</p>
        </div>
      </div>

      <TierLevel className="w-full p-4" />

      <div className="flex flex-col justify-between h-full w-full">
        <div className="sidebar-item-list w-full">
          {links(selected).map((link) => (
            <SidebarLink
              {...link}
              selected={selected === link.key}
              appKey={appKey}
            />
          ))}
        </div>

        <div className="px-4">
          <SidebarLink
            icon={logoutIcon}
            label="Log out"
            onItemClick={onLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
