import React, { useEffect, useState } from "react";
// import "./index.css";
import { Seo } from "../../components/Seo";

import AirConditioningIcon from "../../assets/svgs/air-condition.svg";
import HeatingIcon from "../../assets/svgs/heating.svg";
import FreewifiIcon from "../../assets/svgs/free-wifi.svg";
import PetAllow from "../../assets/svgs/pet-allow.svg";
import FlatTV from "../../assets/svgs/flat-tv.svg";
import SwimmingpoolIcon from "../../assets/svgs/swimming-pool.svg";
import MinibarIcon from "../../assets/svgs/minibar.svg";
import KitchenIcon from "../../assets/svgs/kitchen.svg";
import WashingIcon from "../../assets/svgs/washing-machine.svg";
import RefrigeratorIcon from "../../assets/svgs/refrigerator.svg";
import GardenIcon from "../../assets/svgs/garden.svg";
import WhiteLabelService from "../../services/whiteLabelService";
import { useParams } from "react-router";
import { NotificationManager } from "react-notifications";

import RentalHeader from "./rental-header";
import RentalForm from "./rental-form";
import RentalLocation from "./rental-location";
import RentalInformation from "./rental-information";
import RentalOverview from "./rental-overview";
import ReservationForm from "../../components/ReservationForm";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";
import { useDispatch } from "react-redux";
import { SET_VENUE_DATA } from "../../redux/actions/types";

export const getFacilityImageByValue = (facility) => {
  if (facility === "Air conditioning") return AirConditioningIcon;
  if (facility === "Heating") return HeatingIcon;
  if (facility === "Free Wifi") return FreewifiIcon;
  if (facility === "Pets allowed") return PetAllow;
  if (facility === "Flat-Screen TV") return FlatTV;
  if (facility === "Swimming pool") return SwimmingpoolIcon;
  if (facility === "Minibar") return MinibarIcon;
  if (facility === "Kitchen") return KitchenIcon;
  if (facility === "Kitchenette") return KitchenIcon;
  if (facility === "Washing machine") return WashingIcon;
  if (facility === "Refrigerator") return RefrigeratorIcon;
  if (facility === "Garden view") return GardenIcon;

  // TODO Griseld, implemente new icons for missing types
  if (facility === "Electric vehicle charging station") return WashingIcon;
  if (facility === "Hot tub") return WashingIcon;
  if (facility === "Sauna") return WashingIcon;
  if (facility === "Balcony") return WashingIcon;
  if (facility === "Terrace") return WashingIcon;
  if (facility === "View") return WashingIcon;

  return GardenIcon;
};

const AccommodationWebProfile = () => {
  const [header, setHeader] = useState({});
  const [whatIncluded, setWhatIncluded] = useState({});
  const [location, setLocation] = useState({});
  const [vRlink, setVRlink] = useState({});
  const [thingsToKnow, setThingsToKnow] = useState({});
  const [guestList, setGuestList] = useState([]);
  const [showReservationForm, setShowReservationForm] = useState(false);
  const [enableDate, setEnableDate] = useState(false);
  const [bookingDate, setBookingDate] = useState([]);
  const [pricePerNight, setPericePerNight] = useState([]);
  const [currency, setCurrency] = useState();
  const { code: rental_code } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  const getCurrencySymbol = (value) => {
    if (value) {
      if (value === "ALL") {
        return "L";
      } else if (value === "USD") {
        return "$";
      } else if (value === "EUR") {
        return "€";
      } else {
        return "";
      }
    }
  };

  const getDetails = () => {
    WhiteLabelService.getRentallDetails(rental_code).then(
      (res) => {
        setHeader(res.rental_unit?.header_section);
        setWhatIncluded(res.rental_unit?.what_is_included);
        setLocation(res.rental_unit?.where_located);
        setVRlink(res.rental_unit?.where_located?.vr_link);
        setThingsToKnow(res.rental_unit?.things_to_know);
        setBookingDate(res?.rental_unit?.booking_dates);
        setPericePerNight(res?.rental_unit?.price_per_nights);
        setCurrency(
          getCurrencySymbol(res?.rental_unit?.header_section?.currency)
        );

        if (res?.rental_unit?.price_per_nights) {
          setGuestList(
            res?.rental_unit?.price_per_nights.map((item) => {
              return { value: item.id, label: item.nr_guests };
            })
          );
        }

        dispatch({
          type: SET_VENUE_DATA,
          payload: { ...res.rental_unit, ...res.venue },
        });

        console.log(res, "resres");

        localStorage.setItem(
          "venue_key1",
          res.rental_unit?.header_section?.venue_app_key ?? ""
        );

        identifyVenue(rental_code);
        trackEvent("Vacation Rental - Detail", {
          action_category: "Page Load",
          action_outcome: "success",
          interaction_element: "screen",
          industry: "accommodation",
          source: "vb_web",
        });
      },
      (error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );

        identifyVenue(rental_code);
        trackEvent("Vacation Rental - Detail", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "accommodation",
          source: "vb_web",
        });
      }
    );
  };

  const openGoogleMaps = () => {
    // if (Object.keys(location.length > 0)) {
    //   var url =
    //     "http://www.google.com/maps/place/" +
    //     parseFloat(location?.latitude) +
    //     "," +
    //     parseFloat(location?.longitude);
    //   window.open(url, "_blank", "location=yes");
    // }
  };

  const openVR = () => {
    // window.open(vRlink, "_blank", "location=yes");
  };

  const [price, setprice] = useState(null);
  return (
    <div className={"align-col-middle view-terms mt-0"}>
      <Seo title={`${header?.name} | VenueBoost`} />
      <div className="w-[100vw] relative  ">
        <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16 pb-5">
          <RentalHeader
            header={header}
            enableDate={enableDate}
            price={price}
            currency={currency}
          />
          {showReservationForm ? (
            <ReservationForm onBack={() => setShowReservationForm(false)} />
          ) : (
            <>
              <hr className="my-4" />
              <RentalForm
                header={header}
                guestList={guestList}
                onAction={() => setShowReservationForm(true)}
                onSetDate={setEnableDate}
                bookingDate={bookingDate}
                pricePerNight={pricePerNight}
                setprice={setprice}
                currency={currency}
                rentalId={rental_code}
              />
              <hr className="my-[54px]" />
              <RentalOverview
                whatIncluded={whatIncluded}
                getFacilityImageByValue={getFacilityImageByValue}
              />
              <hr className="my-6 md:my-[54px]" />
              <div className="flex md:flex-row flex-col mb-[63px]">
                <RentalLocation
                  header={header}
                  // openGoogleMaps={openGoogleMaps}
                  // openVR={openVR}
                  location={location}
                  vRlink={vRlink}
                />
                <RentalInformation thingsToKnow={thingsToKnow} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccommodationWebProfile;
