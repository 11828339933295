import React, { useEffect } from "react";
import "./index.css";
import { Seo } from "../../../components/Seo";
import Faq from "../../../components/Faq";
import Advantage from "../../../components/Advantage";
import OverviewBannerImg from "../../../assets/images/who-we-serve/retail.webp";
import OverviewRetailOrders from "../../../assets/images/who-we-serve/retail-orders.webp";
import OverviewRetailInventory from "../../../assets/images/who-we-serve/retail-inventory.webp";
import OverviewRetailStaffManagement from "../../../assets/images/who-we-serve/retail-staff-management.webp";
import OverviewRetailStaffMarketing from "../../../assets/images/who-we-serve/retail-marketing.webp";
import OverviewRetailStaffLoyalty from "../../../assets/images/who-we-serve/retail-loyalty.webp";
import OverviewRetailDashboardRevenue from "../../../assets/images/who-we-serve/retail-dashboard-overview.webp";
import OverviewRetailStoreSettings from "../../../assets/images/who-we-serve/retail-store-settings.webp";
import OverviewRetailSurveyRatings from "../../../assets/images/who-we-serve/retail-survey-ratings.webp";
import OverviewRetailAffiliate from "../../../assets/images/who-we-serve/retail-affiliate.webp";
import OverviewRetailCentralized from "../../../assets/images/who-we-serve/retail-centralized.webp";
import { t } from "i18next";

import OverviewFoodGuestsManagement from "../../../assets/images/who-we-serve/food-guests-management.webp";
import { isMobile } from "react-device-detect";
import { CircleTick, CheckLine } from "../../../components/CheckLine";
import FreeTrial from "../../../components/FreeTrial";
import Grow from "../grow";
import FreePlanCard from "../../../components/FreePlanCard";

import { genRetailText } from "../../resources/faqs/index";
import { accText, whoTabText } from "../accommodation_overview";

const retailText = (label) => t(`who_we_serve.retail_overview.${label}`);
const tabText = (label) => t(`who_we_serve.retail_overview.tabs.${label}`);

const generalFaq = [
  {
    id: 1,
    status: false,
    title: genRetailText("venueBoostBenefitTypes"),
    content: genRetailText("venueBoostVersatility"),
  },
  {
    id: 2,
    status: false,
    title: genRetailText("venueBoostOrderManagement"),
    content: genRetailText("venueBoostOrderManagementDescription"),
  },
  {
    id: 3,
    status: false,
    title: genRetailText("venueBoostInventoryManagementQuestion"),
    content: genRetailText("venueBoostInventoryManagementDescription"),
  },
  {
    id: 4,
    status: false,
    title: genRetailText("venueBoostStaffManagementQuestion"),
    content: genRetailText("venueBoostStaffManagementDescription"),
  },
  {
    id: 5,
    status: false,
    title: genRetailText("venueBoostMarketingSupportQuestion"),
    content: genRetailText("venueBoostMarketingSupportDescription"),
  },
  {
    id: 6,
    status: false,
    title: genRetailText("venueBoostLoyaltyProgramsQuestion"),
    content: genRetailText("venueBoostLoyaltyProgramsDescription"),
  },
  {
    id: 7,
    status: false,
    title: genRetailText("venueBoostAnalyticsToolsQuestion"),
    content: genRetailText("venueBoostAnalyticsToolsDescription"),
  },
  {
    id: 8,
    status: false,
    title: genRetailText("venueBoostInPersonPaymentsQuestion"),
    content: genRetailText("venueBoostInPersonPaymentsDescription"),
  },
  {
    id: 9,
    status: false,
    title: genRetailText("venueBoostBrandProfileQuestion"),
    content: genRetailText("venueBoostBrandProfileDescription"),
  },
  {
    id: 10,
    status: false,
    title: genRetailText("venueBoostOnboardingSupportQuestion"),
    content: genRetailText("venueBoostOnboardingSupportDescription"),
  },
];

const RetailIndustry = () => {
  const currentUrl = "https://venueboost.io/who-we-serve/retail-management";
  const seoTitle = retailText("retailText");
  const seoDescription = retailText("elevateRetailOperationsWithVenueBoost");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabs = [
    {
      title: tabText("ConsistentInventory"),
      badge: tabText("ConsistentInventory"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("StreamlinedEcommerceOperations")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("VenueBoostIntegration")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("SyncInventory")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("MaintainData")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("EnhanceSatisfaction")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("SimplifyInventory")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("ConsistentInventory"),
      content1: tabText("StreamlinedEcommerceOperations"),
      content2: tabText("VenueBoostIntegration"),
      content3: [
        tabText("SyncInventory"),
        tabText("MaintainData"),
        tabText("EnhanceSatisfaction"),
      ],
      content4: tabText("SimplifyInventory"),
    },
    {
      title: tabText("RetailCustomerLoyaltyProgram"),
      badge: tabText("RetailCustomerLoyaltyProgram"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("ForEnhancedShoppingExperiences")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText(
                "VenueBoost presents the Retail Customer Loyalty Program, a powerful tool to enhance shopping experiences and build loyalty."
              )}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              {tabText("Offer personalized discounts and promotions")}
              {tabText("Track customer preferences and purchase history")}
              {tabText(
                "Drive repeat business with targeted marketing campaigns"
              )}
              {tabText("Strengthen customer relationships and boost sales")}
            </div>

            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText(
                "Transform your retail business with VenueBoost's Retail Customer Loyalty Program."
              )}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("RetailCustomerLoyaltyProgram"),
      content1: tabText("ForEnhancedShoppingExperiences"),
      content2: tabText(
        "VenueBoost presents the Retail Customer Loyalty Program, a powerful tool to enhance shopping experiences and build loyalty."
      ),
      content3: [
        tabText("Offer personalized discounts and promotions"),
        tabText("Track customer preferences and purchase history"),
        tabText("Drive repeat business with targeted marketing campaigns"),
        tabText("Strengthen customer relationships and boost sales"),
      ],
      content4: tabText(
        "Transform your retail business with VenueBoost's Retail Customer Loyalty Program."
      ),
    },
    {
      title: tabText("AdvancedCustomerBehaviorAnalytics"),
      badge: tabText("AdvancedCustomerBehaviorAnalytics"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("CustomerInsights")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("GainInsights")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("AnalyzingPatterns")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("DataDrivenDecisions")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("TargetMarketing")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("BusinessEmpowerment")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("AdvancedCustomerBehaviorAnalytics"),
      content1: tabText("CustomerInsights"),
      content2: tabText("GainInsights"),
      content3: [
        tabText("AnalyzingPatterns"),
        tabText("DataDrivenDecisions"),
        tabText("TargetMarketing"),
      ],
      content4: tabText("BusinessEmpowerment"),
    },
    {
      title: tabText("MarketingAutomation"),
      badge: tabText("MarketingAutomation"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("BoostEngagementandSales")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("TakeMarketingtoNextLevel")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("CreateTargetedEmailCampaigns")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("SendPersonalizedOffers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("MeasureEffectiveness")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("StreamlineMarketing")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("MarketingAutomation"),
      content1: tabText("BoostEngagementandSales"),
      content2: tabText("TakeMarketingtoNextLevel"),
      content3: [
        tabText("CreateTargetedEmailCampaigns"),
        tabText("SendPersonalizedOffers"),
        tabText("MeasureEffectiveness"),
      ],
      content4: tabText("StreamlineMarketing"),
    },
    {
      title: tabText("PaymentsClickSecure"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("PaymentsClickSecure")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("VenueBoostInPersonPaymentsFeature")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("VenuesCanAcceptPaymentsSecurely")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("VenueBoostPOSEnsures")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("AccessibleUserFriendlyInterface")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              -
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("PaymentsClickSecure"),
      badge: tabText("InPersonPayments"),
      content1: tabText("PaymentsClickSecure"),
      content2: tabText("VenueBoostInPersonPaymentsFeature"),
      content3: [
        tabText("VenuesCanAcceptPaymentsSecurely"),
        tabText("VenueBoostPOSEnsures"),
        tabText("AccessibleUserFriendlyInterface"),
      ],
      content4: "",
    },
    {
      title: tabText("BrandingUniquelyYours"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("BrandingUniquelyYours")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("TailorThePlatformsLook")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("BrandConsistency")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("EnhancedCustomerExperience")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("MarketingSynergy")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("Professionalism")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("BrandingUniquelyYours"),
      badge: tabText("Branding"),
      content1: tabText("BrandingUniquelyYours"),
      content2: tabText("TailorThePlatformsLook"),
      content3: [
        tabText("BrandConsistency"),
        tabText("EnhancedCustomerExperience"),
        tabText("MarketingSynergy"),
      ],
      content4: tabText("Professionalism"),
    },
  ];

  return (
    <div className={"align-col-middle retail-industry mt-0"}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        image={OverviewBannerImg}
        url={currentUrl}
      />
      <div className="flex flex-col md:flex-row justify-center overflow-hidden bg-[#F3F3F3] px-4 sm:px-6 md:px-8 lg:px-16 pb-20 w-[100vw] relative">
        <div className="xl:!w-[1228px] flex flex-col md:flex-row">
          <div className="w-full md:w-[50%] flex flex-col md:pr-[58px] py-6 md:py-[44px] z-10">
            {!isMobile && (
              <span className=" text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold ">
                Retail
                <br />
                Management
              </span>
            )}
            {isMobile && (
              <span className=" text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold ">
                Retail Management
              </span>
            )}

            <p className="hidden md:block text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]">
              {t("who_we_serve.retail_industry.retail_desc")}
            </p>
            <div className="mb-[15px] md:mb-0 mt-10 hidden md:block">
              <a
                href="/get-started"
                className="rounded-md text-white text-[11px] font-bold md:text-18 leading-[11px] md:leading-[18px] px-[17px] md:px-[30px] py-[9px] md:py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]"
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
          <div className=" md:block w-full md:w-[50%] md:mt-12  relative align-middle">
            <img
              alt=""
              className="object-cover md:absolute rounded-[20px] right-[29px] max-h-full"
              src={OverviewBannerImg}
            />
          </div>

          <div className="flex flex-col md:hidden mt-6">
            <p className="text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]">
              {t("who_we_serve.retail_industry.retail_desc")}
            </p>
            <div className=" mt-6 md:mt-0">
              <a
                href="/get-started"
                className="flex w-full justify-center rounded-md text-white font-bold text-18 leading-[18px] md:px-[30px] py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]"
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[58px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewRetailOrders} />
        </div>

        <div className="w-full md:w-[50%] md:p-4 md:pr-0  flex-col">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {retailText("orderManagement")}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {retailText("StreamlinedOrderProcessing")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {retailText("SmoothOrderManagement")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              retailText("RealTimeNotifications"),
              retailText("IntuitiveInterface"),
              retailText("TrackOrderHistory"),
              retailText("FastFulfillmentShipping"),
              retailText("FocusOnCustomers"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {retailText("UnifiedWorkflows")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[58px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewRetailInventory} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0  ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.retail_industry.inventory_management")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            Actionable Inventory Insights
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.retail_industry.inventory_management_sub")}{" "}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.retail_industry.inventory_tracking"),
              t("who_we_serve.retail_industry.inventory_update"),
              t("who_we_serve.retail_industry.inventory_insight"),
              t("who_we_serve.retail_industry.inventory_detail"),
              t("who_we_serve.retail_industry.inventory_history"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {retailText("OptimizeInventory")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[58px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewRetailStaffManagement} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.retail_industry.staff_managment")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {retailText("SimplifiedStaffManagement")}
          </p>

          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.retail_industry.staff_managment_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              retailText("AutomatedScheduling"),
              retailText("TimeOffRequests"),
              retailText("PayrollProcessing"),
              retailText("TrackSalesPerformance"),
              retailText("IdentifyCoachingOpportunities"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {retailText("SimplifyRetailStaffManagement")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[58px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewRetailStaffMarketing} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.retail_industry.marketing")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {retailText("ExpandedReachandBrandAwareness")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.retail_industry.marketing_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.retail_industry.marketing_email"),
              t("who_we_serve.retail_industry.marketing_coupons"),
              t("who_we_serve.retail_industry.marketing_campaigns"),
              t("who_we_serve.retail_industry.marketing_refferals"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {retailText("Consolidateactivities")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[58px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewRetailStaffLoyalty} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.retail_industry.loyalty")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {retailText("BuildingCustomerLoyaltyandRetention")}
          </p>
          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.retail_industry.loyalty_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              retailText("SegmentMembersIntoTailoredLoyaltyTiers"),
              retailText("SendPersonalizedRewardsAndOffers"),
              retailText("TrackPurchaseHistoryAndShoppingHabits"),
              retailText("AnalyzeMembershipEngagement"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {retailText("StrengthenRelationshipsWithRegularBuyers")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[58px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewRetailDashboardRevenue} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.retail_industry.dashboard")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            Actionable Retail Insights
          </p>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.retail_industry.dashboard_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.retail_industry.dashboard_sale"),
              t("who_we_serve.retail_industry.dashboard_coupons"),
              t("who_we_serve.retail_industry.dashboard_categories"),
              t("who_we_serve.retail_industry.dashboard_sold"),
              t("who_we_serve.retail_industry.dashboard_order"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {retailText("OptimizeYourRetailBusiness")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[68px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewRetailStoreSettings} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.retail_industry.store_managment")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            Customized Online Store Presence
          </p>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.retail_industry.store_managment_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.retail_industry.store_managment_profile"),
              t("who_we_serve.retail_industry.store_managment_selling"),
              t("who_we_serve.retail_industry.store_managment_gallery"),
              t("who_we_serve.retail_industry.store_managment_notifi"),
              t("who_we_serve.retail_industry.store_managment_payment"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-0 md:mt-[88px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
          <img alt="" src={OverviewRetailSurveyRatings} />
        </div>
        <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {retailText("CustomerSurveysAndRatings")}
          </h1>

          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {/* TODO */}
            Gather Valuable Feedback
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              retailText("VenueBoostNowOffersPowerfulSurveysAndRatings"),
              retailText("CreateCustomizedCustomerSurveys"),
              retailText("CollectRatingsAndReviews"),
              retailText("GainInsights"),
              retailText("DataDrivenImprovements"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {retailText("ListenAndOptimize")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[88px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewRetailAffiliate} />
        </div>
        <div className="w-full md:w-[50%]   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {whoTabText("affiliatePartnerships")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("ExpandYourReachAndRevenue")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("withVenueBoostNewAffiliatesFeature")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              whoTabText("establishAffiliatePartnershipsSeamlessly"),
              whoTabText("trackReferralsAndCommissionsInRealTime"),
              whoTabText("accessValuableMarketingResources"),
              whoTabText("extendBrandReach"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("joinForces")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[88px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
          <img alt="" src={OverviewRetailCentralized} />
        </div>
        <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {retailText("MultiBrandAnalytics")}
          </h1>

          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {retailText("BrandSuccessMaximization")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              retailText("CentralizedAnalytics"),
              retailText("ComprehensiveAnalytics"),
              retailText("DataDrivenDecisions"),
              retailText("SimplifyReporting"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {retailText("EmpowerMultiBrandRetailers")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <Grow
        venueColor={"#E14848"}
        title={"Additional Retail Management Features"}
        subtitle={retailText("DiveIntoVenueBoostCapabilities")}
        tabs={tabs}
      />

      <div className="align-col-middle ">
        <div className="w-[100vw] bg-[#240B3B]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className=" text-white text-26 md:text-[42px] text-center font-extrabold leading-[60px] mt-10 md:mt-20 ">
                {t("who_we_serve.retail_industry.faq")}
                <span className=" text-white "> (FAQ) </span>
              </h1>
              <p className="text-14 md:text-20 text-[#fff] px-7 leading-5 font-medium mt-4 mb:mt-20 mb-5 md:mb-[66px]">
                {t("who_we_serve.retail_industry.retail_industry")}
              </p>
              <Faq faqData={generalFaq} className="mt-2 mb-4 md:mb-16" />
            </div>
          </div>
        </div>
      </div>

      <Advantage venueDefinition={"retail industry"} />
      <div className="align-col-middle">
        <div className="w-[100vw] bg-white">
          <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
            <div className="flex flex-col align-middle mb-4 md:mb-20">
              <FreePlanCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailIndustry;
