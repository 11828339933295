export const blockItems = [
  {
    id: 1,
    title: "Free shipping",
    subtitle: "Free shipping for order above $150",
    icon: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30 10.5L18 16.5L6 10.5"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.9368 7.00142L20.4368 3.5374C18.8854 2.82086 17.1146 2.82087 15.5632 3.53741L8.06317 7.00143C5.8964 8.00219 4.5 10.2352 4.5 12.6993V23.3007C4.5 25.7648 5.8964 27.9978 8.06317 28.9986L15.5632 32.4626C17.1146 33.1791 18.8854 33.1791 20.4368 32.4626L27.9368 28.9986C30.1036 27.9978 31.5 25.7648 31.5 23.3007V12.6993C31.5 10.2352 30.1036 8.00219 27.9368 7.00142Z"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 6L25.5 13.5V29.25"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 16.5V33"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    id: 2,
    icon: (
      <svg
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="18.332"
          cy="18"
          r="15"
          stroke="#131118"
          strokeWidth="2.25"
        />
        <path
          d="M21.332 15C21.332 13.3431 19.9889 12 18.332 12C16.6752 12 15.332 13.3431 15.332 15C15.332 16.6569 16.6752 18 18.332 18"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="round"
        />
        <path
          d="M18.332 18C19.9889 18 21.332 19.3431 21.332 21C21.332 22.6569 19.9889 24 18.332 24C16.6752 24 15.332 22.6569 15.332 21"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="round"
        />
        <path
          d="M18.332 9.75V12"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.332 24V26.25"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: "Money Guarantee",
    subtitle: "Within 30 days for an exchange",
  },
  {
    id: 3,
    title: "Online Support",
    subtitle: "24 hours a day, 7 days a week",
    icon: (
      <svg
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.168 24V27C32.168 29.4853 30.1532 31.5 27.668 31.5C25.1827 31.5 23.168 29.4853 23.168 27V24C23.168 21.5147 25.1827 19.5 27.668 19.5C30.1532 19.5 32.168 21.5147 32.168 24ZM32.168 24V18C32.168 10.5442 26.1238 4.5 18.668 4.5C11.2121 4.5 5.16797 10.5442 5.16797 18V24M5.16797 24V27C5.16797 29.4853 7.18269 31.5 9.66797 31.5C12.1532 31.5 14.168 29.4853 14.168 27V24C14.168 21.5147 12.1532 19.5 9.66797 19.5C7.18269 19.5 5.16797 21.5147 5.16797 24Z"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    id: 4,
    title: "Flexible Payment",
    subtitle: "Pay with multiple credit cards",
    icon: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="33"
          y="4.5"
          width="27"
          height="30"
          rx="6"
          transform="rotate(90 33 4.5)"
          stroke="#131118"
          strokeWidth="2.25"
        />
        <circle cx="9" cy="25.5" r="1.5" fill="#131118" />
        <path
          d="M3 10.5L33 10.5L33 16.5L3 16.5L3 10.5Z"
          stroke="#131118"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];
