import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";

import AboutHeader from "./about-header";

import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";
import { SET_VENUE_DATA } from "../../redux/actions/types";
import { useDispatch } from "react-redux";
import { isEmpty } from "../../utils/common";
import AboutIntro from "./about-intro";
import AboutHelp from "./about-help";
import AccommodationQuality from "../venue-accommodation/accommodation-quality/index.js";
import AccommodationLocation from "../venue-accommodation/accommodation-location/index.js";
import { location } from "../venue-accommodation/accommodation-location/location-functions.js";
import AccommodationFooter from "../venue-accommodation/footer.js";

const AboutSp = () => {
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const { app_key: accommodation_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    WhiteLabelService.getDetails(accommodation_id).then(
      (res) => {
        if (res.allowed_only_from !== "vacation_rental") {
          navigate("/");
        } else {
          dispatch({ type: SET_VENUE_DATA, payload: res.venue });
          setDetails(res.venue);

          identifyVenue(accommodation_id);
          trackEvent("White Label - Accommodation", {
            action_category: "Page Load",
            action_outcome: "success",
            interaction_element: "screen",
            industry: "accommodation",
            source: "vb_web",
          });
        }
      },
      (error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );

        identifyVenue(accommodation_id);
        trackEvent("White Label - Accommodation", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "accommodation",
          source: "vb_web",
        });
      }
    );
  };

  const TagsBrand = details?.brand_profile?.filter(
    (item) => item.element_name === "Tags"
  )[0];
  const TagsButtonData = {
    text: isEmpty(
      TagsBrand?.venue_brand_profile_customizations?.find(
        (c) => c.customization_key === "text"
      )?.customization_value
    )
      ? TagsBrand?.default_name
      : TagsBrand?.venue_brand_profile_customizations?.find(
          (c) => c.customization_key === "text"
        )?.customization_value,
    bgColor: TagsBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key === "color"
    )?.customization_value,
    textColor: TagsBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key === "text_color"
    )?.customization_value,
  };

  return (
    <div className="flex flex-col w-full">
      <AboutHeader TagsButtonData={TagsButtonData} data={details} />

      <div className="w-full bg-[#FAFAFA] py-16 mx-auto px-4 sm:px-6 md:px-8 lg:px-[10%]">
        <div className="gap-10 mx-auto w-full">
          <AboutIntro />
        </div>
      </div>

      <div className="w-full mb-8 bg-black px-4 sm:px-6 md:px-8 lg:px-[10%]">
        <div className=" mx-auto w-full my-8 md:my-16">
          <AccommodationQuality />
        </div>
      </div>

      <div className="w-full bg-[#FAFAFA] py-16 mx-auto px-4 sm:px-6 md:px-8 lg:px-[10%]">
        <div className="gap-10 mx-auto w-full">
          <AboutHelp />
        </div>
      </div>

      <div className="w-full my-16 bg-white px-4 sm:px-6 md:px-8 lg:px-[10%]">
        <AccommodationLocation
          // openGoogleMaps={openGoogleMaps(accommodation_id)}
          location={location(accommodation_id)}
          vRlink={location(accommodation_id).vr_link}
          accId={accommodation_id}
          address={details?.overview?.address}
        />
      </div>
    </div>
  );
};

export default AboutSp;
