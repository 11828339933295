import BlogImg1 from '../../assets/images/bybest/blogs/blog1.png';
import BlogImg2 from '../../assets/images/bybest/blogs/blog2.png';
import BlogImg3 from '../../assets/images/bybest/blogs/blog3.png';
import BlogImg4 from '../../assets/images/bybest/blogs/blog4.png';
import BlogImg5 from '../../assets/images/bybest/blogs/blog5.png';
import BlogImg6 from '../../assets/images/bybest/blogs/blog6.png';

export const blogs = [
    {
        id: 1,
        category: '#villeroy&boch',
        title: 'Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!',
        slug: "uljet-net-swarivski",
        content: '',
        date: "29-07-2024",
        image: BlogImg1,
    },
    {
        id: 2,
        category: '#villeroy&boch',
        title: 'Ide dhuratash nga Swatch për një verë të',
        slug: "ide-dhuratash",
        content: '',
        date: "29-07-2024",
        image: BlogImg2,
    },
    {
        id: 3,
        category: '#villeroy&boch',
        title: 'Ide dhuratash nga Villeroy&Boch: Eleganca që lë',
        slug: "ide-eleganca",
        content: '',
        date: "29-07-2024",
        image: BlogImg3,
    },
    {
        id: 4,
        category: '#villeroy&boch',
        title: 'Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!',
        slug: "shfrytezoni",
        content: '',
        date: "29-07-2024",
        image: BlogImg4,
    },
    {
        id: 5,
        category: '#villeroy&boch',
        title: "Eja merr ç'të duhet! 60% ULJE në produkte të selektuara Blukids",
        slug: "eja-merr",
        content: '',
        date: "29-07-2024",
        image: BlogImg5,
    },
    {
        id: 6,
        category: '#villeroy&boch',
        title: 'Ditët e nxehta me 40% ULJE në produktet e Villeroy&Boch',
        slug: "ditet-e-nxehta",
        content: '',
        date: "29-07-2024",
        image: BlogImg6,
    },
    {
        id: 7,
        category: '#villeroy&boch',
        title: 'Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!',
        slug: "shfry-ofertat",
        content: '',
        date: "29-07-2024",
        image: BlogImg2
    },
    {
        id: 8,
        category: '#villeroy&boch',
        title: 'Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!',
        slug: "deri-me-28",
        content: '',
        date: "29-07-2024",
        image: BlogImg4,
    },
    {
        id: 9,
        category: '#villeroy&boch',
        title: 'Shfrytëzoni uljet ekskluzive me Villeroy&Boch: Deri në 25% zbritje',
        slug: "uljet-ekskluzive",
        content: '',
        date: "29-07-2024",
        image: BlogImg2,
    },
    {
        id: 10,
        category: '#villeroy&boch',
        title: 'Nga Vjena në Milano, ekspozita magjike "Masters of Light" nga Swarovski',
        slug: "nga-vjena",
        content: '',
        date: "29-07-2024",
        image: BlogImg1,
    },
    {
        id: 11,
        category: '#villeroy&boch',
        title: 'Asnjë dhuratë mashkullit të familjes për Ditën e Baballarëve? Një guidë me dhurata në ByBest Shop',
        slug: "asnje-dhurate",
        content: '',
        date: "29-07-2024",
        image: BlogImg4,
    },
    {
        id: 12,
        category: '#gifts',
        title: 'Pse syzet SWATCH janë një domosdoshmëri këtë verë?',
        slug: "pse-syzet",
        content: '',
        date: "29-07-2024",
        image: BlogImg5,
    },
    {
        id: 13,
        category: '#swarovski',
        title: 'Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!',
        slug: "deri-uljet-net-swarivski",
        content: '',
        date: "29-07-2024",
        image: BlogImg6,
    },
    {
        id: 14,
        category: '#bybestshop',
        title: 'Uljet në Swarovski: Shfrytëzoni ofertat deri më 28 Korrik!',
        slug: "orrik-net-swarivski",
        content: '',
        date: "29-07-2024",
        image: BlogImg3,
    },
    {
        id: 15,
        category: '#swatch',
        title: 'Gjithçka që të nevojitet, në një platformë: Me ByBest Shop kursen kohë dhe para',
        slug: "gjithcka-net-swarivski",
        content: '',
        date: "29-07-2024",
        image: BlogImg2,
    },
];


export const products = [
    {
        id: 1,
        title: 'LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B',
        category: 'LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B',
        img1: 'https://admin.bybest.shop/storage/products/645bc0cfd6548_12430756.jpg',
        img2: 'https://admin.bybest.shop/storage/products/645bc0ceba512_12430763.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 2,
        title: 'LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B',
        category: 'LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B',
        img1: 'https://admin.bybest.shop/storage/products/64303b956a842_12385477.jpg',
        img2: 'https://admin.bybest.shop/storage/products/64303b91cfd69_12385481.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 3,
        title: 'Level TEAL',
        category: 'SPARKLING DAISY',
        img1: 'https://admin.bybest.shop/storage/products/6411c429967a4_12409318.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6411c42727246_12409324.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 4,
        title: 'LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B',
        category: 'LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B',
        img1: 'https://admin.bybest.shop/storage/products/6411c0ad25d50_12409334.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6411c0ab59783_12409347.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 5,
        title: 'Level TEAL',
        category: 'SPARKLING DAISY',
        img1: 'https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 6,
        title: 'Level TEAL',
        category: 'SPARKLING DAISY',
        img1: 'https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 1,
        title: 'Level TEAL',
        category: 'SPARKLING DAISY',
        img1: 'https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 2,
        title: 'Level TEAL',
        category: 'SPARKLING DAISY',
        img1: 'https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 3,
        img1: 'https://admin.bybest.shop/storage/products/6411c429967a4_12409318.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6411c42727246_12409324.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 4,
        title: 'Level TEAL',
        category: 'SPARKLING DAISY',
        img1: 'https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 5,
        title: 'Level TEAL',
        category: 'SPARKLING DAISY',
        img1: 'https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
    {
        id: 6,
        title: 'Level TEAL',
        category: 'SPARKLING DAISY',
        img1: 'https://admin.bybest.shop/storage/products/6410c8156ee7e_12351222.jpg',
        img2: 'https://admin.bybest.shop/storage/products/6410c81435fa1_12351230.jpg',
        no: 'KLE20QB101',
        price: 3599,
        discount: '60%',
        sale_price: 1440
    },
]



export const home_products = [
    {
        cat_img: 'https://admin.bybest.shop/storage/brands/brands_sidebar_1722415773.png',
        products: [
            {
                id: 1,
                img1: 'https://admin.bybest.shop/storage/products/645bc0cfd6548_12430756.jpg',
                img2: 'https://admin.bybest.shop/storage/products/645bc0ceba512_12430763.jpg',
                price: 3599,
                title: 'Level TEAL',
                category: 'SPARKLING DAISY'
            },
            {
                id: 2,
                img1: 'https://admin.bybest.shop/storage/products/62aa211008ee1_ZFBNP186_fa000_ec002 (1).png',
                img2: 'https://admin.bybest.shop/storage/products/62aa20e11b17e_ZFBNP186_li100_ec001.jpg',
                price: 3599,
                title: 'SPARKLING DAISY',
                category: 'SPARKLING DAISY'
            },
            {
                id: 3,
                img1: 'https://admin.bybest.shop/storage/products/6616e1c4a5d49_ZFPNP147_fa000_ec002.png',
                img2: 'https://admin.bybest.shop/storage/products/6616e1c58d103_ZFPNP147_li100_ec001.avif',
                price: 3599,
                title: 'SHIPPING DOLPHIN',
                category: 'SPARKLING DAISY'
            },
            {
                id: 4,
                img1: 'https://admin.bybest.shop/storage/products/product_feature_1645431474.png',
                img2: 'https://admin.bybest.shop/storage/products/product_feature_0_1645431474.jpg',
                price: 3599,
                title: 'TARANTULINO',
                category: 'SPARKLING DAISY'
            },
            {
                id: 5,
                img1: 'https://admin.bybest.shop/storage/products/6282856e50450_ZFPNP100_fa000_ec002.png',
                img2: 'https://admin.bybest.shop/storage/products/6282857a33198_ZFPNP100_li300_ec001.jpg',
                price: 3599,
                title: 'SPARKLING BUTTERFLY',
                category: 'SPARKLING DAISY'
            },
            {
                id: 6,
                img1: 'https://admin.bybest.shop/storage/products/654ffa43a3432_ZFCSP119_fa000_ec002.png',
                img2: 'https://admin.bybest.shop/storage/products/654ffa2822300_ZFCSP119_li100_ec001.avif',
                price: 3599,
                title: 'FARFALLAXUS',
                category: 'SPARKLING DAISY'
            },
        ]
    },
    {
        cat_img: 'https://admin.bybest.shop/storage/brands/brands_sidebar_1712939381.jpg',
        products: [
            {
                id: 1,
                img1: 'https://admin.bybest.shop/storage/products/product_feature_1645364498.jpg',
                img2: 'https://admin.bybest.shop/storage/products/product_feature_0_1645630116.jpg',
                price: 3599,
                title: 'AMAZONIA GIFTS',
                category: 'SPARKLING DAISY'
            },
            {
                id: 2,
                img1: 'https://admin.bybest.shop/storage/products/629677a1ccc1a_1016361761.jpg',
                img2: 'https://admin.bybest.shop/storage/products/6296779f10e23_1016361761.jpg',
                price: 3599,
                title: 'AMAZONIA GIFTS',
                category: 'SPARKLING DAISY'
            },
            {
                id: 3,
                img1: 'https://admin.bybest.shop/storage/products/6616e1c4a5d49_ZFPNP147_fa000_ec002.png',
                img2: 'https://admin.bybest.shop/storage/products/6616e1c58d103_ZFPNP147_li100_ec001.avif',
                price: 3599,
                title: 'AMAZONIA',
                category: 'SPARKLING DAISY'
            },
            {
                id: 4,
                img1: 'https://admin.bybest.shop/storage/products/product_feature_1645431474.png',
                img2: 'https://admin.bybest.shop/storage/products/product_feature_0_1645431474.jpg',
                price: 3599,
                title: 'TARANTULINO',
                category: 'SPARKLING DAISY'
            },
            {
                id: 5,
                img1: 'https://admin.bybest.shop/storage/products/6282856e50450_ZFPNP100_fa000_ec002.png',
                img2: 'https://admin.bybest.shop/storage/products/6282857a33198_ZFPNP100_li300_ec001.jpg',
                price: 3599,
                title: 'SPARKLING BUTTERFLY',
                category: 'SPARKLING DAISY'
            },
            {
                id: 6,
                img1: 'https://admin.bybest.shop/storage/products/654ffa43a3432_ZFCSP119_fa000_ec002.png',
                img2: 'https://admin.bybest.shop/storage/products/654ffa2822300_ZFCSP119_li100_ec001.avif',
                price: 3599,
                title: 'FARFALLAXUS',
                category: 'SPARKLING DAISY'
            },
        ]
    },
    {
        cat_img: 'https://admin.bybest.shop/storage/brands/brands_sidebar_1704487240.png',
        products: [
            {
                id: 1,
                img1: 'https://admin.bybest.shop/storage/products/product_feature_1645470441.jpg',
                img2: 'https://admin.bybest.shop/storage/products/product_feature_3_1645637641.jpg',
                price: 3599,
                title: 'Level TEAL',
                category: 'SPARKLING DAISY'
            },
            {
                id: 2,
                img1: 'https://admin.bybest.shop/storage/products/62aa211008ee1_ZFBNP186_fa000_ec002 (1).png',
                img2: 'https://admin.bybest.shop/storage/products/62aa20e11b17e_ZFBNP186_li100_ec001.jpg',
                price: 3599,
                title: 'SPARKLING DAISY',
                category: 'SPARKLING DAISY'
            },
            {
                id: 3,
                img1: 'https://admin.bybest.shop/storage/products/6616e1c4a5d49_ZFPNP147_fa000_ec002.png',
                img2: 'https://admin.bybest.shop/storage/products/6616e1c58d103_ZFPNP147_li100_ec001.avif',
                price: 3599,
                title: 'SHIPPING DOLPHIN',
                category: 'SPARKLING DAISY'
            },
            {
                id: 4,
                img1: 'https://admin.bybest.shop/storage/products/product_feature_1645431474.png',
                img2: 'https://admin.bybest.shop/storage/products/product_feature_0_1645431474.jpg',
                price: 3599,
                title: 'TARANTULINO',
                category: 'SPARKLING DAISY'
            },
            {
                id: 5,
                img1: 'https://admin.bybest.shop/storage/products/6282856e50450_ZFPNP100_fa000_ec002.png',
                img2: 'https://admin.bybest.shop/storage/products/6282857a33198_ZFPNP100_li300_ec001.jpg',
                price: 3599,
                title: 'SPARKLING BUTTERFLY',
                category: 'SPARKLING DAISY'
            },
            {
                id: 6,
                img1: 'https://admin.bybest.shop/storage/products/654ffa43a3432_ZFCSP119_fa000_ec002.png',
                img2: 'https://admin.bybest.shop/storage/products/654ffa2822300_ZFCSP119_li100_ec001.avif',
                price: 3599,
                title: 'FARFALLAXUS',
                category: 'SPARKLING DAISY'
            },
        ]
    }
]