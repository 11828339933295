import React from "react";
import TierLevel from "../../../components/Sidebar/tier-level";
import WalletSummary from "./wallet-summary";

const WalletPoints = () => {
  return (
    <div className="flex flex-col gap-4 p-4 border">
      <div className="flex justify-between">
        <div className="text-[#101828] text-18 font-semibold leading-7">
          Wallet
        </div>
        <TierLevel className="w-fit p-2" />
      </div>
      <WalletSummary
        value={1200}
        title="Total Points"
        description="Money value ~ 2000 ALL"
      />
    </div>
  );
};

export default WalletPoints;
