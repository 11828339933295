import FormText from "../../../components/FormText";

import mapImg from '../../../assets/images/services/map.png';

import locationIcon from '../../../assets/svgs/location.svg';
import homeIcon from '../../../assets/svgs/home.svg';
import starIcon from '../../../assets/svgs/start_white.svg';
import dinningIcon from '../../../assets/svgs/dinning.svg';
import parkIcon from '../../../assets/svgs/park.svg';
import wifiIcon from '../../../assets/svgs/wifi.svg';
import additionIcon from '../../../assets/svgs/addition.svg';
import { useEffect, useState } from "react";

const HotelInfo = ({ data}) => {

  const [ address, setAddress] = useState('');
  useEffect(() => {
    let full_address = "no adress";
    if (data?.overview?.address){
      full_address = `${data?.overview?.address?.address_line1 ?? 'no address'}, ${data?.overview?.address?.city ?? 'no city'}, ${data?.overview?.address?.state ?? 'no state'}, ${data?.overview?.address?.country ?? 'no country'}, ${data?.overview?.address?.postcode ?? 'no postcode'}`;
    }
    setAddress(full_address);
  }, [data])

  return (
    <div className='md:col-span-2 flex flex-col pt-8 pb-8'>
      <FormText title='Additional Information' type='itemtitle-sub' />
      <div className="flex flex-col border rounded-xl mt-4 h-full">
        <div className="flex flex-col p-5 border border-x-0 border-t-0 border-b">
          <img src={mapImg} alt="map" />
          <div className="flex flex-row items-center mt-3">
            <img src={locationIcon} alt="location" />
            <FormText
              title={address}
              type="subtitle-based"
              customClass="ml-2"
            />
          </div>
        </div>
        <div className="px-5 py-2">
        {data?.additional_information?.hotel_type ? <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={homeIcon} alt="icon" />
              <FormText title="Hotel Type" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.hotel_type} type="subtitle-dark" />
            </div>
          </div> : null}
          {data?.additional_information?.stars ? <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={starIcon} alt="icon" />
              <FormText title="Stars" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.stars} type="subtitle-dark" />
            </div>
          </div> : null}
          {data?.additional_information?.restaurant_type ? <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={dinningIcon} alt="icon" />
              <FormText title="Restaurant Type" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.restaurant_type} type="subtitle-dark" />
            </div>
          </div> : null}
          {data?.additional_information?.parking_details ? <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={parkIcon} alt="icon" />
              <FormText title="Parking details" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.parking_details} type="subtitle-dark" />
            </div>
          </div> : null}
          {data?.additional_information?.wifi ? <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={wifiIcon} alt="icon" />
              <FormText title="Wifi" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.wifi} type="subtitle-dark" />
            </div>
          </div> : null}
          {data?.additional_information?.additional ? <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={additionIcon} alt="icon" />
              <FormText title="Additional" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.additional} type="subtitle-dark" />
            </div>
          </div> : null}
        </div>
      </div>
    </div>
  )
}

export default HotelInfo;
