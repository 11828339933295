import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import CustomerHeader from "../customer-header";
import Address from "./address";
import CommunicationPreferences from "./communication-preferences";
import GeneralInfo from "./general-info";
import CustomerFooterButtons from "../customer-footer-buttons";
import "./index.css";
import LoggedInFooter from "../../../containers/footer/logged-in-footer";

const PersonalInformation = () => {
  const [switchStatus, setSwitchStatus] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
  });

  const handleChange = (e) => {
    setData((info) => ({ ...info, [e.target.name]: e.target.value }));
  };

  const onSwitchChange = (checked) => {
    setSwitchStatus(checked);
  };

  return (
    <div className="flex flex-1 flex-col gap-5 ">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
        <div className="p-6 gap-2 flex flex-col">
          <CustomerHeader
            title="Personal Information"
            description="Keep your profile up to date: name, email, and phone"
            showItems={false}
          />
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          <GeneralInfo data={data} handleChange={handleChange} />
          <Address data={data} handleChange={handleChange} />
        </div>
        <CustomerFooterButtons />
      </div>
      <CommunicationPreferences
        switchStatus={switchStatus}
        onSwitchChange={onSwitchChange}
        title="Marketing communication"
        description="You can manage your communication preferences with venues. Choose how you'd like to receive notifications from your favorite spots. Turn SMS or email notifications on or off to stay updated about rewards, promotions, and more!"
      />
      <CommunicationPreferences
        switchStatus={switchStatus}
        onSwitchChange={onSwitchChange}
        title="Promotion communication"
        description="You can manage your communication preferences with venues. Choose how you'd like to receive notifications from your favorite spots. Turn SMS or email notifications on or off to stay updated about rewards, promotions, and more!"
      />
    </div>
  );
};

export default PersonalInformation;
