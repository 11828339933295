import React from "react";

import GenericAccommodationHeader from "../../venue-accommodation/accommodation-header/generic-acc-header";

const AboutHeader = ({ TagsButtonData, data }) => {
  return (
    <GenericAccommodationHeader
      {...{
        TagsButtonData,
        data,
        title: "About us",
      }}
    />
  );
};

export default AboutHeader;
