import React from "react";
import { Icon } from "react-icons-kit";
import { home } from "react-icons-kit/typicons/home";
import WalletSummary from "./wallet/wallet-summary";

const CustomerHeader = ({
  title = "",
  numberOfItems,
  items = "",
  description = "",
  showItems = true,
  showHome = true,
  showReferalCode = false,
  wallet,
}) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="gap-2 flex flex-col">
        {showHome && (
          <div className="flex items-center gap-4">
            <Icon
              icon={home}
              style={{
                color: "#6B7280",
                display: "flex",
              }}
              size={20}
            />
            <p className="text-[#6B7280] text-20 font-medium leading-5">{`>`}</p>
            <p className="text-[#6B7280] text-14 font-medium leading-5">
              {title}
            </p>
          </div>
        )}
        <div className="text-[#101828] text-18 font-semibold leading-7">
          {title}
          {showItems && (
            <span className="cursor-pointer ml-2 text-14 leading-[18px] text-[#240b3b] bg-[#F3F1F4] rounded-[16px] px-2 py-[2px]">
              {numberOfItems} {items}
            </span>
          )}
        </div>
        <p className="text-[#667085] text-14 font-medium leading-5">
          {description}
        </p>
      </div>
      {showReferalCode && <WalletSummary {...wallet} />}
    </div>
  );
};

export default CustomerHeader;
