import FormText from '../../../components/FormText';
import { isMobile } from "react-device-detect";

const RetailPhotos = ({ photos }) => {
    const chunkedPhotos = photos.slice(0, 6); // Limit to 6 photos

    return (
        <div className='md:col-span-3 flex flex-col pt-8'>
            <FormText title='Photos' type='itemtitle-sub' />
            <div className="grid grid-cols-3 gap-4 mt-4">
                {chunkedPhotos.map((photo, index) => (
                    <div key={index} className="relative flex justify-center items-center border border-gray-300 rounded-md">
                        <img
                            className="max-w-full max-h-full rounded-md"
                            src={photo?.photo_path ?? "https://via.placeholder.com/197x197"}
                            alt={`Photo ${index + 1}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RetailPhotos;
