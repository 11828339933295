import React from "react";
import { useSelector } from "react-redux";
import { restaurantDetails } from "../data";
import "./info.css";
import {
  additionalIcon,
  cuisinIcon,
  diningIcon,
  locationIcon,
  neighborhoodIcon,
  parkingIcon,
  paymentIcon,
} from "../icons";
import { useMemo } from "react";

const MoreInformation = () => {
  const venueData = useSelector((state) => state.venue);

  const restaurantDetails = useMemo(() => {
    return [
      {
        subtitle: venueData?.overview?.address?.address_line1,
        icon: locationIcon,
      },
      {
        title: "Neighborhood",
        subtitle: venueData?.additional_information?.neighborhood,
        icon: neighborhoodIcon,
      },
      {
        title: "Cuisines",
        subtitle: venueData?.additional_information?.cuisine_types?.join(', '),
        icon: cuisinIcon,
      },
      {
        title: "Dining style",
        subtitle: venueData?.additional_information?.dining_style,
        icon: diningIcon,
      },
      {
        title: "Payment options",
        subtitle: venueData?.additional_information?.payment_options?.join(', '),
        icon: paymentIcon,
      },
      {
        title: "Parking details",
        subtitle: venueData?.additional_information?.parking_details,
        icon: parkingIcon,
      },
      {
        title: "Additional",
        subtitle: venueData?.additional_information?.additional,
        icon: additionalIcon,
      },
    ];
  }, [venueData]) 

  return (
    <div className="flex flex-col gap-5 p-5 wrapper my-5 shadow-4-strong">
      <div className="flex flex-col gap-2">
        <div className="manrope-font text-[20px] font-bold leading-[39px] text-primary1">Additional Info</div>
        <p className="manrope-font text-[15px] font-medium leading-[17px] text-[#747474]">
          Additional information about our restaurant so you would know us
          better
        </p>
      </div>

      <div className="flex flex-col gap-2">
        {restaurantDetails.map((item, index) => (
          <div className="flex items-center gap-3" key={index}>
            <div className="icon-wrapper">
              <span>{item.icon}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="manrope-font text-[14px] font-semibold leading-[20px] text-[#404040]">{item.title ?? ""}</span>
              <span className="manrope-font text-[14px] font-semibold leading-[20px] text-[#404040]">{item.subtitle}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoreInformation;
