import React, { useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { Slider } from 'antd';

const filterItems = [
    {
        label: "MARKAT",
        type: 'checkbox',
        filters: ['BLUKIDS'],
    },
    {
        label: "KOLEKSIONET",
        type: 'checkbox',
        filters: ['VJSHTE-DIMER 2023', 'PRANVERE-VERE 2023', 'PRANVERE-VERE 2024'],
    },
    {
        label: "MASA",
        type: 'checkbox',
        filters: ['40-42', '2/27', '28/30', '28/30', '31/33', '34/36', '37/39'],
    },
    {
        label: "MaTERIALI",
        type: 'checkbox',
        filters: ['PAMBUK'],
    },
    {
        label: "NGJYRA",
        type: 'checkbox',
        filters: ['PAMJE MALORE'],
    },
    {
        label: "GJINIA",
        type: 'checkbox',
        filters: ['M 8-14'],
    },
    {
        label: "FILTRO SIPAS CMIMIT",
        type: 'slider',
        filters: [1258, 6203],
    },
];

const CollapseMenu = ({ filter, isOpened, onSelect }) => {
    const [pRange, setPrange] = useState([1258, 6203])
    return (
        <>
            <div className="w-full flex justify-center items-center py-3 border-b border-b-[#888]" onClick={() => onSelect(filter)}>
                <div className="flex-1"><a className=" no-underline text-[#000] font-bold text-[14px] w-full">{filter.label}</a></div>
                <ChevronRight fontSize={'small'} />
            </div>
            {
                isOpened &&
                <div className="w-full px-2">
                    {filter.type == 'checkbox' ?
                        filter.filters.map((item, i) => (
                            <div key={i} className="flex justify-start items-center py-1">
                                <input type={'checkbox'} />
                                <p className="text-[14px] ml-1">{item}</p>
                            </div>
                        ))
                        :
                        <div className="w-full ">
                            <Slider handleStyle={{ background: '#000' }} trackStyle={{ background: '#000' }} railStyle={{ background: '#999' }} range={{ draggableTrack: true }} min={filter.filters[0]} max={filter.filters[1]} defaultValue={filter.filters} value={pRange} onChange={setPrange} />
                            <div className="mt-2 w-full flex justify-between items-center">
                                <p className="text-[12px] text-[#000]"><b>Min:</b> LEK {pRange[0]}</p>
                                <p className="text-[12px] text-[#000]"><b>Max:</b> LEK {pRange[1]}</p>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

const BybestCategoryFilter = () => {
    const [open, setOpen] = useState(true);
    const [activeMenu, setActiveMenu] = useState();
    return (
        <div className="w-full flex flex-col justify-start items-center">
            <div className="w-full flex justify-center items-center p-2 bg-[#efefef]" onClick={() => setOpen(pre => !pre)}>
                <p className="flex-1 text-[14px] leading-[21px]">FILTRAT</p>
                <FilterList style={{ color: '#000', fontSize: 20 }} />
            </div>
            {
                open && <div className="w-full flex flex-col justify-center items-center mt-4 mb-10">
                    {
                        filterItems.map(filter =>
                            <CollapseMenu filter={filter} isOpened={filter.label == activeMenu?.label} onSelect={setActiveMenu} />
                        )
                    }
                </div>
            }
        </div>
    );
};

export default BybestCategoryFilter;
