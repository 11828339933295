import React, { useState } from "react";
import "../index.css";
import { plus } from "../icons";
import { checkboxLabels as initialLabels } from "../../cart/checkout/data";
import { Checkbox } from "@mui/material";
import { FilterTitles } from "./filter-titles";
import PriceRange from "./price-range";
import { colorLabels } from "../../cart/checkout/data";

export const FilterLayout = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isPrice, setIsPrice] = useState(true);
  const [isColor, setIsColor] = useState(true);
  const [checkboxLabels, setCheckboxLabels] = useState(initialLabels);
  const [priceRange, setPriceRange] = useState([0, 2000]);

  const handleChange = (index) => {
    const updatedCheckboxLabels = [...checkboxLabels];
    updatedCheckboxLabels[index].value = !updatedCheckboxLabels[index].value;
    setCheckboxLabels(updatedCheckboxLabels);
  };

  return (
    <div className="filter-layout hidden md:col-span-1 col-span-5 grid-cols-2 w-full md:flex md:flex-col gap-5">
      <div className="flex flex-col gap-5">
        <FilterTitles
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          title="Product Categories"
        />

        {isExpanded && (
          <div className="flex flex-col">
            {checkboxLabels.map((item, index) => {
              return (
                <div className="flex justify-between items-center">
                  <div className="flex items-center" key={index}>
                    <Checkbox
                      label={item.title}
                      defaultChecked={item.value}
                      onChange={() => handleChange(index)}
                      className="cursor-pointer"
                      key={index}
                      id={index}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fill: "#131118",
                        },
                      }}
                    />
                    <label htmlFor={index} className="cursor-pointer">
                      {item.label}
                    </label>
                  </div>
                  <span className="cursor-pointer">{plus}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="hidden md:flex flex-col gap-5">
        <FilterTitles
          isExpanded={isPrice}
          setIsExpanded={setIsPrice}
          title="Filter by Price"
        />
        {isPrice && (
          <div className="flex flex-col gap-1">
            <span className="price-label">
              Price: ${priceRange[0]} - ${priceRange[1]}
            </span>
            <PriceRange priceRange={priceRange} setPriceRange={setPriceRange} />
          </div>
        )}
      </div>

      <div className="flex flex-col gap-5">
        <FilterTitles
          isExpanded={isColor}
          setIsExpanded={setIsColor}
          title="Filter by Color"
        />
        {isColor && (
          <div className="flex flex-col gap-2">
            {colorLabels.map((item, index) => {
              return (
                <div className="flex justify-between items-center">
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    key={index}
                  >
                    <span
                      className="color-box"
                      style={{
                        background: item.color,
                      }}
                    />

                    <label className="cursor-pointer">{item.label}</label>
                  </div>
                  <span className="pl-4">({item.value})</span>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="flex md:hidden flex-col gap-5">
        <FilterTitles
          isExpanded={isPrice}
          setIsExpanded={setIsPrice}
          title="Filter by Price"
        />
        {isPrice && (
          <div className="flex flex-col gap-1">
            <span className="price-label">
              Price: ${priceRange[0]} - ${priceRange[1]}
            </span>
            <PriceRange priceRange={priceRange} setPriceRange={setPriceRange} />
          </div>
        )}
      </div>
    </div>
  );
};
