import { useState } from "react"



export const FilterOptions = ( { list } ) => {

    const [ selected, setSelected ] = useState(0);

    return (
        <div className="flex flex-row flex-wrap gap-3">
            {
                list?.map((item, index) => (
                    <div key={index} onClick={() => setSelected(index)} className={`flex flex-row items-center gap-2 ${ selected === index ? 'text-white bg-[#131118]': 'text-[#606D93] border-[#606D93] border' } rounded-[20px] py-[8px] px-6`}>
                        {
                            item?.icon
                        }
                        {
                            item?.title
                        }
                    </div>
                ))
            }
        </div>
    )
}