import React from "react";
import { isEmpty } from "../../../utils/common";
import { minus, plus, trash } from "../../venue-retail/icons";
import "./index.css";

export const TableCartMobile = ({
  tableProducts = [],
  updateQuantityAtIndex,
  quantities,
  onRemoveProduct,
}) => {
  return (
    <div className="card-view">
      {tableProducts.map((item, index) => (
        <div key={index} className="product-card">
          <div className="flex items-start gap-4 relative">
            <div className="flex rounded-lg cursor-pointer gap-4 py-2 pr-2 items-center">
              <img
                className="w-[106px] h-[106px] rounded-lg"
                src={
                  isEmpty(item?.image_path) ? item.image_path : item?.image_path
                }
                alt={item.title}
              />
              <div className="flex flex-col justify-start w-full gap-[6px]">
                <div className="grid grid-cols-10">
                  <div className="table-title col-span-9">{item?.title}</div>
                </div>
                <div className="table-description">
                  {item?.short_description?.length <= 80
                    ? item?.short_description
                    : item?.short_description.slice(0, 80) + "..."}
                </div>
                <div className="flex flex-col">
                  <span className="text-price">{item.price}</span>
                </div>
                <div className="flex justify-between gap-2 w-full items-center">
                  <div className="flex justify-center items-center border gap-4 rectangle px-2">
                    <span
                      className="cursor-pointer"
                      onClick={() => updateQuantityAtIndex(item, index, false)}
                    >
                      {minus}
                    </span>
                    <span className="text-price">{quantities[index]}</span>
                    <span
                      className="cursor-pointer"
                      onClick={() => updateQuantityAtIndex(item, index)}
                    >
                      {plus}
                    </span>
                  </div>
                  <span
                    className="cursor-pointer"
                    onClick={() => onRemoveProduct(item)}
                  >
                    {trash}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
