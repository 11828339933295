export const byBestRetailRouteNames = {
  order_tracking: "/:appKey/order/tracking",
  my_club: "/:appKey/my-club",
  cart: "/:appKey/cart",
  checkout: "/:appKey/checkout",
  payment: "/:appKey/payment",
  success_checkout: "/:appKey/success-checkout",
  app_home: "/:appKey",
  product_details: "/:appKey/products/:product_id/:product_slug",
  brand: "/:appKey/brand/:brand_url",
  brand_search: "/:appKey/brand/:brand_url",
  category: "/:appKey/category/:category_url",
  category_search: "/:appKey/category/:category_url",
  collection: "/:appKey/collection/:collection_url",
  collection_search: "/:appKey/collection/:collection_url",
  group: "/:appKey/group/:group_id",
  group_page: "/:appKey/group/:group_id?page=x",
  wishlist: "/:appKey/wishlist",
  blog_category: "/:appKey/blog/category/:category_name",
  search: "/:appKey/search",
  virtual_gift_card: "/:appKey/virtual-gift-card",
  page: "/:appKey/page/:page_id",
  page_return_policy: "/page/return-policy",
  page_help: "/page/help",
  page_faq: "/page/frequently-asked-questions",
  page_job_listing: "/page/job-listing",
  page_contact_us: "/page/contact-us",
  page_about_us: "/page/about-us",
  page_stores_and_hours: "/page/stores-and-hours",
  page_privacy_policy: "/page/privacy-policy",
  page_cookies: "/page/cookies",
  page_prices_and_currency: "/page/prices-and-currency",
  page_payment_methods: "/page/payment-methods",
  page_shipping_methods: "/page/shipping-methods",
  page_product_exchange: "/page/product-exchange",
};

const RouteNames = {
  ...byBestRetailRouteNames,
  about_ap: "/venue/accommodation/:app_key/about",
  contact_ap: "/venue/accommodation/:app_key/contact",
  blog: "/blog",
  blog_detail: "/blog/:id",
  not_found: "/not-found",

  // Customer
  dashboard: "/dashboard",
  reservations: "/reservations",
  payments: "/:app_key/customer/payments",
  login: "/login",
  register: "/register",
  email_not_verified: "/email-not-verified",
  verify_email: "/verify-email",


  // Food
  whoweserve_food_overview: "/who-we-serve/food-and-beverage",
  whoweserve_restaurants: "/who-we-serve/food-and-beverage/restaurants",
  whoweserve_cafes: "/who-we-serve/food-and-beverage/cafes",
  whoweserve_bistros: "/who-we-serve/food-and-beverage/bistros",
  whoweserve_bars: "/who-we-serve/food-and-beverage/bars",
  whoweserve_pub_and_clubs: "/who-we-serve/food-and-beverage/pub-and-clubs",
  whoweserve_food_trucks: "/who-we-serve/food-and-beverage/food-trucks",
  whoweserve_fine_dining_restaurants:
    "/who-we-serve/food-and-beverage/fine-dining-restaurants",
  whoweserve_bakeries: "/who-we-serve/food-and-beverage/bakeries",
  whoweserve_catering_services:
    "/who-we-serve/food-and-beverage/catering-services",
  whoweserve_fast_food_joints:
    "/who-we-serve/food-and-beverage/fast-food-joints",
  whoweserve_ice_cream_parlors:
    "/who-we-serve/food-and-beverage/ice-cream-parlors",
  whoweserve_food_stalls: "/who-we-serve/food-and-beverage/food-stalls",
  whoweserve_buffets: "/who-we-serve/food-and-beverage/buffets",
  whoweserve_pop_up_restaurants:
    "/who-we-serve/food-and-beverage/pop-up-restaurants",
  whoweserve_juice_bars: "/who-we-serve/food-and-beverage/juice-bars",
  whoweserve_food_halls: "/who-we-serve/food-and-beverage/food-halls",
  whoweserve_supper_clubs: "/who-we-serve/food-and-beverage/supper-clubs",
  whoweserve_delis: "/who-we-serve/food-and-beverage/delis",
  whoweserve_food_court: "/who-we-serve/food-and-beverage/food-court",
  whoweserve_pizzeria: "/who-we-serve/food-and-beverage/pizzeria",
  whoweserve_coffe_shop: "/who-we-serve/food-and-beverage/coffe-shop",

  // Sports & Entertainment
  whoweserve_sports_entertainment_overview:
    "/who-we-serve/entertainment-venues",
  whoweserve_bowling: "/who-we-serve/entertainment-venues/bowling",
  whoweserve_golf_courses: "/who-we-serve/entertainment-venues/golf-courses",
  whoweserve_gyms_and_fitness_centers:
    "/who-we-serve/entertainment-venues/gyms-and-fitness-centers",
  whoweserve_arcades_and_game_centers:
    "/who-we-serve/entertainment-venues/arcades-and-game-centers",
  whoweserve_sports_complexes:
    "/who-we-serve/entertainment-venues/sports-arenas",
  whoweserve_concert_venues_and_theaters:
    "/who-we-serve/entertainment-venues/concert-halls-and-theaters",
  whoweserve_amusement_parks:
    "/who-we-serve/entertainment-venues/amusement-parks",
  whoweserve_ski_resorts: "/who-we-serve/entertainment-venues/ski-resorts",
  whoweserve_cinema: "/who-we-serve/entertainment-venues/cinema",
  whoweserve_museum: "/who-we-serve/entertainment-venues/museum",
  whoweserve_beach_bars: "/who-we-serve/entertainment-venues/beach-bars",

  // Accommodation
  whoweserve_accommodation_overview: "/who-we-serve/accommodation",
  whoweserve_hotels: "/who-we-serve/accommodation/hotels",
  whoweserve_hostels: "/who-we-serve/accommodation/hostels",
  whoweserve_hotel_chains: "/who-we-serve/accommodation/hotel-chains",
  whoweserve_cozy_retreats: "/who-we-serve/accommodation/cozy-retreats",
  whoweserve_vacation_rental: "/who-we-serve/accommodation/vacation-rentals",
  whoweserve_luxury_resorts: "/who-we-serve/accommodation/luxury-resorts",
  whoweserve_resorts: "/who-we-serve/accommodation/family-resorts",
  whoweserve_service_apartments:
    "/who-we-serve/accommodation/service-apartments",
  whoweserve_bed_and_breakfast: "/who-we-serve/accommodation/bed-and-breakfast",
  whoweserve_campground: "/who-we-serve/accommodation/campground",
  whoweserve_capsule_hotel: "/who-we-serve/accommodation/capsule-hotel",
  whoweserve_youth_hostel: "/who-we-serve/accommodation/youth-hostel",
  whoweserve_rv_park: "/who-we-serve/accommodation/rv-park",
  whoweserve_motel: "/who-we-serve/accommodation/motel",

  // Retail
  whoweserve_retail_overview: "/who-we-serve/retail-management",
  whoweserve_home_decor: "/who-we-serve/retail-management/home-decor",
  whoweserve_sports_essentials:
    "/who-we-serve/retail-management/sporting-goods-stores",
  whoweserve_fashion_threads:
    "/who-we-serve/retail-management/fashion-boutiques",
  whoweserve_retail_chains: "/who-we-serve/retail-management/retail-chains",
  whoweserve_boutiques_and_specialty_stores:
    "/who-we-serve/retail-management/boutiques-and-specialty-stores",
  whoweserve_online_retailers: "/who-we-serve/retail-management/online-shops",
  whoweserve_grocery_stores: "/who-we-serve/retail-management/grocery-stores",
  whoweserve_electronics_stores:
    "/who-we-serve/retail-management/electronics-stores",
  whoweserve_pharmacies: "/who-we-serve/retail-management/pharmacies",
  whoweserve_auto_parts_stores:
    "/who-we-serve/retail-management/auto-parts-stores",
  whoweserve_liquor_stores: "/who-we-serve/retail-management/liquor-stores",
  whoweserve_pet_supply_stores:
    "/who-we-serve/retail-management/pet-supply-stores",

 

  // Healthcare
  // whoweserve_healthcare_overview: "/who-we-serve/healthcare",
  // whoweserve_dentalclinic: "/who-we-serve/dental-clinic",
  // whoweserve_hospital: "/who-we-serve/hospital",
  whoweserve_sportsandentertainment_old:
    "/who-we-serve/entertainment-venues-old",
  product_updates: "/product-updates",
  integrations: "/integrations",

  venue_restaurant_contact: "/venue/restaurant/:app_key/contact",


  venue_restaurant_hospital: "/venue/restaurant",
  venue_restaurant_hospital_contact: "/venue/restaurant",

 

  venue_golf: "/venue/golf/:app_key",
  venue_golf_contact: "/venue/golf/:app_key/contact",
  venue_golf_book: "/venue/golf/book",

  cart: "/cart/:app_key",
  billing_information: "billing-information",
  checkout: "/checkout",
  cart_restaurant: "/venue/restaurant/:app_key/cart",
  cart_sport: "/venue/sport/:app_key/cart",
  cart_hotel: "/venue/hotel/:app_key/cart",
  cart_retail: "/venue/retail/:app_key/cart",
  accommodation_rental: "rental/:code",
  accommodation_rental_reserve: "rental/reserve",
  food_checkout: "/food-checkout",
  //affiliates
  affiliates_register: "/affiliates/register",
  affiliates_login: "/affiliates/login",
  affiliates_dashboard: "/affiliates/dashboard",
};

export default RouteNames;
