const statusStyles = {
  completed: {
    textColor: "text-[#027A48]",
    bgColor: "bg-[#ECFDF3]",
  },
  joined: {
    textColor: "text-[#027A48]",
    bgColor: "bg-[#ECFDF3]",
  },
  earned: {
    textColor: "text-[#027A48]",
    bgColor: "bg-[#ECFDF3]",
  },
  active: {
    textColor: "text-[#027A48]",
    bgColor: "bg-[#ECFDF3]",
  },
  delivered: {
    textColor: "text-[#027A48]",
    bgColor: "bg-[#ECFDF3]",
  },

  cancelled: {
    textColor: "text-[#FF3E3E]",
    bgColor: "bg-[#FDEFEC]",
  },
  unavailble: {
    textColor: "text-[#FF3E3E]",
    bgColor: "bg-[#FDEFEC]",
  },
  "no active": {
    textColor: "text-[#FF3E3E]",
    bgColor: "bg-[#FDEFEC]",
  },
  spent: {
    textColor: "text-[#FF3E3E]",
    bgColor: "bg-[#FDEFEC]",
  },
  confirmed: {
    textColor: "text-[#3E45FF]",
    bgColor: "bg-[#EDEEFF]",
  },
  default: {
    textColor: "text-[#344054]",
    bgColor: "bg-[#F2F4F7]",
  },
};

export const getStatus = (status = "") => {
  const lowerStatus = status.toLocaleLowerCase();
  const { textColor, bgColor } =
    statusStyles[lowerStatus] || statusStyles.default;

  return (
    <div className="align-start">
      <div
        className={`align-middle flex ${textColor} ${bgColor} px-2 py-[2px] rounded-[16px] text-12 font-medium leading-[18px]`}
      >
        <span className="text-[20px] content-center mr-1">•</span>
        {status}
      </div>
    </div>
  );
};
