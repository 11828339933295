import React from "react";
import ByBestSidebar from "./sideBar";

const ByBestPriceCurrency = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
        <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
          BYBEST {`>`} FAQE {`>`} NA KONTATONI
        </p>
      </div>
      <div className={`w-full max-w-screen-main mt-7 mb-5`}>
        <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
          <ByBestSidebar />
          <div className=" col-span-7">
            <p className="text-[13px] leading-[20px] text-[#000]  ">
              Çmimet e publikuara në faqe janë në Lekë (ALL) dhe Euro (EUR).
            </p>
            <p className="mt-6 text-[13px] leading-[20px] text-[#000]  ">
              Faturat e porosive janë në Lekë{" "}
              <span className="text-[#666]">
                (ALL) (shuma do të konvertohet në bazë të kursit të këmbimit të
                Bankës së Shqipërisë).
              </span>
            </p>
            <p className="text-[13px] leading-[20px] text-[#000]  ">
              BY BEST fton klientët të kontaktojnë bankën e tyre për të kërkuar
              informacion të detajuar mbi kursin e këmbimit dhe tarifat bankare
              në lidhje me transaksionin e tyre.
            </p>
            <p className="text-[13px] leading-[20px] text-[#000] font-bold ">
              Çmimet nuk përfshijnë taksat e importit, të cilat duhet të paguhen
              me momentin e dorëzimit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ByBestPriceCurrency;
