import React, { useState } from "react";
import { cashDeliveryIcon, plusIcon, visaIcon } from "./icons";
import ChoosePayment from "./choose-payment";

const PaymentMethod = () => {
  const [selectedPayment, setSelectedPayment] = useState("Cash on Delivery");

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="text-[#101828] text-18 font-semibold leading-7">
        Payment methods
      </div>
      <div className="grid lg:flex items-center gap-4 cash-delivery">
        <ChoosePayment
          icon={cashDeliveryIcon}
          borderColor={
            selectedPayment === "Cash on Delivery"
              ? "border-[#E14848]"
              : "border-[#677489]"
          }
          description="Default"
          title="Cash on Delivery"
          isSelected={selectedPayment === "Cash on Delivery"}
          onSelect={handlePaymentSelect}
        />
        <ChoosePayment
          icon={visaIcon}
          borderColor={
            selectedPayment === "**** 8304"
              ? "border-[#E14848]"
              : "border-[#677489]"
          }
          description="Visa Edit"
          title="**** 8304"
          isSelected={selectedPayment === "**** 8304"}
          onSelect={handlePaymentSelect}
        />

        <div className="button flex justify-center items-center">
          <button>
            <span>{plusIcon}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
