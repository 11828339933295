import React from "react";
import "./index.css";
import BrandName from "../../assets/images/retail/brand_name.png";
import {
  infoData,
  retailInfoData,
  serviceData,
  subscribeData,
} from "../cart/checkout/data";
import {
  amex,
  email,
  facebook,
  gPay,
  instagram,
  masterCard,
  paypal,
  twitter,
  visa,
} from "./icons";

const RetailFooter = () => {
  return (
    <div className="footer pt-[70px] p-2 flex flex-col gap-5">
      <img alt="brand name" src={BrandName} width={168} height={25} />
      <div
        className={`grid md:flex md:justify-between xs:grid-cols-2 grid-cols-1 gap-y-2 items-center mx-auto mb-10 py-2 w-full`}
      >
        <div className="flex flex-col gap-5 w-full">
          {retailInfoData.map((item, index) => {
            return (
              <div className="flex items-center gap-2" key={index}>
                <span>{item.icon}</span>
                <span className="info-label max-w-[260px]">{item.label}</span>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-5 w-full">
          {infoData.map((item, index) => {
            return (
              <div className="flex items-center gap-5" key={index}>
                <span className={index === 0 ? "info-title" : "info-label"}>
                  {item.label}
                </span>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-5 w-full">
          {serviceData.map((item, index) => {
            return (
              <div className="flex items-center gap-2" key={index}>
                <span className={index === 0 ? "info-title" : "info-label"}>
                  {item.label}
                </span>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-5 w-full">
          {subscribeData.map((item, index) => {
            return (
              <div className="flex items-center gap-2" key={index}>
                <span className={index === 0 ? "info-title" : "info-label"}>
                  {item.label}
                </span>
              </div>
            );
          })}
          <div className="flex items-center justify-between subscribe">
            <div className="flex gap-2">
              <span>{email}</span>
              <input
                className="info-label border-none outline-none"
                placeholder="Your Email"
              />
            </div>
            <span className="cursor-pointer">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8L18.5 12M18.5 12L14.5 16M18.5 12L6.5 12"
                  stroke="#1A1818"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <span className="divider" />
      <div className="grid sm:grid-cols-4 gap-2 items-center">
        <div className="cards flex gap-2 items-center">
          <span>{visa}</span>
          <span>{masterCard}</span>
          <span>{gPay}</span>
          <span>{amex}</span>
          <span>{paypal}</span>
        </div>
        <div className="right-reservation col-span-2 flex justify-center">
          ©2024 Swarowski - All Rights Reserved️ - Powered by VenueBoost
        </div>
        <div className="cards gap-2 items-center flex justify-end">
          <span>{facebook}</span>
          <span>{instagram}</span>
          <span>{twitter}</span>
        </div>
      </div>
    </div>
  );
};

export default RetailFooter;
