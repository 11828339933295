import React from "react";
import Sidebar from "../../../components/Sidebar";
import CustomerHeader from "../customer-header";
import WalletPoints from "./wallet-points";
import PaymentMethod from "./payment-method";
import "./index.css";
import WalletTable from "./wallet-table";
import ReferralsTable from "./referrals-table";
import LoggedInFooter from "../../../containers/footer/logged-in-footer";

const Wallet = () => {
  return (
    <div className="flex flex-1 flex-col gap-5 ">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
        <div className="px-6 py-[10px] flex">
          <CustomerHeader
            title="Wallet"
            description="View and manage your payment transactions and reservation history"
            showItems={false}
          />
        </div>

        <div className="grid lg:grid-cols-3 grid-cols-1">
          <div className="col-span-2 border p-2 px-6">
            <PaymentMethod />
          </div>
          <WalletPoints />
        </div>
      </div>
      <WalletTable />
      <ReferralsTable />
    </div>
  );
};

export default Wallet;
