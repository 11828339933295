import Carousel from "react-multi-carousel";
import { motion } from "framer-motion";
import { useRef } from "react";
import "./index.css";
import { carouselItems } from "./data";
import "react-multi-carousel/lib/styles.css";

const AccommodationGuests = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 2050 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 2050, min: 1240 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1240, min: 768 },
      items: 1,
    },
    mobile1: {
      breakpoint: { max: 768, min: 414 },
      items: 1,
    },
    mobile2: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const carouselRef = useRef(null);

  const handlePrev = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.previous();
    }
  };

  const handleNext = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.next();
    }
  };

  return (
    <div className="bg-[#FAFAFA] guest-saying py-16 w-full mx-auto px-4 sm:px-6 md:px-8 lg:px-16 flex justify-center items-center flex-col gap-4 large-width">
      <div className="text-[#2D3748] text-24 lg:text-30 font-bold">
        What our guests are saying
      </div>
      <div className="w-full flex flex-col lg:flex-row sm:gap-8 !gap-0 items-center max-w-[997px]">
        <div
          className="!w-11 !h-11 min-w-[44px] bg-white rounded-full items-center justify-center border cursor-pointer lg:flex hidden"
          onClick={() => handlePrev()}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.94444 15.4444L3.5 9.99999M3.5 9.99999L8.94444 4.55554M3.5 9.99999L17.5 9.99999"
              stroke="#171717"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <Carousel
          ref={carouselRef}
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          showDots={false}
          partialVisible={false}
          arrows={false}
          swipeable={false}
          draggable={true}
          keyBoardControl={false}
          className="!h-auto"
          containerClass={"pt-6 services-carousel w-full"}
        >
          {carouselItems.map((item, index) => (
            <motion.div
              key={index}
              className="flex !h-auto"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <div className="rounded-[6px] bg-white py-[30px] px-6 flex-col gap-[10px] justify-center items-center w-full flex shadow-md m-3">
                <div className="text-20 text-[#171717] font-medium">
                  {item.name}
                </div>
                <div className="text-18 font-medium text-[#6B6B6B] leading-7 text-center max-w-[592px]">
                  {item.review}
                </div>
                <div className="flex gap-1">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <img
                      key={star}
                      src={item.img}
                      alt=""
                      className="mt-4 rounded-lg"
                    />
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </Carousel>

        <div className="flex items-center gap-8">
          <div
            className="!w-11 !h-11 min-w-[44px] bg-white rounded-full items-center justify-center border cursor-pointer lg:hidden flex"
            onClick={() => handlePrev()}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.94444 15.4444L3.5 9.99999M3.5 9.99999L8.94444 4.55554M3.5 9.99999L17.5 9.99999"
                stroke="#171717"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div
            className="w-11 h-11 min-w-[44px] bg-white rounded-full items-center justify-center border cursor-pointer flex"
            onClick={() => handleNext()}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0556 4.55554L17.5 9.99999M17.5 9.99999L12.0556 15.4444M17.5 9.99999L3.5 9.99999"
                stroke="#171717"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccommodationGuests;
