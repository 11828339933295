import React from "react";
import rentalIcon from "../../../assets/svgs/rental.svg";

const openVR = () => {
  window.open('https://teliportme.com/virtualtour/7ad3a639', "_blank", "location=yes");
};

const AccommodationInformation = ({
                                    accId,
                                    vRlink,
                                    // openGoogleMaps, // Uncomment if you want to use this prop
                                  }) => {
  const isTirana = accId !== "OAS9218APPU";

  const locationDescription = isTirana ? (
      <>
        This really is the best neighborhood in Tirana. "Sami Frasheri"
        street is one of the most popular streets in Tirana, which leads
        directly to the main avenues and Lana river, only river that crosses
        the city in between. The neighborhood is one of the safest, you can
        easily find the Directory of Policy in front of the building. A
        small Conad store and a bakery is located nearby the apartment.
        Shopping from food markets to clothing are accessible as soon as you
        step out of the apartment.
      </>
  ) : (
      <>
        West Blaine Street in Riverside, CA 93950, is nestled in a vibrant
        neighborhood renowned for its charm and convenience. It boasts a
        prime location, just a stone's throw away from the bustling main
        avenues and the serene Lana River, making it an ideal setting for
        both urban exploration and tranquil retreats. With its tree-lined
        streets and a diverse array of shops, restaurants, and cultural
        attractions nearby, West Blaine Street offers the perfect blend of
        convenience and leisure.
      </>
  );

  const gettingAround = isTirana ? (
      <>
        Public transit and taxis are conveniently located just outside the
        apartment. However, because the apartment is in such close
        proximity to the center of Tirana, the block, Taiwan center,
        National Museum, etc, a car is not necessary for getting around
        Tirana.
      </>
  ) : (
      <>
        Public transit and taxis are conveniently located just outside the
        apartment. However, because the apartment is in such close
        proximity to the center of Riverside, the block, Riverside Art
        Museum, California Citrus State Historic Park, etc., a car is not
        necessary for getting around Riverside. However, a bus station is
        located just 50 meters nearby the building, and the cost for a
        one-way trip is affordable.
      </>
  );

  return (
      <div className="w-full mt-6 mx-auto large-width md:mt-0 flex flex-col">
        <h2 className="text-[#2D3748] text-24 md:text-[30px] font-bold leading-[120%] mb-2">
          Where you'll be
        </h2>
        <span className="text-[#646464]">{locationDescription}</span>

        <p className="mt-4">
          <b>Getting around</b>
        </p>
        <p>
          <span className="text-[#646464]">{gettingAround}</span>
        </p>
        <div className="flex flex-row gap-4 mt-6">
          <img src={rentalIcon} alt="icon" width="32" />
          <div className="flex flex-col">
            <span className="text-lg font-semibold text-black1">Rental Type</span>
            <span className="text-[#646464]">Hotel and B&B</span>
          </div>
        </div>
      {/*  <span*/}
      {/*      className="text-[14px] leading-5 font-medium text-[#240b3b] cursor-pointer mt-4"*/}
      {/*      // onClick={() => openGoogleMaps()}*/}
      {/*  >*/}
      {/*  /!*todo: redirect to google map with lat long and should be good*!/*/}
      {/*    Open in Google Maps {`>`}*/}
      {/*</span>*/}
        {vRlink && isTirana && (
            <div className="mt-2">
          <span
              className="text-[14px] leading-5 font-medium text-[#240b3b] cursor-pointer"
              onClick={openVR}
          >
            Virtual Tour {`>`}
          </span>
            </div>
        )}
      </div>
  );
};

export default AccommodationInformation;