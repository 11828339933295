import { useEffect, useMemo } from "react";
import { useState } from "react";
import RenderTable from "./table";

export const Tables = ({ tables, selectedTable, onSelect }) => {
  const [tab, setTab] = useState(null);
  const [curTable, setCurTable] = useState(null);

  const locations = useMemo(() => {
    let locs = [];
    for (let i = 0; i < tables.length; i++) {
      if (tables[i].location != null && !locs.includes(tables[i].location)) {
        locs.push(tables[i].location)
      }
    }
    locs.sort((a, b) => a.localeCompare(b));
    return locs;
  }, [tables])

  const dispTables = useMemo(() => {
    return tables.filter(t =>  t.location == tab)
  }, [tab, tables])

  useEffect(()=>{
    if (locations.length > 0) {
      setTab(locations[0])
    }
  }, [locations])

  useEffect(() => {
    setCurTable(selectedTable)
  }, [selectedTable])

  return (
    <div className="w-full mt-7 mb-4">
      <div className="w-full flex">
        <div className="flex justify-center items-center w-full h-[34px] overflow-x-auto rounded-full bg-[#F1F1F1] mb-6">
          {
            locations.map((loc, index) =>
              <div
                key={index}
                className={` whitespace-nowrap flex justify-center items-center rounded-full cursor-pointer px-4 h-full ${tab === loc ? " bg-green1 text-white" : "text-[#6B6B6B]"
                  } manrope-font font-bold text-[16px] `}
                onClick={() => setTab(loc)}
              >
                {loc}
              </div>
            )
          }
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-center w-full gap-[20px] mb-4">
        {dispTables.map((item, i) => {
          return (
            <RenderTable
              isSelected={curTable?.id === item.id}
              num={item.seats}
              content={item.name}
              shape={item.shape}
              onClick={() => {
                setCurTable(item);
                onSelect(item);
              }}
              isHChair={false}
              vertical={false}
              key={i}
            // showTime={showTime}
            // showAmount={showAmount}
            // showReservations={showReservations}
            />
          );
        })}
      </div>
      {
        curTable != null &&
        <p className="px-6 manrope-font text-center font-semibold text-[15px] text-[#747474]">
          You can choose any of available tables if table no.{curTable.number} is not
          your preferences
        </p>
      }
    </div>
  );
};
