import React from "react";
import "./index.css";
import { blockItems } from "./block-items";

const FreeShipping = () => {
  return (
    <div
      className={`grid md:flex md:justify-between xs:grid-cols-2 grid-cols-1 gap-y-2 items-center mx-auto mb-10 py-2 w-full`}
    >
      {blockItems.map((item) => {
        return (
          <div className="main-block">
            {item.icon}
            <div className="title">{item.title}</div>
            <div className="subtitle">{item.subtitle}</div>
          </div>
        );
      })}
    </div>
  );
};

export default FreeShipping;
