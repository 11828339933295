import React from "react";
import ByBestSidebar from "./sideBar";
import useSeoData from "../../services/hooks/use-seo";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";

const ByBestShippingMethod = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
            BYBEST {`>`} FAQE {`>`} DËRGESAT & KOSTOT
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <h3 className="text-[13px] leading-[21px] font-bold text-[#666] uppercase">
                DËRGESAT & KOSTOT
              </h3>
              <p className="mt-[5px] text-[13px] leading-[20px] text-[#000] ">
                Dorëzimi do të bëhet brenda afatit prej 2-3 ditësh pas
                konfirmimit të porosisë. Klienti duhet të vendosë informacionin
                e saktë për marrjen dhe dorëzimin e porosisë.{" "}
                <span className="text-[#666]">ByBest.shop</span> nuk është
                përgjegjës për pasojat e ngjarjeve që janë përtej kontrollit të
                tij, si:
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000]">
                  forca madhore
                </li>
                <li className="mt-[6px] text-[12px] leading-[20px] text-[#666] ">
                  ngjarjet që varen nga ofruesi i transportit, i cili do të
                  vononte ose të ndalonte shpërndarjen e produkteve të
                  porositura
                </li>
              </ul>
              <p className="mt-[13px] text-[13px] leading-[20px] text-[#000] ">
                Dorëzimi do të bëhet në adresën që klienti do të deklarojë sipas
                mundësive të mëposhtme:
              </p>
              <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                SRANDARTI I DËRGIMIT TË POROSIVE:
              </h3>
              <p className="mt-2 text-[13px] leading-[25px] text-[#000] ">
                <span className="text-[#666]">
                  Kostoja e transportit është 150 ALL / Porositë brenda qytetit
                  të Tiranës dhe 330 ALL
                </span>
                /porosi në të gjitha rrethet e Shqipërisë. Kostoja e transportit
                në Kosovë dhe Maqedoninë e Veriut është 5-7 EUR/porosi.
                Gjithashtu ne ofrojme dergesa të shpejta për porositë brenda
                territorit te Shqiperisë.{" "}
                <span className="text-[#666]">
                  Kostoja e transportit është 500 ALL / Porositë brenda qytetit
                  të Tiranës dhe 700 ALL
                </span>
                /porosi në të gjitha rrethet e Shqipërisë dhe sherbimi do te
                ofrohet nga ora 9:00 deri ne oren 20:00. Koha per te derguar
                porosite brenda qytetit te Tiranes do te jete 3-4 ore ndersa per
                porosite jashte qyetetit te Tiranes do te jete deri ne 12 ore.{" "}
                <span className="text-[#666]">
                  Ofruesi ynë i transportit do të kontaktojë klientin në ditën e
                  dorëzimit të porosisë për të konfirmuar kohën dhe vendin e
                  dorëzimit. Klienti duhet të kontrollojë produktet e dorëzuara
                  nga transportuesi dhe nëse ai vëren se produktet mungojnë ose
                  dëmtohen, klienti duhet të dërgojë postë për të
                  orders@bybest.shop ku për të përshkruar të gjitha vërejtjet.
                  Këto vërejtje duhet të jenë të qarta (përcaktimi i dëmeve) në
                  mënyrë që të merren parasysh nga më të mirat për zëvendësimin
                  e produktit. Emaili duhet gjithashtu të shoqërohet me
                  fotografi të produktit së bashku me dokumentin zyrtar të
                  blerjes së sendit (kupon tatimor). Ky njoftim duhet të
                  dërgohet brenda ditës që është marrë porosia.
                </span>
              </p>
              <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                DORËZIMI I DHURATAVE:
              </h3>
              <p className="mt-2 text-[13px] leading-[25px] text-[#000] ">
                Blerësi do të jetë në gjendje të vendosë një porosi dhe ta
                dërgojë atë si një dhuratë për një palë të tretë. Kur porosia
                është për një dhuratë, klienti mund të bëjë pagesën nga numrat e
                llogarisë, ose me kartë krediti / debiti. Komentet përkatëse
                janë vendosur në porosi. Gjithashtu, blerësi ka të drejtë të
                kërkojë vendosjen e një dedikimi të personalizuar.
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestShippingMethod;
