import React from 'react';

import ServiceBtn from '../../../components/FormBtn/ServiceBtn';
import FormText from '../../../components/FormText';

import gymImg from '../../../assets/images/services/gym.png';

const GymHeader = ({ data }) => {
  const handleAction = () => {
    console.log('button clicked');
  };

  return (
    <div className="md:bg-gray2 bg-white">
      {/* <div className='block md:hidden h-48 w-full'>
        <img src={data?.overview?.logo} alt='restaurant' className='h-full w-full' />
      </div> */}
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-10 md:py-14 max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full">
        <div className='flex flex-col justify-center md:pr-16 pl-0 md:pl-3'>
          <FormText
            title="Alpha Exercise Gym"
            type='page-title'
            customClass='mt-5 !font-bold'
          />
          <FormText
            title="Classes for all ages and abilities or individual training sessions so you can reach your goals at your own pace, we can help."
            type='subtitle-dark'
            customClass='mt-4 md:mt-8'
          />
          <FormText
            title="We are open 24 hours a day."
            type='subtitle-dark'
            customClass='mt-0'
          />
          <div className='flex flex-row flew-wrap'>
            <ServiceBtn label="Gym" classes="mr-3" onActin={() => handleAction()} />
            <ServiceBtn label="Zumba Classes" classes="mr-3" onActin={() => handleAction()} />
            <ServiceBtn label="1500m2" classes="mr-3" onActin={() => handleAction()} />
            <ServiceBtn label="Lunch" classes="mr-3" onActin={() => handleAction()} />
          </div>
        </div>
        <img src={gymImg} alt='gym' className='rounded-xl w-full h-full' />
      </div>
    </div>
  )
}

export default GymHeader;
