import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestFaqs = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
            BYBEST {`>`} FAQE {`>`} RRETH NESH
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <h3 className="text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                PYETJE TË SHPESHTA
              </h3>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    SI TË KONTAKTOJ SHËRBIMIN E KLIENTIT?
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    Shërbimi ynë ndaj klientit është gjithmonë i disponueshëm
                    për t'ju ndihmuar.
                    <br />
                    Detajet e kontaktit janë si më poshtë:
                    <br />
                    Telefon: +355 68 805 2000
                    <br />
                    Shërbimi ndaj klientit:{" "}
                    <span className=" underline">
                      customerservice@bybest.shop
                    </span>
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    A JANË AUTENTIKË ARTIKUJT E SHITUR NË KËTË FAQE?
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    By Best është një shitës i autorizuar për të gjitha markat e
                    pranishme në e-commerce dhe në dyqanet tona
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    A SHTOHEN SËRISH ARTIKUJT E SHITUR?
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    ByBest e-commerce pasqyron disponueshmërinë aktuale të
                    artikujve në magazinat dhe dyqanet tona. Nëse një madhësi
                    nuk është më e disponueshme, do të thotë se është shitur.
                    Fatkeqësisht, ne nuk jemi në gjendje të parashikojmë nëse
                    një artikull do të furnizohet më vonë gjatë sezonit. Ne ju
                    rekomandojmë që të kontrolloni periodikisht faqen e
                    artikullit ose koleksionin për disponueshmërinë e madhësisë
                    së re ose ta shtoni artikullin në listën tuaj të dëshirave
                    për të kontrolluar lehtësisht statusin e tij.
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    KUR DO TË DËGOHET POROSIA IME?
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    Artikujt e disponueshëm do të dërgohen brenda 1-2 ditëve të
                    punës (e hënë-e premte) pas përfundimit të porosisë. Gjatë
                    shitjeve ose promocioneve speciale, kohët e përpunimit mund
                    të zgjasin më shumë.
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    SA ËSHTË KOSTOMJA E DERGIMIT?
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    Metoda dhe kostoja e transportit ndryshojnë në varësi të
                    vendit të zgjedhur të transportit. Në faqen{" "}
                    <span className=" underline ">DËRGESAT DHE TARIFAT</span>,
                    ju mund të gjeni të gjithë informacionin përkatës duke
                    zgjedhur vendin e dëshiruar të transportit në krye të faqes.
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    A MUND TË ANULOJ NJË POROSI?
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    Vetëm porositë që nuk janë përpunuar mund të anulohen.
                    <br />
                    Për të kërkuar anulimin e porosisë, ju lutemi shkoni te
                    porosia juaj e statusit.
                    <br />
                    Nëse është e mundur të anuloni porosinë, do të shihni
                    butonin ANULO POROSI.
                    <br />
                    Për më shumë informacion, kontaktoni shërbimin tonë ndaj
                    klientit në emailin e mëposhtëm: customerservice@bybest.shop
                  </p>
                </li>
                <li className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                  <p className=" text-[13px] leading-[25px]  text-[#000] font-bold">
                    SI MUND TË NJOFTOHEM PËR PROMOCIONET?
                  </p>
                  <p className=" text-[13px] leading-[25px] text-[#000]  ">
                    Regjistrohu në <b>FORMËN NË FUND TË FAQES</b> për të marrë
                    email për të gjitha tendencat më të fundit, ardhjet e reja,
                    ofertat ekskluzive, ngjarjet speciale dhe promovimet.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestFaqs;
