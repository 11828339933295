import React from "react";
import { isMobile } from "react-device-detect";

import FormText from "../../../components/FormText";
import gym1Img from "../../../assets/images/services/gym1.png";
import gym2Img from "../../../assets/images/services/gym2.png";
import gym3Img from "../../../assets/images/services/gym3.png";
import gym4Img from "../../../assets/images/services/gym4.png";

const GymPhotos = ({ photos = [] }) => {
  return (
    <div className="md:col-span-3 flex flex-col pt-4 md:pt-8">
      <FormText title="Photos" type="itemtitle-sub" />
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        {photos.map((item, index) => {
          return (
            <img
              key={index}
              className={`${
                index === 0
                  ? "md:col-span-2 md:row-span-2"
                  : "md:col-span-1 md:row-span-1"
              } `}
              style={{
                position: "relative",
                background: isMobile
                  ? "linear-gradient(0deg, #F9F7F7 0%, #F9F7F7 100%)"
                  : "white",
                border: "1px #E0E0E0 solid",
              }}
              src={item.photo_path}
              alt="img"
            />
          );
        })}
      </div>
    </div>
  );
};

export default GymPhotos;
