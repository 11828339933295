import React, { useState } from 'react';

import FormText from '../../components/FormText';

import bookIcon from '../../assets/images/golf-book.png'
import additionIcon from '../../assets/svgs/addition.svg';
import timeIcon from '../../assets/svgs/time.svg';
import FormTextInput from '../../components/FormTextInput';
import { SubmitBtn } from '../../components/FormBtn';
import FormTextarea from '../../components/FormTextarea';

const VenueGolfBook = () => {
  const [info, setInfo] = useState({
    firstName: '', lastName: '', phoneNumber: '', email: '', note: ''
  })

  const handleChangeInfo = (field, value) => {
    setInfo(v => ({ ...v, [field]: value }))
  }

  return (
    <div className='max-w-screen-main mx-auto bg-white px-4 sm:px-6 md:px-8 lg:px-16 md:py-14 my-4 md:my-0'>
      <div className='flex flex-col p-4 md:p-8 lg:p-20 border rounded-xl gap-4 md:gap-6'>
        <div className='flex flex-row items-center gap-6'>
          <img src={bookIcon} alt='book' className='w-24 h-24' />
          <div className='flex flex-col gap-2'>
            <FormText
              title='Reservation for'
              type='subtitle'
              customClass="!font-black"
            />
            <FormText
              title='The Golf Venue Croydon Class'
              type='itemtitle'
              customClass="!font-black"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between w-full px-4 py-4 md:py-6 bg-gray2 border rounded-md gap-2 md:gap-0">
          <div className="flex flex-row items-center w-full md:w-3/5">
            <div className="flex flex-row items-center w-48 gap-3">
              <img src={additionIcon} alt="icon" width={28} />
              <FormText title="Golf Basic" type="itemtitle-sub-normal" />
            </div>
            <div className="flex flex-row items-center w-48 gap-3">
              <img src={timeIcon} alt="icon" width={28} />
              <FormText title="Monthly" type="itemtitle-sub-normal" />
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 items-center w-full md:w-2/5 gap-2 md:0'>
            <div className="flex flex-row items-center gap-2">
              <FormText
                title='$ 35'
                type="pretitle-bold"
              />
              <FormText
                title='per month'
                type="subtitle"
              />
            </div>
            <FormText
              title='10/10/2023 - 10/11/2023'
              type="itemtitle-small"
              customClass='!font-medium'
            />
          </div>
        </div>
        <div className='flex flex-col gap-3 md:gap-5'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-y-4 gap-x-8'>
            <FormTextInput
              placeholder="First Name"
              value={info.firstName}
              enableLabel={false}
              inputClassName="h-12 md:h-[60px]"
              onChange={(e) => handleChangeInfo('firstName', e.target.value)}
            />
            <FormTextInput
              placeholder="Last Name"
              value={info.lastName}
              enableLabel={false}
              inputClassName="h-12 md:h-[60px]"
              onChange={(e) => handleChangeInfo('lastName', e.target.value)}
            />
            <div className='flex flex-row items-center h-[50px] md:h-[60px] border border-gray3 mt-2 rounded-[5px]'>
              <div className='flex items-center px-6 border-r border-gray3 h-full rounded-[5px]'>
                <FormText
                  title='+355'
                  type='subtitle'
                  customClass="!font-bold"
                />
              </div>
              <FormTextInput
                placeholder="Phone Number"
                value={info.phoneNumber}
                enableLabel={false}
                inputClassName="!border-none !mt-0"
                onChange={(e) => handleChangeInfo('phoneNumber', e.target.value)}
              />
            </div>
            <FormTextInput
              placeholder="Email"
              value={info.email}
              enableLabel={false}
              inputClassName="h-12 md:h-[60px]"
              onChange={(e) => handleChangeInfo('email', e.target.value)}
            />
          </div>
          <FormTextarea
            placeholder="Note"
            cols={5}
            enableLabel={false}
            inputClassName="!px-5 !py-3 h-28"
            value={info.note}
            onChange={(e) => handleChangeInfo('note', e.target.value)}
          />
        </div>
        <div className='bg-blue3 px-6 py-2 rounded'>
          <FormText
            title="If you click Pay now, a form will be shown and you’ll procced to pay with Stripe"
          />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8'>
          <SubmitBtn
            title="Pay later"
            classes="!bg-gray-100 !py-4 md:!py-6 border border-primary1"
            textClasses="!text-primary1 text-xl md:text-2xl"
          />
          <SubmitBtn
            title="Pay now $30"
            dark={true}
            classes="!py-4 md:!py-6"
            textClasses="text-xl md:text-2xl font-medium"
          />
        </div>
      </div>
    </div>
  )
}

export default VenueGolfBook;
