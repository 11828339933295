import React, {useEffect, useState} from "react";
import {ChevronRightSharp} from "@mui/icons-material";
import {NotificationManager} from "react-notifications";
import {CircularProgress} from "@mui/material";

import "./index.css";
import lineImg from "../../../assets/images/pink-line.webp";
// import FormTextInput from "../../../components/FormTextInput";
import {
  create as createContactSale,
  getRegisterConfig,
} from "../../../redux/actions/contactsales";

const TextInput = ({label, name, value, required = false, onChange}) => (
  <div className='flex flex-col gap-2'>
    <label for={name} className='text-base text-black1'>
      {label}
      {required && <span className='text-pink1'>*</span>}
    </label>
    <input
      id={name}
      name={name}
      className='bg-transparent outline-none border border-gray3 rounded p-2 md:p-3'
      value={value || ""}
      onChange={onChange}
    />
  </div>
);

const Select = ({label, name, value, data, required = false, onChange}) => (
  <div className='flex flex-col gap-2'>
    <span className='text-base text-black1'>
      {label}
      {required && <span className='text-pink1'>*</span>}
    </span>
    <select
      name={name}
      className='bg-transparent outline-none border border-gray3 rounded p-2 md:p-3'
      value={value || ""}
      onChange={onChange}
    >
      <option value=''>Please select</option>
      {data.length > 0 &&
        data.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
    </select>
  </div>
);

const socialList = ["Facebook", "Twitter", "Instagram", "Tiktok", "Other"];

const industryList = [
  {value: "Food", label: "Food & Beverage"},
  {value: "Sport & Entertainment", label: "Entertainment Venues"},
  {value: "Accommodation", label: "Accommodation"},
  {value: "Retail", label: "Retail Management"},
  // { value: "Healthcare", label: "Healthcare" },
];

const categoryList = {
  Food: [
    {value: "restaurant", label: "Restaurant"},
    {value: "bistro", label: "Bistro"},
    {value: "cafe", label: "Cafe"},
    {value: "bar", label: "Bar"},
    {value: "pub_club", label: "Pub & Club"},
    {value: "food_truck", label: "Food Truck"},
    {value: "fine_dining", label: "Fine Dining"},
    {value: "bakery", label: "Bakery"},
    {value: "catering_service", label: "Catering Service"},
    {value: "fast_food", label: "Fast Food"},
    {value: "ice_cream_parlor", label: "Ice Cream Parlor"},
    {value: "food_stall", label: "Food Stall"},
    {value: "buffet", label: "Buffet"},
    {value: "pop_up_dining", label: "Pop-Up Dining"},
    {value: "juice_bar", label: "Juice Bar"},
    {value: "food_hall", label: "Food Hall"},
    {value: "supper_club", label: "Supper Club"},
    {value: "pizzeria", label: "Pizzeria"},
    {value: "coffee_shop", label: "Coffee Shop"},
    {value: "delis", label: "Delis"},
    {value: "food_court", label: "Food Court"},
  ],
  "Sport & Entertainment": [
    {value: "golf_venue", label: "Golf Course"},
    {value: "bowling", label: " Bowling Alley"},
    {value: "gym", label: "Gym & Fitness Center"},
    {value: "arcade_and_game_center", label: "Arcade & Game Center"},
    {value: "sports_arena", label: "Sports Arena"},
    {value: "concert_hall_and_theater", label: "Concert Hall & Theater"},
    {value: "amusement_and_theme_park", label: "Amusement & Theme Park"},
    {value: "ski_resort", label: "Ski Resort"},
    {value: "museum", label: "Museum"},
    {value: "cinema", label: "Cinema"},
  ],
  Accommodation: [
    {value: "hotel", label: "Hotel"},
    {value: "cozy_retreat", label: "Cozy Retreat"},
    {value: "hostel", label: "Hostel"},
    {value: "hotel_chain", label: "Hotel Chain"},
    {value: "vacation_rental", label: "Vacation Rental"},
    {value: "luxury_resort", label: " Luxury Resort"},
    {value: "family_resort", label: "Family Resorts"},
    {value: "service_apartment", label: "Service Apartment"},
    {value: "bed_and_breakfast", label: "Bed and Breakfast"},
    {value: "motel", label: "Motel"},
    {value: "capsule_hotel", label: "Capsule Hotel"},
    {value: "youth_hostel", label: "Youth Hostel"},
    {value: "campground", label: "Campground"},
    {value: "rv_park", label: "RV Park"},
  ],
  Retail: [
    {value: "sport_essentials", label: "Sporting Goods Store"},
    {value: "home_decor", label: "Home Décor Store"},
    {value: "fashion_threads", label: "Fashion Boutique"},
    {value: "retail_chain", label: "Retail Chain"},
    {value: "specialty_store", label: "Specialty Store"},
    {value: "online_shop", label: "Online Shop"},
    {value: "grocery_store", label: "Grocery Store"},
    {value: "electronics_store", label: "Electronics Store"},
    {value: "pharmacy", label: "Pharmacy"},
    {value: "auto_motorcycle_parts", label: "Auto Parts Store"},
    {value: "liquor_store", label: "Liquor Store"},
    {value: "pet_store", label: "Pet Store"},
  ],
  // Healthcare: [
  //   { value: "", title: "Choose category" },
  //   { value: "hospital", title: "Hospital" },
  //   { value: "dental_clinic", title: "Dental Clinic" },
  // ],
};

const Business = () => {
  const [step, setStep] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [basicInfo, setBasicInfo] = useState(null);
  const [detail, setDetail] = useState(null);

  const [engagement, setEngagement] = useState(null);
  const [activeSocials, setActiveSocials] = useState([]);

  useEffect(() => {
    setEngagement(v => ({...v, activeSocials}));
  }, [activeSocials]);

  useEffect(() => {
    loadRegisterConfig();
  }, []);

  const loadRegisterConfig = () => {
    getRegisterConfig()
      .then(data => {
        setAllCountries(data?.countries || []);
        // setCountries(data?.countries.map(country => ({
        //   value: country.name,
        //   title: country.name
        // })) || []);
        setCountries([
          {
            value: "USA",
            label: "USA",
          },
        ]);
        setAllStates(data?.states || []);
        // setCities(data?.cities || []);
      })
      .catch(error => {});
  };

  const handleChangeBasicInfo = e => {
    setBasicInfo(v => ({...v, [e.target.name]: e.target.value}));
  };

  const handleChangeDetail = e => {
    const {name, value} = e.target;
    if (name === "country") {
      const selectedCountry = allCountries.find(
        country => country.name === value
      );
      const statesForSelectedCountry =
        selectedCountry && selectedCountry.id
          ? [
              ...selectedCountry.states?.map(state => ({
                value: state.name,
                label: state.name,
              })),
            ]
          : [];

      setStates(statesForSelectedCountry);

      // Reset state and city selections in form data
      setDetail(data => ({
        ...data,
        state: "",
        city: "",
        country: value,
      }));
    } else if (name === "state") {
      const selectedState = allStates.find(state => state.name === value);
      const citiesForSelectedState =
        selectedState && selectedState.id
          ? [
              ...selectedState.cities?.map(city => ({
                value: city.name,
                label: city.name,
              })),
            ]
          : [];

      setCities(citiesForSelectedState);
      setCities(citiesForSelectedState);

      // Reset city selection in form data
      setDetail(data => ({
        ...data,
        city: "",
        state: value,
      }));
    } else setDetail(v => ({...v, [name]: value}));
  };

  const handleChangeEngagement = e => {
    setEngagement(v => ({...v, [e.target.name]: e.target.value}));
  };

  const handleChangeWebsite = value => {
    setEngagement(v => ({...v, website: value}));
  };

  const handleChangeActiveSocials = value => {
    if (activeSocials.includes(value))
      setActiveSocials(v => v.filter(item => item !== value));
    else setActiveSocials(v => [...v, value]);
  };

  const handleNext = () => {
    if (step === 1) {
      if (basicInfo) {
        const {firstName = "", lastName = "", email = ""} = basicInfo;
        if (firstName && lastName && email) {
          if (!/\S+@\S+\.\S+/.test(email)) {
            NotificationManager.warning(
              `Invalid email format`,
              "Warning",
              3000
            );
            return;
          }
          setStep(2);
        } else
          NotificationManager.warning(`Please fill the form`, "Warning", 3000);
      } else
        NotificationManager.warning(`Please fill the form`, "Warning", 3000);
    } else if (step === 2) {
      if (detail) {
        const {
          businessName = "",
          country = "",
          state = "",
          city = "",
          zipCode = "",
          industry = "",
          category = "",
          years_in_business = "",
        } = detail;
        if (
          businessName &&
          country &&
          state &&
          city &&
          zipCode &&
          industry &&
          category &&
          years_in_business
        )
          setStep(3);
        else
          NotificationManager.warning(`Please fill the form`, "Warning", 3000);
      } else
        NotificationManager.warning(`Please fill the form`, "Warning", 3000);
    } else if (step === 3) {
      if (engagement) {
        const {
          employeeCnt = "",
          revenue = "",
          website = "",
          businessChallenges = "",
          interestedIn = "",
          hearFrom = "",
          biggestChallenge = "",
          subscribe = "",
        } = engagement;

        setSubmitting(true);
        createContactSale({...basicInfo, ...detail, ...engagement})
          .then(data => {
            setSubmitting(false);
            setBasicInfo(null);
            setDetail(null);
            setEngagement(null);
            NotificationManager.success(
              data?.message ||
                "Thank you for submitting your request to join VenueBoost! We have received your information and will review it shortly. We will notify you regarding the status of your request through phone or email.",
              "Success",
              5000
            );
          })
          .catch(error => {
            setSubmitting(false);
            NotificationManager.error(
              error?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
              "Error",
              3000
            );
            throw new Error(
              "An error occurred while contacting VB sales from Get Started Form"
            );
          });
      } else
        NotificationManager.warning(`Please fill the form`, "Warning", 3000);
    }
  };

  const handleBack = () => {
    setStep(v => v - 1);
  };

  const leftContent = () => {
    if (step === 1)
      return (
        <>
          <span className='text-28 md:text-[42px] font-black text-white max-w-[320px] leading-9 md:leading-[64px]'>
            Transform Your Venue with VenueBoost
          </span>
          <div className='h-1.5 w-[94px]'>
            <img
              alt={"VenueBoost SAAS Platform"}
              src={lineImg}
              className='w-full h-full'
            />
          </div>
          <span className='text-sm md:text-xl text-white'>
            Experience Tailored Support and Growth.
            <br />
            We Value Each Client - No Generic Demos,
            <br />
            Just Personalized, Hands-On Guidance.
            <br />
          </span>
          <a
            aria-label='Get Started'
            href='/get-started'
            className='text-xl font-bold text-white'
          >
            Claim Your Tailored Free Plan!
          </a>
        </>
      );
    else
      return (
        <>
          <span className='text-28 md:text-[42px] font-black text-white max-w-[320px] leading-9 md:leading-[64px]'>
            Transform Your Venue with VenueBoost
          </span>
          <div className='h-1.5 w-[94px]'>
            <img
              alt={"VenueBoost Hospitality SAAS Platform"}
              src={lineImg}
              className='w-full h-full'
            />
          </div>
          <span className='text-sm md:text-xl text-white'>
            Experience Tailored Support and Growth.
            <br />
            We Value Each Client - No Generic Demos,
            <br />
            Just Personalized, Hands-On Guidance.
            <br />
          </span>
          <a
            aria-label='Get Started'
            href='/get-started'
            className='text-xl font-bold text-white'
          >
            Claim Your Tailored Free Plan!
          </a>
        </>
      );
  };

  const titleContent = () => {
    switch (step) {
      case 1:
        return "Basic Information";
      case 2:
        return "Business Details";
      case 3:
        return "Interest and Engagement";
      default:
        break;
    }
  };

  const rightContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <TextInput
              label='First Name'
              name='firstName'
              required={true}
              value={basicInfo?.firstName || ""}
              onChange={handleChangeBasicInfo}
            />
            <TextInput
              label='Last Name'
              name='lastName'
              required={true}
              value={basicInfo?.lastName || ""}
              onChange={handleChangeBasicInfo}
            />
            <TextInput
              label='Email'
              name='email'
              required={true}
              value={basicInfo?.email || ""}
              onChange={handleChangeBasicInfo}
            />
            <TextInput
              label='Phone'
              name='phone'
              value={basicInfo?.phone || ""}
              onChange={handleChangeBasicInfo}
            />
          </>
        );
      case 2:
        return (
          <>
            <TextInput
              label='Business Name'
              name='businessName'
              required={true}
              value={detail?.businessName || ""}
              onChange={handleChangeDetail}
            />
            <Select
              label='Country'
              name='country'
              required={true}
              value={detail?.country || ""}
              data={countries}
              onChange={handleChangeDetail}
            />
            <Select
              label='State'
              name='state'
              required={true}
              value={detail?.state || ""}
              data={states}
              onChange={handleChangeDetail}
            />
            <Select
              label='City'
              name='city'
              required={true}
              value={detail?.city || ""}
              data={cities}
              onChange={handleChangeDetail}
            />
            <TextInput
              label='Zip/Postal Code'
              name='zipCode'
              required={true}
              value={detail?.zipCode || ""}
              onChange={handleChangeDetail}
            />
            <Select
              label='Industry'
              name='industry'
              required={true}
              value={detail?.industry || ""}
              data={industryList}
              onChange={handleChangeDetail}
            />
            <Select
              label='Business Type'
              name='category'
              required={true}
              value={detail?.category || ""}
              data={detail?.industry ? categoryList[detail?.industry] : []}
              onChange={handleChangeDetail}
            />
            <TextInput
              label='Years in business'
              name='years_in_business'
              required={true}
              value={detail?.years_in_business || ""}
              onChange={handleChangeDetail}
            />
          </>
        );
      case 3:
        return (
          <>
            <TextInput
              label='Number of employees'
              name='employeeCnt'
              required={true}
              value={engagement?.employeeCnt || ""}
              onChange={handleChangeEngagement}
            />
            <TextInput
              label='Annual revenue'
              name='revenue'
              required={true}
              value={engagement?.revenue || ""}
              onChange={handleChangeEngagement}
            />
            <div className='flex flex-col gap-2'>
              <span className='text-base text-black1'>
                Do you have a website?
                <span className='text-pink1'>*</span>
              </span>
              <div className='flex flex-row py-2 gap-6'>
                <div
                  className='flex flex-row gap-2'
                  onClick={() => handleChangeWebsite("yes")}
                >
                  <input
                    name='website'
                    type='radio'
                    valule={"yes"}
                    checked={engagement?.website === "yes" || false}
                  />
                  <span>Yes</span>
                </div>
                <div
                  className='flex flex-row gap-2'
                  onClick={() => handleChangeWebsite("no")}
                >
                  <input
                    name='website'
                    type='radio'
                    valule={"no"}
                    checked={engagement?.website === "no" || false}
                  />
                  <span>No</span>
                </div>
              </div>
            </div>
            <Select
              label='Business Challenges'
              name='businessChallenges'
              required={true}
              value={engagement?.businessChallenges || ""}
              data={[
                {value: "challenges1", label: "Challenges1"},
                {value: "challenges2", label: "Challenges2"},
              ]}
              onChange={handleChangeEngagement}
            />
            <div className='md:col-span-2 flex flex-col gap-2'>
              <span className='text-base text-black1'>
                Active Social Media presence
                <span className='text-pink1'>*</span>
              </span>
              <div className='grid grid-cols-2 md:flex flex-row py-2 gap-6'>
                {socialList.map((item, index) => (
                  <div
                    key={index}
                    className='flex flex-row gap-2 cursor-pointer'
                    onClick={() => handleChangeActiveSocials(item)}
                  >
                    <input
                      name='website'
                      type='checkbox'
                      checked={activeSocials.includes(item)}
                    />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <Select
              label='Interested in'
              name='interestedIn'
              required={true}
              value={engagement?.interestedIn || ""}
              data={[
                {value: "challenges1", label: "Challenges1"},
                {value: "challenges2", label: "Challenges2"},
              ]}
              onChange={handleChangeEngagement}
            />
            <Select
              label='How did you hear about us?'
              name='hearFrom'
              value={engagement?.hearFrom || ""}
              data={[
                {value: "Social", label: "Social"},
                {value: "Friend", label: "Friend"},
              ]}
              onChange={handleChangeEngagement}
            />
            <div className='md:col-span-2'>
              <TextInput
                label="What's your biggest operational challenge"
                name='biggestChallenge'
                value={engagement?.biggestChallenge || ""}
                onChange={handleChangeEngagement}
              />
            </div>
            <div
              className='md:col-span-2 flex flex-row items-start gap-2'
              onClick={() =>
                setEngagement(v => ({
                  ...v,
                  subscribe: v.hasOwnProperty("subscribe")
                    ? !v.subscribe
                    : true,
                }))
              }
            >
              <input
                name='subscribe'
                type='checkbox'
                className='mt-1'
                checked={engagement?.subscribe || false}
              />
              <span>
                I would like to subscribe to emails from VenueBoost. You will
                receive marketing communications from VenueBoost about news,
                events, promotions and monthly newsletters. You can unsubscribe
                from VenueBoost emails at any time.
              </span>
            </div>
          </>
        );

      default:
        break;
    }
  };

  return (
    <div className={"business align-col-middle view-terms mt-0"}>
      <div className='w-[100vw] relative bg-[#F3F3F3]'>
        <div className='mx-auto max-w-screen-main px-7 sm:px-6 md:px-8 lg:px-16 pb-24'>
          <div className='grid grid-cols-1 md:grid-cols-5 business-content rounded-2xl'>
            <div className='md:col-span-2 flex flex-col left-side rounded-t-2xl md:rounded-l-2xl md:!rounded-r-0 px-8 md:px-12 py-8 md:py-12 gap-8'>
              {leftContent()}
            </div>
            <div className='md:col-span-3 flex flex-col rounded-b-2xl md:rounded-r-2xl'>
              <div className='flex flex-row items-center bg-[#e3e3e3] px-8 py-3 gap-4 rounded-tr-2xl overflow-auto whitespace-nowrap'>
                <span className='text-pink1 text-xs md:text-base fhont-medium'>
                  Basic Information
                </span>
                <ChevronRightSharp className='text-primary1' />
                <span className='text-pink1 text-xs md:text-base font-medium'>
                  Business Details
                </span>
                <ChevronRightSharp className='text-primary1' />
                <span className='text-pink1 text-xs md:text-base font-medium'>
                  Interest and Engagement
                </span>
              </div>
              <div className='flex flex-col gap-4 p-8 bg-white flex-1 rounded-b-2xl md:rounded-br-2xl'>
                <span className='text-pink1 text-2xl font-black overflow-auto'>
                  {titleContent()}
                </span>
                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-x-10 gap-y-4 md:gap-y-6'>
                  {rightContent()}
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-6 mt-4 md:mt-8 pb-5'>
                  <div>
                    {step > 1 && (
                      <div
                        className='bg-gray1 h-10 md:h-[60px] flex justify-center items-center rounded-lg cursor-pointer'
                        onClick={() => handleBack()}
                      >
                        <span className='text-white font-bold text-xl'>
                          Back
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className='bg-primary1 h-10 md:h-[60px] flex justify-center items-center rounded-lg cursor-pointer'
                    onClick={() => handleNext()}
                  >
                    <span className='text-pink3 font-bold text-xl flex items-center justify-center'>
                      {step !== 3 ? (
                        "Next"
                      ) : submitting ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Submit"
                      )}
                    </span>
                  </div>
                </div>
                {step === 3 && (
                  <div className='flex flex-row'>
                    <span>
                      By clicking Submit you agree to our&nbsp;
                      <a
                        aria-label='Privacy Policy'
                        href='href="https://venueboost.io/privacy-policy/'
                        className='underline'
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
