import React, { useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Sidebar from "../../../components/Sidebar";
import "./index.css";
import { useNavigate } from "react-router-dom";
import CustomerHeader from "../customer-header";
import GenericTable from "../../../components/Table/generic-table";
import { columns, dataSource } from "./columns";
import PromotionCalendar from "./promotion-calendar";
import LoggedInFooter from "../../../containers/footer/logged-in-footer";

const Promotions = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    total: dataSource.length,
  });

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const vbToken = localStorage.getItem("vbToken");

  useEffect(() => {
    if (!vbToken) {
      navigate("/login");
      window.location.reload();
    }
  }, [vbToken]);

  return (
    <div className="bg-white w-full grid lg:grid-cols-3 grid-cols-1  gap-4 ">
      <div className="col-span-2 w-full border rounded-[12px]">
        <div className="flex justify-between items-center w-full p-6">
          <CustomerHeader
            description="Keep track of promotions and much more..."
            numberOfItems={dataSource.length}
            items="promotions"
            title="Current Promotions"
          />
        </div>
        <GenericTable
          columns={columns}
          data={dataSource.slice(
            (pagination.current - 1) * 10,
            pagination.current * 10
          )}
          className="w-full md:block pr-4"
          pagination={{
            ...pagination,
            onChange: handlePageChange,
          }}
        />
      </div>

      <PromotionCalendar />

      <div className="col-span-2 w-full border rounded-[12px]">
        <div className="flex justify-between items-center w-full p-6">
          <CustomerHeader
            description="Keep track of promotions and much more..."
            numberOfItems={dataSource.length}
            items="promotions"
            title="Used Promotions"
          />
        </div>
        <GenericTable
          columns={columns}
          data={dataSource.slice(
            (pagination.current - 1) * 10,
            pagination.current * 10
          )}
          className="w-full md:block pr-4"
          pagination={{
            ...pagination,
            onChange: handlePageChange,
          }}
        />
      </div>
    </div>
  );
};

export default Promotions;
