import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";
import { useTranslation } from "react-i18next";

const ByBestHelp = () => {
  const { pageSEO } = useSeoData("https://bybest.shop/help");
  const { t } = useTranslation();

  const getTranslation = (label = "") => t(`bybest.${label}`);

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] uppercase ">
            {getTranslation("help.breadcrumb")}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-5">
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getTranslation("help.clientService")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <p className="text-[13px] leading-[20px] text-[#000] ">
                    {getTranslation("help.phoneLabel")}{" "}
                    <span className="underline">+355 69 356 1111</span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getTranslation("help.clientService")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <p className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                    {getTranslation("help.emailLabel")}{" "}
                    <span className="underline text-[#00f]">
                      customerservice@bybest.shop
                    </span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getTranslation("help.productInfo")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <p className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                    {getTranslation("help.emailLabel")}{" "}
                    <span className="underline text-[#00f]">
                      customerservice@bybest.shop
                    </span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getTranslation("help.salesOffice")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <p className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                    {getTranslation("help.emailLabel")}{" "}
                    <span className="underline text-[#00f]">
                      marketing@bybest.shop
                    </span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1">
                  <h3 className="mt-3 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    {getTranslation("help.socialMedia")}
                  </h3>
                </div>
                <div className="md:col-span-2">
                  <ul className="mt-[13px] list-disc pl-5">
                    <li>
                      <a
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.facebook.com/bybestshop-105726465473349/"
                        target={"_blank"}
                      >
                        {getTranslation("help.facebook")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.instagram.com/bybest.shop/"
                        target={"_blank"}
                      >
                        {getTranslation("help.instagram")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://twitter.com/ByBestShop"
                        target={"_blank"}
                      >
                        {getTranslation("help.twitter")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.pinterest.com/bybestshop/_saved/"
                        target={"_blank"}
                      >
                        {getTranslation("help.pinterest")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.tiktok.com/@bybestshop"
                        target={"_blank"}
                      >
                        {getTranslation("help.tiktok")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[12px] leading-[20px] text-[#000] underline"
                        href="https://www.linkedin.com/in/bybest-shop-6b819523a/"
                        target={"_blank"}
                      >
                        {getTranslation("help.linkedin")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-span-2"></div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestHelp;
