import Img1 from "../../../assets/images/accom/new-image.jpeg";
import Img2 from "../../../assets/images/accom/new-image-last.jpeg";

import Img3 from "../../../assets/images/accom/Image_3.webp";
import Img4 from "../../../assets/images/accom/new-image-4.jpeg";
import { useLocation } from "react-router-dom";

const AccommodationPhotos = () => {
  const { pathname } = useLocation();
  return (
    <div className=" flex flex-col pt-8 item item w-full mx-auto">
      <div className="flex w-full justify-center">
        <div className="flex lg:max-w-[1216px] w-full flex-col lg:flex-row">
          <div className="lg:w-[737px] w-full font-extrabold text-24 lg:text-[36px]">
            We give our guests space but also are available when needed
          </div>
          <div className="flex flex-col gap-6">
            <div className="text-[#7A7A7A] text-16 font-normal">
              {" "}
              We are available for any inquiries or questions that guests might
              have during their stay.
            </div>
            <a href={`${pathname}/about`}>
              <div className="text-18 font-semibold flex gap-2">
                More Info
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7504 6V15.75C18.7504 15.9489 18.6714 16.1397 18.5307 16.2803C18.3901 16.421 18.1993 16.5 18.0004 16.5C17.8015 16.5 17.6107 16.421 17.4701 16.2803C17.3294 16.1397 17.2504 15.9489 17.2504 15.75V7.81031L6.53104 18.5306C6.39031 18.6714 6.19944 18.7504 6.00042 18.7504C5.80139 18.7504 5.61052 18.6714 5.46979 18.5306C5.32906 18.3899 5.25 18.199 5.25 18C5.25 17.801 5.32906 17.6101 5.46979 17.4694L16.1901 6.75H8.25042C8.0515 6.75 7.86074 6.67098 7.72009 6.53033C7.57943 6.38968 7.50042 6.19891 7.50042 6C7.50042 5.80109 7.57943 5.61032 7.72009 5.46967C7.86074 5.32902 8.0515 5.25 8.25042 5.25H18.0004C18.1993 5.25 18.3901 5.32902 18.5307 5.46967C18.6714 5.61032 18.7504 5.80109 18.7504 6Z"
                    fill="#191818"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-3 mt-4 w-full">
        <div className="flex justify-between w-full flex-col lg:flex-row lg:max-w-[1216px] gap-4">
          <div className=" row-span-2">
            <img
              className=" lg:h-[693px] max-w-full w-full lg:w-[395px]"
              src={Img1}
              alt={``}
            />
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <img
                className="h-[293px] max-w-full w-full lg:w-[395px]"
                src={Img3}
                alt={``}
              />
            </div>
            <div>
              <img
                className="h-[388px] max-w-full w-full lg:w-[395px]"
                src={Img4}
                alt={``}
              />
            </div>
          </div>
          <div className=" row-span-2">
            <img
              className=" lg:h-[693px] max-w-full w-full lg:w-[395px]"
              src={Img2}
              alt={``}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccommodationPhotos;
