import React from "react";
import ProductItems from "../venue-retail/retail-shop/product-items";
import { unFavorite } from "../venue-retail/icons";
import "./index.css";
import { dummyData } from "../cart/checkout/data";

const Wishlist = () => {
  return (
    <>
      <div className=" bg-opacity-5 h-[50px] w-full bg-[#000000] absolute left-0 top-[82px] items-center hidden md:flex">
        <div className="large-width mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full flex flex-row items-center">
          <div>{`Wishlist`}</div>
          <span className="w-[20px] h-[20px] flex justify-center items-center">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.943573 0.345232C0.674034 0.560863 0.630333 0.954171 0.845964 1.22371L3.86695 4.99994L0.845964 8.77617C0.630333 9.04571 0.674034 9.43902 0.943573 9.65465C1.21311 9.87028 1.60642 9.82658 1.82205 9.55704L5.15538 5.39038C5.33799 5.16211 5.33799 4.83777 5.15538 4.60951L1.82205 0.442841C1.60642 0.173302 1.21311 0.129601 0.943573 0.345232Z"
                fill="#131118"
              />
            </svg>
          </span>
        </div>
      </div>

      <div className="wishlist flex flex-col gap-5 py-3 mb-24">
        <div className="flex justify-between items-center p-2">
          <div className="flex items-center gap-2">
            <span className="text-[#131118] text-20 font-semibold md:text-[32px]">
              Wishlist
            </span>
            <span className="number">(8)</span>
          </div>
          <button className="bg-[#131118] px-[30px] h-[46px] md:h-[56px] text-white rounded-[10px] text-12 md:text-16 font-bold">
            <span>Move all to Cart</span>
          </button>
        </div>
        <ProductItems
          className="md:grid-cols-4 grid grid-cols-2 gap-y-9 gap-4"
          topIcon={unFavorite}
          buttonLabel="Move to Cart"
          data={dummyData}
          itemClass="!w-full !h-full"
        />
      </div>

      {/* <div className=" h-[100px] w-full bg-[#1A1818] absolute left-0 bottom-0 items-center hidden md:flex">
        <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full">
          <div className="w-full flex items-center justify-between">
            <img src={LineImg} />

            <div className="text-white text-16 font-normal">Powered by VenueBoost</div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Wishlist;
