import React, { useState } from "react";
import { FiHeart } from "react-icons/fi";
import { FaChevronDown } from "react-icons/fa";
import BybestProductItem from "../../components/BybestProductItem";
import { products } from "./data";
import BybestCheckoutModal from "../../components/BybestCheckoutModal";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const CollapseMenu = ({ data, isOpened, onSelect }) => {
  return (
    <>
      <div
        className="w-full flex justify-center items-center px-4 py-2 bg-[#f5f5f5] border border-[#E6E6E6]"
        onClick={() => onSelect(data)}
      >
        <div className="flex-1">
          <a className=" no-underline text-[#000] text-[14px] font-bold w-full uppercase">
            {data.label}
          </a>
        </div>
        <FaChevronDown size={18} color={"#000"} />
      </div>
      {isOpened && <div className="w-full p-3 bg-[white]">{data.content}</div>}
    </>
  );
};

const ByBestProductDetails = () => {
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [product, setProduct] = useState({
    id: 5,
    title: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    category: "LOFT ROUND SUGAR CANISTER WITH BAMBOOO LID Q.B",
    img1: "https://admin.bybest.shop/storage/products/62447fc3e1f44_KLE20QB101-01 frusta.jpg",
    img2: "https://admin.bybest.shop/storage/products/62447fc3ecd4b_KLE20QB101-03.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  });
  const data = [
    {
      label: "DETAJET",
      content: (
        <div class="w-full ">
          <p className="text-[13px] text-[#000] font-semibold">
            Mjete të vogla dhe të dobishme
            <br />
          </p>
          <ul className="mt-2 px-4 list-disc">
            <li className="mt-1 text-[13px] text-[#000]">
              Ngjyra:Rozë ose Jeshile
            </li>
            <li className="mt-1 text-[13px] text-[#000]">
              Materiali:SS + Silikoni + PP + TPR
            </li>
            <li className="mt-1 text-[13px] text-[#000]">Masa:27x7x7</li>
          </ul>
        </div>
      ),
    },
    {
      label: "Më shumë info?",
      content: (
        <div class="w-full ">
          <p className="text-[13px] text-[#000]">
            <b>Mopita</b>
          </p>
          <p className="text-[13px] text-[#000]">
            Mopita është një kompani italiane e specializuar në prodhimin dhe
            tregtimin e eneve te gatimit. E gjithë kjo falë kreativitetit,
            fleksibilitetit dhe inovacionit.
          </p>
          <p className="text-[13px] text-[#000]">
            Prodhimi i produkteve të kuzhinës me dizajn origjinal, rezistent dhe
            cilësor, kërkon profesionalizëm të madh, jo vetëm të natyrës
            teknike, por edhe artistike. Për ne, shija e mirë estetike italiane,
            intuita kreative dhe funksionale, vëmendja ndaj detajeve dhe
            përzgjedhja e materialeve janë elemente thelbësore për të kënaqur
            klientët tanë. Jemi të vetëdijshëm se kemi përgjegjësinë e madhe për
            të hyrë në kuzhinat e botës, ndaj e bëjmë këtë me produkte të
            cilësisë së lartë që nuk çlirojnë ndotje dhe nuk shkaktojnë
            ndryshime në ushqim. Qëllimi është t'u japim adhuruesve të kuzhinës
            produkte me një profil të lartë estetik dhe efikasitet të
            padiskutueshëm, pa neglizhuar asnjëherë rëndësinë e qëndrueshmërisë
            mjedisore që ne ndjekim me vendosmëri. Qëllimi ynë është të krijojmë
            një markë ku bukuria është një vlerë e arritshme.&nbsp;Mopita ju
            ofrom mjetet, ju kujdeseni per shijen.
          </p>
          <p className="text-[13px] text-[#000]">Misioni</p>
          <p className="text-[13px] text-[#000]">
            1. Ofron një gamë të gjerë produktesh cilësore me çmime të
            arsyeshme.
            <br />
            <span>
              2. Projektimi, zhvillimi dhe prodhimi i enëve të kuzhinës, me një
              dizajn origjinal, duke përdorur materiale të cilësisë së lartë që
              nuk çlirojnë ndotje ose nuk shkaktojnë ndryshime.
              <br />
            </span>
            <span>
              3. Krijimi i produkteve që respektojnë mjedisin nëpërmjet
              teknologjive që optimizojnë konsumin e energjisë.
              <br />
            </span>
            <span>
              4. Promovoni njohuritë e Mopita në mbarë botën, të krijuara mbi
              tridhjetë vjet përvojë në këtë sektor.
              <br />
            </span>
            <span>
              5. Mbështetja e procesit të rivlerësimit të prodhimit artizanal
              dhe industrial italian, i cili ndër vite ka bërë që Made in Italy
              të shkëlqejë në konkurrencën tregtare.
            </span>
          </p>
        </div>
      ),
    },
    {
      label: "Anëtaret BB",
      content: (
        <div class="w-full ">
          <p className="text-[13px] text-[#000]">
            <b>KARTË KLIENTI “BY BEST GROUP”</b>
          </p>
          <p className="text-[13px] text-[#000]">
            <b>
              Çfarë është karta “By Best Group”
              <br />
            </b>
            <span>
              Është një program i cili ju mundëson të fitoni pikë me blerjet
              tuaja në 4 dyqanet tona dhe t’i shpenzoni ato në secilën prej tyre
              nëpërmjet një karte të vetme. Karta “By Best Group” përdoret vetëm
              në rrjetin e dyqaneve të kompanisë “By Best Duty Free”. Nëpërmjet
              kartës “By Best Group” ju përfitoni ulje, dhurata, promocione e
              aktivitete të dedikuara vetëm për ju në dyqanet: SWAROVSKI,
              SWATCH, BLUKIDS, VILLEROY &amp; BOCH.
            </span>
          </p>
          <p className="text-[13px] text-[#000]">
            <b>
              Si funksionon karta “By Best Group”
              <br />
            </b>
            <span>
              Karta “By Best Group” nuk është e transferueshme tek persona të
              tjerë të padeklaruar në formularin e antarësimit.
            </span>
          </p>
          <p className="text-[13px] text-[#000]">
            <b>
              Si ta përdorni kartën “By Best Group”
              <br />
            </b>
            <span>
              Tregoni kartën “By Best Group” çdo herë që bëni blerje në dyqanet
              partnere.&nbsp;
            </span>
            <span>
              Mblidhni pikë në secilën prej këtyre blerjeve duke filluar nga 100
              lekë. Për çdo 100 lekë të shpenzuara ju do të fitoni 1 pikë. Pikët
              e grumbulluara do të shfaqen në faturën e blerjes.&nbsp;
            </span>
            <span>
              Bëhuni pjesë e klubeve të përfitimeve duke filluar nga 150 pikë.
              Për çdo 150, 250, 500, e deri 1.000 pikë, ju keni të drejtë të
              kërkoni një kupon në kasë, me të cilin do të përfitoni, uljen apo
              dhuratat.
            </span>
          </p>
          <p className="text-[13px] text-[#000]">
            <span>
              Në rast se ju harroni të përdorni kartën në blerjet tuaja, pikët
              nuk mund t’ju kalojnë manualisht nga kasieri në kartë. Karta është
              e detyrueshme të përdoret si në blerje ashtu edhe në shkarkimin e
              pikëve. Pa kartën prezente nuk mund të kryhet asnje veprim në
              kasë.
            </span>
            <br />
          </p>
          <p className="text-[13px] text-[#000]">
            <span>
              Në rastet kur sistemi mund të jetë offline nuk humbisni asnjë
              pike. Ato do të kalohen në kartë në momentin që do të realizohet
              lidhja. E njëjta gjë ndodh edhe kur ju do të doni të shkarkoni
              pikët tuaja ndërkohë që sistemi është offline. Ju do të mund të
              përfitoni kuponin në një moment të dytë dhe ta përdorni atë në
              blerjet tuaja.
            </span>
            <br />
          </p>
          <p className="text-[13px] text-[#000]">
            <span>
              Karta “By Best Group” nuk ështe dhe nuk vlen si një kartë krediti.
              Me të nuk mund të kryhen veprime monetare, por vetëm të
              grumbullohen pikë e të përfitohen privilegjet e përcaktuara për
              pjestarët e skemës.
            </span>
            <br />
          </p>
        </div>
      ),
    },
  ];

  const onGoProductDetails = (p) => {
    setProduct(p);
    // navigate(`/shop/${app_key}/products/${p.id}/akeasf`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] uppercase ">
            BYBEST {`>`} PRODUKT {`>`} {product?.title}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-12 gap-0 md:gap-8">
            <div class="col-span-1 md:col-span-7 flex ">
              <div class="w-[80px]">
                <div class="w-full mt-3">
                  <img
                    src={product?.img1}
                    alt="KLEIN whisk with spatula in display box 4 mix colours Q.b."
                  />
                </div>
                <div class="w-full mt-3">
                  <img
                    src={product?.img2}
                    alt="KLEIN whisk with spatula in display box 4 mix colours Q.b."
                  />
                </div>
              </div>
              <div class="flex-1" id="full_data">
                <img
                  id="expandedImg"
                  alt="KLEIN whisk with spatula in display box 4 mix colours Q.b."
                  src={product?.img1}
                  className="w-full"
                />
              </div>
            </div>
            <div class="col-span-1 md:col-span-5">
              <div class="w-full flex flex-col justify-start items-start">
                <h1 class="text-[#000] text-[18px] font-bold uppercase">
                  {product?.title}
                </h1>
                <p class="mt-2 text-[#000] text-[13px] uppercase">
                  {product?.category}
                  <br />
                </p>
                <p class="text-[#000] text-[13px] uppercase">Marka: Mopita</p>
                <p class=" text-[#000] text-[13px] uppercase">
                  Artikulli Nr : {product?.no}
                </p>
                <div class="flex justify-center items-center relative mt-2">
                  <span class="text-[#f00] text-[18px] font-bold uppercase">
                    LEK {product?.sale_price}
                  </span>
                  <div className="flex justify-center items-center relative mx-2">
                    <span class="old-price text-[#000] text-[14px] font-bold uppercase">
                      LEK {product?.price}
                    </span>
                  </div>
                  <span class="text-[#000] text-[14px] font-bold uppercase">
                    -{product?.discount}
                  </span>
                </div>
                <div class="mt-4 text-[#000] text-[13px] font-semibold uppercase">
                  Perfito 12 Pikë By Best{" "}
                </div>
                <div className="my-4 w-full flex justify-center items-center">
                  <div className="w-[47px] h-[47px] rounded-[4px] flex justify-center items-center border border-[#000] mr-4">
                    <FiHeart size={24} color={"#000"} />
                  </div>
                  <button
                    type="button"
                    class="flex-1 h-[47px] rounded-[4px] border border-[#000] text-[#000] text-[14px] font-medium uppercase"
                  >
                    SHTO NET SHPORTE
                  </button>
                </div>
                <button
                  type="button"
                  class="w-full h-[47px] rounded-[4px] bg-[#000] text-[white] text-[14px] font-medium uppercase"
                  onClick={() => {
                    console.log("aaaaaaaaaaaaa");
                    setShowCheckoutModal(true);
                  }}
                >
                  Blej me nje klik
                </button>
                <div className="w-full mt-5">
                  {data.map((info) => (
                    <CollapseMenu
                      key={info.label}
                      data={info}
                      isOpened={info.label == activeMenu?.label}
                      onSelect={(item) => {
                        if (item.label == activeMenu?.label) {
                          setActiveMenu(null);
                        } else {
                          setActiveMenu(item);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-5 px-4">
            <h4 className="text-[#000] text-[14px] font-bold ">
              Produkte të Ngjashme
            </h4>
            <div className="mt-5 grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-8">
              {products.slice(0, 4).map((p, index) => (
                <BybestProductItem
                  key={index}
                  type={"similar"}
                  data={p}
                  onClick={() => onGoProductDetails(p)}
                />
              ))}
            </div>
          </div>
        </div>
        {showCheckoutModal && (
          <BybestCheckoutModal
            product={product}
            showModal={showCheckoutModal}
            onClose={() => setShowCheckoutModal(false)}
          />
        )}
      </div>
    </BBMainLayout>
  );
};

export default ByBestProductDetails;
