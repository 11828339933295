import React from "react";
import { Spin } from 'antd';

const RestaurantButton = ({ onClick, style, title = "", loading = false }) => {
  return (
    <div className="available-tickets-btn" style={style} onClick={loading ? () => { } : onClick}>
      <button>
        {
          loading ? <Spin />
            :
            <span className="text-white">{title}</span>
        }
      </button>
    </div>
  );
};

export default RestaurantButton;
