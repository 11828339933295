import OliviaIcon from "../../../assets/images/icons/olivia_icon.png";
import Avatar1 from "../../../assets/images/retail/Avatar1.png";
import Avatar2 from "../../../assets/images/retail/Avatar2.png";
import { getStatus } from "../../../components/columns/get-status";

export const orderColumns = [
  {
    title: "Brand",
    dataIndex: "name",
    key: "name",
    render: () => (
      <div className="flex-row justify-start flex items-center">
        <img
          alt=""
          src={OliviaIcon}
          className="w-[40px] h-[40px] rounded-[100px]"
        />
        <div className="justify-between ml-3">
          <p className="text-14 font-bold leading-5 text-[#240b3b]">
            Olivia Cooks
          </p>
          <p className="text-[#6B7280] font-normal text-14 leading-5">Order</p>
        </div>
      </div>
    ),
  },
  {
    title: "Order #",
    dataIndex: "order",
    key: "order",
    render: (time) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{time}</p>
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{date}</p>
    ),
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (total) => (
      <p className="text-14 text-[#240b3b] leading-5 font-bold">{total}</p>
    ),
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (notes) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{notes}</p>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: getStatus,
  },
];

const statuses = ["Delivered", "Cancelled", "Pending", "Confirmed"];

export const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i + 1,
    id: i + 1,
    icon: OliviaIcon,
    name: `Olivia ${i}`,
    order: "DF324F1SD",
    date: "15/10/2023 16:00 PM",
    total: `$ 100`,
    notes: "No note",
    status: statuses[i % statuses.length],
  });
}

export const orderDetailsColumn = [
  {
    title: "No.",
    dataIndex: "no",
    key: "no",
    render: (value) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
    ),
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    render: (image) => (
      <img src={image} alt="product" style={{ width: 40, height: 40 }} />
    ),
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    render: (value) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
    ),
  },
  {
    title: "Product name",
    dataIndex: "productName",
    key: "productName",
    render: (value) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    render: (value) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (price, record) => (
      <p className="text-14 text-[#240b3b] leading-5 font-bold flex items-center gap-1">
        <span>{price}</span>
        {record.discount ? (
          <p className="text-14 text-[#E14848] leading-5 font-bold">
            ({record.discount})
          </p>
        ) : (
          ""
        )}
      </p>
    ),
  },
  {
    title: "Total EUR",
    dataIndex: "totalEur",
    key: "totalEur",
    render: (total) => (
      <p className="text-14 text-[#240b3b] leading-5 font-bold">{total}</p>
    ),
  },
  {
    title: "Total Lek",
    dataIndex: "totalLek",
    key: "totalLek",
    render: (total) => (
      <p className="text-14 text-[#240b3b] leading-5 font-bold">{total}</p>
    ),
  },
];

export const orderDetailsData = [
  {
    key: "1",
    no: "1",
    image: Avatar1,
    sku: "7632452566435",
    productName: "PUNTOSTELLA MERIDIANA BLUE",
    quantity: 1,
    price: "3920.00 LEK",
    discount: "20%",
    totalEur: "39.07 EUR",
    totalLek: "3920.00 LEK",
  },
  {
    key: "2",
    no: "2",
    image: Avatar2,
    sku: "4234754784754",
    productName: "THAT'S SO PEACHY",
    quantity: 2,
    price: "4230.00 LEK",
    totalEur: "42.00 EUR",
    totalLek: "4230.00 LEK",
  },
];
