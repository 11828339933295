import React from "react";
import FormText from "../../../components/FormText";
import homeIcon from "../../../assets/svgs/home.svg";
import paymentIcon from "../../../assets/svgs/payment.svg";
import parkIcon from "../../../assets/svgs/park.svg";
import golfIcon from "../../../assets/svgs/sport-golf.svg";
import golfStyleIcon from "../../../assets/svgs/golf-style.svg";
import spaceIcon from "../../../assets/svgs/space.svg";
import holeIcon from "../../../assets/svgs/hole.svg";

const GolfInfo = ({ data, overview }) => {
  const resultString = data?.payment_options?.join(", ");
  const facilitiesString = data?.facilities?.join(", ");
  let full_address = null;
  if (overview?.address) {
    full_address = `${overview?.address?.address_line1 ?? "No Address"}, ${
      overview?.address?.city ?? "No City"
    }, ${overview?.address?.state ?? "No State"}, ${
      overview?.address?.country ?? ""
    }, ${overview?.address?.postcode ?? "No Postal Code"}`;
  }

  return (
    <div className="md:col-span-2 flex flex-col pt-8 pb-8">
      <FormText title="Additional Information" type="itemtitle-sub" />
      <div className="flex flex-col border rounded-xl mt-4 h-full">
        <div className="flex flex-col p-5 pb-3 border border-x-0 border-t-0 border-b">
          {full_address && (
            <div className="flex items-center pb-1">
              <FormText
                title={full_address}
                type="subtitle-based"
                customClass="!font-bold"
              />
            </div>
          )}
        </div>
        <div className="px-5 py-2">
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={homeIcon} alt="icon" width={24} />
              <FormText
                title="Neighborhood"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={data?.neighborhood}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={holeIcon} alt="icon" width={24} />
              <FormText
                title="Nr. of holes"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={data?.nr_holes}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          {data?.field_m2 && (
            <div className="flex flex-row items-center my-4">
              <div className="flex flex-row items-center w-1/2 md:w-[45%]">
                <img src={spaceIcon} alt="icon" width={24} />
                <FormText
                  title="Space"
                  customClass="ml-2 !font-bold"
                  type="subtitle-based"
                />
              </div>
              <div className="w-1/2 md:w-[55%]">
                <FormText
                  title={`${data?.field_m2} m2`}
                  type="subtitle-dark"
                  customClass="!font-medium"
                />
              </div>
            </div>
          )}
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={golfIcon} alt="icon" width={24} />
              <FormText
                title="Facilities"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={facilitiesString}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={golfStyleIcon} alt="icon" width={24} />
              <FormText
                title="Golf style"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={data?.golf_style}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>

          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={parkIcon} alt="icon" />
              <FormText
                title="Parking details"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={data?.parking_details}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={paymentIcon} alt="icon" />
              <FormText
                title="Payment options"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText
                title={resultString}
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GolfInfo;
