import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Products from "./products";

const Menus = () => {
  const [curTab, setCurTab] = useState("All Menu");
  const venueData = useSelector((state) => state.venue);
  const tabs = useMemo(() => {
    if (venueData?.menu) {
      return ["All Menu", ...Object.keys(venueData?.menu)];
    }
    return ["All Menu"];
  }, [venueData])

  const products = useMemo(() => {
    if (venueData?.menu) {
      if (curTab == "All Menu") {
        return Object.keys(venueData?.menu).map(key => venueData?.menu[key]).flat()
      }
      else {
        return venueData?.menu[curTab]
      }
    }
    return [];
  }, [curTab, venueData])

  return (
    <div className="mt-4">
      <div className="w-full flex ">
        <div className="menu-tabs w-full overflow-x-auto">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={` whitespace-nowrap tab ${curTab === tab ? "selected" : ""}`}
              onClick={() => setCurTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5 flex flex-col gap-4">
        <Products data={products}/>
      </div>
    </div>
  );
};

export default Menus;
