import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormSelect from "../../../components/FormSelect";
import FormText from "../../../components/FormText";
import FormTextInput from "../../../components/FormTextInput";
import { generateDateArray, generateTimeArray } from "../../../utils/common";
import { Modal } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import BrandProfileBtn from "../../../components/FormBtn/BrandProfileBtn";

const TimeItem = ({ time, onAction }) => (
  <div
    className="flex flex-col bg-primary1 text-center px-2 py-2 cursor-pointer"
    onClick={() => onAction()}
  >
    <span className="text-white font-bold rounded">{time}</span>
    <div className="flex flex-row items-center gap-1">
      <span className="text-gray3 text-xs whitespace-nowrap">
        2nd Row Table
      </span>
      <MdOutlineInfo className="text-gray3 text-lg" />
    </div>
  </div>
);

const SelectTimeItem = ({ time, onAction, TimeSuggestedBrandButtonData }) => (
  <div
      style={{
        backgroundColor: TimeSuggestedBrandButtonData?.bgColor
            ? TimeSuggestedBrandButtonData?.bgColor
            : "#240b3b",
        textWrap: "nowrap",
      }}
    className="flex flex-col text-center p-2 rounded-lg cursor-pointer"
    onClick={() => onAction()}
  >
    <span
        style={{
          color: TimeSuggestedBrandButtonData?.textColor
              ? TimeSuggestedBrandButtonData?.textColor
              : "#fff",
          textWrap: "nowrap",
        }}
        className="font-bold rounded">{time}</span>
  </div>
);

const RestaurantReservation = (props) => {
  const { onAction, TimeSuggestedBrandButtonData } = props;
  const venueData = useSelector((state) => state.venue);
  const [submitted, setSubmitted] = useState(false);
  const [people, setPeople] = useState(1);
  const [date, setDate] = useState("2023-7-20");
  const [time, setTime] = useState("7:00 AM");
  const [listDates, setListDates] = useState([]);
  const [listTimes, setListTimes] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [visibleOtherTime, setVisibleOtherTime] = useState(false);
  const [modalData] = useState([
    {
      date: "Friday, 3 Nov",
      times: ["19:00", "19:30", "20:00", "20:30", "21:00"],
    },
    {
      date: "Saturday, 4 Nov",
      times: ["19:00", "19:30", "20:00", "20:30", "21:00"],
    },
    {
      date: "Sunday, 5 Nov",
      times: ["19:00", "19:30", "20:00", "20:30", "21:00"],
    },
    {
      date: "Monday, 6 Nov",
      times: ["19:00", "19:30", "20:00", "20:30", "21:00"],
    },
  ]);

  const handleChange = (e) => {
    setPeople(e.target.value);
  };

  useEffect(() => {
    let dates = generateDateArray();
    setListDates(dates);
    let times = generateTimeArray();
    setListTimes(times);
  }, []);

  const handleFindTime = () => {
    setSubmitted(true);
    const timeList = [
      // '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM'
    ];
    setTimeList(timeList);
  };

  const handleSelectTime = (item) => {
    setVisibleOtherTime(false);
    setTime(item);
    handleSubmit();
  };

  const handleSubmit = () => {
    const data = {
      people: people,
      date,
      time,
    };
    onAction(data);
  };

  const footer = (
    <div className="flex flex-col gap-2">
      <div className="flex items-center">
        <span className="DgfZ8z-b7Vk- C7Tp-bANpE4- flex items-center">
          <span
            className="irNswZTgdxU- _0i3TIrwHBUc- text-lg mr-2"
            data-test="icSocialProof"
            data-testid="icSocialProof"
          >
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
            >
              <path
                d="M15.5 5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V8.414l-4.646 4.647a.5.5 0 0 1-.708 0L12 11.414 6.414 17H19v-2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V17a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h6.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H5v8.586l6.646-6.647a.5.5 0 0 1 .707 0L14 10.586 17.586 7H15.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Z"
                fill="#2D333F"
              />
            </svg>
          </span>
          Booked 1 time today
        </span>
      </div>
      <div className="flex items-center">
        <span className="DgfZ8z-b7Vk- C7Tp-bANpE4- flex items-center">
          <span
            className="irNswZTgdxU- _0i3TIrwHBUc- text-lg mr-2"
            data-test="icScarcity"
            data-testid="icScarcity"
          >
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
            >
              <path
                d="M17.5 11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V11h1.5Z"
                fill="#2D333F"
              />
              <path
                d="M8 12a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm2 0a5 5 0 1 0 10 0 5 5 0 0 0-10 0ZM4 7h2a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2ZM5 13H3a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2ZM3 16a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z"
                fill="#2D333F"
              />
            </svg>
          </span>
          You're in luck! We still have 4 timeslots left
        </span>
      </div>
    </div>
  );

  const selectTime = [
    {
      date: "Friday, 3 Nov",
      times: ["19:00", "19:30"],
    },
  ].map((item, key) => (
    <div key={key} className="flex flex-col gap-2">
      <FormText title="Select a time" type="itemtitle-sub" />
      <div className="grid grid-cols-2 justify-between gap-2">
        {item.times.map((sItem, sKey) => (
          <SelectTimeItem
            key={sKey}
            time={sItem}
            TimeSuggestedBrandButtonData={TimeSuggestedBrandButtonData}
            onAction={() => handleSelectTime(item)}
          />
        ))}
      </div>
    </div>
  ));

  return (
    <div className="md:col-span-2 flex flex-col md:pt-8">
      <FormText title="Make a reservation" type="itemtitle-sub" />
      <div className="flex flex-col border rounded-xl mt-4 gap-4 px-4 md:px-8 py-5 md:py-4">
        <div className="flex flex-col justify-center">
          <FormTextInput
            placeholder="How Many People"
            value={people}
            className="mt-4"
            type="number"
            onChange={(e) => {
              if (e.target.value) {
                handleChange(e);
              }
            }}
          />
          <div className="grid grid-cols-2 mt-5 gap-6">
            <FormSelect
              placeholder="Date"
              value={date}
              dataList={listDates}
              onChanage={(e) => setDate(e.target.value)}
            />
            <FormSelect
              value={time}
              placeholder="Time"
              dataList={listTimes}
              onChanage={(e) => setTime(e.target.value)}
            />
          </div>
          <BrandProfileBtn
            brandData={venueData?.brand_profile}
            element_name="FindATimeButton"
            classes="mt-5"
            // onClick={() => handleFindTime()}
            dark={true}
          />

          {submitted && (
            <div className="flex flex-col justify-between">
              {timeList.length > 0 ? (
                <>
                  <h3>Select a time</h3>
                  <div className="flex flex-wrap gap-5">
                    {timeList.map((item, index) => (
                      <span
                        key={index}
                        className="bg-primary1 text-white font-bold px-5 py-2 rounded"
                        onClick={() => handleSelectTime(item)}
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                </>
              ) : (
                <div
                  className="mt-4 cursor-pointer underline"
                  onClick={() => setVisibleOtherTime(true)}
                >
                  <FormText customClassName="py-4" title="Other dates" />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex">{footer}</div>
        {<div className="mt-4">{selectTime}</div>}
      </div>
      <Modal
        // title="Add a New Card"
        centered
        open={visibleOtherTime}
        onCancel={() => setVisibleOtherTime(false)}
        onOk={() => setVisibleOtherTime(false)}
        maskClosable={false}
        okText={"Select"}
        okButtonProps={{
          style: { backgroundColor: "#240b3b", color: "white", height: "40px" },
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: "transparent",
            color: "#240b3b",
            borderColor: "#CBD5E1",
            height: "40px",
          },
        }}
        bodyStyle={{ height: "500px" }}
        width={720}
      >
        <div
          className={
            "flex flex-col py-4 px-5 w-full h-max md:h-[500px] overflow-auto gap-5"
          }
        >
          <div className="flex flex-row items-center gap-2">
            <span className="font-bold text-xl">Available Time Table</span>
          </div>
          <div className="flex flex-col gap-4">
            {modalData.map((item, key) => (
              <div key={key} className="flex flex-col gap-2">
                <span className="text-lg font-semibold">{item.date}</span>
                <div className="flex flex-row justify-between gap-2">
                  {item.times.map((sItem, sKey) => (
                    <TimeItem
                      key={sKey}
                      time={sItem}
                      onAction={() => handleSelectTime(item)}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RestaurantReservation;
