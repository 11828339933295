import React from "react";
import { isEmpty } from "../../../utils/common";
import "./index.css";
import { TableCartMobile } from "./table-cart-mobile";
import { minus, plus, trash } from "../../venue-retail/icons";

export const TableCart = ({
  tableProducts = [],
  updateQuantityAtIndex,
  quantities = [],
  onRemoveProduct,
}) => {
  const columns = ["Products", "Price", "Quantity", "Subtotal"];

  return (
    <div className="table-cart-container">
      <div className="table-view">
        <table className="w-full">
          <tr className="theader w-full grid grid-cols-5 border-b-[1px]">
            {columns.map((item, index) => (
              <td
                key={index}
                className={`${index === 0 ? "col-span-2" : "col-span-1"} pb-2`}
              >
                {item}
              </td>
            ))}
          </tr>
          {tableProducts.map((item, index) => (
            <tr
              key={index}
              className="theader grid grid-cols-5 py-4 border-b-[1px] gap-5"
            >
              <td className="col-span-2">
                <div className="flex items-center gap-4">
                  <div className="flex rounded-lg cursor-pointer items-center gap-4 py-2 pr-2">
                    {isEmpty(item?.image_path) ? (
                      <img
                        src={item.image_path}
                        alt="food"
                        className="h-[80px] w-[680px]"
                      />
                    ) : (
                      <img
                        className="w-[106px] h-[106px] rounded-lg"
                        style={{ objectFit: "cover" }}
                        src={item?.image_path}
                        alt={item.title}
                      />
                    )}
                    <div className="flex flex-col justify-start">
                      <div className="table-title">{item?.title}</div>
                      <div className="table-description">
                        {item?.short_description.length <= 80
                          ? item?.short_description
                          : item?.short_description.slice(0, 80) + "..."}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="flex items-center gap-4">
                <span className="text-price">{item.price}</span>
              </td>
              <td className="flex items-center">
                <div className="flex justify-center items-center border gap-4 rectangle px-2">
                  <span
                    className="cursor-pointer"
                    onClick={() => updateQuantityAtIndex(item, index, false)}
                  >
                    {minus}
                  </span>
                  <span className="text-price">{quantities[index]}</span>
                  <span
                    className="cursor-pointer"
                    onClick={() => updateQuantityAtIndex(item, index)}
                  >
                    {plus}
                  </span>
                </div>
              </td>
              <td className="flex justify-between gap-10 pl-2 items-center w-fit">
                <div className="table-title">
                  {parseFloat(item?.price.replace("$", "") ?? 1) *
                    quantities[index]}
                </div>
                <span
                  className="cursor-pointer"
                  onClick={() => onRemoveProduct(item)}
                >
                  {trash}
                </span>
              </td>
            </tr>
          ))}
        </table>
      </div>

      <TableCartMobile
        onRemoveProduct={onRemoveProduct}
        quantities={quantities}
        updateQuantityAtIndex={updateQuantityAtIndex}
        tableProducts={tableProducts}
      />
    </div>
  );
};
