import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./index.css";

const CustomCalendar = ({ data = [] }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const dateStr = date.toISOString().split("T")[0];
      const booking = data.find((item) => item.date === dateStr);

      if (booking) {
        return "calhighlight";
      } else if (date.toDateString() === new Date().toDateString()) {
        return "today";
      }
    }
  };

  const onChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="calendar-container !w-full">
      <Calendar
        onChange={onChange}
        value={selectedDate}
        tileClassName={tileClassName}
      />
    </div>
  );
};

export default CustomCalendar;
