import starIcon from "../../../assets/svgs/star_yellow.svg";

const AccommodationQuality = () => {
  return (
    <div className="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-1 gap-8 w-full">
      <div className="md:col-span-2 lg:col-span-2 flex flex-col justify-center gap-8">
        <span className="text-[24px] md:text-[28px] lg:text-[32px] font-medium leading-[36px] md:leading-[40px] lg:leading-[48px] text-white">
          The apartment was really cozy for me and my wife and we had a wonderful time
        </span>
        <hr className="w-full bg-gray-500" />
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center gap-2.5">
            <img src={starIcon} width={32} height={32} alt="Star Icon" />
            <img src={starIcon} width={32} height={32} alt="Star Icon" />
            <img src={starIcon} width={32} height={32} alt="Star Icon" />
            <img src={starIcon} width={32} height={32} alt="Star Icon" />
            <img src={starIcon} width={32} height={32} alt="Star Icon" />
            <span className="text-xl md:text-2xl font-medium leading-7 md:leading-9">
              5.0
            </span>
          </div>
          <span className="font-medium leading-6 md:leading-7 text-gray-400">
            Emmanuel, United Kingdom
          </span>
        </div>
      </div>
      <div className="md:col-span-2 lg:col-span-3 flex flex-col md:flex-row justify-center items-center gap-8 md:gap-4 lg:gap-0">
        <div className="flex justify-center items-center border border-gray-500 rounded-full min-w-[150px] md:min-w-[150px] lg:min-w-[300px] w-[150px] md:w-[150px] lg:w-[300px] h-[150px] md:h-[150px] lg:h-[300px]">
          <div className="max-w-[90px] md:max-w-[90px] lg:max-w-[126px] flex flex-col">
            <span className="text-[36px] text-center md:text-[36px] lg:text-[56px] font-medium text-[#FAFAFA]">
              10
            </span>
            <span className="text-sm md:text-sm lg:text-lg font-medium text-gray-400 text-center">
              Comfortable rooms
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center border border-gray-500 rounded-full min-w-[150px] md:min-w-[150px] lg:min-w-[300px] w-[150px] md:w-[150px] lg:w-[300px] h-[150px] md:h-[150px] lg:h-[300px] mt-8 md:mt-0 lg:mt-0 lg:-ml-14">
          <div className="max-w-[90px] md:max-w-[90px] lg:max-w-[126px] flex flex-col">
            <span className="text-[36px] text-center md:text-[36px] lg:text-[56px] font-medium text-[#FAFAFA]">
              10
            </span>
            <span className="text-sm md:text-sm lg:text-lg font-medium text-gray-400 text-center">
              Value for money
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center border border-gray-500 rounded-full min-w-[150px] md:min-w-[150px] lg:min-w-[300px] w-[150px] md:w-[150px] lg:w-[300px] h-[150px] md:h-[150px] lg:h-[300px] mt-8 md:mt-0 lg:mt-0 lg:-ml-14">
          <div className="max-w-[90px] md:max-w-[90px] lg:max-w-[126px] flex flex-col">
            <span className="text-[36px] text-center md:text-[36px] lg:text-[56px] font-medium text-[#FAFAFA]">
              10
            </span>
            <span className="text-sm md:text-sm lg:text-lg font-medium text-gray-400 text-center">
              Facilities
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccommodationQuality;
