// AuthRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import LoginSecurity from "../../views/customer/login-security/index.js";
import PersonalInformation from "../../views/customer/personal-information/index.jsx";
import Promotions from "../../views/customer/promotions/index.js";
import Wallet from "../../views/customer/wallet/index.jsx";
import CustomerOrders from "../../views/customer/orders/index.jsx";
import CustomerWishlist from "../../views/customer/wishlist/index.jsx";
import OrderDetails from "../../views/customer/orders/order-details/index.jsx";

const bybestRoutes = [
  <Route
    exact
    path={":app_key/customer/personal-information"}
    element={<PersonalInformation />}
  />,
  <Route
    exact
    path=":app_key/customer/login-security"
    element={<LoginSecurity />}
  />,
  <Route
    exact
    path={":app_key/customer/orders"}
    element={<CustomerOrders />}
  />,
  <Route
    exact
    path={":app_key/customer/orders/:orderId"}
    element={<OrderDetails />}
  />,
  <Route
    exact
    path={":app_key/customer/wishlist"}
    element={<CustomerWishlist />}
  />,
  <Route
    exact
    path={":app_key/customer/promotions"}
    element={<Promotions />}
  />,
  <Route exact path={":app_key/customer/wallet"} element={<Wallet />} />,
];

export default bybestRoutes;
