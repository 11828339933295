import React, { useMemo } from 'react';
import { isEmpty } from '../../utils/common';

const BrandProfileBtn = ({ dark, classes, textClasses, brandData, element_name, onClick }) => {
  const title = useMemo(() => {
    const btnData = brandData?.find(b => b.element_name == element_name);
    return isEmpty(btnData?.venue_brand_profile_customizations?.find(c => c.customization_key == 'text')?.customization_value) ?
      btnData?.default_name : btnData?.venue_brand_profile_customizations?.find(c => c.customization_key == 'text')?.customization_value;
  }, [brandData, element_name])

  const bgColor = useMemo(() => {
    const btnData = brandData?.find(b => b.element_name == element_name);
    return btnData?.venue_brand_profile_customizations?.find(c => c.customization_key == 'color')?.customization_value;
  }, [brandData, element_name])

  const txtColor = useMemo(() => {
    const btnData = brandData?.find(b => b.element_name == element_name);
    return btnData?.venue_brand_profile_customizations?.find(c => c.customization_key == 'text_color')?.customization_value;
  }, [brandData, element_name])

  return (
    <div
      className={`${dark ? 'bg-primary1' : 'bg-primary2'} w-full text-center py-3 rounded-lg cursor-pointer ${classes}`}
      style={bgColor ? { backgroundColor: bgColor } : {}}
      onClick={onClick}
    >
      <span className={`text-white font-bold ${textClasses}`} style={txtColor ? { color: txtColor } : {}}>{title}</span>
    </div>
  )
}

export default BrandProfileBtn;
