import React from "react";
import { arrowDown, arrowUp } from "../icons";

export const FilterTitles = ({ isExpanded, setIsExpanded, title }) => {
  return (
    <div
      className="flex justify-between items-center"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <span className="title text-[1px] md:text-[16px] w-fit">{title}</span>
      <span className="cursor-pointer">{isExpanded ? arrowUp : arrowDown}</span>
    </div>
  );
};
