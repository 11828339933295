import { ServiceBtn } from "../../../components/FormBtn";
import FormText from "../../../components/FormText";

import { useEffect, useState } from "react";
import { Button } from "antd";
import { MdOutlineAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/actions/cart";
import CartCheckoutButton from "../../../components/CartCheckoutButton";
import ImgPlaceholder from "../../../assets/images/img_placeholder.png";
import { isEmpty } from "../../../utils/common";
import { SearchBox } from "../../../components/SearchBox";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";

const RetailMenu = ({ menuItems, venueKey, venueName, currency, venueLogo, outOfStockData, cartPlusButtonData }) => {
  const [menu, setMenu] = useState("All Products");
  const [menuList, setMenuList] = useState([]);
  const [menuProducts, setMenuProducts] = useState([]);
  const [search, setSearch] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAction = (value) => {
    let products = [];
    setMenu(value);
    if (value === "All Products") {
      for (const prop in menuItems) {
        if (Array.isArray(menuItems[prop])) {
          menuItems[prop].forEach((element) => {
            products.push(element);
          });
        }
      }
      setMenuProducts(products);
    } else {
      setMenuProducts(menuItems[value]);
    }
  };

  const returnSymbol = (value) => {
    if (value === "ALL") {
      return "L";
    } else if (value === "USD") {
      return "$";
    } else if (value === "EUR") {
      return "€";
    } else {
      return "";
    }
  };

  const cartProducts = useSelector((state) => state.cart.products); // Get products from the cart

  const getCartQuantity = (productId) => {
    // Find the product in the cart by its ID and return its quantity
    const cartProduct = cartProducts?.find(
      (product) => product.id === productId
    );
    return cartProduct ? cartProduct.quantity : 0;
  };

  const onAddToCart = (item) => {
    const cartQuantity = getCartQuantity(item.id);
    if (cartQuantity + 1 <= item.stock_quantity) {
      // If adding 1 won't exceed the stock quantity, proceed to add to cart
      dispatch(addToCart(item, venueKey, venueName, currency, venueLogo));
      NotificationManager.success("Product added to cart", "Success", 3000);
    } else {
      // Show a warning alert if the cart quantity would exceed the stock
      NotificationManager.warning(
        "Cannot add more. Stock limit reached",
        "Warning",
        3000
      );
    }
  };

  const onGoToProductDetails = (item) => {
    navigate(`/retail/${venueKey}/product-details/${item.id}`);
  };

  useEffect(() => {
    const keyArray = Object.keys(menuItems);
    if (keyArray.length > 0) {
      keyArray.unshift("All Products");
      setMenuList(keyArray);
      handleAction(menu);
    }
  }, [menuItems]);

  return (
    <div className="md:col-span-3 flex flex-col pt-12 md:pb-8">
      <div className="flex flex-col md:mt-4 ">
        <div
          className="flex flex-row border-b"
          style={{
            overflowX: "scroll",
            minWidth: "100%",
            borderBottom: "3px solid #d9cde4",
          }}
        >
          {menuList.map((item, index) => (
            <ServiceBtn
              label={item}
              active={menu === item}
              classes={`mr-3 ${index == 0 ? "hidden md:block" : ""}`}
              onAction={() => handleAction(item)}
              alt="food"
              className="h-[150px] h-[75px] cursor-pointer"
            />
          ))}
        </div>
        <div className="flex flex-col py-4 my-0 mt-2 flex_wrap">
          <>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-4 my-0 mt-2 mb-10 flex_wrap">
              {menuProducts.map((item, index) => (
                  <div key={index} className="flex flex-col border rounded-xl">
                    <img
                        onClick={() => onGoToProductDetails(item)}
                        src={
                          isEmpty(item?.image_path)
                              ? ImgPlaceholder
                              : item?.image_path
                        }
                        alt="VenueBoost - Whitelabel Retail"
                        className="cursor-pointer"
                        // style={{ height: 450 }}
                    />

                    <div className="flex border-t flex-col flex-grow">
                      <div className="flex flex-col py-2 px-4">
                        <FormText title={item?.title} type="itemtitle-whitelabel" />
                        {item?.short_description !== null && (
                            <FormText
                                title={item?.short_description}
                                type="custom"
                                customClass="mt-2 mb-4 color-gray"
                                customStyle={{ fontSize: "13px" }}
                            />
                        )}
                      </div>
                      {item?.stock_quantity === 0 && (
                          <span
                              style={outOfStockData?.text ?
                                  { backgroundColor: outOfStockData?.bgColor, color: outOfStockData?.textColor } :
                                  { backgroundColor: "#ef4444", color: "#fff" }
                              }
                              className={
                                "bg-red-500 mt-2 px-2 py-0.5 w-max text-sm font-bold "
                              }
                          >
                       {outOfStockData?.text ? outOfStockData?.text : "Out of Stock"}
                    </span>
                      )}
                      <div className="flex flex-row justify-between align-center py-2 px-4 mt-auto">
                        <FormText
                            title={returnSymbol(currency) + ' ' + item?.price}
                            type="itemtitle-whitelabel"
                            customClass="mt-0"
                        />
                        <Button
                            disabled={item?.stock_quantity === 0}
                            shape="default"
                            type="primary"
                            icon={<MdOutlineAdd size={20} color="#fff" />}
                            className="flex flex-col justify-center items-center"
                            style={{ backgroundColor: cartPlusButtonData?.bgColor ? cartPlusButtonData?.bgColor : "#240b3b", minWidth: 35 }}
                            onClick={() => {
                              onAddToCart(item);
                            }}
                        />
                      </div>
                    </div>


                  </div>

              ))}
            </div>
            {menuItems.length == 0 ? (
              <div
                className={"align-col-middle view-terms"}
                style={{ height: "55vh" }}
              >
                <h1>No products</h1>
              </div>
            ) : null}
          </>
        </div>
        <div className="md:mx-4 mb-4">
          <CartCheckoutButton venueKey={venueKey} />
        </div>
        {/*TODO: fix this*/}
        {/*<div*/}
        {/*  className="text-center py-2 cursor-pointer md:border-none border rounded-lg mt-3 md:mt-0"*/}
        {/*  onClick={() => handleViewMore()}*/}
        {/*>*/}
        {/*  <FormText title="View more" type="subtitle-based" />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default RetailMenu;
