import React from "react";

const RestaurantHeader = ({icon}) => {
  return (
    <div className="flex justify-center w-full bg-white py-3 shadow-4-strong">
      <img
        className="w-[74px] h-8 object-contain object-center"
        src={icon}
        alt="Restaurant"
      />
    </div>
  );
};

export default RestaurantHeader;
