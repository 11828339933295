import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import BybestCartStepper from "../../components/BybestCartStepper";
import { CloseOutlined } from "@mui/icons-material";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestCartProducts = () => {
  const { app_key } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    id: 5,
    title: "Byzylyk Idyllia",
    category: "Guaska, rozë, e veshur me rodium",
    img1: "https://admin.bybest.shop/storage/products/62447fc3e1f44_KLE20QB101-01 frusta.jpg",
    img2: "https://admin.bybest.shop/storage/products/62447fc3ecd4b_KLE20QB101-03.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  });

  const onSubmit = () => {
    navigate(`/shop/${app_key}/cart/user-info`);
  };

  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] uppercase ">
            BYBEST {`>`} SHPORTA
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="flex justify-start items-center mb-5">
            <FaChevronLeft color="#000" size={20} />
            <span className=" ml-1 text-[16px] text-[#000] font-semibold">
              Vazhdo blerjen
            </span>
          </div>
          <BybestCartStepper curStep={0} />
          <div className="mt-5 w-full px-4 grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-6">
            <div class="col-span-1 md:col-span-2 ">
              <div className="w-full flex">
                <div class="p-2 rounded flex justify-center items-center border border-[#D2D2D2]">
                  <img
                    src={product?.img1}
                    className="w-[200px] h-[200px] object-contain"
                  />
                </div>
                <div className="ml-2 flex flex-1 flex-col justify-center items-center">
                  <div className="w-full flex flex-1 justify-between items-center">
                    <div>
                      <h3 className=" text-[16px] text-[#000] leading-[24px] font-bold uppercase">
                        {product?.title}
                      </h3>
                      <p className=" text-[13px] text-[#000] leading-[19px] font-semibold uppercase">
                        {product?.category}
                      </p>
                    </div>
                    <button className=" rounded border border-[#000] p-1 w-[32px] h-[32px]">
                      <CloseOutlined />
                    </button>
                  </div>
                  <div className="w-full flex flex-1 justify-between items-center">
                    <div>
                      <p className=" text-[13px] text-[#000] leading-[19px] font-semibold uppercase">
                        CMIMI: <b>LEK{product?.sale_price}</b>
                      </p>
                      <p className=" text-[13px] text-[#000] leading-[19px] font-semibold uppercase">
                        SASIA:
                      </p>
                    </div>
                    <p className=" text-[13px] text-[#000] leading-[19px] font-semibold uppercase">
                      TOTAL: <b>LEK{product?.sale_price}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 px-4 py-2 bg-[#D2D2D2] rounded text-[#313131] text-[12px] leading-[18px]">
                Çmimet nuk perfshijnë detyrimet e importit, të cilat duhet të
                paguhen në momentin e dorëzimit.
              </div>
            </div>
            <div class="col-span-1">
              <div class="w-full rounded bg-[#F2F2F2] p-4 flex flex-col justify-start items-start">
                <p className="text-[14px] text-[#000]">
                  Keni nje kod kuponi? Aplikoni kodin e kuponit ose kodi e
                  anetaresimit ByBest per te perfituar uljen tuaj!
                </p>
                <div className="mt-3 w-full flex justify-center items-center">
                  <input
                    placeholder="Kodi i Kuponit"
                    className="h-[45px] flex-1 px-2 rounded border-[2px] border-[#767676]"
                  />
                  <button className=" ml-3 rounded bg-[#000] h-[45px] px-5 font-bold text-[14px] text-white">
                    APLIKO
                  </button>
                </div>
                <div className="mt-3 w-full flex justify-center items-center">
                  <input
                    placeholder="Kodi i anetarit BB"
                    className="h-[45px] flex-1 px-2 rounded border-[2px] border-[#767676]"
                  />
                  <button className=" ml-3 rounded bg-[#000] h-[45px] px-5 font-bold text-[14px] text-white">
                    APLIKO
                  </button>
                </div>
                <div className="mt-4 w-full flex justify-between items-start">
                  <h2 className="text-[16px] text-[#000] font-bold leading-[24px]">
                    TOTAL
                  </h2>
                  <div className="flex flex-col justify-end items-end">
                    <h2 className="text-[16px] text-[#000] font-bold leading-[24px]">
                      LEK {product?.sale_price}
                    </h2>
                    <p className="mt-2 text-[11px] text-[#000] leading-[14px] uppercase">
                      Perfito 160 Pikë By Best
                    </p>
                  </div>
                </div>
              </div>
              <button
                className=" mt-4 rounded bg-[#000] w-full h-[45px]  font-bold text-[14px] text-white"
                onClick={onSubmit}
              >
                BLEJ
              </button>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCartProducts;
