import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestCookies = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
            BYBEST {`>`} FAQE {`>`} NA KONTATONI
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <h3 className="text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                COOKIE POLICY
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                We are keenly aware of the trust you place in us, and our
                responsibility to protect your privacy. As part of this
                responsibility, we let you know what information we collect
                using cookies when you use our websites, why we collect it and
                how we use it to improve your experience.{" "}
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                ABOUT COOKIES{" "}
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                Cookies are small text files that websites save locally to your
                computer, which allow sites to store and use information during
                your visit. They can improve your experience when using a
                website by:{" "}
              </p>
              <ul className=" list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Remembering preferences, so you don’t have to keep re-entering
                  your choices when you visit again
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  Measuring how you use a site, so that changes can be made to
                  ensure it meets your needs{" "}
                </li>
                <li className="text-[12px] leading-[20px] text-[#000] ">
                  None of our cookies used for our sites can be used to identify
                  you personally. They are just used to help make the site
                  better for you. To learn more about cookies and how to manage
                  them, visit About Cookies or All About Cookies. Read on to
                  find out more about how we use cookies.{" "}
                </li>
              </ul>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#3c4043] uppercase">
                WHAT KIND OF COOKIES DO WE USE?{" "}
              </h3>
              <p className="text-[13px] leading-[25px] text-[#3c4043]  ">
                Generally, the cookies that are used on the site can be broken
                down into the following categories:
              </p>
              <ul className="mt-2 list-disc pl-5">
                <li className="text-[12px] leading-[20px] text-[#3c4043] ">
                  Session Cookies
                  <br />
                  These cookies are used 'in-session' each time you visit and
                  then expire when you leave a site or shortly thereafter: they
                  are not stored on your device permanently, do not contain any
                  personal data and help by minimizing the need to transfer
                  personal data across the internet. These cookies can be
                  deleted or you can refuse to allow their use, but this will
                  hamper the performance and your experience in using the site.
                  These cookies also take time stamps that record when you
                  access a site and when you leave a site.
                </li>
                <li className="text-[12px] leading-[20px] text-[#3c4043] ">
                  Tracking Cookies
                  <br />
                  These cookies enable us to recognize repeat visitors to our
                  sites. By matching an anonymous, randomly generated
                  identifier, a tracking cookie keeps track of where a user of
                  our sites came from, what search engine they may have used,
                  what link they clicked on, what keyword they used and where
                  they were in the world when they accessed a site. By
                  monitoring this data, we can make improvements to our site.
                </li>
                <li className="text-[12px] leading-[20px] text-[#3c4043] ">
                  Persistent Cookies
                  <br />
                  This type of cookie is saved on your device for a fixed period
                  (sometimes for a few hours, sometimes for a year) and is not
                  deleted when the browser is closed. Persistent cookies are
                  used where we need to remember who you are for more than one
                  browsing session. For example, this type of cookie can be used
                  to store your preferences, so that they are remembered for the
                  next visit to a site.
                </li>
                <li className="text-[12px] leading-[20px] text-[#3c4043] ">
                  Performance or Analytical Cookies
                  <br />
                  Performance cookies are used to analyze how the site is used
                  and to monitor its performance, which allows us to improve
                  your experience in using the site. These cookies help us to
                  tailor the content of the site to reflect what the site users
                  find most interesting and to identify when technical issues
                  with the site arise. We may also use this data to compile
                  reports to help us to analyze how site is used, what the most
                  common issues are and how we can improve the site.
                </li>
              </ul>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                HOW WE USE COOKIES
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                The dotknowledge.uk site uses cookies in several places, and
                we’ve listed them below with more details about why we use them
                and how long they will last. You can set your browser to refuse
                all cookies or to indicate when a cookie is being sent, however
                some website features or services may not function properly
                without cookies.{" "}
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                MEASURING OUR WEBSITE USAGE - GOOGLE ANALYTICS
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                We only use first-party Google Analytics cookies to track
                anonymous usage statistics and do not collect any personal
                information that can be used to identify you. This helps us
                analyse data about webpage usage and improve our website in
                order to tailor it to customer needs.
                <br />
                Google Analytics stores information about what pages you visit,
                how long you are on the site, how you got here and what you
                click on. We do not allow Google to use or share our analytics
                data.
                <br />
                Google Analytics sets the following first-party cookies:
                <br />
                <table
                  border
                  className="mt-4 table border border-[#dee2e6] w-full"
                >
                  <tbody>
                    <tr>
                      <td className="p-1 border border-[#dee2e6]">
                        Cookie Name
                      </td>
                      <td className="p-1 border border-[#dee2e6]">
                        Expiration Time
                      </td>
                      <td className="p-1 border border-[#dee2e6]">
                        Description&nbsp;
                      </td>
                      <td className="p-1 border border-[#dee2e6]">
                        Purpose&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 border border-[#dee2e6]">
                        XSRF-TOKEN&nbsp;
                      </td>
                      <td className="p-1 border border-[#dee2e6]">2 hours</td>
                      <td className="p-1 border border-[#dee2e6]">
                        This cookie is set by ByBest and is used for security
                        purposes
                      </td>
                      <td className="p-1 border border-[#dee2e6]">Necessary</td>
                    </tr>
                    <tr>
                      <td className="p-1 border border-[#dee2e6]">
                        cookie_notice_accepted&nbsp;
                      </td>
                      <td className="p-1 border border-[#dee2e6]">1 year</td>
                      <td className="p-1 border border-[#dee2e6]">
                        Used to store acceptance of cookies
                      </td>
                      <td className="p-1 border border-[#dee2e6]">Necessary</td>
                    </tr>
                    <tr>
                      <td className="p-1 border border-[#dee2e6]">
                        by_best_eccommerce_session
                      </td>
                      <td className="p-1 border border-[#dee2e6]">1 week</td>
                      <td className="p-1 border border-[#dee2e6]">
                        Needs to identify logged users
                      </td>
                      <td className="p-1 border border-[#dee2e6]">Necessary</td>
                    </tr>
                  </tbody>
                </table>
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                CURRENT CONCERNS WITH COOKIES
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                There are many legitimate uses for cookies to enhance
                convenience and efficiency. However, some uses of cookies can
                lead to privacy concerns. These concerns typically emerge when a
                cookie holds personal information identifying you (e.g. a
                person’s name, e-mail address, billing information, address or
                similar), or is used across multiple sites to track users and
                internet usage.
                <br />
                Dotknowledge. does not use any of its first-party cookies to
                hold any personal information or track users across multiple
                sites.
                <br />
                Other concerns some users have are around privacy, and in
                particular those third party cookies which are generated by
                advertisers which track browsing activity across different
                sites. For all cookies, there are steps that you can take to
                manage your user preferences on how these are set.
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                HOW DO I MANAGE COOKIES OR TURN THEM OFF?
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                You can manage the cookies stored on your computer, or turn them
                off completely, through your browser settings. Be aware that
                this may affect your browsing experience or even prevent you
                from using certain services such as those with a secure login,
                which require cookies to be set in order to work.
                <br />
                All modern browsers will let you change your cookie settings to
                give you broad control over whether cookies can be set or not.
                For more information, visit the help section for the browser you
                use.
                <br />
                <br />
                Here are a few of the most popular:
                <br />
                <a
                  className="underline"
                  href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  target="_blank"
                >
                  <span>Cookie settings in Internet Explorer&nbsp;</span>
                </a>
                <br />
                <a
                  className="underline"
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                  target="_blank"
                >
                  <span>Cookie settings in Firefox&nbsp;</span>
                </a>
                <br />
                <a
                  className="underline"
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                  target="_blank"
                >
                  <span>Cookie settings in Chrome&nbsp;</span>
                </a>
                <br />
                <a
                  className="underline"
                  href="https://support.apple.com/?path=Safari/3.0/en/9277.html"
                  target="_blank"
                >
                  <span>Cookie settings in Safari&nbsp;</span>
                </a>
                <br />
                <br />
                If you are concerned about third party cookies generated by
                advertisers, you can visit the{" "}
                <a
                  className=" underline"
                  href="https://www.youronlinechoices.com/uk/"
                  target={"_blank"}
                >
                  Your Online Choices website
                </a>{" "}
                which allows you to turn these off.
                <br />{" "}
                <a
                  className="underline"
                  href="https://thenai.org/"
                  target={"_blank"}
                >
                  The Network Advertising Initiative 
                </a>
                also provides{" "}
                <a
                  className=" underline"
                  href="https://optout.networkadvertising.org/?c=1#!/"
                  target={"_blank"}
                >
                  opt-out controls
                </a>{" "}
                for targeted advertising by NAI member ad networks. Please bear
                in mind that there are many more networks listed on the Network
                Advertising Initiative than those that we use at Nominet.
              </p>
              <h3 className="mt-7 text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                FURTHER INFORMATION ON COOKIES
              </h3>
              <p className="text-[13px] leading-[25px] text-[#000]  ">
                For more information about how to manage cookies and their uses
                visit{" "}
                <a
                  className="underline"
                  href="https://www.aboutcookies.org/"
                  target={"_blank"}
                >
                  About Cookies.
                </a>
                <br />
                For information regarding the updated law on cookies visit{" "}
                <a
                  className="underline"
                  href="http://www.idp.al/"
                  target={"_blank"}
                >
                  www.idp.al.{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCookies;
