import { API_AI, API_QUIZ, WEB_AI } from "../constants/apiConfig";
import apiFactory from "../utils/apiFactory";

export const analyticsService = () => {
  const getBlogs = async (venue_app_code) => {
    let api = {
      content_type: "application/json",
      path: `${API_AI.path}/blogs-list`,
      method: "GET",
    };
    return apiFactory(api);
  };

  const blogCount = async (payload) => {
    let api = {
      content_type: "application/json",
      path: `${API_AI.path}/suggest-quiz`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  const faqCount = async (payload) => {
    let api = {
      content_type: "application/json",
      path: `${WEB_AI.path}/update-statistics`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  const createQuiz = async (payload) => {
    let api = {
      content_type: "application/json",
      path: API_QUIZ.path,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  return { blogCount, faqCount, createQuiz, getBlogs };
};
