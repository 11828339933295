import React from "react";
import "../index.css";
import Image1 from "../../../assets/images/retail/jewer11.png";
import { timeData } from "../../cart/checkout/data";
import { arrowRightIcon } from "../icons";

const DealsOfMonth = () => {
  return (
    <div className="deals large-width">
      <div
        className={`flex flex-col md:flex-row items-start py-[38px]  md:gap-1 gap-10`}
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-col md:gap-3 gap-1">
            <div className="text-24 md:text-[32px] text-[#131118] font-medium text-center md:text-left">Deals of the Month</div>
            <div className="text-[#131118] font-normal text-12 md:text-16 text-center md:text-left">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters
            </div>
          </div>

          <div className="flex gap-3 items-center">
            {timeData.map((item) => {
              return (
                <div className="timer">
                  <div>
                    <span className="title">{item.value}</span>
                    <span className="label">{item.label}</span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="product-name pt-5 flex justify-center md:justify-start">
            <button className="flex bg-[#131118] items-center gap-[10px]">
              <span>View All Products</span>
              <span>{arrowRightIcon}</span>
            </button>
          </div>
        </div>

        <img alt="" src={Image1} className="md:w-[523px] md:h-[360px] hidden md:block" />
      </div>
    </div>
  );
};

export default DealsOfMonth;
