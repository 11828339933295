import React, { useState } from "react";
import "./index.css";
import { FavoriteBorderOutlined } from "@mui/icons-material";

const BybestProductItem = ({ data, type, onClick = () => {} }) => {
  const [visibleSecond, setVisibleSecond] = useState(false);
  return (
    <div className="flex flex-col rounded-[5px] relative" onClick={onClick}>
      <div
        className="w-full h-[180px] md:h-[300px] rounded-[5px] relative"
        onMouseEnter={() => setVisibleSecond(true)}
        onMouseLeave={() => setVisibleSecond(false)}
      >
        <img
          alt=""
          className="absolute top-0 z-[1] bg-white left-0 w-full h-[180px] md:h-[300px] rounded-[5px] object-cover"
          src={data?.img1}
        />
        <img
          alt=""
          className={
            "absolute top-0 left-0 w-full h-[180px] md:h-[300px] rounded-[5px] object-cover " +
            (visibleSecond ? " z-[2]" : "")
          }
          src={data?.img2}
        />
      </div>
      {type === "home" ? (
        <div className="flex flex-col justify-start items-start py-3">
          <p className="text-[15px] font-bold text-[#000]">{data.title}</p>
          <p className="text-[15px] font-medium text-[#000] my-1">
            {data.category}
          </p>
          <p className="text-[15px] font-bold text-[#000]">LEK {data.price}</p>
        </div>
      ) : type === "similar" ? (
        <div className="flex flex-col justify-start items-start py-3">
          <p className="text-[15px] font-bold text-[#000]">{data.title}</p>
          <p className="text-[15px] font-medium text-[#000] my-1">
            {data.category}
          </p>
          <div className="flex justify-start items-center text-[15px] px-1 py-3">
            <span className="old-price relative">LEK {data?.price} </span>
            <span className=" px-2"> -{data?.discount} </span>
            <span className="text-[#f00]"> LEK {data?.sale_price}</span>
          </div>
        </div>
      ) : (
        <div className="flex justify-start items-center text-[15px] px-1 py-3">
          <span className="old-price relative">LEK {data?.price} </span>
          <span className=" px-2"> -{data?.discount} </span>
          <span className="text-[#f00]"> LEK {data?.sale_price}</span>
        </div>
      )}
      <div className="absolute top-4 right-4 z-[3]">
        <FavoriteBorderOutlined style={{ color: "#000", fontSize: 24 }} />
      </div>
    </div>
  );
};

export default BybestProductItem;
