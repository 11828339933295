import React from "react";
import Img1 from "../../../assets/images/about-ap/hyperbest.png";
import Img2 from "../../../assets/images/about-ap/ultimatewinner.png";
import Img3 from "../../../assets/images/about-ap/ultar.png";

const AboutIntro = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center w-full my-8 md:my-16 gap-10">
      <div className="flex-1 flex flex-col gap-5 md:gap-20">
        <h2 className="text-[24px] md:text-[32px] lg:text-[44px] font-medium leading-[36px] md:leading-[40px] lg:leading-[58px]">
            We hope to best serve our guests
        </h2>
        {/*<button className="w-full lg:w-40 h-14 bg-[#191818] rounded text-white text-14 lg:text-xl my-4 lg:my-0 py-2 lg:py-0">*/}
        {/*  Find Room*/}
        {/*</button>*/}
      </div>
      <div className="flex-1 flex flex-col gap-5 md:gap-20">
        {/*<div className="flex flex-row justify-center items-center gap-5">*/}
        {/*  <img*/}
        {/*    className="max-w-full w-full lg:w-[25%]"*/}
        {/*    src={Img1}*/}
        {/*    alt={`Photo 0`}*/}
        {/*  />*/}

        {/*  <img*/}
        {/*    className="max-w-full w-full lg:w-[25%]"*/}
        {/*    src={Img3}*/}
        {/*    alt={`Photo 2`}*/}
        {/*  />*/}
        {/*  <img*/}
        {/*    className="max-w-full w-full lg:w-[20%]"*/}
        {/*    src={Img2}*/}
        {/*    alt={`Photo 1`}*/}
        {/*  />*/}
        {/*</div>*/}
        <p className="font-medium leading-6 md:leading-7 text-gray-400 text-18">
            We give our guests space but also are available when needed.
            We are available for any inquiries or questions that guests might have during their stay.
        </p>
      </div>
    </div>
  );
};

export default AboutIntro;
