import FormText from "../../../components/FormText";

import mapImg from '../../../assets/images/services/map.png';

import locationIcon from '../../../assets/svgs/location.svg';
import homeIcon from '../../../assets/svgs/home.svg';
import timeIcon from '../../../assets/svgs/time.svg';
import forkIcon from '../../../assets/svgs/fork.svg';
import dinningIcon from '../../../assets/svgs/dinning.svg';
import dressIcon from '../../../assets/svgs/dress.svg';
import parkIcon from '../../../assets/svgs/park.svg';
import paymentIcon from '../../../assets/svgs/payment.svg';
import additionIcon from '../../../assets/svgs/addition.svg';
import { useEffect, useState } from "react";

const RetailInfo = ({ data}) => {
  const [ address, setAddress] = useState('');

  useEffect(() => {
    let full_address = "No Address";
    if (data?.overview?.address){
      full_address = `${data?.overview?.address?.address_line1 ?? 'No Address'}, ${data?.overview?.address?.city ?? 'No City'}, ${data?.overview?.address?.state ?? 'No State'}, ${data?.overview?.address?.country ?? ''}, ${data?.overview?.address?.postcode ?? 'No Postal Code'}`;
    }
    setAddress(full_address);
  }, [data]);

  return (
    <div className='md:col-span-2 flex flex-col md:pt-8 md:pb-8'>
      <FormText title='Additional Information' type='itemtitle-sub' />
      <div className="flex flex-col border rounded-xl mt-4 h-full">
        <div className="flex flex-col p-5 border border-x-0 border-t-0 border-b">
          {/*TODO: implement google map here*/}
          {/*<img src={mapImg} alt="map" />*/}
          <div className="flex flex-row items-center mt-3">
            <img src={locationIcon} alt="location" />
            <FormText
              title={address}
              type="subtitle-based"
              customClass="ml-2"
            />
          </div>
        </div>
        <div className="px-5 py-2">
        {data?.additional_information?.neighborhood ? <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={homeIcon} alt="icon" />
              <FormText title="Neighborhood" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.neighborhood} type="subtitle-dark" />
            </div>
          </div> : null}
          {/* Todo Griseld, added an input hours_of_operation, which will describe a descrion of how much need to be open
          a venue, ex: Open 24*/}
          {data?.additional_information?.hours_of_operation ? <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={timeIcon} alt="icon" />
              <FormText title="Hours of operation" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.hours_of_operation} type="subtitle-dark" />
            </div>
          </div> : null}
          {data?.additional_information?.parking_details ?<div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={parkIcon} alt="icon" />
              <FormText title="Parking details" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.parking_details} type="subtitle-dark" />
            </div>
          </div> : null}
          {data?.additional_information?.payment_options ?<div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={paymentIcon} alt="icon" />
              <FormText title="Payment options" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText title={data?.additional_information?.payment_options?.join(", ")} type="subtitle-dark" />
            </div>
          </div> : null}
          {data?.additional_information?.additional ?<div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-[45%]">
              <img src={additionIcon} alt="icon" />
              <FormText title="Additional" customClass="ml-2" type="subtitle-based" />
            </div>
            <div className="w-[55%]">
              <FormText
                  customStyle={{wordWrap: "break-word"}}
                  title={
                    data?.additional_information?.additional
                  }
                  type="subtitle-dark"
              />
            </div>
          </div> : null}
        </div>
      </div>
    </div>
  )
}

export default RetailInfo;
