import React from 'react';

import ServiceBtn from '../../../components/FormBtn/ServiceBtn';
import FormText from '../../../components/FormText';

import bowlingImg from '../../../assets/images/services/bowling.png';

const BowlingHeader = ({ data }) => {
  const handleAction = () => {
    console.log('button clicked');
  };

  return (
    <div className="md:bg-gray2 bg-white">
      {/* <div className='block md:hidden h-48 w-full'>
        <img src={data?.overview?.logo} alt='restaurant' className='h-full w-full' />
      </div> */}
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-10 md:py-14 max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full">
        <div className='flex flex-col justify-center pl-0 md:pl-3'>
          <FormText
            title="Bowlero Times Square"
            type='page-title'
            customClass='mt-5 !font-bold'
          />
          <FormText
            title="Huge portions of pub grub & flat-screen TV sports in a stadium-themed eatery inside Bowlmor Lanes."
            type='subtitle-dark'
            customClass='mt-4 md:mt-8'
          />
          <FormText
            title="We are open 24 hours a day."
            type='subtitle-dark'
          />
          <div className='flex flex-row flew-wrap mt-2'>
            <ServiceBtn label="Bowling" classes="mr-3" onActin={() => handleAction()} />
            <ServiceBtn label="Pub Grub" classes="mr-3" onActin={() => handleAction()} />
            <ServiceBtn label="1200m2" classes="mr-3" onActin={() => handleAction()} />
            <ServiceBtn label="TV Sports" classes="mr-3" onActin={() => handleAction()} />
          </div>
        </div>
        <img src={bowlingImg} alt='restaurant' className='rounded-xl w-full h-full' />
      </div>
    </div>
  )
}

export default BowlingHeader;
