export const phoneIcon = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0234 11.8755C14.0234 12.1013 13.9733 12.3333 13.8667 12.5591C13.7601 12.7848 13.6221 12.998 13.4402 13.1987C13.1329 13.5374 12.7943 13.7819 12.4118 13.9387C12.0355 14.0955 11.6279 14.177 11.1889 14.177C10.5492 14.177 9.86568 14.0265 9.1445 13.7192C8.42332 13.4119 7.70214 12.998 6.98723 12.4775C6.26606 11.9508 5.5825 11.3676 4.93031 10.7216C4.28438 10.0694 3.70116 9.38588 3.18066 8.67097C2.66643 7.95606 2.25254 7.24115 1.95152 6.53252C1.65051 5.81761 1.5 5.13405 1.5 4.48186C1.5 4.05542 1.57525 3.6478 1.72576 3.27153C1.87627 2.88899 2.11457 2.53781 2.44694 2.22425C2.84829 1.82917 3.28727 1.63477 3.75133 1.63477C3.92693 1.63477 4.10252 1.67239 4.2593 1.74765C4.42234 1.8229 4.56658 1.93578 4.67946 2.09883L6.13436 4.14949C6.24724 4.30627 6.32877 4.4505 6.38521 4.58847C6.44165 4.72016 6.473 4.85185 6.473 4.971C6.473 5.12151 6.4291 5.27202 6.34131 5.41625C6.25978 5.56049 6.14063 5.711 5.99013 5.8615L5.51352 6.35692C5.44454 6.42591 5.41318 6.50743 5.41318 6.60777C5.41318 6.65794 5.41945 6.70184 5.432 6.752C5.45081 6.80217 5.46962 6.8398 5.48217 6.87743C5.59505 7.08437 5.78945 7.35403 6.06538 7.68013C6.34758 8.00623 6.64859 8.3386 6.97469 8.67097C7.31333 9.00334 7.63943 9.31062 7.9718 9.59282C8.2979 9.86875 8.56756 10.0569 8.78078 10.1698C8.81213 10.1823 8.84976 10.2011 8.89366 10.2199C8.94383 10.2387 8.994 10.245 9.05044 10.245C9.15704 10.245 9.23857 10.2074 9.30755 10.1384L9.78416 9.66808C9.94094 9.5113 10.0914 9.39215 10.2357 9.31689C10.3799 9.2291 10.5241 9.1852 10.6809 9.1852C10.8001 9.1852 10.9255 9.21029 11.0635 9.26673C11.2014 9.32317 11.3457 9.40469 11.5024 9.5113L13.5782 10.985C13.7412 11.0979 13.8541 11.2296 13.9231 11.3864C13.9858 11.5431 14.0234 11.6999 14.0234 11.8755Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export const calendarIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33203 2.23926V4.23926"
      stroke="#2D333F"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.668 2.23926V4.23926"
      stroke="#2D333F"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 6.57259V12.2393C14 14.2393 13 15.5726 10.6667 15.5726H5.33333C3 15.5726 2 14.2393 2 12.2393V6.57259C2 4.57259 3 3.23926 5.33333 3.23926H10.6667C13 3.23926 14 4.57259 14 6.57259Z"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33203 6.96582H13.6654"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4625 10.0389H10.4685"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4625 12.0389H10.4685"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99764 10.0389H8.00363"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99764 12.0389H8.00363"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.52889 10.0389H5.53488"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.52889 12.0389H5.53488"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const clockIcon = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 15.9058C12.366 15.9058 15.5 12.7718 15.5 8.90576C15.5 5.03977 12.366 1.90576 8.5 1.90576C4.63401 1.90576 1.5 5.03977 1.5 8.90576C1.5 12.7718 4.63401 15.9058 8.5 15.9058Z"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.07422 4.75635V9.91016H11.8561"
      stroke="#2D333F"
      strokeWidth="1.5"
    />
  </svg>
);

export const tableIcon = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4987 6.42236C12.1806 6.42236 15.1654 5.52693 15.1654 4.42236C15.1654 3.31779 12.1806 2.42236 8.4987 2.42236C4.8168 2.42236 1.83203 3.31779 1.83203 4.42236C1.83203 5.52693 4.8168 6.42236 8.4987 6.42236Z"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49837 11.7557C9.38504 11.7557 9.88037 12.261 10.111 12.6997C10.1646 12.8041 10.1787 12.9243 10.1506 13.0382C10.1225 13.1522 10.0543 13.2521 9.95837 13.3197C9.52617 13.6076 9.01766 13.7594 8.49837 13.7557C7.94637 13.7557 7.43837 13.5924 7.03837 13.319C6.94247 13.2514 6.8742 13.1515 6.84615 13.0376C6.81809 12.9236 6.83214 12.8034 6.88571 12.699C7.11637 12.2624 7.61171 11.7557 8.49837 11.7557ZM8.49837 11.7557V6.42236"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const userIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.10573 7.3357C8.03906 7.32903 7.95906 7.32903 7.88573 7.3357C6.29906 7.28236 5.03906 5.98236 5.03906 4.38236C5.03906 2.74903 6.35906 1.42236 7.99906 1.42236C9.6324 1.42236 10.9591 2.74903 10.9591 4.38236C10.9524 5.98236 9.6924 7.28236 8.10573 7.3357Z"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.7725 9.79537C3.15917 10.8754 3.15917 12.6354 4.7725 13.7087C6.60583 14.9354 9.6125 14.9354 11.4458 13.7087C13.0592 12.6287 13.0592 10.8687 11.4458 9.79537C9.61917 8.57537 6.6125 8.57537 4.7725 9.79537Z"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const noteIcon = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1654 5.42236C15.1654 2.7557 13.832 1.42236 11.1654 1.42236H5.83203C3.16536 1.42236 1.83203 2.7557 1.83203 5.42236V14.089C1.83203 14.4557 2.13203 14.7557 2.4987 14.7557H11.1654C13.832 14.7557 15.1654 13.4224 15.1654 10.7557V8.08903"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 6.42236H11.8333"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16797 6.42236H8.5013"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16797 9.75537H9.83464"
      stroke="#2D333F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const mailIcon = (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1107_2114)">
      <path d="M12.0221 2.28076H4.02214C2.54938 2.28076 1.35547 3.40005 1.35547 4.78076V11.0308C1.35547 12.4115 2.54938 13.5308 4.02214 13.5308H12.0221C13.4949 13.5308 14.6888 12.4115 14.6888 11.0308V4.78076C14.6888 3.40005 13.4949 2.28076 12.0221 2.28076Z" stroke="#292D32" stroke-width="1.5" />
      <path d="M1.35547 4.78076L6.35628 8.53139C7.3302 9.26182 8.71407 9.26182 9.688 8.53139L14.6888 4.78076" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1107_2114">
        <rect width="16" height="15" fill="white" transform="translate(0.0234375 0.405762)" />
      </clipPath>
    </defs>
  </svg>

);