import React, { useEffect, useState } from "react";
import "./index.css";
import General from "./general/general";
import Photos from "./photos/photos";
import MoreInformation from "./more-info/more-information";
import RestaurantHeader from "./restaurant-header";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadWhiteLabel } from "../../redux/actions/venue";

const RestaurantCard = () => {
  const { app_key } = useParams();
  const dispatch = useDispatch();
  const venueData = useSelector((state) => state.venue);
  const [tabIndex, setTabIndex] = useState(0);
  const tabs = ["General", "Photos", "More Info"];

  useEffect(() => {
    dispatch(loadWhiteLabel(app_key));
  }, [app_key])

  return (
    <div className="w-full bg-[#fdfdfd] rounded-lg overflow-hidden  ">
      <RestaurantHeader icon={venueData?.overview?.logo}/>
      <img
        className="w-full h-48 object-cover object-center shadow-4-strong"
        src={venueData?.overview?.cover}
        alt="Restaurant"
      />
      <div className="parent-tab px-4">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${tabIndex === index ? "selected" : ""}`}
              onClick={() => setTabIndex(index)}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-5 px-4">
        {tabIndex === 0 && <General />}

        {tabIndex === 1 && (
          <div className="flex flex-col gap-1">
            <Photos />
          </div>
        )}

        {tabIndex === 2 && <MoreInformation />}
      </div>
      <div className="text-[#1A1818] text-center text-16 font-medium mb-2 px-4">
        ©2024 - All Rights Reserved️ - Powered by VenueBoost
      </div>
    </div>
  );
};

export default RestaurantCard;
