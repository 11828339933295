import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { identifyVenue, trackEvent } from "../../../utils/mixpanelUtil";
import { NotificationManager } from "react-notifications";
import WhiteLabelService from "../../../services/whiteLabelService";

export const SelectApartment = () => {
  const rental_units =
    useSelector((state) => state.venue?.additional_information?.rental_units) ??
    [];
  const navigate = useNavigate();

  const [rental_unit, setRentalUnit] = useState();
  const [guestList, setGuestList] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedApartment, setSelectedApartment] = useState();
  const [checkinDate, setCheckinDate] = useState("");
  const [checkoutDate, setCheckoutDate] = useState("");
  const [days, setDays] = useState("");
  const [price, setPrice] = useState("");
  const [total, setTotal] = useState("");
  const [discount, setDiscount] = useState(null);
  const [discountValue, setDiscountValue] = useState(null);
  const [selectedGuests, setSelectedGuests] = useState("");

  const guestOptions = useMemo(() => {
    if (rental_unit?.price_per_nights) {
      return rental_unit?.price_per_nights?.map((item) => {
        return {
          value: item.nr_guests,
          label: `${item?.nr_guests} ${
            item?.nr_guests === 1 ? "Guest" : "Guests"
          }`,
        };
      });
    }
    return [];
  }, [rental_unit, selectedApartment]);

  const getDetails = (rental_code) => {
    WhiteLabelService.getRentallDetails(rental_code).then(
      (res) => {
        setRentalUnit(res?.rental_unit);

        if (res?.rental_unit?.price_per_nights) {
          setGuestList(
            res?.rental_unit?.price_per_nights.map((item) => {
              return { value: item.id, label: item.nr_guests };
            })
          );
        }

        // dispatch({ type: SET_VENUE_DATA, payload: res.venue });

        identifyVenue(rental_code);
        trackEvent("Vacation Rental - Detail", {
          action_category: "Page Load",
          action_outcome: "success",
          interaction_element: "screen",
          industry: "accommodation",
          source: "vb_web",
        });
      },
      (error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );

        identifyVenue(rental_code);
        trackEvent("Vacation Rental - Detail", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "accommodation",
          source: "vb_web",
        });
      }
    );
  };

  const handleGuestChange = (event) => {
    setSelectedGuests(event.target.value);
  };

  const handleCheckinChange = (event) => {
    setCheckinDate(event.target.value);
  };

  const handleCheckoutChange = (event) => {
    setCheckoutDate(event.target.value);
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const apartment = JSON.parse(selectedValue);
    if (apartment) {
      setSelectedApartment(apartment);
    }
  };

  const getPriceValue = (guestNumber) => {
    return (rental_unit?.price_per_nights ?? []).find(
      (item) => Number(item?.nr_guests) === Number(guestNumber)
    );
  };

  const CheckDateDifference = () => {
    const startDate = new Date(checkinDate);
    const endDate = new Date(checkoutDate);
    const differenceInMilliseconds = endDate - startDate;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (24 * 60 * 60 * 1000)
    );
    return differenceInDays;
  };

  const extractIdFromUrl = (url) => {
    const parts = url.split("/rental/");
    return parts.length > 1 ? parts[1] : null;
  };

  const handleNavigate = () => {
    navigate("/rental/reserve", {
      state: {
        price,
        days,
        total,
        discount,
        discountValue,
        guestNo: selectedGuests,
        geustId: selectedGuests.label,
        checkInDate: checkinDate,
        checkOutDate: checkoutDate,
        pricePerNight: rental_unit?.price_per_nights ?? [],
        guestList,
        selectedGuests,
        currency: rental_unit?.header_section?.currency,
        rentalId: extractIdFromUrl(selectedApartment?.url),
        rentalName: selectedApartment?.name,
        rentalImg: rental_unit?.header_section?.gallery[0].photo_path,
      },
    });
  };

  const checkApartmentAvailability = () => {
    const payload = {
      rental_unit: selectedApartment.name,
      check_in_date: checkinDate,
      check_out_date: checkoutDate,
    };
    WhiteLabelService.apartamentDetails(payload)
      .then((res) => {
        if (res.code === 1) {
          handleNavigate();
        } else {
          NotificationManager.warning(res.message ?? "");
        }
      })
      .catch(() => {
        console.log("");
      });
  };

  useEffect(() => {
    if (selectedGuests) {
      let discountValue = 0;
      let DaysRange = CheckDateDifference();
      setDays(DaysRange);
      const data = getPriceValue(selectedGuests);
      setPrice(Number(data?.price));
      setTotal(Number(data?.price) * Number(CheckDateDifference() ?? 1));
      setDiscount(Number(data?.discount ?? 0));
      discountValue = Number(total) * (Number(data?.discount ?? 0) / 100);
      setDiscountValue(discountValue);
    }
    // eslint-disable-next-line
  }, [selectedGuests]);

  useEffect(() => {
    if (selectedApartment) {
      const url = selectedApartment.url;
      const id = extractIdFromUrl(url);

      getDetails(id);
    }
  }, [selectedApartment?.url]);

  useEffect(() => {
    const generateNext45Days = () => {
      const today = new Date();
      const dateArray = [];

      for (let i = 0; i < 25; i++) {
        const date = new Date();
        date.setDate(today.getDate() + i);

        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const dd = String(date.getDate()).padStart(2, "0");

        const dateString = `${dd} ${date.toLocaleString("default", {
          month: "long",
        })} ${yyyy}`;
        const optionValue = `${yyyy}-${mm}-${dd}`;

        dateArray.push({ value: optionValue, label: dateString });
      }

      setDates(dateArray);
    };

    generateNext45Days();
  }, []);

  const isSearchEnabled =
    selectedApartment && checkinDate && checkoutDate && selectedGuests;

  return (
    <div className="w-full bg-gray-50 z-50 mb-20 lg:mb-0">
      <div className="max-w-[1114px] w-full h-[150px] rounded bg-white flex flex-col lg:flex-row justify-between items-center mx-auto lg:-mt-20 px-10 py-9">
        <div className="w-full grid grid-cols-2 lg:flex lg:flex-col items-center gap-2 lg:pr-8">
          <span className="text-14 lg:text-xl leading-[30px] text-gray-500">
            Apartment
          </span>
          <select
            placeholder="Select type"
            className="text-16 lg:text-2xl font-semibold p-0"
            onChange={handleChange}
          >
            <option value="">Select Apartment</option>
            {rental_units.map((unit, index) => (
              <option key={index} value={JSON.stringify(unit)}>
                {unit.name}
              </option>
            ))}
          </select>
        </div>
        <hr className="bg-[#D3D3D3] w-[1px] h-full" />
        <div className="w-full grid grid-cols-2 lg:flex lg:flex-col items-center gap-2 lg:pr-8">
          <span className="text-14 lg:text-xl leading-[30px] text-gray-500">
            Checkin
          </span>
          <select
            placeholder="Select check-in date"
            className="text-16 lg:text-2xl font-semibold p-0"
            onChange={handleCheckinChange}
            value={checkinDate}
          >
            <option value="">Checkin</option>
            {dates.map((date, index) => (
              <option key={index} value={date.value}>
                {date.label}
              </option>
            ))}
          </select>
        </div>
        <hr className="bg-[#D3D3D3] w-[1px] h-full" />
        <div className="w-full grid grid-cols-2 lg:flex lg:flex-col items-center gap-2 lg:pr-8">
          <span className="text-14 lg:text-xl leading-[30px] text-gray-500">
            Checkout
          </span>
          <select
            placeholder="Checkout"
            className="text-16 lg:text-2xl font-semibold p-0"
            onChange={handleCheckoutChange}
            value={checkoutDate}
          >
            <option value="">Checkout</option>
            {dates.map((date, index) => (
              <option key={index} value={date.value}>
                {date.label}
              </option>
            ))}
          </select>
        </div>
        <hr className="bg-[#D3D3D3] w-[1px] h-full" />
        <div className="w-full grid grid-cols-2 lg:flex lg:flex-col items-center gap-2 lg:pr-8">
          <span className="text-14 lg:text-xl leading-[30px] text-gray-500">
            Guests
          </span>
          <select
            placeholder="Select Guests"
            className="text-16 lg:text-2xl font-semibold p-0"
            value={selectedGuests}
            onChange={handleGuestChange}
          >
            <option value="">Select Guests</option>
            {guestOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={checkApartmentAvailability}
          className={`w-full lg:w-40 h-14 rounded text-white text-14 lg:text-xl my-4 lg:my-0 py-2 lg:py-0 ${
            isSearchEnabled ? "bg-[#191818]" : "bg-gray-400"
          }`}
          disabled={!isSearchEnabled}
        >
          Search
        </button>
      </div>
    </div>
  );
};
