import { useState } from "react";

import FormSelect from "../../../components/FormSelect";
import FormText from "../../../components/FormText";
import { SubmitBtn } from "../../../components/FormBtn";
import RouteNames from '../../../constants/route_names';

const GymBook = (props) => {
  const [bookClass, setBookClass] = useState('zumba_basic');

  return (
    <div className='md:col-span-2 flex flex-col md:pt-8'>
      <FormText title='Book now' type='itemtitle-sub' />
      <div className="flex flex-col justify-center border rounded-xl mt-4 h-full px-4 md:px-8 py-5 md:py-8">
        <FormSelect
          placeholder='Choose class'
          value={bookClass}
          dataList={[{ value: 'zumba_basic', title: 'Zumba Basic'}, { value: 'zumba_advanced', title: 'Zumba Advanced'}]}
          onChanage={(e) => setBookClass(e.target.value)}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between w-full px-4 py-6 mt-5 bg-gray2 border rounded-md">
          <div className="flex flex-row items-center gap-2">
            <FormText
              title='$ 35'
              type="pretitle-bold"
            />
            <FormText
              title='per month'
              type="subtitle-based"
            />
          </div>
          <FormText
            title='10/10/2023 - 10/11/2023'
            type="subtitle-dark"
            customClass="!font-semibold"
          />
        </div>
        <a href={RouteNames.venue_gym_book}>
          <SubmitBtn
            title='Book now'
            classes='mt-5'
            dark={true}
          />
        </a>
      </div>
    </div>
  )
}

export default GymBook;
