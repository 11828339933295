import { useNavigate } from "react-router-dom";
import "./index.css";
import { useCallback } from "react";

export const SidebarLink = ({
  href,
  icon,
  label,
  selected,
  onItemClick,
  appKey,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      if (onItemClick) {
        onItemClick(e);
      } else {
        navigate(`/shop/${appKey}/customer${href}`);
      }
    },
    [navigate, appKey, href, onItemClick]
  );

  return (
    <div
      className={`sidebar-item cursor-pointer ${
        selected ? "sidebar-item-active" : ""
      }`}
      href={href}
      onClick={handleClick}
    >
      <div className="flex items-center gap-2">
        <span>{icon}</span>
        <p className="text-[#191D23] text-[13px] font-semibold">{label}</p>
      </div>
    </div>
  );
};
