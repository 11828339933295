import React, { useState } from "react";
import "./index.css";
import cartIcon from "../../assets/svgs/bybest/bb-cart.svg";
import headerIcon from "../../assets/svgs/bybest/bb-lang.svg";
import heartIcon from "../../assets/svgs/bybest/bb-heart.svg";
import userIcon from "../../assets/svgs/bybest/bb-user.svg";
import searchIcon from "../../assets/svgs/bybest/bb-search.svg";
import searchBlackIcon from "../../assets/svgs/bybest/search.svg";
import menuIcon from "../../assets/svgs/bybest/menu.svg";
import ByBestLogo from "../../assets/images/retail/bybest-logo.png";
import Offers from "../../assets/images/retail/offers.png";

import BybestDropdownMenu from "../../components/BybestHeaderMenu";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderDrawer, { menuItems } from "./header-drawer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions/app";

const BBHeader = () => {
  const { t } = useTranslation();
  const getTranslation = (label = "") => t(`bybest.header.${label}`);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);

  const navigate = useNavigate();
  const { app_key = "BYB2802APPY" } = useParams();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen((pre) => !pre);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onWishList = () => {
    navigate(`/shop/${app_key}/wishlist`);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    dispatch(changeLanguage(newLanguage));
  };

  console.log(language, "language");

  return (
    <>
      <div className="flex justify-center items-center w-full bg-white z-10 top-[32px] fixed border-b border-b-[#F4F4F4]">
        <div className="w-full max-w-screen-main hidden md:flex justify-center items-center">
          <div className="flex flex-col py-2 bg-white">
            <div className="bg-white lg:flex lg:justify-between grid gap-5">
              <div className="menu-header grid grid-cols-2 gap-5">
                <div className="left-side flex items-center">
                  <img alt="" src={headerIcon} />
                  <span className="lang cursor-pointer">
                    {getTranslation("languageLabel")}
                  </span>
                  <select
                    className="choose-lang cursor-pointer bg-transparent border-none outline-none"
                    value={language}
                    onChange={handleLanguageChange}
                  >
                    <option value="AL">AL</option>
                    <option value="EN">EN</option>
                  </select>
                  <select className="bold-label cursor-pointer bg-transparent border-none outline-none">
                    <option value="LEK">
                      {getTranslation("currencyLabel")}
                    </option>
                  </select>
                </div>
              </div>
              <div className="flex items-center lg:justify-end justify-between w-full gap-2">
                <div className="my-club">
                  <Link to={`/shop/${app_key}/my-club`}>
                    <span>{getTranslation("myClub")}</span>
                  </Link>
                </div>
                <div className="my-club">
                  <Link to={`/shop/${app_key}/tracking`}>
                    <span>{getTranslation("trackOrder")}</span>
                  </Link>
                </div>
                <div className="my-club">
                  <Link to={`/shop/${app_key}/login`}>
                    <img alt="" src={userIcon} />
                  </Link>
                </div>
                <div className="my-club" onClick={onWishList}>
                  <img alt="" src={heartIcon} />
                </div>
                <div className="my-club">
                  <Link to={`/shop/${app_key}/cart/products`}>
                    <img alt="" src={cartIcon} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              <Link to={`/shop/${app_key}`}>
                <img
                  alt="bybestlogo"
                  src={ByBestLogo}
                  className="w-[211px] h-[52px] object-contain"
                />
              </Link>
            </div>
            <div className="w-full mt-3">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-2" />
                <div className="flex justify-center items-center col-span-8 w-full">
                  <div className="flex items-center">
                    {menuItems.map((item, index) => (
                      <BybestDropdownMenu key={index} menu={item} />
                    ))}
                  </div>
                  <img alt="" src={Offers} />
                </div>
                <div className="relative flex justify-end w-full col-span-2 py-1 border-b border-b-[#333333] px-1">
                  <span className="absolute left-0 inset-y-0 flex items-center">
                    <img alt="" src={searchIcon} />
                  </span>
                  <input
                    autoComplete="off"
                    type="search"
                    placeholder={getTranslation("searchPlaceholder")}
                    className="pl-8 pr-2 w-full border-none outline-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:flex h-[164px]" />
      <div className="flex md:hidden z-10 top-[32px] fixed w-full justify-center items-center px-5 py-4 gap-4 border-b border-b-[#F4F4F4] bg-white">
        <div
          className="flex justify-center items-center"
          onClick={toggleDrawer}
        >
          <img alt="" src={menuIcon} />
        </div>
        <div
          className="flex justify-center items-center"
          onClick={toggleDrawer}
        >
          <img alt="" src={searchBlackIcon} />
        </div>
        <div className="flex-1 flex justify-center items-center">
          <Link to={`/shop/${app_key}`}>
            <img
              alt="bybestlogo"
              src={ByBestLogo}
              className={"w-[162px] h-[40px] object-contain"}
            />
          </Link>
        </div>
        <div className="flex justify-center items-center" onClick={onWishList}>
          <img alt="" src={heartIcon} />
        </div>
        <div className="flex justify-center items-center">
          <img alt="" src={cartIcon} />
        </div>
      </div>
      <div className="flex md:hidden h-[104px]" />
      <HeaderDrawer {...{ app_key, open, onClose }} />
    </>
  );
};

export default BBHeader;
