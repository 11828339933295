import React, { useState } from "react";
import { Table, Pagination } from "antd";
import { Icon } from "react-icons-kit";
import { home } from "react-icons-kit/typicons/home";
import Sidebar from "../../../components/Sidebar";
import "./index.css";
import LoggedInFooter from "../../../containers/footer/logged-in-footer";

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `#04873${i}`,
    amount: "$ 120",
    payment_method: "Card",
    date: "15/10/2023 4:00 PM",
  });
}

const Payments = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [minValue1, setMinValue1] = useState(0);
  const [maxValue1, setMaxValue1] = useState(4);
  const [numEachPage1, setNumEachPage1] = useState(4);

  const handleChange1 = (value) => {
    setMinValue1((value - 1) * numEachPage1);
    setMaxValue1(value * numEachPage1);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="flex flex-col align-col-middle view-terms ">
      <div className="flex w-[100vw] bg-[#FCFCFD]">
        <Sidebar selected="payments" />
        <div className="flex flex-col md:flex-row flex-1 px-[20px] py-[9px] md:px-[42px] md:py-[32px] gap-5 md:gap-6">
          <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
            <div className="p-6 gap-2 flex flex-col">
              <div className="flex items-center gap-4">
                <Icon
                  icon={home}
                  style={{
                    color: "#6B7280",
                    display: "flex",
                  }}
                  size={20}
                />
                <p className="text-[#6B7280] text-20 font-medium leading-5 ">{`>`}</p>
                <p className="text-[#6B7280] text-14 font-medium leading-5 ">
                  Payments{" "}
                </p>
              </div>
              <div className="text-[#101828] text-18 font-semibold leading-7">
                Payments
              </div>
              <p className="text-[#667085] text-14 font-medium leading-5">
                View and manage your payment transactions and reservation
                history{" "}
              </p>
            </div>
            <Table
              rowSelection={rowSelection}
              dataSource={data}
              className="w-full hidden md:block"
            >
              <Table.Column
                title="Restaurant ID"
                dataIndex="name"
                key="name"
                render={(name) => (
                  <p className="text-14 text-[#667085] leading-5 font-medium">
                    {name}
                  </p>
                )}
              />
              <Table.Column
                title="Amount"
                dataIndex="amount"
                key="amount"
                render={(amount) => (
                  <p className="text-14 text-[#667085] leading-5 font-medium">
                    {amount}
                  </p>
                )}
              />
              <Table.Column
                title="Paid with"
                dataIndex="payment_method"
                key="payment_method"
                render={(payment_method) => (
                  <p className="text-14 text-[#667085] leading-5 font-medium">
                    {payment_method}
                  </p>
                )}
              />
              <Table.Column
                title="Transaction Date"
                dataIndex="date"
                key="date"
                render={(date) => (
                  <p className="text-14 text-[#667085] leading-5 font-medium">
                    {date}
                  </p>
                )}
              />
            </Table>
          </div>
          <div className="flex flex-col md:hidden gap-[9px] justify-center flex-1">
            {data &&
              data.length > 0 &&
              data.slice(minValue1, maxValue1).map((val) => (
                <div className="boxshadow flex-1 border-[1px] border-[#E3e3e3] rounded-[8px] p-3 md:p-4 flex flex-col md:flex-row ">
                  <div className="flex flex-col flex-1 gap-[11px] mt-[13px]">
                    <div className="flex  justify-between flex-1">
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        Restaurant ID
                      </p>
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        {val.name}
                      </p>
                    </div>
                    <div className="flex  justify-between flex-1">
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        Amount
                      </p>
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        {val.amount}
                      </p>
                    </div>

                    <div className="flex  justify-between flex-1">
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        Paid with
                      </p>
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        {val.payment_method}
                      </p>
                    </div>
                    <div className="flex  justify-between flex-1">
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        Transaction Date
                      </p>
                      <p className="text-[#6B7280] text-14 font-normal leading-5">
                        {val.date}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            <Pagination
              defaultCurrent={1}
              defaultPageSize={numEachPage1} //default size of page
              onChange={handleChange1}
              className="flex-1 justify-center flex "
              total={data.length} //total number of card data available
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
