import React from "react";
import FormText from "../../../components/FormText";

const GolfPhotos = ({ photos }) => {
  return (
    <div className="md:col-span-3 flex flex-col pt-8">
      <FormText title="Photos" type="itemtitle-sub" />
      <div className="grid grid-cols-3 gap-3 mt-4">
        {photos.map((photo, index) => (
          <div
            key={index}
            className="relative flex justify-center items-center border border-gray-300 rounded-md h-[114px] md:h-[229px]"
          >
            <img
              className="max-w-full max-h-full object-cover h-full rounded-md"
              src={photo?.photo_path ?? ""}
              alt="img"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GolfPhotos;
