import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import BybestCartStepper from "../../components/BybestCartStepper";
import ImgGed from "../../assets/images/bybest/ged.png";
import SvgRadioChecked from "../../assets/svgs/bybest/radio-checked.svg";
import SvgRadio from "../../assets/svgs/bybest/radio.svg";
import SvgCash from "../../assets/svgs/bybest/cash.svg";
import SvgCard from "../../assets/svgs/bybest/card.svg";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const PaymentMethod = ({ data, isSelected, onSelect }) => {
  return (
    <div
      className={`w-full flex justify-center items-center p-2 rounded-[5px] bg-[#f2f2f2] border border-[#333] `}
      onClick={() => onSelect(data)}
    >
      <img src={isSelected ? SvgRadioChecked : SvgRadio} alt="" />
      <img
        src={data?.img}
        className="w-[30px] h-[30px] object-contain mx-2"
        alt=""
      />
      <div className="flex-1">
        <h1 className="text-[14px] text-[#000] font-bold">{data?.title}</h1>
        <p className="text-[12px] text-[#000]">{data?.desc}</p>
      </div>
    </div>
  );
};

const ByBestCartPaymethod = () => {
  const { app_key } = useParams();
  const navigate = useNavigate();
  const [curPaymentMethod, setCurPaymentMethod] = useState(0);
  const [product, setProduct] = useState({
    id: 5,
    title: "Byzylyk Idyllia",
    category: "Guaska, rozë, e veshur me rodium",
    img1: "https://admin.bybest.shop/storage/products/62447fc3e1f44_KLE20QB101-01 frusta.jpg",
    img2: "https://admin.bybest.shop/storage/products/62447fc3ecd4b_KLE20QB101-03.jpg",
    no: "KLE20QB101",
    price: 3599,
    discount: "60%",
    sale_price: 1440,
  });

  const paymentMethods = [
    { id: 0, img: SvgCash, title: "Para ne Dore", desc: "Paguaj ne dorezim" },
    {
      id: 1,
      img: SvgCard,
      title: "Credit/Debit Card",
      desc: "Paguaj online nepermje...",
    },
  ];

  const onSubmit = () => {
    navigate(`/shop/${app_key}/cart/success`);
  };

  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] uppercase ">
            BYBEST {`>`} Përfundo
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="flex justify-start items-center mb-5">
            <FaChevronLeft color="#000" size={20} />
            <span className=" ml-1 text-[16px] text-[#000] font-semibold">
              Vazhdo blerjen
            </span>
          </div>
          <BybestCartStepper curStep={1} />
          <h1 className="mt-5 text-[16px] text-[#000] leading-[24px] font-bold uppercase">
            Perfundo Pagesën
          </h1>
          <p className="text-[13px] text-[#000] leading-[19px] font-semibold ">
            Paguani produktet e porositura
          </p>
          <div className="mt-5 w-full px-4 grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-6">
            <div class="col-span-1 md:col-span-2 ">
              <div className=" w-full grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                {paymentMethods.map((pm, index) => (
                  <PaymentMethod
                    key={index}
                    data={pm}
                    isSelected={pm.id == curPaymentMethod}
                    onSelect={(p) => setCurPaymentMethod(p.id)}
                  />
                ))}
              </div>
            </div>
            <div class="col-span-1">
              <div class="w-full rounded bg-[#F2F2F2] p-4 flex flex-col justify-start items-start">
                <div className="w-full flex justify-between items-start">
                  <h2 className="text-[16px] text-[#000] font-semibold leading-[24px]">
                    NENTOTAL
                  </h2>
                  <h2 className="text-[16px] text-[#000] font-semibold leading-[24px]">
                    LEK {product?.sale_price}
                  </h2>
                </div>
                <div className="mt-3 w-full flex justify-between items-center">
                  <div className="flex justify-center items-center">
                    <img src={ImgGed} className="w-[35px] h-[35px]" />
                    <div className="ml-2">
                      <h2 className="text-[14px] text-[#000] font-bold leading-[18px]">
                        Ged Normale
                      </h2>
                      <p className="text-[12px] leading-[16px] text-[#000]">
                        Koha e Dorezimit: ~24 Ore
                      </p>
                    </div>
                  </div>
                  <h2 className="text-[16px] text-[#000] font-semibold leading-[24px]">
                    LEK {product?.sale_price}
                  </h2>
                </div>
                <div className="mt-4 w-full flex justify-between items-start">
                  <h2 className="text-[16px] text-[#000] font-bold leading-[24px]">
                    TOTAL
                  </h2>
                  <div className="flex flex-col justify-end items-end">
                    <h2 className="text-[16px] text-[#000] font-bold leading-[24px]">
                      LEK {product?.sale_price}
                    </h2>
                    <p className="mt-2 text-[11px] text-[#000] leading-[14px] uppercase">
                      Perfito 160 Pikë By Best
                    </p>
                  </div>
                </div>
              </div>
              <button
                className=" mt-4 rounded bg-[#000] w-full h-[45px]  font-bold text-[14px] text-white uppercase"
                onClick={onSubmit}
              >
                Vazhdoni
              </button>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCartPaymethod;
