import React from 'react';
import FormText from '../FormText';

const IconBtn = (props) => {
  const {
    title,
    subtitle,
    classes = '',
    btnIcon,
    active = false,
    onAction = () => {}
  } = props;

  return (
    <div
      className={`flex flex-row border cursor-point px-3 md:px-5 py-1 md:py-2 my-3 rounded-xl cursor-pointer ${active ? 'border-primary2 bg-blue2' : 'bg-white'} ${classes}`}
      onClick={() => onAction()}
    >
      <img src={btnIcon} alt='button' />
      <div className='flex flex-col ms-5'>
        <FormText title={title} type='itemtitle-sub' />
        <FormText title={subtitle} type='custom' customClass='color-gray text-sm' />
      </div>
    </div>
  )
}

export default IconBtn;
