import React from "react";

import FormText from "../../../components/FormText";

import mapImg from '../../../assets/images/services/map.png';
import homeIcon from '../../../assets/svgs/home.svg';
import paymentIcon from '../../../assets/svgs/payment.svg';
import parkIcon from '../../../assets/svgs/park.svg';
import additionIcon from '../../../assets/svgs/addition.svg';
import bowlingIcon from '../../../assets/svgs/sport-bowling.svg';
import starIcon from '../../../assets/svgs/start_white.svg';
import forkIcon from '../../../assets/svgs/fork.svg';

const BowlingInfo = ({ data}) => {

  // const [ address, setAddress] = useState('');
  // useEffect(() => {
  //   let full_address = "no adress";
  //   if (data?.overview?.address){
  //     full_address = `${data?.overview?.address?.address_line1 ?? 'no address'}, ${data?.overview?.address?.city ?? 'no city'}, ${data?.overview?.address?.state ?? 'no state'}, ${data?.overview?.address?.country ?? 'no country'}, ${data?.overview?.address?.postcode ?? 'no postcode'}`;
  //   }
  //   setAddress(full_address);
  // }, [data])

  return (
    <div className='md:col-span-2 flex flex-col pt-8 pb-8'>
      <FormText title='Additional Information' type='itemtitle-sub' />
      <div className="flex flex-col border rounded-xl mt-4 h-full">
        <div className="flex flex-col p-5 pb-3 border border-x-0 border-t-0 border-b">
          <img src={mapImg} alt="map" />
          <div className="flex flex-row items-center mt-5">
            {/* <img src={locationIcon} alt="location" /> */}
            <FormText
              title={'46 Nikis, Athina, Greece 105 58'}
              type="subtitle-based"
              customClass="!font-bold"
            />
          </div>
        </div>
        <div className="px-5 py-2">
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={homeIcon} alt="icon" width={24} />
              <FormText title="Neighborhood" customClass="ml-2 !font-bold" type="subtitle-based" />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText title={'Athens'} type="subtitle-dark" customClass="!font-medium" />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={bowlingIcon} alt="icon" width={24} />
              <FormText title="Facilities" customClass="ml-2 !font-bold" type="subtitle-based" />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText title="Clubhouse, Pro Shop" type="subtitle-dark" customClass="!font-medium" />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={forkIcon} alt="icon" width={24} />
              <FormText title="Food & Beverage" customClass="ml-2 !font-bold" type="subtitle-based" />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText title="Yes, we offer" type="subtitle-dark" customClass="!font-medium" />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={starIcon} alt="icon" width={24} />
              <FormText title="Advance Lane" customClass="ml-2 !font-bold" type="subtitle-based" />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText title="No" type="subtitle-dark" customClass="!font-medium" />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={paymentIcon} alt="icon" />
              <FormText title="Payment options" customClass="ml-2 !font-bold" type="subtitle-based" />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText title={'MasterCard, Visa'} type="subtitle-dark" customClass="!font-medium" />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={parkIcon} alt="icon" />
              <FormText title="Parking details" customClass="ml-2 !font-bold" type="subtitle-based" />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText title={'Free'} type="subtitle-dark" customClass="!font-medium" />
            </div>
          </div>
          <div className="flex flex-row items-center my-4">
            <div className="flex flex-row items-center w-1/2 md:w-[45%]">
              <img src={additionIcon} alt="icon" />
              <FormText title="Additional" customClass="ml-2 !font-bold" type="subtitle-based" />
            </div>
            <div className="w-1/2 md:w-[55%]">
              <FormText title={'Gym, Free Trial'} type="subtitle-dark" customClass="!font-medium" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BowlingInfo;
