export const Occasions = [
  { value: 'birthday', label: "Birthday" },
  { value: 'special_event', label: "Special Event" },
  { value: 'wedding', label: "Wedding" },
  { value: 'other', label: "Other" },
];

export const signs = [
  "Sign me up to receive dining offers and news from this restaurant by email",
  "Sign me up to receive dining offers and news from VenueBoost by email",
  "Yes, I want to get text updates and reminders about my reservations",
];
