import React from "react";
import Sidebar from "../../../components/Sidebar";
import CustomerHeader from "../customer-header";
import "./index.css";
import WishlistItems from "./wishlist-items";
import { wishlistItems } from "./data";
import DropdownFilter from "../../../components/Dropdown/dropdown-filter";
import LoggedInFooter from "../../../containers/footer/logged-in-footer";

const CustomerWishlist = () => {
  return (
    <div className="flex flex-1 flex-col gap-5  ">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
        <div className="w-full p-6">
          <CustomerHeader
            description="Keep track of your favourites products. You can remove or add more at your preference."
            numberOfItems={wishlistItems.length}
            items="products"
            title="Wishlist"
          />
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 my-5">
            <WishlistItems items={wishlistItems} />
          </div>
          <div className="flex justify-center">
            <DropdownFilter showIcon={false} title="Load More" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerWishlist;
