import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestStoreAndHours = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
            BYBEST {`>`} FAQE {`>`} DYQANET DHE ORARET
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-8">
                <div>
                  <h3 className="text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    SWAROVSKI METROPOL
                  </h3>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Metropol - Qendra Tregtare, Rr. “Sami Frashëri”
                  </p>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Tiranë, Shqipëri
                  </p>
                </div>
                <div>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Tel. <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Tel. <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Orari i punës: 09:00-21:00
                  </p>
                </div>
              </div>
              <div className="mt-8 w-full grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-8">
                <div>
                  <h3 className=" text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    SWAROVSKI TEG
                  </h3>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Metropol - Qendra Tregtare, Rr. “Sami Frashëri”
                  </p>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Tiranë, Shqipëri
                  </p>
                </div>
                <div>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Tel. <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Tel. <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Orari i punës: 09:00-21:00
                  </p>
                </div>
              </div>
              <div className="mt-8  w-full grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-8">
                <div>
                  <h3 className=" text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    SWATCH METROPOL
                  </h3>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Metropol - Qendra Tregtare, Rr. “Sami Frashëri”
                  </p>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Tiranë, Shqipëri
                  </p>
                </div>
                <div>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Tel. <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Tel. <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Orari i punës: 09:00-21:00
                  </p>
                </div>
              </div>
              <div className="mt-8 w-full grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-8">
                <div>
                  <h3 className=" text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    BLUKIDS METROPOL
                  </h3>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Metropol - Qendra Tregtare, Rr. “Sami Frashëri”
                  </p>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Tiranë, Shqipëri
                  </p>
                </div>
                <div>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Tel. <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Orari i punës: 09:00-21:00
                  </p>
                </div>
              </div>
              <div className="mt-8 w-full grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-8">
                <div>
                  <h3 className=" text-[13px] leading-[21px] font-bold text-[#000] uppercase">
                    VILLEROY & BOCH METROPOL
                  </h3>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Metropol - Qendra Tregtare, Rr. “Sami Frashëri”
                  </p>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Tiranë, Shqipëri
                  </p>
                </div>
                <div>
                  <p className=" text-[12px] leading-[20px] text-[#000]">
                    Tel. <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Tel. <span className="underline">+355 69 356 1111</span>
                  </p>
                  <p className="text-[12px] leading-[20px] text-[#000]">
                    Orari i punës: 09:00-21:00
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestStoreAndHours;
