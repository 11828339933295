import React from "react";
import { CarusellImages } from "../../venue-accommodation/accommodation-services/carusell-images";

const AboutHelp = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full my-8 md:my-16 gap-10 md:gap-20">
      <div className="flex flex-col md:flex-row justify-center items-center w-full gap-5">
        <div className="flex-1 flex flex-col gap-3 md:gap-10">
          <h2 className="text-[24px] md:text-[32px] lg:text-[48px] font-medium leading-[36px] md:leading-[40px] lg:leading-[58px]">
            Helping You Find The Most Comfortable Place
          </h2>
        </div>
        <div className="flex-1 flex flex-col pl-0 md:pl-10 gap-3 md:gap-10">
          <p className="font-medium leading-6 md:leading-7 text-gray-400">
            Experience Unmatched Comfort and Modern
            Amenities in the Heart of Tirana's Most Sought-After Neighborhood
          </p>

          <p className="font-medium leading-6 md:leading-7 text-gray-400">
            Discover Ultimate Convenience and Vibrant City
            Life with Our Centrally Located, Fully Equipped Apartments in Tirana
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col items-start justify-start gap-5 md:gap-10">
        <p className="">OUR APARTMENTS</p>
        <CarusellImages isHome={false} btnLabel="Check dates" />
      </div>
    </div>
  );
};

export default AboutHelp;
