import { useState } from "react";
import BowlingHeader from "./bowling-header";
import BowlingInfo from "./bowling-info";
import BowlingPhotos from "./bowling-photos";
import BowlingBook from "./bowling-book";
import BowlingTab from "./bowling-tab";
import BowlingScheule from "./bowling-schedule";
import ReservationForm from "../../components/ReservationForm";
import { useNavigate, useParams } from "react-router-dom";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import moment from "moment";
import Enroll from "../../components/venue/enroll";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";

const VenueBowling = () => {
  const [details, setDetails] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [canEnroll, setCanEnroll] = useState(false);
  const [success, setSuccess] = useState(false);
  const { app_key: bowling_id } = useParams();
  const [reservation, setReservation] = useState({});
  const [enrollEmail, setEnrollEmail] = useState(null);
  const [enrolledCompleted, setEnrolledCompleted] = useState(false);
  const navigate = useNavigate();

  const setFormSubmited = (data) => {
    setReservation(data);
    setSubmitted(true);
  };
  useEffect(() => {
    // getDetails();
  }, []);

  // eslint-disable-next-line
  const getDetails = () => {
    localStorage.removeItem("venueData");

    WhiteLabelService.getDetails(bowling_id).then(
      (res) => {
        if (res.allowed_only_from !== "bowling") {
          navigate("/");
        } else {
          setDetails(res.venue);

          identifyVenue(bowling_id);
          trackEvent("White Label - Bowling", {
            action_category: "Page Load",
            action_outcome: "success",
            interaction_element: "screen",
            industry: "entertainment_venues",
            venue_type: "bowling",
            source: "vb_web",
          });
        }
        // bowling_venue
      },
      (error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );

        identifyVenue(bowling_id);
        trackEvent("White Label - Bowling", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "entertainment_venues",
          venue_type: "bowling",
          source: "vb_web",
        });
      }
    );
  };

  const handleSubmit = (data) => {
    const payload = {
      start_time: moment(reservation?.date + " " + reservation?.time).format(
        "YYYY-MM-DD hh:mm"
      ),
      is_for_main: false,
      guest_count: reservation?.people,
      facility_type: reservation?.reservation,
      main_guest: {
        first_name: data.name,
        last_name: data?.lastName,
        email: data?.email,
        phone: data?.phone,
      },
      notes: data?.additional,
    };
    setEnrollEmail(data?.email);
    WhiteLabelService.reservation(payload, bowling_id)
      .then((res) => {
        setSuccess(true);
        if (res.data?.can_enroll) {
          setCanEnroll(true);
        }
        identifyVenue(bowling_id);
        trackEvent("White Label - Bowling Book", {
          action_category: "Confirm Booking",
          action_outcome: "success",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "bowling",
          source: "vb_web",
        });
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong!");
        identifyVenue(bowling_id);
        trackEvent("White Label - Bowling Book", {
          action_category: "Confirm Booking",
          action_outcome: "fail",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "bowling",
          source: "vb_web",
        });
      });
  };

  const onEnrollSubmit = () => {
    const payload = {
      email: enrollEmail,
    };
    WhiteLabelService.guestEnroll(payload, bowling_id)
      .then((res) => {
        NotificationManager.success(
          res?.message ?? "Guest enrolled successfully"
        );
        identifyVenue(bowling_id);
        trackEvent("White Label - Bowling Loyalty", {
          action_category: "Guests Enrolled",
          action_outcome: "success",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "bowling",
          source: "vb_web",
        });
        setEnrolledCompleted(true);
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong!");
        identifyVenue(bowling_id);
        trackEvent("White Label - Bowling Loyalty", {
          action_category: "Guests Enrolled",
          action_outcome: "fail",
          interaction_element: "button",
          industry: "entertainment_venues",
          venue_type: "bowling",
          source: "vb_web",
        });
      });
  };

  return (
    <div className="flex flex-col">
      <BowlingHeader data={details} />
      {submitted ? (
        <div>
          <ReservationForm
            success={success}
            data={reservation}
            onBack={() => setSubmitted(false)}
            onSubmit={(data) => handleSubmit(data)}
          />
          {canEnroll ? (
            <Enroll onSubmit={onEnrollSubmit} completed={enrolledCompleted} />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="bg-white">
          <BowlingTab photos={details?.photos} />
          <div className="grid md:grid-cols-5 grid-cols-1 gap-0 md:gap-10 max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full">
            <div className="md:col-span-3 flex flex-col px-0 md:px-3">
              <BowlingPhotos photos={details?.photos ?? []} />
              <BowlingScheule
                facilities={details?.facilities}
                menu={details?.menu ?? {}}
                venueKey={bowling_id}
              />
            </div>
            <div className="md:col-span-2 flex flex-col">
              <BowlingBook onAction={(data) => setFormSubmited(data)} />
              <BowlingInfo data={details} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VenueBowling;
