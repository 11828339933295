import React from 'react';
import { getWhitelabelSeoClientConfig } from '../../../../utils/whitelabelSeoConfigs';

const BBBodyStart = ({ clientKey = 'bybest' }) => {
    const clientConfig = getWhitelabelSeoClientConfig(clientKey);

    return (
        <>
            {clientConfig.bodyScripts.start.matomo && (
                <script dangerouslySetInnerHTML={{__html: `
                    /* Matomo */
                    var _paq = window._paq = window._paq || [];
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    (function() {
                        var u="https://bybestshop.matomo.cloud/";
                        _paq.push(['setTrackerUrl', u+'matomo.php']);
                        _paq.push(['setSiteId', '1']);
                        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                        g.async=true; g.src='//cdn.matomo.cloud/bybestshop.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
                    })();
                `}} />
            )}

            {clientConfig.bodyScripts.start.matomoTagManager && (
                <script dangerouslySetInnerHTML={{__html: `
                    /* Matomo Tag Manager */
                    var _mtm = window._mtm = window._mtm || [];
                    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.async=true; g.src='https://cdn.matomo.cloud/bybestshop.matomo.cloud/container_kZwgFl4z.js'; s.parentNode.insertBefore(g,s);
                `}} />
            )}

            {/* Google Tag Manager (noscript) */}
            <noscript>
                <iframe src={`https://www.googletagmanager.com/ns.html?id=${clientConfig.analytics.googleTagManager}`}
                        height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
            </noscript>
        </>
    );
};

export default BBBodyStart;