import { ServiceBtn } from "../../../components/FormBtn";
import FormText from "../../../components/FormText";
import "../index.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/actions/cart";
import CartCheckoutButton from "../../../components/CartCheckoutButton";
import ImgPlaceholder from "../../../assets/images/img_placeholder.png";
import { isEmpty } from "../../../utils/common";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { isMobile } from "react-device-detect";

const RestaurantMenu = ({
  spanValue = 3,
  gridValue = 2,
  menuItems,
  venueKey,
  hospitalRoomId,
}) => {
  const [menu, setMenu] = useState("All Menu");
  const [menuList, setMenuList] = useState([]);
  const [menuProducts, setMenuProducts] = useState([]);

  const handleAction = (value) => {
    let products = [];
    setMenu(value);
    if (value === "All Menu") {
      for (const prop in menuItems) {
        if (Array.isArray(menuItems[prop])) {
          menuItems[prop].forEach((element) => {
            products.push(element);
          });
        }
      }
      setMenuProducts(products);
    } else {
      setMenuProducts(menuItems[value]);
    }
  };

  useEffect(() => {
    const keyArray = Object.keys(menuItems);
    if (keyArray.length > 0) {
      keyArray.unshift("All Menu");
      setMenuList(keyArray);
      handleAction(menu);
    }
  }, [menuItems, menu]);

  return (
    <div className={`md:col-span-${spanValue} mt-4 flex flex-col pt-8 md:pb-8`}>
      <div className="flex flex-row justify-between ">
        <FormText title="Menu" type="itemtitle-sub" />
        {/*<SearchBox onChange={() => {}} />*/}
      </div>
      <div className="flex flex-col mt-1 ">
        <div
          className="flex flex-row border-b"
          style={{
            overflowX: "scroll",
            minWidth: "100%",
            borderBottom: "3px solid #d9cde4",
          }}
        >
          {menuList.map((item, index) => (
            <ServiceBtn
              label={item}
              active={menu === item}
              classes={`mr-3 ${index === 0 ? "hidden md:block" : ""}`}
              onAction={() => handleAction(item)}
              alt="food"
              className="h-[75px] cursor-pointer"
            />
          ))}
        </div>
        <div className="flex flex-col">
          <div
            className={`grid md:grid-cols-${gridValue} grid-cols-1 w-full gap-4 py-3 border border-x-0 border-t-0 border-b`}
          >
            {menuProducts.map((item) => (
              <div className="flex flex-row md:w-full border border-x-0 border-t-0 border-b md:border-b-0 pb-3 md:pb-0">
                <div className="relative">
                  <img
                    alt=""
                    src={
                      isEmpty(item?.image_path)
                        ? ImgPlaceholder
                        : item?.image_path
                    }
                    className="fb-item-wl"
                  />
                  {isMobile && (
                    <AddIcon className="absolute right-1 top-1 bg-gray-500 rounded-24 text-white" />
                  )}
                </div>

                <div className="flex flex-col ml-3">
                  <FormText
                    title={item?.title}
                    type="custom"
                    customClass="text-base font-bold color-dark"
                  />
                  {item?.description ? (
                    <FormText
                      title={item?.description}
                      type="custom"
                      customClass="text-xs color-gray"
                    />
                  ) : null}
                  <FormText
                    title={"$" + item?.price}
                    type="custom"
                    customClass="text-base font-bold color-dark"
                  />
                </div>
              </div>
            ))}
            {menuItems.length == 0 ? (
              <div
                className={"align-col-middle view-terms"}
                style={{ height: "55vh" }}
              >
                <h1>No menu items</h1>
              </div>
            ) : null}
          </div>
        </div>
        <CartCheckoutButton
          venueKey={venueKey}
          type="food"
          hospitalRoomId={hospitalRoomId}
        />
      </div>
    </div>
  );
};

export default RestaurantMenu;
