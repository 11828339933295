import React, { useEffect, useState } from "react";

import IconBtn from "../../../components/FormBtn/IconBtn";
import FormText from "../../../components/FormText";

import forkIcon from '../../../assets/svgs/fork.svg';
import gymIcon from '../../../assets/svgs/gym.svg';
import events_hall from '../../../assets/svgs/events_hall.svg';
import timeIcon from '../../../assets/svgs/time.svg';
import forkGrayIcon from '../../../assets/svgs/forkGray.svg';
import dinningIcon from '../../../assets/svgs/dinning.svg';
import dressIcon from '../../../assets/svgs/dress.svg';
import locationIcon from '../../../assets/svgs/location.svg';

import foodImg from '../../../assets/images/services/food.png'
import facility1Img from '../../../assets/images/services/facility_1.png'
import facility2Img from '../../../assets/images/services/facility_2.png'
import facility3Img from '../../../assets/images/services/facility_3.png'
import facility4Img from '../../../assets/images/services/facility_4.png'
import facility5Img from '../../../assets/images/services/facility_5.png'
import facility6Img from '../../../assets/images/services/facility_6.png'
import facility7Img from '../../../assets/images/services/facility_7.png'

import facility11Img from '../../../assets/images/services/facility_11.png'
import facility12Img from '../../../assets/images/services/facility_12.png'
import facility13Img from '../../../assets/images/services/facility_13.png'
import facility14Img from '../../../assets/images/services/facility_14.png'
import facility15Img from '../../../assets/images/services/facility_15.png'
import facility16Img from '../../../assets/images/services/facility_16.png'
import facility17Img from '../../../assets/images/services/facility_17.png'
import { Button } from "antd";
import { MdOutlineAdd } from "react-icons/md";
import { addToCart } from "../../../redux/actions/cart";
import { useDispatch } from "react-redux";
import CartCheckoutButton from "../../../components/CartCheckoutButton";
import ImgPlaceholder from "../../../assets/images/img_placeholder.png"
import { isEmpty } from "../../../utils/common";

const HotelFacilities = ({ facilities, menu, venueKey }) => {
  const [category, setCategory] = useState('restaurant')
  const [type, setType] = useState('info')

  const [restarauntAvailability, setRestarauntAvailability] = useState([]);
  const [restarauntGallery, setrestarauntGallery] = useState([]);
  const [gymAvailability, setGymAvailability] = useState([]);
  const [gymGallery, setGymGallery] = useState([]);
  const [eventsAvailability, setEventsAvailability] = useState([]);
  const [eventGallery, setEventsGallery] = useState([]);

  // menu
  const [menuList, setMenuList] = useState([]);
  const [menuProducts, setMenuProducts] = useState([]);
  const dispatch = useDispatch();


  useEffect(() => {
    setRestarauntAvailability(facilities?.restaraunt?.availability ?? []);
    setrestarauntGallery(facilities?.restaraunt?.gallery ?? []);
    setGymAvailability(facilities?.gym?.availability ?? [])
    setGymGallery(facilities?.gym?.gallery ?? []);
    setEventsAvailability(facilities?.events_hall?.availability ?? []);
    setEventsGallery(facilities?.events_hall?.gallery ?? []);
  }, [facilities]);


  useEffect(() => {
    const keyArray = Object.keys(menu);
    if (keyArray.length > 0) {
      keyArray.unshift("All Menu")
      setMenuList(keyArray);
      handleSelectType(type)
    }

  }, [menu]);

  const handleSelectCategory = (value) => {
    setCategory(value);
  }

  const handleSelectType = (value) => {
    let products = [];
    setType(value);
    if (value == "All Menu") {
      for (const prop in menu) {
        if (Array.isArray(menu[prop])) {
          menu[prop].forEach(element => {
            products.push(element);
          });
        }
      }
      setMenuProducts(products);
    } else if (value != "info") {
      setMenuProducts(menu[value]);
    }
  }

  const onAddToCart = (item) => {
    // dispatch(addToCart(item, venueKey));

  }

  return (
    <div className="flex flex-col pt-8">
      <FormText title='Facilities' type='itemtitle-sub' />
      {/* Todo Griseld, added an input hours_of_operation in subtitle, which will describe a descrion of how much need to be open
          a venue, ex: Open 24*/}
      <div className="flex flex-row">
        <IconBtn
          title='Restaurant'
          // subtitle='From 10 AM to 10 PM'
          btnIcon={forkIcon}
          classes='mr-5'
          active={category === 'restaurant'}
          onAction={() => handleSelectCategory('restaurant')}
        />
        <IconBtn
          title='Gym'
          // subtitle='Open 24 Hours'
          btnIcon={gymIcon}
          classes='mr-5'
          active={category === 'gym'}
          onAction={() => handleSelectCategory('gym')}
        />
        <IconBtn
          title='Events hall'
          // subtitle='Open 24 Hours'
          btnIcon={events_hall}
          active={category === 'events_hall'}
          onAction={() => handleSelectCategory('events_hall')}
        />
      </div>
      {category === 'restaurant' ?
        <>
          <div className="flex flex-row w-full mb-3 md:mb-0">
            <div
              className={`cursor-pointer py-1 md:py-3 px-8 my-3 border rounded-xl mr-5 ${type === 'info' && 'bg-blue2'}`}
              onClick={() => handleSelectType('info')}
            >
              <FormText title='Info' type="subtitle-dark-s" />
            </div>
            <div className="flex flex-row my-3 border rounded-xl overflow-auto">
              {menuList.map((item, index) => (
                <div
                  className={`cursor-pointer py-1 md:py-3 px-4 border border-t-0 border-b-0 border-s-0 ${index === 0 ? 'rounded-l-xl' : (index + 1) == menuList.length ? 'rounded-r-xl' : ''} ${type === 'All Menu' ? 'bg-blue2' : type == item ? 'bg-blue2 border border-t-0 border-b-0' : ''}`}
                  onClick={() => handleSelectType(item)}
                >
                  <FormText title={item} type="subtitle-dark-s" customClass="whitespace-nowrap" />
                </div>
              ))}
            </div>
          </div>
          {type !== 'info' ?
            <div className="flex flex-col">
              <div className="grid md:grid-cols-2 grid-cols-1 w-full md:px-5 gap-3 py-3 border border-x-0 border-t-0 border-b">
                {menuProducts.map((item, index) => (
                  <div className="flex flex-row md:w-full border border-x-0 border-t-0 border-b md:border-b-0 pb-3 md:pb-0">
                {/* TODO Griseld, check for img height width, and rounded if correct */}
                <img src={isEmpty(item?.image_path) ? ImgPlaceholder : (item?.image_path)} className="h-[80px] w-[80px] rounded rounded-[12px]" />
                    <div className="flex flex-col ml-3">
                      <FormText
                        title={item?.title}
                        type="custom"
                        customClass="text-base font-bold color-dark"
                      />
                      {item?.description ? <FormText
                        title={item?.description}
                        type="custom"
                        customClass="text-xs color-gray"
                      /> : null}
                      <FormText
                        title={"$" + item?.price}
                        type="custom"
                        customClass="text-base font-bold color-dark"
                      />
                      {/* <Button
                      shape="default"
                      type="primary"
                      icon={<div className="flex flex-row justify-center align-center"><MdOutlineAdd size={20} color="#fff" /></div>}
                      className="mt-4"
                      style={{ backgroundColor: "#240b3b", minWidth: 35 }}
                      onClick={() => {
                        onAddToCart(item);
                      }}
                    /> */}
                    </div>
                  </div>
                ))}
                {menuProducts.length == 0 ? <div className={"align-col-middle view-terms"} style={{ height: "55vh" }}>
                  <h1>No menu items</h1>
                </div> : null}
              </div>
            </div>
            // <>
            //   <div className="grid md:grid-cols-4 grid-cols-2 gap-5 my-0 md:mt-2 md:mb-10">
            //     {menuProducts.map((item, index) => (
            //       <div key={index} className="flex flex-col border rounded-xl">
            //         <img src={isEmpty(item?.image_path) ? ImgPlaceholder : (item?.image_path)} alt="food" className="h-full" />
            //         <div className="flex flex-row justify-between align-center mx-2">
            //           <div className="flex flex-col py-2 px-4">
            //               <FormText title={item?.title} type="itemtitle-small" />
            //               <FormText title={item?.description} type="custom" customClass="text-xs color-gray" />
            //               <FormText title={"$" + item?.price} type="itemtitle-small" customClass="mt-0" />
            //           </div>
            //           <div className="flex flex-col justify-center items-center py-2 px-4">
            //           </div>
            //         </div>
            //       </div>
            //     ))}
            //   <CartCheckoutButton venueKey={venueKey} />

            //   </div>
            //   {menuProducts.length == 0 ? <div className={"align-col-middle view-terms"} style={{ height: "55vh" }}>
            //     <h1>No menu </h1>
            //   </div> : null}
            // </>
            :
            <div className="border rounded-xl px-4 py-4">
              <div className="grid md:grid-cols-4 grid-cols-3 gap-3">
                {/* <img src={facility1Img} alt='facility1' className='hidden md:block  w-full' />
                <img src={facility2Img} alt='facility2' className=' w-full' />
                <img src={facility3Img} alt='facility3' className=' w-full' />
                <img src={facility4Img} alt='facility4' className=' w-full' />
                <img src={facility5Img} alt='facility5' className='hidden md:block  w-full' />
                <img src={facility6Img} alt='facility6' className=' w-full' />
                <img src={facility7Img} alt='facility7' className=' w-full' /> */}
                {restarauntGallery.map((item, index) => {
                  return <img src={item?.photo_path} alt='facility image' className=' w-full' />
                })}
                {/* {menu.length > 8 ? <div className='border rounded-xl flex items-center justify-center '>
                  <FormText title={} />
                </div> : null} */}
              </div>
              <div className="py-4">
                <FormText
                  title={facilities?.restaraunt?.description}
                  type="subtitle-dark-s"
                />
              </div>
              {/* TODO Griseld, add information for restaurants */}
              {/* <div className="flex flex-col">
                <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                  <div className="flex flex-row items-center">
                    <img src={timeIcon} alt="icon" />
                    <FormText title="Hours of operation" type="subtitle-based-s" customClass="ms-2" />
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <FormText
                      title="Daily 11:00 am - 12:30 am"
                      type="subtitle-dark-s"
                      customClass="col-span-2"
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                  <div className="flex flex-row items-center">
                    <img src={forkIcon} alt="icon" className="ms-1" />
                    <FormText title="Cuisines" type="subtitle-based-s" customClass="ms-3" />
                  </div>
                  <div>
                    <FormText
                      title="Greek"
                      type="subtitle-dark-s"
                      customClass="col-span-2"
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                  <div className="flex flex-row items-center">
                    <img src={dinningIcon} alt="icon" />
                    <FormText title="Dining sytle" type="subtitle-based-s" customClass="ms-2" />
                  </div>
                  <div>
                    <FormText
                      title="Casual Dining"
                      type="subtitle-dark-s"
                      customClass="col-span-2"
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                  <div className="flex flex-row items-center">
                    <img src={dressIcon} alt="icon" />
                    <FormText title="Dress code" type="subtitle-based-s" customClass="ms-2" />
                  </div>
                  <div>
                    <FormText
                      title="Casual Dress"
                      type="subtitle-dark-s"
                      customClass="col-span-2"
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                  <div className="flex flex-row items-center">
                    <img src={locationIcon} alt="icon" className="ms-1" />
                    <FormText title="Additional" type="subtitle-based-s" customClass="ms-3" />
                  </div>
                  <div>
                    <FormText
                      title="Beer, Wine"
                      type="subtitle-dark-s"
                      customClass="col-span-2"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          }
        </>
        : category === 'gym' ?
          <div className="border rounded-xl px-4 py-4 md:mb-8">
            <div className="grid md:grid-cols-4 grid-cols-3 gap-3">
              {gymGallery.map((item, index) => {
                return <img src={item?.photo_path} alt='facility image' className=' w-full' />
              })}
              <div className="py-4">
                <FormText
                  title={facilities?.gym?.description}
                  type="subtitle-dark-s"
                />
              </div>
              {/* TODO Griseld */}
              {/* <div className="flex flex-col">
              <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                <div className="flex flex-row items-center">
                  <img src={timeIcon} alt="icon" />
                  <FormText title="Hours of operation" type="subtitle-based-s" customClass="ms-2" />
                </div>
                <div>
                  <FormText
                    title="24 hours"
                    type="subtitle-dark-s"
                    customClass="col-span-2"
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                <div className="flex flex-row items-center">
                  <img src={dressIcon} alt="icon" />
                  <FormText title="Dress code" type="subtitle-based-s" customClass="ms-2" />
                </div>
                <div>
                  <FormText
                    title="Sport Outfit"
                    type="subtitle-dark-s"
                    customClass="col-span-2"
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                <div className="flex flex-row items-center">
                  <img src={locationIcon} alt="icon" className="ms-1" />
                  <FormText title="Additional" type="subtitle-based-s" customClass="ms-3" />
                </div>
                <div>
                  <FormText
                    title="Sauna, Crossfit"
                    type="subtitle-dark-s"
                    customClass="col-span-2"
                  />
                </div>
              </div>*/}
            </div>
            <div className="grid grid-cols-2">
              {gymAvailability.map((item, index) => (
                <div className="flex flex-col w-full py-4">
                  <FormText title={item.day_of_week} type="itemtitle-sub-s" customClass="mb-4" />
                  <div className="flex flex-row">
                    <FormText title='Open: ' type="subtitle-based-s" customClass="md:w-1/3 w-2/5" />
                    <FormText title={item.open_time} type="subtitle-dark-s" customClass="md:w-2/3 w-3/5" />
                  </div>
                  <div className="flex flex-row">
                    <FormText title='Close: ' type="subtitle-based-s" customClass="md:w-1/3 w-2/5" />
                    <FormText title={item.close_time} type="subtitle-dark-s" customClass="md:w-2/3 w-3/5" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          :
          // TODO, implement events_hall details
          <div className="border rounded-xl px-4 py-4 md:mb-8">
            <div className="grid md:grid-cols-4 grid-cols-3 gap-3">
              {eventGallery.map((item, index) => (
                <img src={item?.photo_path} alt='facility image' className=' w-full' />
              ))}
            </div>
            <div className="py-4">
              <FormText
                title={facilities?.events_hall?.description}
                type="subtitle-dark-s"
              />
            </div>
            {/* TODO Griseld */}
            {/* <div className="flex flex-col">
              <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                <div className="flex flex-row items-center">
                  <img src={timeIcon} alt="icon" />
                  <FormText title="Hours of operation" type="subtitle-based-s" customClass="ms-2" />
                </div>
                <div>
                  <FormText
                    title="24 hours"
                    type="subtitle-dark-s"
                    customClass="col-span-2"
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                <div className="flex flex-row items-center">
                  <img src={dressIcon} alt="icon" />
                  <FormText title="Dress code" type="subtitle-based-s" customClass="ms-2" />
                </div>
                <div>
                  <FormText
                    title="Sport Outfit"
                    type="subtitle-dark-s"
                    customClass="col-span-2"
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-3 grid-cols-2 my-1.5">
                <div className="flex flex-row items-center">
                  <img src={locationIcon} alt="icon" className="ms-1" />
                  <FormText title="Additional" type="subtitle-based-s" customClass="ms-3" />
                </div>
                <div>
                  <FormText
                    title="Sauna, Crossfit"
                    type="subtitle-dark-s"
                    customClass="col-span-2"
                  />
                </div>
              </div>
            </div> */}
            <div className="grid grid-cols-2">
              {eventsAvailability.map((item, index) => (
                <div className="flex flex-col w-full py-4">
                  <FormText title={item.day_of_week} type="itemtitle-sub-s" customClass="mb-4" />
                  <div className="flex flex-row">
                    <FormText title='Open: ' type="subtitle-based-s" customClass="md:w-1/3 w-2/5" />
                    <FormText title={item.open_time} type="subtitle-dark-s" customClass="md:w-2/3 w-3/5" />
                  </div>
                  <div className="flex flex-row">
                    <FormText title='Close: ' type="subtitle-based-s" customClass="md:w-1/3 w-2/5" />
                    <FormText title={item.close_time} type="subtitle-dark-s" customClass="md:w-2/3 w-3/5" />
                  </div>
                </div>
              ))}
            </div>
          </div>
      }
    </div>
  )
}

export default HotelFacilities;
