import React, { useState, useEffect } from "react";
import { Input, Select, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import RestaurantHeader from "../restaurant-header";
import RestaurantButton from "../restaurant-button";
import { Tables } from "./reserve-table";
import { images, Occasions, signs } from "../data";
import "../index.css";
import { useDispatch, useSelector } from "react-redux";
import { loadWhiteLabel } from "../../../redux/actions/venue";
import SvgWifi from "../../../assets/svgs/resto-profile/wifi-square.svg";
import SvgTableRound from "../../../assets/svgs/resto-profile/table-round.svg";
import SvgCalendar from "../../../assets/svgs/resto-profile/calendar.svg";
import SvgTime from "../../../assets/svgs/resto-profile/time.svg";
import SvgProfile from "../../../assets/svgs/resto-profile/profile.svg";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import ReservationService from "../../../services/reservationService";
import { isEmpty } from "../../../utils/common";

const RestaurantReserve = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { app_key } = useParams();
  const { state } = useLocation();
  const venueData = useSelector((state) => state.venue);

  const [loading, setLoading] = useState(false);
  const [isPhone, setIsPhone] = useState(true);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [occation, setOccation] = useState(null);
  const [notes, setNotes] = useState('');
  const [resto_email, setRestoEmail] = useState(false);
  const [venueboost_email, setVenueBoostEmail] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [tables, setTables] = useState([]);
  const [showTables, setShowTables] = useState(false);

  useEffect(() => {
    dispatch(loadWhiteLabel(app_key));
    loadAvailableTables(app_key);
  }, [app_key, state])

  const loadAvailableTables = (app_key) => {
    ReservationService.getAvailableTables({
      start_time: moment(state?.date + " " + state?.time).format(
        "YYYY-MM-DD hh:mm:ss"
      ),
    }, app_key)
      .then((res) => {
        setTables(res.data);
        if (res.data?.length > 0) {
          setSelectedTable(res.data[0])
        }
        console.log('tables ', res)
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong while loading tables!");
      });
  }

  const onComplete = () => {
    if (selectedTable == null) {
      return NotificationManager.error("Please select a table!");
    }
    if (isPhone && isEmpty(phone)) {
      return NotificationManager.error("Please enter phone number!");
    }
    else if (!isPhone && isEmpty(email)) {
      return NotificationManager.error("Please enter email!");
    }

    const reservation = {
      start_time: moment(state?.date + " " + state?.time).format(
        "YYYY-MM-DD hh:mm"
      ),
      date: state?.date,
      time: state?.time,
      guest_count: state?.people,
      main_guest: {
        is_phone: isPhone,
        email: email,
        phone: phone,
        resto_email: (resto_email == true ? 1 : 0),
        venueboost_email: (venueboost_email == true ? 1 : 0),
        reminder: (reminder == true ? 1 : 0),
      },
      notes: notes,
      occation: occation,
      table_id: selectedTable?.id,
      table: selectedTable
    };

    setLoading(true);
    ReservationService.create(reservation, app_key)
      .then((res) => {
        setLoading(false);
        navigate(`/venue/restaurant/${app_key}/reserve/complete`, { state: reservation });
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err?.error ?? "Something went wrong!");
      });
  }


  return (
    <div className="w-full make-reservation">
      <RestaurantHeader icon={venueData?.overview?.logo} />
      <div className="w-full bg-[#fdfdfd] p-4">
        <div className="flex justify-center items-start">
          <div className="flex justify-center items-center w-[60px] h-[60px] p-2 rounded-[10px] border border-[#D8D9DB]">
            <img
              className="w-full h-full object-contain"
              src={venueData?.overview?.logo}
              alt="Restaurant"
            />
          </div>
          <div className="ml-2 flex-1">
            <h2 className="manrope-font font-bold text-20 text-primary1">
              {venueData?.overview?.venue_name}
            </h2>
            <div className="grid grid-cols-2 custom_380:flex items-center gap-1">
              <div className="flex">
                <img alt="" src={SvgCalendar} />
                <div className="ml-1 manrope-font font-semibold text-[14px] text-[#939393]">
                  {moment(state.date).format("ddd, MMM DD")}
                </div>
              </div>
              <div className="flex">
                <img alt="" src={SvgTime} />
                <div className="ml-1 manrope-font font-semibold text-[14px] text-[#939393]">
                  {state.time}
                </div>
              </div>
              <div className="flex">
                <img alt="" src={SvgProfile} />
                <div className="ml-1 manrope-font font-semibold text-[14px] text-[#939393]">
                  {state.people} people
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-start items-center mt-3">
          <div className="flex justify-center items-start flex-1 py-2 px-3 rounded-lg bg-[#C8C8F422]">
            <img alt="" src={SvgWifi} style={{width: 20, height: 20}} />
            <div className="ml-1 manrope-font font-semibold text-[12px] leading-normal text-primary1">
              We're holding this table for you for{" "}
              <span className="text-green1">4:56</span> minutes.
            </div>
          </div>
          <div
            className="ml-3 flex justify-center items-center cursor-pointer min-w-[32px] w-[32px] h-[32px] rounded-lg bg-green1"
            onClick={() => setShowTables((prev) => !prev)}
          >
            <img alt="" src={SvgTableRound} />
          </div>
        </div>
        {showTables && <Tables tables={tables} selectedTable={selectedTable} onSelect={setSelectedTable}/>}
        <div className="w-full rounded-24 bg-white p-4 shadow-md mt-4">
          <h4 className="manrope-font font-bold text-20 text-primary1">
            Dinner Details
          </h4>
          <p className="manrope-font font-medium text-[15px] leading-[17px] text-[#747474] mt-2">
            You will receive a text message to verify your account. Message &
            data rates may apply.
          </p>
          {
            isPhone ?
              <>
                <div className="manrope-font font-medium text-[15px] text-[#8F8F8F] mt-5 mb-[5px]">
                  Phone number
                </div>
                <Input
                  placeholder="EX. +1..."
                  className="w-full h-[42px] rounded-[10px] reserve-phone-input border-[#ADADAD]"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </>
              :
              <>
                <div className="manrope-font font-medium text-[15px] text-[#8F8F8F] mt-5 mb-[5px]">
                  Email
                </div>
                <Input
                  placeholder="Enter email"
                  className="w-full h-[42px] rounded-[10px] reserve-phone-input border-[#ADADAD]"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </>
          }
          <div className="manrope-font font-medium text-center text-[15px] text-[#8F8F8F] mt-2">
            or
          </div>
          <RestaurantButton style={{height: 42}} title={isPhone ? "Use email instead" : "Use phone number instead"} onClick={() => setIsPhone(pre => !pre)} />
        </div>
        <p className="manrope-font font-bold text-16 text-primary1 mt-4 mb-1">
          Reservation Details
        </p>
        <Select
          placeholder="Select an occasion (optional)"
          className="w-full h-[48px] rounded-[10px] reserve-select"
          value={occation}
          options={Occasions}
          onChange={setOccation}
        />
        <Input
          placeholder="Special requests (optional)"
          className="mt-2 w-full h-[48px] rounded-[10px] reserve-phone-input border-[#ADADAD]"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        {signs.map((text, idx) => (
          <div
            key={idx}
            className="flex justify-center items-start w-full mt-2"
          >
            <Checkbox className="reserve-checkbox"
              checked={idx == 0 ? resto_email : idx == 1 ? venueboost_email : reminder}
              onChange={(e) => {
                if (idx == 0) { setRestoEmail(e.target.checked) }
                if (idx == 1) { setVenueBoostEmail(e.target.checked) }
                if (idx == 2) { setReminder(e.target.checked) }
              }} />
            <div className="ml-2 flex-1 manrope-font text-[#939393] leading-6 font-semibold text-[14px]">
              {text}
            </div>
          </div>
        ))}
        <RestaurantButton loading={loading} onClick={onComplete} title="Complete Reservation" />
        <div className="mt-7 manrope-font text-[#939393] leading-6 font-semibold text-[14px]">
          By clicking "Complete reservation" you agree to the{" "}
          <span className="text-[#17CE8C]">VenueBoost Terms of Use</span> and{" "}
          <span className="text-[#17CE8C]">Privacy Policy</span>.
          <br />
          Message & data rates may apply. You can opt out of receiving text
          messages at any time in your account settings or by replying STOP.
        </div>
      </div>
      <div className="text-[#1A1818] text-center text-16 font-medium mb-2 px-4">
        ©2024 - All Rights Reserved️ - Powered by VenueBoost
      </div>
    </div>
  );
};

export default RestaurantReserve;
