import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ImgBlog1 from "../../assets/images/bybest/blogs/blog3.png";
import ImgBlog2 from "../../assets/images/bybest/blogs/blog1.png";
import ImgBlog3 from "../../assets/images/bybest/blogs/blog2.png";
import ImgBlog4 from "../../assets/images/bybest/blogs/blog4.png";
import ImgBlog5 from "../../assets/images/bybest/blogs/blog5.png";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const BybestHomeCarousel = () => {
  const data = [
    { title: "SWAROVSKI", img: ImgBlog1 },
    { title: "SWAROVSKI", img: ImgBlog2 },
    { title: "SWAROVSKI", img: ImgBlog3 },
    { title: "SWAROVSKI", img: ImgBlog4 },
    { title: "SWAROVSKI", img: ImgBlog5 },
  ];

  return (
    <div className="w-full ">
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        showDots={false}
        partialVisible={false}
        arrows={false}
      >
        {data.map((item, index) => (
          <div
            className="w-full h-[280px] md:h-[700px] p-5 bg-white flex  justify-center items-center"
            key={index}
          >
            <img
              src={item.img}
              className="w-[50%] h-full object-cover"
              alt=""
            />
            <div className="w-[50%] h-full flex flex-col justify-center items-center">
              <h1 className="text-[18px] md:text-[72px] text-[#000] font-semibold">
                {item.title}
              </h1>
            </div>
          </div>
        ))}
      </Carousel>
      <div className=" absolute left-[20px] top-[50%]">
        <ChevronLeft style={{ color: "#f2f2f2" }} />
      </div>
      <div className=" absolute right-[20px] top-[50%]">
        <ChevronRight style={{ color: "#f2f2f2" }} />
      </div>
    </div>
  );
};

export default BybestHomeCarousel;
