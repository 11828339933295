import React, { useState } from "react";
import { CollapseMenu } from "./collapse-menu";
import { Drawer, Input } from "antd";
import { SearchOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ImgMenu1 from "../../assets/images/bybest/menus/menu_1.jpg";
import ImgMenu2 from "../../assets/images/bybest/menus/menu_2.jpg";
import ImgMenu3 from "../../assets/images/bybest/menus/menu_3.jpg";
import ImgMenu4 from "../../assets/images/bybest/menus/menu_4.jpg";
import ImgMenu5 from "../../assets/images/bybest/menus/menu_5.png";
import { t } from "i18next";

const getTranslation = (label = "") => t(`bybest.${label}`);

export const menuItems = [
  {
    label: getTranslation("headerDrawer.menuItems.men1.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men1.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men1.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men1.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men1.menus.cat1.items.item3"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men1.menus.cat2.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men1.menus.cat2.items.item1"),
          getTranslation("headerDrawer.menuItems.men1.menus.cat2.items.item2"),
        ],
      },
    ],
    menu_image: ImgMenu1,
  },
  {
    label: getTranslation("headerDrawer.menuItems.men2.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men2.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men2.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat1.items.item3"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men2.menus.cat2.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men2.menus.cat2.items.item1"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat2.items.item2"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men2.menus.cat3.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men2.menus.cat3.items.item1"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat3.items.item2"),
          getTranslation("headerDrawer.menuItems.men2.menus.cat3.items.item3"),
        ],
      },
    ],
    menu_image: ImgMenu2,
  },
  {
    label: getTranslation("headerDrawer.menuItems.men3.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men3.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item3"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item4"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item5"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item6"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat1.items.item7"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men3.menus.cat2.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men3.menus.cat2.items.item1"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat2.items.item2"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat2.items.item3"),
          getTranslation("headerDrawer.menuItems.men3.menus.cat2.items.item4"),
        ],
      },
    ],
    menu_image: ImgMenu3,
  },
  {
    label: getTranslation("headerDrawer.menuItems.men4.label"),
    menus: [
      {
        label: getTranslation("headerDrawer.menuItems.men4.menus.item1.label"),
        link: "blog",
      },
      {
        label: getTranslation("headerDrawer.menuItems.men4.menus.item2.label"),
        link: "event",
      },
      {
        label: getTranslation("headerDrawer.menuItems.men4.menus.item3.label"),
        link: "event",
      },
    ],
  },
  {
    label: getTranslation("headerDrawer.menuItems.men5.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men5.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men5.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat1.items.item3"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat1.items.item4"),
        ],
      },
      {
        category: getTranslation(
          "headerDrawer.menuItems.men5.menus.cat2.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men5.menus.cat2.items.item1"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat2.items.item2"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat2.items.item3"),
          getTranslation("headerDrawer.menuItems.men5.menus.cat2.items.item4"),
        ],
      },
    ],
    menu_image: ImgMenu4,
  },
  {
    label: getTranslation("headerDrawer.menuItems.men6.label"),
    menus: [
      {
        category: getTranslation(
          "headerDrawer.menuItems.men6.menus.cat1.category"
        ),
        items: [
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item1"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item2"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item3"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item4"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item5"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item6"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item7"),
          getTranslation("headerDrawer.menuItems.men6.menus.cat1.items.item8"),
        ],
      },
    ],
    menu_image: ImgMenu5,
  },
  {
    label: getTranslation("headerDrawer.menuItems.men7.label"),
    menus: [],
  },
];

const HeaderDrawer = ({ open, onClose, app_key }) => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState();

  return (
    <Drawer
      placement={"left"}
      closable={false}
      onClose={onClose}
      open={open}
      width={"100%"}
      mask={false}
      zIndex={5}
      bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <div className="w-full px-4 mt-[94px]">
        <Input
          placeholder={getTranslation("headerDrawer.searchPlaceholder")}
          prefix={
            <SearchOutlined fontSize={"small"} style={{ color: "#888" }} />
          }
          className="w-full border-none h-11 bg-[#e9e9e9]"
          styles={{ input: { backgroundColor: "#e9e9e9" } }}
        />
      </div>
      <div className="w-full flex flex-col justify-center items-center mt-4 mb-10">
        <CollapseMenu
          menu={{ label: getTranslation("headerDrawer.login"), items: [] }}
          onSelect={() => {
            onClose();
            navigate(`/shop/${app_key}/login`);
          }}
        />
        {menuItems.map((menu) => (
          <CollapseMenu
            key={menu.label}
            menu={menu}
            isOpened={menu.label === activeMenu?.label}
            onSelect={() => {
              if (menu.label === activeMenu?.label) {
                setActiveMenu(null);
              } else {
                setActiveMenu(menu);
              }
            }}
            onCloseDrawer={onClose}
          />
        ))}
        <div className="w-full px-4 bg-[#f4f4f4]">
          <div className="py-3">
            <p className="text-[#000] text-[14px] w-full">
              {getTranslation("headerDrawer.language")}:{" "}
              <span className="font-bold">AL</span>
            </p>
          </div>
          <div className="py-3">
            <p className="text-[#000] text-[14px] w-full">
              {getTranslation("headerDrawer.currency")}:{" "}
              <span className="font-bold">LEK</span>
            </p>
          </div>
        </div>
        <CollapseMenu
          menu={{ label: getTranslation("headerDrawer.myClub"), items: [] }}
          onSelect={() => {
            onClose();
            navigate(`/shop/${app_key}/my-club`);
          }}
        />
      </div>
    </Drawer>
  );
};

export default HeaderDrawer;
