import React from "react";
import FormText from "../../../components/FormText";
import accomodationImg from "../../../assets/images/accomodation-2.jpg";
import { SelectApartment } from "./select-apartment";

const GenericAccommodationHeader = ({
  TagsButtonData,
  data,
  title,
  selectApartments,
}) => {
  return (
    <div className="md:bg-gray2 bg-white flex flex-col">
      <div className="flex items-center justify-center md:gap-10 md:py-10 w-full relative overflow-hidden h-[300px] lg:h-[600px]">
        <div className="flex flex-col items-start justify-start z-10">
          <FormText
            title={title}
            type="title-whitelabel"
            customClass="mt-5 !text-white !font-medium text-[24px] lg:!text-[74px] max-w-[1100px] lg:!leading-[111px]"
            customStyle={{ textAlign: "center" }}
          />

          <div
            className="hidden md:flex flex-row flex-wrap mt-4"
            style={{ overflowY: "auto", maxWidth: "100%" }}
          >
            {data?.additional_information?.main_tag && (
              <div
                className={`bg-white px-3 md:px-4 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{data?.additional_information?.main_tag}</b>
                </span>
              </div>
            )}
            {data?.additional_information?.tags?.map((item, index) => (
              <div
                key={index}
                className={`bg-white px-3 md:px-4 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{item}</b>
                </span>
              </div>
            ))}
          </div>

          <div
            className="md:hidden flex flex-row flex-wrap"
            style={{ overflowY: "auto", maxWidth: "100%" }}
          >
            {data?.additional_information?.main_tag && (
              <div
                className={`bg-white px-3 md:px-3 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{data?.additional_information?.main_tag}</b>
                </span>
              </div>
            )}
            {data?.additional_information?.tags?.map((item, index) => (
              <div
                key={index}
                className={`bg-white px-3 md:px-3 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{item}</b>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="block w-full absolute top-0 lg:top-[-150px]">
          {" "}
          <img
            src={accomodationImg}
            alt="accommodation"
            className="w-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
      </div>
      {selectApartments && <SelectApartment />}
    </div>
  );
};

export default GenericAccommodationHeader;
