import React, { useState } from "react";
import OverviewFoodGuestsManagement from "../../assets/images/who-we-serve/food-guests-management.webp";
import FoodMarketingAutomation from "../../assets/images/who-we-serve/marketing-automation-food.webp";
import AccommodationMarketingAutomation from "../../assets/images/who-we-serve/marketing-automation-accomodation.webp";
import RetailMarketingAutomation from "../../assets/images/who-we-serve/marketing-automation-retail.webp";
import AccomodationBooking from "../../assets/images/who-we-serve/accomodation-booking.webp";
import AccomodationStaff from "../../assets/images/who-we-serve/accomodation-staff-management.webp";
import AccomodationLoyalty from "../../assets/images/who-we-serve/accomodation-staff-loyalty.webp";
import OverviewRetailSurveyRatings from "../../assets/images/who-we-serve/retail-survey-ratings.webp";
import OverviewRetailStaffManagement from "../../assets/images/who-we-serve/retail-staff-management.webp";
import OverviewRetailStaffLoyalty from "../../assets/images/who-we-serve/retail-loyalty.webp";
import EvBookingsManagement from "../../assets/images/who-we-serve/ev-bookings-management.webp";
import EvLoyalty from "../../assets/images/who-we-serve/ev-loyalty.webp";
import EvPaymentLinks from "../../assets/images/who-we-serve/ev-payment-links.webp";
import EvGuestsSurveys from "../../assets/images/who-we-serve/ev-guest-surveys.webp";
import EvStaffManagement from "../../assets/images/who-we-serve/ev-staff-management.webp";
import CircleTick from "./components/CircleTick";
import { t } from "i18next";
import OverviewImg1 from "../../assets/images/who-we-serve/overviewimg1.webp";
import OverviewImg3 from "../../assets/images/who-we-serve/overviewimg3.webp";
import OverviewImg5 from "../../assets/images/who-we-serve/overviewimg5.webp";
import OverviewImg7 from "../../assets/images/who-we-serve/overviewimg7.webp";
import AccommodationGuestsManagement from "../../assets/images/who-we-serve/accomodation-guests.webp";
import OverviewRetailOrders from "../../assets/images/who-we-serve/retail-orders.webp";
import "./index.css";
import { Seo } from "../../components/Seo";

const featureText = (label) => t(`feature.${label}`);
const tabText = (label) => t(`feature.tabs.${label}`);
const componentText = (label) => t(`feature.component.${label}`);
const retailText = (label) => t(`who_we_serve.retail_overview.tabs.${label}`);

const FreeTrial = (props) => {
  const className = `mt-4 ${props.className}`;
  return (
    <p className={className}>
      {" "}
      <a href="/get-started" className={"link-button"}>
        {featureText("UnlockFreeTrial")} {"->"}
      </a>
    </p>
  );
};
const Features = (props) => {
  // Food & Beverage
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      title: "Reservations",
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={OverviewImg1} />
          </div>

          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center w-full md:w-[80%]">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <div>
                  <p className="text-14 md:text-16   text-[#252A35]  font-extrabold">
                    {t("who_we_serve.food_industry.streamlined_reservation")}
                  </p>
                  <p className="text-14 md:text-16 font-medium text-[#252A35]  ">
                    {t(
                      "who_we_serve.food_industry.streamlined_reservation_sub"
                    )}{" "}
                  </p>
                </div>
              </div>

              <div className="flex flex-row items-center w-full md:w-[80%]">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <div>
                  <p className="text-14 md:text-16   text-[#252A35]  font-extrabold">
                    {t("who_we_serve.food_industry.rservation_booking")}{" "}
                  </p>
                  <p className="text-14 md:text-16 font-medium text-[#252A35]  ">
                    {t("who_we_serve.food_industry.rservation_booking_sub")}
                  </p>
                </div>
              </div>

              <div className="flex flex-row items-center w-full md:w-[80%]">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <div>
                  <p className="text-14 md:text-16   text-[#252A35]  font-extrabold">
                    {featureText("data")}
                  </p>
                  <p className="text-14 md:text-16 font-medium text-[#252A35]  ">
                    {featureText("BookingTrendAnalytics")}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mt-6">
              {featureText("CentralizedReservations")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: "Staff Management",
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={OverviewImg3} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {featureText("EasierSchedulingPayroll")}
            </p>

            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {featureText("VenueBoostEmpowers")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {featureText("CreateAndUpdateSchedules")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {featureText("AutomateWageAndTipCalculations")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {featureText("TrackHoursAndStaffPerformance")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {featureText("IdentifySchedulingGapsAndBottlenecks")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {featureText("EnsureSmoothOperationsAndCoordination")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-middle mt-8 text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-0">
              {featureText("CentralizeStaffManagement")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("loyality"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={OverviewImg5} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("RewardYourGuestsAndMotivateReturnVisits")}
            </p>
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {t("who_we_serve.food_industry.loyalty_sub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("TailorTiersAndBenefits")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("TrackMemberProfilesAndPurchaseHistory")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("SendPersonalizedRewardsAndOffers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("AnalyzeEngagementAndActivity")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mt-8">
              {tabText("StrengthenRelationshipsWithRegulars")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("payment_links"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-2 md:mt-0">
            <img alt="" src={OverviewImg7} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("SimplifiedOnlineReservationsPayments")}
            </p>

            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {t("who_we_serve.food_industry.payment_links_sub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CreateSecureOnlineReservationPaymentLinks")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("OrganizedListToTrackPaymentLinks")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("InsightsIntoTransactionVolumesAndRevenue")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("SmoothConvenientPaymentProcessForGuests")}
                </span>
              </div>
            </div>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: t("who_we_serve.accommodation_industry.guest_management"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("GuestsManagement")}
            </p>
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {t("who_we_serve.food_industry.guest_management_sub")}
            </p>

            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("GuestManagementSuite")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("GuestManagementPreference")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("GuestManagementLoyalty")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CustomLoyaltyProgramsWithSpecialRewards")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px]">
              Deliver exceptional experiences by truly understanding your
              guests. VenueBoost provides the tools to engage, retain, and
              attract customers.
            </p>
            <FreeTrial className="mb-4" />
          </div>
        </div>
      ),
    },
    {
      title: t(
        "who_we_serve.sport_entertainment_overview.tabs.MarketingAutomation"
      ),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={FoodMarketingAutomation} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("MarketingAutomation")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CreateTargetedEmailCampaignsBasedOnGuestBehavior")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("SendPersonalizedOffersAndRecommendations")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("MeasureEffectivenessAndOptimizeStrategies")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px]">
              {tabText(
                "StreamlineYourMarketingAcrossIndustriesWithPowerfulAutomation"
              )}
            </p>
            <FreeTrial className="mb-4" />
          </div>
        </div>
      ),
    },
  ];

  // Accommodation
  const [activeTabAccommodation, setActiveTabAccommodation] = useState(0);
  const tabsAccommodation = [
    {
      title: tabText("BookingsManagement"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={AccomodationBooking} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0 flex-col">
            <p className="text-[#161C2D] text-12 md:text-18 font-medium leading-[21px] md:leading-[30px] mb-4">
              {tabText("OptimizedAccommodationBookings")}
            </p>
            <p className="text-[#161C2D] text-12 md:text-18 font-medium leading-[21px] md:leading-[30px] mb-4">
              {tabText("StreamlineYourBookings")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14 md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("EasilyCreateBookingsSpecifyingDatesRoomsGuests")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14 md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("ReservationDashboardToTrackModifyOrganize")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14 md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("RSVPFeaturesForSmoothCommunication")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14 md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("GainInsightsIntoOccupancyAndTrafficPatterns")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] text-12 md:text-18 font-medium leading-[21px] md:leading-[30px] mt-8">
              {tabText("SayGoodbyeToIncohesiveSystems")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("SimplifiedSchedulingAndPayroll"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={AccomodationStaff} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] text-12 md:text-18 font-medium leading-[21px] md:leading-[30px] mb-4">
              {t("who_we_serve.accommodation_industry.staff_managment_sub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14 md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("AutomatedSchedulingBasedOnOccupancy")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14 md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("TrackTimeOffRequestsAndVacationDays")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14 md:text-18 font-bold text-[#240B3B] inline">
                  {tabText(
                    "StreamlinedPayrollProcessingWithEasyWageCalculations"
                  )}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14 md:text-18 font-bold text-[#240B3B] inline">
                  {tabText(
                    "PerformanceMonitoringToIdentifyCoachingOpportunities"
                  )}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14 md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("EnsureExceptionalServiceThroughStaffCoordination")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] text-12 md:text-18 font-medium leading-[21px] md:leading-[30px] mt-8">
              {tabText("ForgetStaffSchedulingStress")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("loyalityAndRetention"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={AccomodationLoyalty} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("BuildingGuestLoyaltyRetention")}
            </p>
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {t("who_we_serve.accommodation_industry.loyalty_sub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("BuildSpecialTiersAndBenefitsForLoyalMembers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("ViewMemberProfilesAndTrackPurchaseHistory")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("SendTailoredRewardsAndOffers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("AnalyzeProgramActivityAndEngagement")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mt-8 ">
              {tabText("StrengthenRelationshipsWithReturnGuests")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("Management"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={AccommodationGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("Experience")}
            </p>
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {t("who_we_serve.accommodation_industry.guest_management_sub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {t(
                    "who_we_serve.accommodation_industry.guest_management_suite"
                  )}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {t(
                    "who_we_serve.accommodation_industry.guest_management_preference"
                  )}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {t(
                    "who_we_serve.accommodation_industry.guest_management_loyalty"
                  )}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("Programs")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mt-8">
              {tabText("Exceptional")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("MarketingAutomationTitle"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={AccommodationMarketingAutomation} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("MarketingAutomationDescription")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CreateCampaigns")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("SendOffers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("MeasureEffectiveness")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px]">
              {tabText("MarketingAutomationBenefits")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
  ];

  // Entertainment Venues
  const [activeTabEVenues, setActiveTabEVenues] = useState(0);
  const tabsEVenues = [
    {
      title: tabText("BookingsManagementTitle"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={EvBookingsManagement} />
          </div>

          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
              {tabText("BookingsManagementDescription")}
            </p>

            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
              {tabText("BookingsManagementDetails")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CreateBookings")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("ViewDashboard")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("RSVPFeatures")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("GainInsights")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mt-8">
              {tabText("BookingsManagementBenefits")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("StaffManagementTitle"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={EvStaffManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("StaffManagementDescription")}
            </p>
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("StaffManagementSub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("AutomatedScheduling")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("TrackTimeOff")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("IntegratedPayroll")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("PerformanceMonitoring")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CoordinateStaff")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mt-8">
              {tabText("StaffManagementBenefits")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },

    {
      title: tabText("loyalty"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={EvLoyalty} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("BuildingGuestLoyaltyRetention")}
            </p>
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {t("who_we_serve.sport_and_entertainment_industry.loyalty_sub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("BuildSpecialTiersLoyalMembers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("ViewPurchaseHistoryActivityTrends")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("SendTailoredRewardsOffers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("AnalyzeEngagementAcrossTiers")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mt-8">
              {tabText("StrengthenRelationshipsReturnGuests")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("PaymentLinksTitle"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={EvPaymentLinks} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("PaymentLinksDescription")}
            </p>
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("PaymentLinksSub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("SecurelyAcceptPurchases")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("OrganizedLinkList")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("InsightsIntoSales")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("IntegratedBookingPayments")}
                </span>
              </div>
            </div>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("GuestSurveysTitle"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={EvGuestsSurveys} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("GuestSurveysDescription")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("PowerfulSurveys")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CustomizedGuestSurveys")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CollectRatings")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("GainInsights")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("DataDrivenImprovements")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px]">
              {tabText("ListenToGuests")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
  ];

  // Retail Management
  const [activeTabRetail, setActiveTabRetail] = useState(0);
  const tabsRetail = [
    {
      title: tabText("OrderManagement"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={OverviewRetailOrders} />
          </div>

          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
              {tabText("AutomatedScheduling")}
            </p>

            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
              {tabText("SkipTheHassles")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("RealTimeOrderNotifications")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("IntuitiveInterfaceToUpdateOrders")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("TrackOrderHistoryAndCustomerDetails")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("EnsureFastFulfillmentAndShipping")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("FocusOnCustomersNotPaperwork")}
                </span>
              </div>
            </div>

            {/* <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mt-8">
              {tabText("UnifiedOrderManagementWorkflows")}
            </p> */}
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("StaffManagement"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={OverviewRetailStaffManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("SimplifiedRetailStaffManagement")}
            </p>

            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("RetailStaffManagementSub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("AutomatedScheduling")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("ManageTimeOffRequests")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("PayrollProcessing")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("TrackSalesPerformance")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("IdentifyCoachingOpportunities")}
                </span>
              </div>
            </div>

            <p className="text-[#161C2D] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mt-8">
              {tabText("SkipTheHassles")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: "Loyalty and Retention",
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
            <img alt="" src={OverviewRetailStaffLoyalty} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("BuildingCustomerLoyaltyandRetention")}
            </p>
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {t("who_we_serve.retail_industry.loyalty_sub")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("SegmentMembersIntoTailoredLoyaltyTiers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("SendPersonalizedRewardsAndOffers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("TrackPurchaseHistoryAndShoppingHabits")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("AnalyzeMembershipEngagement")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mt-8">
              {tabText("StrengthenRelationshipsWithRegularBuyers")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: tabText("CustomerSurveysAndRatings"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewRetailSurveyRatings} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("gatherValuableFeedback")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("VenueBoostNowOffersPowerfulSurveysAndRatings")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CreateCustomizedCustomerSurveys")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("CollectRatingsAndReviewsToGaugeSatisfaction")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("GainInsightsIntoPreferencesAndOpinions")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {tabText("MakeInformedImprovementsUsingDataDrivenDecisions")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px]">
              {tabText("ListenToYourCustomersAndOptimizeOfferings")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
    {
      title: "Marketing Automation",
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-6 md:mt-[18px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={RetailMarketingAutomation} />
          </div>
          <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
            <p className="text-[#161C2D] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("TakeYourMarketing")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {retailText("TargetedEmailCampaigns")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {retailText("PersonalizedOffers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
                <span className="text-12  md:text-18 font-bold text-[#240B3B] inline">
                  {retailText("MeasureEffectiveness")}
                </span>
              </div>
            </div>
            <p className="text-[#161C2D] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px]">
              {retailText("StreamlineMarketing")}
            </p>
            <FreeTrial />
          </div>
        </div>
      ),
    },
  ];

  const currentUrl = "https://venueboost.io/features";
  const seoTitle = tabText("UnlockVB");
  const seoDescription = tabText("SeoDescription");

  return (
    <div className={""}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />
      <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16">
        <div className={"md:px-8 px-4 mt-0 md:mt-0"}>
          <div className="flex flex-col align-middle">
            <p className="text-[#666A73] text-14 md:text-20 leading-5 md:leading-7 font-semibold text-center w-[90%] md:w-[80%] mb-6  ">
              <div className="flex flex-col align-middle">
                <h1 className="text-[#312A37] text-[32px] md:text-40 leading-[60px] font-extrabold text-center pt-10 pb-3 md:pt-20 md:pb-6">
                  {componentText("FoodBeverageFeatures")}
                </h1>
                <p className="text-[#666A73] text-14 md:text-20 leading-5 md:leading-7 font-semibold text-center w-[90%] md:w-[100%] mb-6  ">
                  {componentText("VenueBoostDescription")}
                </p>
              </div>
            </p>
          </div>
          <div
            className="tabs-container features-food"
            role="tablist"
            aria-label="Products"
            data-tracking-container="Product Tabs"
          >
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`tab js-tab ${
                  activeTab === index ? "is-active" : ""
                }`}
                role="tab"
                aria-selected={activeTab === index}
                aria-controls={`tab-content-${index}`}
                tabIndex="0"
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div
            className="tabs-content features-food"
            data-tracking-container="Tab Content"
          >
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab tab-content__panel js-tab-panel ${
                  activeTab === index ? "is-active" : ""
                }`}
                role="tabpanel"
                id={`tab-content-${index}`}
                aria-labelledby={`tab-${index}`}
                aria-hidden={activeTab !== index}
                tabIndex="0"
              >
                {tab.content}
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            <a
              className="text-white  features-food wws-learn-more mb-10 md:mb-20 cursor-pointer rounded-lg px-9 py-4 text-sm font-bold m-auto"
              href="/who-we-serve/food-and-beverage"
            >
              {componentText("CheckAllFeaturesButton")}
            </a>
          </div>
        </div>
      </div>
      <div className="bg-[#F3F3F3] ">
        <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16">
          <div className={"md:px-8 px-4 mt-5 md:mt-5"}>
            <div className="flex flex-col align-middle">
              <p className="text-[#666A73] text-14 md:text-20 leading-5 md:leading-7 font-semibold text-center w-[90%] md:w-[80%] mb-6  ">
                <div className="flex flex-col align-middle">
                  <h1 className="text-[#312A37] text-[32px] md:text-40 leading-[60px] font-extrabold text-center pt-10 pb-3 md:pt-20 md:pb-6">
                    {componentText("AccommodationFeature")}
                  </h1>
                  <p className="text-[#666A73] text-14 md:text-20 leading-5 md:leading-7 font-semibold text-center w-[90%] md:w-[100%] mb-6  ">
                    {componentText("AccommodationFeaturesText")}
                  </p>
                </div>
              </p>
            </div>
            <div
              className="tabs-container accommodation-features"
              role="tablist"
              aria-label="Products"
              data-tracking-container="Product Tabs"
            >
              {tabsAccommodation.map((tab, index) => (
                <button
                  key={index}
                  className={`tab js-tab ${
                    activeTabAccommodation === index ? "is-active" : ""
                  }`}
                  role="tab"
                  aria-selected={activeTabAccommodation === index}
                  aria-controls={`tab-content-${index}`}
                  tabIndex="0"
                  onClick={() => setActiveTabAccommodation(index)}
                >
                  {tab.title}
                </button>
              ))}
            </div>
            <div
              className="tabs-content accommodation-features"
              data-tracking-container="Tab Content"
            >
              {tabsAccommodation.map((tab, index) => (
                <div
                  key={index}
                  className={`tab tab-content__panel js-tab-panel ${
                    activeTabAccommodation === index ? "is-active" : ""
                  }`}
                  role="tabpanel"
                  id={`tab-content-${index}`}
                  aria-labelledby={`tab-${index}`}
                  aria-hidden={activeTabAccommodation !== index}
                  tabIndex="0"
                >
                  {tab.content}
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <a
                className="text-white  accommodation-features wws-learn-more mb-10 md:mb-20 cursor-pointer rounded-lg px-9 py-4 text-sm font-bold m-auto"
                href="/who-we-serve/accommodation"
              >
                {componentText("CheckAllAccommodationFeaturesButton")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16">
        <div className={"md:px-8 px-4 mt-5 md:mt-5"}>
          <div className="flex flex-col align-middle">
            <p className="text-[#666A73] text-14 md:text-20 leading-5 md:leading-7 font-semibold text-center w-[90%] md:w-[80%] mb-6  ">
              <div className="flex flex-col align-middle">
                <h1 className="text-[#312A37] text-[32px] md:text-40 leading-[60px] font-extrabold text-center pt-10 pb-3 md:pt-20 md:pb-6">
                  {componentText("EntertainmentVenuesFeatures")}
                </h1>
                <p className="text-[#666A73] text-14 md:text-20 leading-5 md:leading-7 font-semibold text-center w-[90%] md:w-[100%] mb-6  ">
                  {componentText("EntertainmentVenuesSolutions")}
                </p>
              </div>
            </p>
          </div>
          <div
            className="tabs-container venues-features"
            role="tablist"
            aria-label="Products"
            data-tracking-container="Product Tabs"
          >
            {tabsEVenues.map((tab, index) => (
              <button
                key={index}
                className={`tab js-tab ${
                  activeTabEVenues === index ? "is-active" : ""
                }`}
                role="tab"
                aria-selected={activeTabEVenues === index}
                aria-controls={`tab-content-${index}`}
                tabIndex="0"
                onClick={() => setActiveTabEVenues(index)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div
            className="tabs-content venues-features"
            data-tracking-container="Tab Content"
          >
            {tabsEVenues.map((tab, index) => (
              <div
                key={index}
                className={`tab tab-content__panel js-tab-panel ${
                  activeTabEVenues === index ? "is-active" : ""
                }`}
                role="tabpanel"
                id={`tab-content-${index}`}
                aria-labelledby={`tab-${index}`}
                aria-hidden={activeTabEVenues !== index}
                tabIndex="0"
              >
                {tab.content}
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            <a
              className="text-white venues-features wws-learn-more mb-10 md:mb-20 cursor-pointer rounded-lg px-9 py-4 text-sm font-bold m-auto"
              href={`/who-we-serve/entertainment-venues`}
            >
              {componentText("CheckAllEntertainmentVenuesFeatures")}
            </a>
          </div>
        </div>
      </div>
      <div className="bg-[#F3F3F3] ">
        <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16">
          <div className={"md:px-8 px-4 mt-5 md:mt-5"}>
            <div className="flex flex-col align-middle">
              <p className="text-[#666A73] text-14 md:text-20 leading-5 md:leading-7 font-semibold text-center w-[90%] md:w-[80%] mb-6  ">
                <div className="flex flex-col align-middle">
                  <h1 className="text-[#312A37] text-[32px] md:text-40 leading-[60px] font-extrabold text-center pt-10 pb-3 md:pt-20 md:pb-6">
                    {componentText("RetailManagementFeatures")}
                  </h1>
                  <p className="text-[#666A73] text-14 md:text-20 leading-5 md:leading-7 font-semibold text-center w-[90%] md:w-[100%] mb-6  ">
                    {componentText("RetailManagementDescription")}
                  </p>
                </div>
              </p>
            </div>
            <div
              className="tabs-container retail-features"
              role="tablist"
              aria-label="Products"
              data-tracking-container="Product Tabs"
            >
              {tabsRetail.map((tab, index) => (
                <button
                  key={index}
                  className={`tab js-tab ${
                    activeTabRetail === index ? "is-active" : ""
                  }`}
                  role="tab"
                  aria-selected={activeTabRetail === index}
                  aria-controls={`tab-content-${index}`}
                  tabIndex="0"
                  onClick={() => setActiveTabRetail(index)}
                >
                  {tab.title}
                </button>
              ))}
            </div>
            <div
              className="tabs-content retail-features"
              data-tracking-container="Tab Content"
            >
              {tabsRetail.map((tab, index) => (
                <div
                  key={index}
                  className={`tab tab-content__panel js-tab-panel ${
                    activeTabRetail === index ? "is-active" : ""
                  }`}
                  role="tabpanel"
                  id={`tab-content-${index}`}
                  aria-labelledby={`tab-${index}`}
                  aria-hidden={activeTabRetail !== index}
                  tabIndex="0"
                >
                  {tab.content}
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <a
                className="text-white retail-features wws-learn-more mb-10 md:mb-20 cursor-pointer rounded-lg px-9 py-4 text-sm font-bold m-auto"
                href={`/who-we-serve/retail-management`}
              >
                {componentText("CheckAllRetailFeaturesButton")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
