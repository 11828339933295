import starIcon from "../../../assets/svgs/starIcon.svg";

export const carouselItems = [
  {
    img: starIcon,
    name: "Maurice, Netherlands",
    review:
      "Beautiful view from the balcony! We really liked the check-in process and the kindness of the people there! Ejona was very kind and helpful! The bed was very nice and the apartment very spacious. Highly recommend.",
  },
  {
    img: starIcon,
    name: "Marvin, Italy",
    review:
      "It was pretty great, this apartment really surprised us. It was very spacious for two people and very modern. The check in was seamless and we could pay by card (4 PM). The kitchen had everything we needed for our stay. The TV was a very nice...",
  },
  {
    img: starIcon,
    name: "Fatimah, Durrës, Albania",
    review:
      "This is a really cool place to stay at. It’s a studio, but it’s very large and very nicely arranged as such. The view from the balcony is really pretty and the king sized bed is extremely comfortable! Ejona was very accommodating towards our check in and checkout time. I’d def recommend this place for a couple nights stay in Tiranë!",
  },
  {
    img: starIcon,
    name: "Denise",
    review:
      "We had a lovely few days in Ejona's apartment, it was extremely clean, comfortable and well equipped with a lovely sunny balcony to sit out on however it is in central Tirana so there is obviously traffic noise but this didn't bother us. Ideally situated to explore Tirana and pick up buses to other parts of Albania. Ejona was quick to respond and very helpful. We would definitely recommend this apartment.",
  },
  {
    img: starIcon,
    name: "Ema, Ireland",
    review:
      "This apartment was so comfortable and had so many amenities. A washing machine, clothes horse, dishwasher, nice shower, comfy bed, balcony. Good kitchen. The location was very convenient. Was more spacious than expected.",
  },
  {
    img: starIcon,
    name: "Alexa, Germany",
    review:
      "We had a very spacious apartment with a lovely view over Tirana. Everything needed was there. We would have liked to stay longer.",
  },
];
