import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestReturnPolicy = () => {
  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
            BYBEST {`>`} PAGE {`>`} PRODUCT EXCHANGE
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <p className="text-[13px] leading-[20px] text-[#000] ">
                By Best's top priority is to keep the customer satisfied.
              </p>
              <p className="mt-[13px] text-[13px] leading-[20px] text-[#000] ">
                The customer can change the product within 14 days from the day
                of receiving the order.
              </p>
              <ul className="mt-[13px] list-disc pl-5">
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  The customer must have the original purchase invoice which
                  must be attached to that exchange package.
                </li>
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  The client assumes the postage costs.
                </li>
                <li className="text-[13px] leading-[20px] text-[#000] ">
                  The client contacts the customer service of the company By
                  Best <a className=" underline">customerservice@bybest.shop</a>{" "}
                  to complete the switching process in:
                </li>
              </ul>
              <p className="mt-[13px] text-[12px] leading-[20px] text-[#000] ">
                Email:{" "}
                <span className="underline">customerservice@bybest.shop</span>
              </p>
              <p className="text-[13px] leading-[20px] text-[#000] ">
                Nr Tel: <span className="underline">+355 68 805 2000</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestReturnPolicy;
