import React from "react";
import PromotionSummary from "./promotion-summary";
import CustomCalendar from "./calendar";
import "./index.css";

const PromotionCalendar = () => {
  return (
    <div className="px-[15px] w-full boxshadow border-[1px] border-[#EAECF0] rounded-[8px] bg-white ">
      <div className="w-full">
        <CustomCalendar />
      </div>

      <div className="flex flex-col gap-4">
        <PromotionSummary
          title="VILLEROY20"
          description="24.57% Expires on 12/08/2024"
          promo="15% Off"
        />

        <hr className="border-[1px] border-[#E5E7EB]" />

        <PromotionSummary
          title="bbvrtc707hv"
          description="24.57% Expires on 12/08/2024"
          promo="20% Off"
        />
      </div>
    </div>
  );
};

export default PromotionCalendar;
