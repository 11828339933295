export const arrowDownIcon = (
  <svg
    width="11"
    height="7"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.51078 6.20113L0.000493954 1.69085L1.12787 0.563476L5.07447 4.51008L9.02107 0.563477L10.1484 1.69085L5.63815 6.20113C5.48864 6.3506 5.28588 6.43457 5.07447 6.43457C4.86305 6.43457 4.66029 6.3506 4.51078 6.20113Z"
      fill="#344054"
    />
  </svg>
);
