import apiFactory from "../utils/apiFactory";
import {
  API_ACCOMMODATION,
  API_AFFILIATES,
  API_RETAIL,
  API_TABLES,
  API_WHITE_LABEL,
} from "../constants/apiConfig";

export default class ReservationService {
  static create = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/reservation`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  static getAvailableTables = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_TABLES.path}/available`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  static getBookTimes = async (venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/reservation-times`,
      query: `venue_app_key=${venue_app_key}`,
      method: "GET",
    };
    return apiFactory(api);
  };
  static getProductDetails = async (id, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_RETAIL.path}/product-details/${id}`,
      query: `venue_app_key=${venue_app_key}`,
      method: "GET",
    };
    return apiFactory(api);
  };
}
