import React from "react";
import Sidebar from "../../../components/Sidebar";
import "./index.css";
import CustomerHeader from "../customer-header";
import ChangePassword from "./change-password";
import LoginSecurityActivities from "./login-security-activities";
import LoggedInFooter from "../../../containers/footer/logged-in-footer";

const LoginSecurity = () => {
  return (
    <div className="flex flex-1 flex-col gap-5 ">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
        <div className="p-6 gap-2 flex flex-col">
          <CustomerHeader
            title="Login Security"
            description="Manage your password, recent logins and recent activities on ByBest Shop"
            showItems={false}
          />
          <hr className="border-[1px]  border-[#E5E7EB] my-5" />

          <div className="grid lg:grid-cols-2 gap-5">
            <ChangePassword />
            <LoginSecurityActivities />
          </div>
        </div>

        <LoggedInFooter />
      </div>
    </div>
  );
};

export default LoginSecurity;
