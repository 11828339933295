import React from "react";
import { walletIcon } from "./icons";

const WalletSummary = ({
  value,
  title = "",
  description = "",
  showCopy = false,
  icon = walletIcon,
  className = "",
}) => {
  return (
    <div className="hidden md:flex boxshadow rounded-[8px] gap-2 p-4 border">
      <div className="wallet-icon-wrapper">
        <span>{icon}</span>
      </div>
      <div className={`hidden md:flex flex-col items-start ${className}`}>
        <div className="flex items-center gap-2">
          {value && (
            <p className="text-6 text-[#111827] font-semibold leading-8">
              {value}
            </p>
          )}
          <p className="text-16 text-[#6B7280] font-normal leading-5">
            {title}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-14 text-[#6B7280] font-semibold leading-5">
            {description}
            {showCopy && (
              <span className="cursor-pointer ml-2 text-14 leading-[18px] text-[#240b3b] bg-[#F3F1F4] rounded-[16px] px-2 py-[2px]">
                Copy
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WalletSummary;
