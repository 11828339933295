import React, {useEffect, useState} from "react";
import {Icon} from "react-icons-kit";
import {eyeOff} from "react-icons-kit/feather/eyeOff";
import {eye} from "react-icons-kit/feather/eye";
import {NotificationManager} from "react-notifications";
import "./index.css";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";
import {
  verifyResetPasswordLink,
  resetPassword,
} from "../../redux/actions/onboarding";
import {CircularProgress} from "@mui/material";

const validator = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(
      /[@$!%*#?&]/,
      "Password must contain at least one special character"
    ),
  password_confirmation: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const {token} = useParams();
  const [type, setType] = useState("password");
  const [confType, setConfType] = useState("password");
  const [email, setEmail] = useState(null);
  const [icon, setIcon] = useState(eyeOff);
  const [confIcon, setConfIcon] = useState(eyeOff);
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const formikProps = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: validator,
    onSubmit: values => onSubmit(values),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    window.sessionStorage.clear();
  }, []);

  useEffect(() => {
    checkResetPasswordLink();
  }, []);

  const checkResetPasswordLink = () => {
    verifyResetPasswordLink({token})
      .then(data => {
        setEmail(data?.email);
      })
      .catch(error => {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      });
  };

  const handleTogglePassword = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleToggleConfirmPassword = () => {
    if (confType === "password") {
      setConfIcon(eye);
      setConfType("text");
    } else {
      setConfIcon(eyeOff);
      setConfType("password");
    }
  };

  const handleChange = e => {
    formikProps.setFieldValue([e.target.name], e.target.value);
  };

  const onSubmit = values => {
    values.token = token;
    values.email = email;
    setSubmitting(true);
    resetPassword(values)
      .then(data => {
        setSubmitting(false);
        formikProps.resetForm();
        setSubmitSuccess(true);
      })
      .catch(error => {
        setSubmitting(false);
        NotificationManager.error(
          error.error ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error("Error on setting new password");
      });
  };

  return (
    <div className='flex flex-col align-col-middle view-terms'>
      <div className='py-10 w-full md:w-[40%]'>
        <h1 className='text-24 md:text-28 font-bold leading-3 md:leading-[34px] text-[#240b3b]'>
          Set New Password
        </h1>
        <p className='mt-5 text-18 md:text-20 font-medium leading-7 md:leading-[34px] text-[#240b3b]'>
          Create a new password to access and dive into your personalized
          VenueBoost Admin Panel – your new hub for managing and transforming
          your business operations.
        </p>
        <div className='mt-6 md:mt-10'>
          <p className='mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold'>
            Password
          </p>
          <div className='w-full h-[48px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 '>
            <input
              type={type}
              name='password'
              placeholder='Enter your password'
              value={formikProps.values.password}
              error={formikProps.errors.password}
              onChange={handleChange}
              autoComplete='current-password'
              className='flex-1 border-none focus-visible:border-none focus-visible:outline-none'
            />
            <span
              className='flex justify-around items-center'
              style={{color: "#CBD5E1"}}
              onClick={handleTogglePassword}
            >
              <Icon class='absolute ' icon={icon} size={18} />
            </span>
          </div>
          {formikProps.errors.password && (
            <div className='text-red-600 text-14'>
              {formikProps.errors.password}
            </div>
          )}
          <p className='mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold'>
            Confirm Password
          </p>
          <div className='w-full h-[48px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400'>
            <input
              type={confType}
              name='password_confirmation'
              placeholder='Confirm your password'
              value={formikProps.values.password_confirmation}
              onChange={handleChange}
              autoComplete='new-password'
              className='flex-1 border-none focus-visible:border-none focus-visible:outline-none'
            />
            <span
              className='flex justify-around items-center'
              style={{color: "#CBD5E1"}}
              onClick={handleToggleConfirmPassword}
            >
              <Icon class='absolute ' icon={confIcon} size={18} />
            </span>
          </div>
          {formikProps.errors.password_confirmation && (
            <div className='text-red-600 text-14'>
              {formikProps.errors.password_confirmation}
            </div>
          )}
          <button
            className='mt-6 md:mt-10 text-white text-20 font-bold bg-[#240b3b] border-[1px] border-[#CBD5E1] rounded-[5px] h-[60px] md:h-[70px] w-full'
            type='button'
            disabled={submitting}
            onClick={formikProps.handleSubmit}
          >
            {submitting ? <CircularProgress size={16} /> : "Set Password"}
          </button>
          {!submitSuccess && (
            <p className='text-[#707070] font-medium text-[18px] text-center my-5'>
              Please create a new password with at least 8 characters, including
              an uppercase letter, a lowercase letter, and a special character.
            </p>
          )}

          {submitSuccess && (
            <p
              style={{
                border: "2px dotted #009688",
                padding: "14px",
                color: "#009688",
              }}
              className='text-[#707070] font-medium text-[18px] text-center my-5'
            >
              Password set successfully! Please proceed to the
              <a
                style={{
                  color: "#607D8B",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                href='https://admin.venueboost.io'
                className='text-blue-600 hover:text-blue-800'
              >
                &nbsp;login page&nbsp;
              </a>
              and log in with your new password.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
