import React from "react";
import ByBestSidebar from "./sideBar";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestAbout = () => {
  const { pageSEO } = useSeoData();
  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] ">
            BYBEST {`>`} FAQE {`>`} RRETH NESH
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full px-4 grid grid-cols-1 md:grid-cols-9 gap-8">
            <ByBestSidebar />
            <div className=" col-span-7">
              <div className="w-full">
                <h3 className="text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  PËRVOJË E GJATË NË SEKTORIN E SHITJES ME PAKICË
                </h3>
                <p className=" mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  By Best Duty Free sh.p.k është një shoqëri e themeluar në
                  1996, drejtuar nga Znj. Dasha Frashëri dhe gjithmonë ka mbetur
                  në menaxhim familjar. Kompania By Best Duty Free sh.p.k
                  kujdeset për të përmbushur pritshmëritë e klienteve të saj
                  duke kërkuar gjithmonë frymëzime dhe emocione të reja. Është
                  kompania e parë në Shqipëri që ofron produkte të markave
                  internacionale me cilësi superiore dhe çmime të përballueshme.
                  Duke ndjekur hapin e kohës By Best Duty Free sh.p.k është në
                  kreshtën e valës dhe vazhdon të ndjekë një strategji të
                  suksesshme. Dyqanet e kompanisë By Best Duty Free sh.p.k
                  ndodhen në zemër të Tiranës dhe janë një destinacion për
                  mijëra klientë.
                </p>
                <h3 className="mt-7 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  PRILL 1996
                </h3>
                <p className=" mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  By Best Duty Free sh.p.k filloi aktivitetin e saj në Tiranë
                  duke u pozicionuar në zhvillimin e markave: Swarovski, Swatch,
                  Mont Blanc, Estee Lauder, YSL, Cartier, Salvador Dali,Misaki,
                  Perfumes EurocosPB të cilat u tregëtuan për herë të parë në
                  tregun Shqiptar në një dyqan multibrand.
                </p>
                <h3 className="mt-7 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  1999 - 2007
                </h3>
                <p className=" mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  By Best Duty Free sh.p.k fiton të drejtën për të hyrë në një
                  Marrëveshje Duty Free me AlbTransport (sot quhet TIA) dhe
                  drejton dyqanin pa tarifa deri në Prill 2007
                </p>
                <h3 className="mt-7 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  NËNTOR 2005
                </h3>
                <p className=" mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  Nga Best Duty Free Ltd. investon në Ndërtim dhe Turizëm:
                </p>
                <ul className="mt-2 list-disc pl-5">
                  <li className="text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000] underline">
                    {" "}
                    Qëndra Tregtare Metropol në Tiranë ku është aktiviteti i
                    shitjeve
                  </li>
                  <li className="text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000] underline">
                    Dy fshatra Turistikë në Golem, Kavajë me 120 vila
                  </li>
                  <li className="text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000] underline">
                    Tregti Ndërkombëtare
                  </li>
                  <li className="text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000] underline">
                    Ushqim dhe Pije
                  </li>
                </ul>
                <h3 className="mt-7 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                  2007 - SOT
                </h3>
                <p className=" mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                  By Best Duty Free sh.p.k zhvendos aktivitetin e saj me dyqane
                  monobrand për markat Swarovski, Swatch, Villeroy&Boch, Blukids
                  në Qendrën Tregtare “Metropol”
                </p>
              </div>
              <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-8 mt-7">
                <div className="">
                  <h3 className="text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                    FILOZOFIA
                  </h3>
                  <p className=" mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                    By Best Duty Free sh.p.k është biznes familjar që kërkon të
                    krijojë një përvojë stimuluese blerëse për klientët e saj,
                    duke rritur karakteristikat kryesore të secilës markë.
                  </p>
                  <h3 className="mt-4 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                    VIZIONI
                  </h3>
                  <p className=" mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                    By Best Duty Free sh.p.k është një pikë referimi për ata që
                    kërkojnë cilësi, çmime konkurruese dhe zgjidhje gjithnjë në
                    modë.
                  </p>
                </div>
                <div>
                  <h3 className="mt-4 text-[26px] leading-[39px] md:text-[13px] md:leading-[21px] font-bold text-[#000] uppercase">
                    MISIONI
                  </h3>
                  <p className=" mt-2 text-[24px] leading-[39px] md:text-[12px] md:leading-[20px] text-[#000]">
                    Ne jemi kompania e parë shqiptare që ofron produkte të
                    markave me cilësi të lartë, duke promovuar rikthimin tregtar
                    dhe turistik të vendit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestAbout;
