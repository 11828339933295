import foodImg1 from '../../../assets/images/services/food_1.png';
import foodImg2 from '../../../assets/images/services/food_2.png';
import foodImg3 from '../../../assets/images/services/food_3.png';
import foodImg4 from '../../../assets/images/services/food_4.png';
import FormText from '../../../components/FormText';
import {isMobile} from "react-device-detect";

const imageArray = [ foodImg1, foodImg2, foodImg3, foodImg4, foodImg3, foodImg4];

const RestaurantPhotos = ({photos}) => {
  const chunkedPhotos = photos.slice(0, 6); // Limit to 6 photos

  return (
    <div className='md:col-span-3 flex flex-col pt-8'>
      <FormText title='Photos' type='itemtitle-sub' />
      <div className="grid grid-cols-3 gap-3 mt-4">
        {chunkedPhotos.map((photo, index) => (
          <div key={index} className="relative flex justify-center items-center border border-gray-300 rounded-md h-[114px] md:h-[229px]">
            <img
              className="max-w-full max-h-full object-cover h-full rounded-md"
              src={photo?.photo_path ?? "https://via.placeholder.com/197x197"}
              alt='img'
            />
          </div>
        ))}
        {imageArray.slice(0, 6 - chunkedPhotos.length).map((photo, index) => (
          <div key={index} className="relative flex justify-center items-center border border-gray-300 rounded-md h-[114px] md:h-[229px]">
            <img
              className="max-w-full max-h-full object-cover h-full rounded-md"
              src={photo}
              alt='img'
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default RestaurantPhotos;
