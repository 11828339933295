import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import RouteNames from "../../constants/route_names.js";
import Home from "../../views/home/index.js";
import NotFound from "../../views/notFound/index.js";
import TermsConditions from "../../views/terms/index.js";
import About from "../../views/about/index.js";
import Contact from "../../views/contact/index.js";
import WhyVenueBoost from "../../views/why_venueboost/index.js";
import GetStarted from "../../views/getstarted/index.js";
import Rcustomerhub from "../../views/resources/customerhub/index.js";
import Rfaqs from "../../views/resources/faqs/index.js";
import Rtools from "../../views/resources/tools/index.js";
import Rtool from "../../views/resources/tool/index.js";
import Rstories from "../../views/resources/success-stories/index.js";
import UseCases from "../../views/resources/use-cases/index.js";
import Rstory from "../../views/resources/success-story/index.js";
import Features from "../../views/features/index.js";
import Pricing from "../../views/pricing/index.js";
import Integrations from "../../views/integrations/index.js";
import PrivacyPolicy from "../../views/policy/index.js";
import Blog from "../../views/blog/index.js";
// import BlogDetail from "../views/blog-detail";
import BlogDetailUpdated from "../../views/blog-detail-updated/index.js";
import VendorRegister from "../../views/vendor-register/index.js";
import VendorRegisterScreen from "../../views/vendor-register/register/index.js";
import VendorNotVerifedScreen from "../../views/vendor-register/not_verified/index.js";
import VendorRegisterVerifyScreen from "../../views/vendor-register/verify-email/index.js";
import VendorRegisterPlanPayScreen from "../../views/vendor-register/planpay/index.js";
import ProductUpdates from "../../views/product-updates/index.js";
import ProductDetail from "../../views/product-updates/product-detail/index.js";
import Cookie from "../../views/cookie-policy/index.js";
import Sitemap from "../../views/sitemap/index.js";
import VenueRestaurant from "../../views/venue-restaurant/index.js";
import VenueHotel from "../../views/venue-hotel/index.js";
// import VenueSport from "../views/venue-sport";
import UseCaseDetails from "../../views/resources/usecase-details/index.js";

import CartScreen from "../../views/cart/index.js";
import BillingInformationScreen from "../../views/cart/billing_information.js";
import ProductDetailsScreen from "../../views/product-details/index.js";
import AboutAp from "../../views/about-ap/index.js";
import VenueAccommodation from "../../views/venue-accommodation/index.js";
// import OrderRetailSuccessScreen from "../views/cart/order-sucess";
// import OrderRestaurantSuccessScreen from "../views/cart/order-restaurant-sucess";
import AccommodationRentalProfile from "../../views/rental/index.js";
import CartOrderSuccessScreen from "../../views/cart/card-order-success.js";
import VenueGym from "../../views/venue-gym/index.js";
import VenueGolf from "../../views/venue-golf/index.js";
import VenueBowling from "../../views/venue-bowling/index.js";
import VenueGolfBook from "../../views/venue-golf-book/index.js";
import VenueGymBook from "../../views/venue-gym-book/index.js";
import VenueBowlingBook from "../../views/venue-bowling-book/index.js";
// import ComingSoon from "../views/comingSoon";
import WaitlistMarketing from "../../views/waitlist-marketing/index.js";
import WaitlistMarketingLoggedIn from "../../views/waitlist-marketing-logged-in/index.js";
import RentalReserve from "../../views/rental-reserve/index.js";
import BookingSuccessScreen from "../../views/rental-reserve/booking-success.js";
import Affilicates from "../../views/affiliates/index.js";
import Refer from "../../views/refer/index.js";
import AffiliateCampaign from "../../views/affiliateCampaign/index.js";
import Onboarding from "../../views/onboarding/index.js";
import RateExperience from "../../views/rate_experience/index.js";
import PrivacyRightRequestForm from "../../views/privacy_rights_request_form/index.js";
import PrivacyRequestFormConfirm from "../../views/privacyRequestFormConfirm/index.js";
import SurveyCompleted from "../../views/surveyCompleted/index.js";
import Bpractices from "../../views/best-practice/index.js";
import Guides from "../../views/guides/index.js";
import FeatureTutorials from "../../views/feature-tutorials/index.js";
import GuideDetails from "../../views/guides-detail/index.js";
import BpracticesDetails from "../../views/best-practice-detail/index.js";
// import BeachBarOrder from "../views/beach-bar-order";
import GetStartedDetails from "../../views/getstarted-detail/index.js";
import GettingStarted from "../../views/gettingstarted/index.js";
import RetailContact from "../../views/venue-retail/retail-contact/index.js";
import AccommodationContact from "../../views/venue-accommodation/accommodation-contact/index.js";
import RestaurantContact from "../../views/venue-restaurant/restaurant-contact/index.js";
import BowlingContact from "../../views/venue-bowling/bowling-contact/index.js";
import HotelContact from "../../views/venue-hotel/hotel-contact.js";
import BowlingBookContact from "../../views/venue-bowling-book/bowling-book-contact/index.js";
import GymContact from "../../views/venue-gym/gym-contact/index.js";
import GolfContact from "../../views/venue-golf/golf-contact/index.js";
import PizzeriaContact from "../../views/venue-pizzeria/index.js";
import GymBookContact from "../../views/venue-gym/gym-book/gym-book-contact/index.js";
import VenueBeachBar from "../../views/venue-beach-bar/index.js";
import BeachbarContact from "../../views/venue-beach-bar/beach-bar-contact/index.js";
import VenuePharmacy from "../../views/venue-pharmacy/index.js";
import PharmacyContact from "../../views/venue-pharmacy/pharmacy-contact.js";

import Menu from "../../views/beach-bar-order/index.js";
import Success from "../../views/beach-bar-order/Success.js";
import RestaurantCard from "../../views/venure-restaurant-upgrade/index.jsx";
import MakeReservation from "../../views/venure-restaurant-upgrade/make-reservation/index.jsx";
import RestaurantReserve from "../../views/venure-restaurant-upgrade/reserve/index.jsx";
import ReserveComplete from "../../views/venure-restaurant-upgrade/reserve-complete/index.jsx";
import AuthRoutes from "./auth-routes.jsx";
import AffiliateRoutes from "./affiliate-routes.jsx";
import { LoginWithFacebook } from "../../views/login/facebook-login.jsx";
import retailRoutes from "./retailRoutes.jsx";
import accommodationRoutes from "./accommodation-routes.jsx";
import foodRoutes from "./food-routes.jsx";
import sportsEntertainmentRoutes from "./sports-entertainment-routes.jsx";
import whoWeServeRoutes from "./who-we-serve-routes.jsx";
import byBestRetailRoutes from "./by-best-retail-routes.jsx";
import MainLayout from "../layouts/MainLayout.js";
import BrandLayout from "../layouts/BrandLayout.js";
import VenueRetailAbout from "../../views/venue-retail/retail-about";
import VenueRetailShop from "../../views/venue-retail/retail-shop/retail-shop.js";
import { CheckoutStepper } from "../../views/cart/checkout/checkout-stepper.js";
import Wishlist from "../../views/wishlist/index.js";
import VenueRetailHome from "../../views/venue-retail/home.js";
import bybestRoutes from "./bybest-routes.jsx";
import BBLayout from "../layouts/BBLayout.js";
import BBGuestLayout from "../layouts/BBGuestLayout";
import bybestGuestRoutes from "./bybest-guest-routes.jsx";

const AppRoutes = () => {
  // const isBetaShop = true;
  const isBetaShop = window.location.hostname === "beta.bybest.shop";

  return (
    <React.Fragment>
      <Routes>
        {isBetaShop ? (
          // Routes for beta.bybest.shop
          <>
            <Route path="/*" element={<BBGuestLayout />}>
              {bybestGuestRoutes}
            </Route>
            {/*<Route path="/shop/BYB2802APPY" element={<AuthMiddleware><BBLayout /></AuthMiddleware>}>*/}
            {/*    {bybestRoutes}*/}
            {/*</Route>*/}
          </>
        ) : (
          // // Routes for beta.bybest.shop
          // <>
          //   <Route path="/*" element={<BBGuestLayout />}>
          //     {bybestGuestRoutes}
          //   </Route>
          //   <Route
          //     path="/BYB2802APPY"
          //     element={
          //       // <AuthMiddleware>
          //       <BBLayout />
          //     }
          //   >
          //     {bybestRoutes}
          //   </Route>
          // </>
          // Routes for other domains
          <>
            <Route path="/venue" element={<BrandLayout />}>
              <Route path="restaurant" element={<Outlet />}>
                <Route exact path={":app_key"} element={<RestaurantCard />} />
                <Route
                  exact
                  path={":app_key/reserve/availability"}
                  element={<MakeReservation />}
                />
                <Route
                  exact
                  path={":app_key/reserve"}
                  element={<RestaurantReserve />}
                />
                <Route
                  exact
                  path={":app_key/reserve/complete"}
                  element={<ReserveComplete />}
                />
                <Route
                  exact
                  path={":app_key/reserve/contact"}
                  element={<RestaurantContact />}
                />
                <Route exact path={":app_key/cart"} element={<CartScreen />} />
              </Route>
              <Route path="pizzeria" element={<Outlet />}>
                <Route exact path={":app_key"} element={<VenueRestaurant />} />
                <Route
                  exact
                  path={":app_key/contact"}
                  element={<PizzeriaContact />}
                />
              </Route>
              <Route path="hotel" element={<Outlet />}>
                <Route exact path={":app_key"} element={<VenueHotel />} />
                <Route
                  exact
                  path={":app_key/contact"}
                  element={<HotelContact />}
                />
                <Route exact path={":app_key/cart"} element={<CartScreen />} />
              </Route>
              <Route path="pharmacy" element={<Outlet />}>
                <Route exact path={":app_key"} element={<VenuePharmacy />} />
                <Route
                  exact
                  path={":app_key/contact"}
                  element={<PharmacyContact />}
                />
              </Route>
              <Route path="beach-bar" element={<Outlet />}>
                <Route exact path={":app_key"} element={<VenueBeachBar />} />
                <Route
                  exact
                  path={":app_key/contact"}
                  element={<BeachbarContact />}
                />
              </Route>
              <Route path="retail" element={<Outlet />}>
                <Route exact path={":app_key"} element={<VenueRetailHome />} />
                <Route
                  exact
                  path={":app_key/about"}
                  element={<VenueRetailAbout />}
                />
                <Route
                  exact
                  path={":app_key/shop"}
                  element={<VenueRetailShop />}
                />
                <Route
                  exact
                  path={":app_key/wishlist"}
                  element={<Wishlist />}
                />
                <Route
                  exact
                  path={":app_key/contact"}
                  element={<RetailContact />}
                />
                <Route exact path={":app_key/cart"} element={<CartScreen />} />
              </Route>
              <Route path="accommodation" element={<Outlet />}>
                <Route
                  exact
                  path={":app_key"}
                  element={<VenueAccommodation />}
                />
                <Route exact path={":app_key/about"} element={<AboutAp />} />
                <Route
                  exact
                  path={":app_key/contact"}
                  element={<AccommodationContact />}
                />
              </Route>
              <Route path="gym" element={<Outlet />}>
                <Route exact path={":app_key"} element={<VenueGym />} />
                <Route
                  exact
                  path={":app_key/contact"}
                  element={<GymContact />}
                />
                <Route
                  exact
                  path={":app_key/book"}
                  element={<VenueGymBook />}
                />
                <Route
                  exact
                  path={":app_key/book-contact"}
                  element={<GymBookContact />}
                />
              </Route>
              <Route path="bowling" element={<Outlet />}>
                <Route exact path={":app_key"} element={<VenueBowling />} />
                <Route
                  exact
                  path={":app_key/contact"}
                  element={<BowlingContact />}
                />
                <Route
                  exact
                  path={":app_key/book"}
                  element={<VenueBowlingBook />}
                />
                <Route
                  exact
                  path={":app_key/book-contact"}
                  element={<BowlingBookContact />}
                />
              </Route>
              <Route path="golf" element={<Outlet />}>
                <Route exact path={":app_key"} element={<VenueGolf />} />
                <Route
                  exact
                  path={":app_key/contact"}
                  element={<GolfContact />}
                />
                <Route
                  exact
                  path={":app_key/book"}
                  element={<VenueGolfBook />}
                />
              </Route>
            </Route>

            <Route path="/" element={<MainLayout />}>
              <Route exact path={""} element={<Home />} />
              {/*<Route exact path={RouteNames.new_home} element={<NewHome />} />*/}
              <Route
                exact
                path={"coming-soon"}
                element={<WaitlistMarketing />}
              />
              <Route
                exact
                path={"terms-conditions"}
                element={<TermsConditions />}
              />
              <Route
                exact
                path={"privacy-policy"}
                element={<PrivacyPolicy />}
              />
              <Route exact path={"about"} element={<About />} />
              <Route exact path={"contact-us"} element={<Contact />} />
              <Route exact path={"get-started"} element={<GetStarted />} />
              {/* <Route exact path={"beach-bar-order"} element={<BeachBarOrder />} /> */}
              <Route path="resources" element={<Outlet />}>
                <Route exact path={""} element={<Rcustomerhub />} />
                <Route
                  exact
                  path={"getting-started"}
                  element={<GettingStarted />}
                />
                <Route
                  exact
                  path={"getting-started/:slug"}
                  element={<GetStartedDetails />}
                />
                <Route exact path={"best-practices"} element={<Bpractices />} />
                <Route
                  exact
                  path={"best-practices/:slug"}
                  element={<BpracticesDetails />}
                />
                <Route
                  exact
                  path={"guides-and-templates"}
                  element={<Guides />}
                />
                <Route
                  exact
                  path={"guides-and-templates/:slug"}
                  element={<GuideDetails />}
                />
                <Route
                  exact
                  path={"feature-tutorials"}
                  element={<FeatureTutorials />}
                />
                <Route exact path={"success-stories"} element={<Rstories />} />
                <Route
                  exact
                  path={"resources/success-stories/:slug"}
                  element={<Rstory />}
                />
                <Route exact path={"use-cases"} element={<UseCases />} />
                <Route
                  exact
                  path={"use-cases/:slug"}
                  element={<UseCaseDetails />}
                />
                <Route exact path={"tools-guides"} element={<Rtools />} />
                <Route exact path={"tools-guides/:slug"} element={<Rtool />} />
                <Route exact path={"faqs"} element={<Rfaqs />} />
              </Route>
              <Route path={"onboarding/:token"} element={<Onboarding />} />
              <Route
                path={":venue_name/rate-your-experience/:token"}
                element={<RateExperience />}
              />
              <Route
                path={"privacy-rights-request-form"}
                element={<PrivacyRightRequestForm />}
              />
              <Route path={"survey-completed"} element={<SurveyCompleted />} />
              <Route
                path={"privacy-rights-request-confirm/:token"}
                element={<PrivacyRequestFormConfirm />}
              />
              <Route
                path={"waitlist/:token"}
                element={<WaitlistMarketingLoggedIn />}
              />
              <Route
                exact
                path={"restaurants/verify-email/:token"}
                element={<VendorRegisterVerifyScreen />}
              />
              <Route exact path={"blog"} element={<Blog />} />
              <Route exact path={"blog/:id"} element={<BlogDetailUpdated />} />
              <Route
                exact
                path={"why-venueboost"}
                element={<WhyVenueBoost />}
              />
              {/* <Route exact path={RouteNames.snapfood_network} element={<SnapfoodNetwork />} /> */}
              <Route exact path={"integrations"} element={<Integrations />} />
              <Route exact path={"pricing-plans"} element={<Pricing />} />
              {/* <Route exact path={RouteNames.staffs} element={<Feature_staffs />} /> */}
              {/* <Route exact path={RouteNames.feature_tables} element={<Feature_tables />} /> */}
              {/* <Route exact path={RouteNames.feature_menus} element={<Feature_menus />} /> */}
              {/* <Route exact path={RouteNames.feature_waitlist} element={<Feature_waitlist />} /> */}
              {/* <Route exact path={RouteNames.feature_reservations} element={<Feature_reservations />} /> */}
              <Route exact path={"features"} element={<Features />} />
              <Route
                exact
                path={"product-updates"}
                element={<ProductUpdates />}
              />
              <Route
                exact
                path={"product-updates/:slug"}
                element={<ProductDetail />}
              />
              <Route exact path={"checkout"} element={<CheckoutStepper />} />
              <Route exact path={"cookie-notice"} element={<Cookie />} />{" "}
              <Route exact path={"sitemap"} element={<Sitemap />} />
              <Route exact path={"affiliates"} element={<Affilicates />} />
              <Route exact path={"referrals"} element={<Refer />} />
              <Route
                exact
                path={"affiliate-campaign"}
                element={<AffiliateCampaign />}
              />
              <Route
                exact
                path={"facebook-login"}
                element={<LoginWithFacebook />}
              />
              <Route
                path={":token/apply/complete-registration"}
                element={<VendorRegister />}
              >
                <Route index element={<VendorRegisterScreen />} />
                <Route
                  exact
                  path="verify-email"
                  element={<VendorNotVerifedScreen />}
                />
                <Route
                  exact
                  path="choose-plan-and-pay"
                  element={<VendorRegisterPlanPayScreen />}
                />
              </Route>
              {/*Accommodation*/}
              {accommodationRoutes}
              {/*Retail*/}
              {sportsEntertainmentRoutes}
              <Route
                exact
                path={RouteNames.accommodation_rental}
                element={<AccommodationRentalProfile />}
              />
              <Route
                exact
                path={RouteNames.accommodation_rental_reserve}
                element={<RentalReserve />}
              />
              <Route
                exact
                path={"beach-bar-order/:app_key/umbrella/:umbrellaNo"}
                element={<Menu />}
              />
              <Route
                exact
                path={"beach-bar-order/:app_key/umbrella/:umbrellaNo/success"}
                element={<Success />}
              />
              <Route
                exact
                path={RouteNames.billing_information}
                element={<BillingInformationScreen />}
              />
              <Route
                exact
                path={"retail/:app_key/product-details/:id"}
                element={<ProductDetailsScreen />}
              />
              <Route
                exact
                path={"order-retail-success"}
                element={<CartOrderSuccessScreen />}
              />
              <Route
                exact
                path={"order-restaurant-success"}
                element={<CartOrderSuccessScreen />}
              />
              <Route
                exact
                path={"booking-success"}
                element={<BookingSuccessScreen />}
              />
              {/*BB Shop - Specific Retail Routes*/}
              {byBestRetailRoutes}
              {/*All Retail Routes*/}
              {retailRoutes}
              {/* Food */}
              {foodRoutes}
              {/* Auth */}
              {AuthRoutes}
              {/* Affiliate */}
              {AffiliateRoutes}
              {/* Who we serve */}
              {whoWeServeRoutes}
            </Route>

            {/*<Route path="/shop" element={<BBGuestLayout />}>*/}
            {/*  {bybestGuestRoutes}*/}
            {/*</Route>*/}
            {/*<Route path="/shop" element={ <AuthMiddleware><BBLayout /></AuthMiddleware>}>*/}
            {/*  {bybestRoutes}*/}
            {/*</Route>*/}
          </>
        )}
        {/* Not Found + Other Routes */}
        <Route exact path={RouteNames.not_found} element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
};

export default AppRoutes;
