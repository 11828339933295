import React from "react";

import "./index.css";
import { t } from "i18next";
import HomeLogoIntegration from "../../../components/HomeLogoIntegration";

const Integration = (props) => {
  return (
    <div className="align-col-middle">
      <div className="w-[100vw] bg-primary1">
        <div
          className={
            "max-w-screen-main mx-auto px-7 py-12 sm:px-6 md:px-8 lg:px-16"
          }
        >
          <div
            className={
              "flex flex-col justify-center items-center align-middle gap-2"
            }
          >
            <span className="text-white font-black text-26 md:text-[42px] leading-[28px] md:leading-[50px] text-center">
              Maximize Your Success with Smart Integrations
            </span>
            <span className="text-sm md:text-xl text-medium text-white  text-center">
              VenueBoost effortlessly syncs with top industry tools, amplifying
              your venue’s capabilities. <br />
              Discover how our tailored integrations can elevate your operations
            </span>
          </div>
          <div className="mt-12">
            <HomeLogoIntegration />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
