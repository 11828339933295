import React, { useEffect, useState } from "react";
import FormTextInput from "../../components/FormTextInput";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
// import { chevronDown } from "react-icons-kit/feather/chevronDown";
import { register } from "../../redux/actions/auth";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { useFormik } from "formik";
import { isEmpty } from "../../utils/common";
import "./index.css";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../constants/route_names";
import { useDispatch } from "react-redux";

const validator = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  last_name: yup
    .string()
    .trim()
    .required("Last name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .max(250, "Max exceeded")
    .min(6, "Min not met"),
  acceptTerm: yup
    .boolean()
    .required("You must accept the terms and conditions")
    .oneOf([true], "You must accept the terms and conditions"),
});

const VerifyEmailSent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const formikProps = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      acceptTerm: false,
    },
    validationSchema: validator,
    onSubmit: (values) => onSubmit(values),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const vbToken = localStorage.getItem("vbToken");

  useEffect(() => {
    if (vbToken) {
      navigate("/customer/orders");
    }
  }, [vbToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.sessionStorage.clear();
  }, []);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleChange = (e) => {
    formikProps.setFieldValue([e.target.name], e.target.value);
  };

  const onSubmit = (values) => {
    dispatch(register(values))
      .then((data) => {
        formikProps.resetForm();
      })
      .catch((error) => {
        NotificationManager.error(
          error.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error("Error with register");
      });
  };

  const onLogin = () => {
    navigate(RouteNames.login);
  };

  return (
    <div className="flex flex-col align-col-middle view-terms">
      <div className="py-10 w-full  md:w-[40%]">
        <h1 className="text-24 md:text-28 font-bold leading-3 md:leading-[34px] text-[#240b3b]">
          Sign Up
        </h1>
        <p className="mt-5 text-18 md:text-20 font-medium leading-7 md:leading-[34px] text-[#240b3b]">
          Signup to VenueBoost
        </p>
        <div className="mt-6 md:mt-10">
          <p className=" mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Email Address
          </p>
          <FormTextInput
            enableLabel={false}
            name="email"
            type={"email"}
            placeholder="Enter email address"
            value={formikProps.values.email}
            error={formikProps.errors.email}
            onChange={handleChange}
          />
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            First Name
          </p>
          <FormTextInput
            enableLabel={false}
            name="first_name"
            placeholder="Enter first Name"
            value={formikProps.values.first_name}
            error={formikProps.errors.first_name}
            onChange={handleChange}
          />
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Last Name
          </p>

          <FormTextInput
            enableLabel={false}
            name="last_name"
            placeholder="Enter last Name"
            value={formikProps.values.last_name}
            error={formikProps.errors.last_name}
            onChange={handleChange}
          />
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Password
          </p>
          <div className="w-full h-[48px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <input
              type={type}
              name="password"
              placeholder="Enter your password"
              value={formikProps.values.password}
              error={formikProps.errors.password}
              onChange={handleChange}
              autoComplete="current-password"
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
            <span
              className="flex justify-around items-center"
              style={{ color: "#CBD5E1" }}
              onClick={handleToggle}
            >
              <Icon class="absolute " icon={icon} size={18} />
            </span>
          </div>
          {!isEmpty(formikProps.errors.password) && (
            <div className={"text-red-600 text-12 mt-2"}>
              {formikProps.errors.password}
            </div>
          )}
          {/*<div className="flex gap-[8px] md:gap-[18px] mt-10 justify-center items-start">*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    name="acceptTerm"*/}
          {/*    className={`w-[28px] md:w-[38px] h-[28px] md:h-[38px] align-top bg-white  border-[1px] rounded-[5px] border-[#CBD5E1] focus:border-[1px] outline-none  `}*/}
          {/*    checked={formikProps.values.acceptTerm}*/}
          {/*    onChange={(e) => {*/}
          {/*      formikProps.setFieldValue("acceptTerm", e.target.checked);*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <p className="text-[#240b3b] flex-1 text-16 md:text-20 font-medium leading-8 md:leading-[34px]">*/}
          {/*    I agree to accept Terms and Condition.*/}
          {/*  </p>*/}
          {/*</div>*/}
          {!isEmpty(formikProps.errors.acceptTerm) && (
            <div className={"text-red-600 text-12 mt-2"}>
              {formikProps.errors.acceptTerm}
            </div>
          )}
          <button
            className="mt-6 md:mt-10 text-white text-20 font-bold bg-[#240b3b] border-[1px] border-[#CBD5E1] rounded-[5px] h-[60px] md:h-[70px] w-full"
            type="button"
            onClick={formikProps.handleSubmit}
          >
            Sign Up
          </button>
          <p className="text-[#707070] font-medium text-[18px] text-center my-5">
            Do you already have an account?
          </p>
          <button
            className="text-[#240b3b] text-20 font-bold bg-white border-[1px] border-[#240b3b] rounded-[5px] h-[60px] md:h-[70px] w-full"
            onClick={onLogin}
          >
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailSent;
