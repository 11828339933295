import React from 'react';

const ServiceBtn = (props) => {
  const {
    label,
    classes='',
    innerClasses='',
    active=false,
    onAction=()=>{},
  } = props;

  return (
      <div
          className={`bg-white border ${active && 'border-primary2'} cursor-point px-3 md:px-6 py-1 md:py-2 my-3 cursor-pointer ${classes}`}
          onClick={() => onAction()}
          style={{ whiteSpace: 'nowrap'}} // Add this style
      >
        <span className={`md:text-base text-sm ${innerClasses}`}>{label}</span>
      </div>
  )
}

export default ServiceBtn;
