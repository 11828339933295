import React, { useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import "./index.css";

import FormText from "../../../components/FormText";
import { ServiceBtn } from "../../../components/FormBtn";

import additionIcon from "../../../assets/svgs/addition.svg";
import userIcon from "../../../assets/svgs/user.svg";

const GymScheule = ({ availability = [], additional }) => {
  const [category, setCategory] = useState("basic");
  const [selectedDay, setSelectedDay] = useState({
    year: 2019,
    month: 10,
    day: 5,
  });

  const handleSelectCategory = (value) => {
    setCategory(value);
  };

  return (
    <div className="flex flex-col my-8 border rounded-xl gym-schedule">
      <div className="flex flex-col px-6 py-4 md:py-8 border-b">
        <FormText
          title={additional ?? ""}
          type="subtitle-dark"
          customClass="!font-medium"
        />
      </div>
      <div className="grid gird-cols-1 md:grid-cols-2 px-6 py-4 md:py-8 border-b md:gap-6 gap-4">
        {availability.map((item) => {
          return (
            <div className="flex flex-col md:gap-3 gap-1">
              <FormText
                title={item?.day_of_week}
                type="itemtitle-sub"
                customClass="!font-bold"
              />
              <div className="flex flex-col gap-1">
                <div className="flex flex-row">
                  <FormText
                    title="Open"
                    customClass="!font-bold w-24"
                    type="subtitle-based"
                  />
                  <FormText
                    title={item?.open_time}
                    type="subtitle-dark"
                    customClass="!font-medium"
                  />
                </div>
                <div className="flex flex-row">
                  <FormText
                    title="Close"
                    customClass="!font-bold w-24"
                    type="subtitle-based"
                  />
                  <FormText
                    title={item?.close_time}
                    type="subtitle-dark"
                    customClass="!font-medium"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col px-6 py-4 md:py-8 border-b">
        <FormText
          title="Classes"
          type="itemtitle-sub"
          customClass="!font-bold"
        />
        <div className="grid grid-cols-1 md:flex md:flex-row">
          <ServiceBtn
            label="Zumba Basic"
            classes={`mr-5 md:!px-5 !py-3 ${
              category === "basic" && "!bg-primary21"
            }`}
            innerClasses="font-bold !text-xl"
            active={category === "basic"}
            onAction={() => handleSelectCategory("basic")}
          />
          <ServiceBtn
            label="Zumba Rumba"
            classes={`mr-5 md:!px-5 !py-3 ${
              category === "rumba" && "!bg-primary21"
            }`}
            innerClasses="font-bold !text-xl"
            active={category === "rumba"}
            onAction={() => handleSelectCategory("rumba")}
          />
          <ServiceBtn
            label="Zumba Expert"
            classes={`mr-5 md:!px-5 !py-3 ${
              category === "expert" && "!bg-primary21"
            }`}
            innerClasses="font-bold !text-xl"
            active={category === "expert"}
            onAction={() => handleSelectCategory("expert")}
          />
        </div>
        <div className="flex flex-col my-6 gap-4">
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center w-48">
              <img src={additionIcon} alt="icon" width={24} />
              <FormText
                title="Price"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="">
              <FormText
                title="$499 yearly"
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center w-48">
              <img src={userIcon} alt="icon" width={24} />
              <FormText
                title="People capacity"
                customClass="ml-2 !font-bold"
                type="subtitle-based"
              />
            </div>
            <div className="">
              <FormText
                title="100 max"
                type="subtitle-dark"
                customClass="!font-medium"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-6 py-4 md:py-8">
        <FormText
          title="Select date to see availability"
          type="itemtitle-sub"
          customClass="!font-bold"
        />
        <Calendar
          value={selectedDay}
          onChange={setSelectedDay}
          calendarClassName="!shadow-none !w-full"
        />
        <div className="flex flex-col md:my-6 my-0 gap-4 md:gap-8">
          <div className="flex flex-col gap-2">
            <FormText
              title="8AM - 10AM"
              customClass="!font-bold"
              type="subtitle-based"
            />
            <FormText
              title="Zumba Basic"
              type="itemtitle-sub"
              customClass="!font-bold text-[22px]"
            />
          </div>
          <div className="flex flex-col gap-2">
            <FormText
              title="11AM - 1PM"
              customClass="!font-bold"
              type="subtitle-based"
            />
            <FormText
              title="Zumba Basic"
              type="itemtitle-sub"
              customClass="!font-bold text-[22px]"
            />
          </div>
          <div className="flex flex-col gap-2">
            <FormText
              title="3PM - 5PM"
              customClass="!font-bold"
              type="subtitle-based"
            />
            <FormText
              title="Zumba Basic"
              type="itemtitle-sub"
              customClass="!font-bold text-[22px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GymScheule;
