import React from 'react'

import FormText from '../FormText'
import { useSelector } from 'react-redux'
import { useEffect } from 'react';
import { useState } from 'react';
import { MdShoppingCart } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
import { KEYS, setStorageKey } from '../../utils/storage';
import { APP_KEY } from '../../constants/config';
import {isMobile} from "react-device-detect";

const CartCheckoutButton = props => {
    const {
        venueKey,
        type,
        hospitalRoomId = '',
    } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const { products, venue_app_key, currency } = useSelector(state => state.cart);
    const [total, setTotal] = useState(0);


    const goToCart =() => {
        if (type === 'food') {
            let route = `/food-checkout`;
            setStorageKey(KEYS.HOSPITAL_ROOM_KEY, {value: hospitalRoomId})
            navigate(route);
        } else {
            let route = `/venue/retail/${venueKey}/cart`;
            navigate(route);
        }
    } 

    useEffect(() => {
        if (products) {
            let tot= 0;
            products.forEach(item => {
                tot += parseFloat(item.price * item?.quantity); 
            });
            setTotal(tot);
        }
    }, [products]);
    if (products && (venue_app_key == venueKey) && products.length > 0 && isMobile) {
        return (
            <div className='flex flex-row justify-between items-center bg-primary p-[12px] px-4 md:mb-8 mb-4 cursor-pointer rounded rounded-[12px]' onClick={() => goToCart()}>
                <div className='flex flex-row justify-start items-center gap-2'>
                    <MdShoppingCart color='#ffffff' />
                    <span className='text-[#ffffff] text-12 w-[100px] text-center font-bold'>Your cart ({products ? products.length : 0})</span>
                </div>
                <div className='text-[#ffffff] text-12 w-[100px] text-center font-bold'>Total: {currency ?? ''} {total}</div>
            </div>
        )
    }
    return null;

}

export default CartCheckoutButton
