import FormText from "../../../components/FormText";

import infoIcon from '../../../assets/svgs/info.svg';
import forkIcon from '../../../assets/svgs/forkGray.svg';
import menuIcon from '../../../assets/svgs/menuGray.svg';

const HotelTab = () => {
  return (
    <>
      <div className="hidden md:block border">
        <div className="py-4 max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full">
          <FormText title='Overview' customClass="cursor-pointer mr-20" />
          <FormText title='Photos' customClass="cursor-pointer mr-20" />
          <FormText title='Menu' customClass="cursor-pointer mr-20" />
        </div>
      </div>
      <div className="md:hidden grid grid-cols-3 max-w-screen-main mx-auto w-full">
        <div className="border border-e-0 py-3 flex justify-center">
          <img src={infoIcon} alt="info" />
        </div>
        <div className="border border-e-0 py-3 flex justify-center">
          <img src={forkIcon} alt="fork" />
        </div>
        <div className="border py-3 flex justify-center">
          <img src={menuIcon} alt="menu" />
        </div>
      </div>
    </>
  )
}

export default HotelTab;
