import React from "react";
import "./index.css";
import Partners from "../../assets/images/retail/partners.png.png";

const LoggedInFooter = () => {
  return (
    <div className="lg:ml-[300px] footer-wrapper p-4">
      <div className="flex items-center">
        <img alt="" src={Partners} />
      </div>
      <div className="copyright">
        <span>© 1996-2024 By Best Duty Free Sh.p.k - NIPT: K01621002N</span>
      </div>
    </div>
  );
};

export default LoggedInFooter;
