import React from "react";
import { Route } from "react-router-dom";
import { byBestRetailRouteNames } from "../../constants/route_names";
import PlaceholderEmptyComponent from "./empty-component";

const byBestRetailRoutes = [
  <Route
    key="order_tracking"
    exact
    path={byBestRetailRouteNames.order_tracking}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="my_club"
    exact
    path={byBestRetailRouteNames.my_club}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="cart"
    exact
    path={byBestRetailRouteNames.cart}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="checkout"
    exact
    path={byBestRetailRouteNames.checkout}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="payment"
    exact
    path={byBestRetailRouteNames.payment}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="success_checkout"
    exact
    path={byBestRetailRouteNames.success_checkout}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="app_home"
    exact
    path={byBestRetailRouteNames.app_home}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="product_details"
    exact
    path={byBestRetailRouteNames.product_details}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="brand"
    exact
    path={byBestRetailRouteNames.brand}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="brand_search"
    exact
    path={byBestRetailRouteNames.brand_search}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="category"
    exact
    path={byBestRetailRouteNames.category}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="category_search"
    exact
    path={byBestRetailRouteNames.category_search}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="collection"
    exact
    path={byBestRetailRouteNames.collection}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="collection_search"
    exact
    path={byBestRetailRouteNames.collection_search}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="group"
    exact
    path={byBestRetailRouteNames.group}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="group_page"
    exact
    path={byBestRetailRouteNames.group_page}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="wishlist"
    exact
    path={byBestRetailRouteNames.wishlist}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="blog_category"
    exact
    path={byBestRetailRouteNames.blog_category}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="search"
    exact
    path={byBestRetailRouteNames.search}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="virtual_gift_card"
    exact
    path={byBestRetailRouteNames.virtual_gift_card}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page"
    exact
    path={byBestRetailRouteNames.page}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_return_policy"
    exact
    path={byBestRetailRouteNames.page_return_policy}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_help"
    exact
    path={byBestRetailRouteNames.page_help}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_faq"
    exact
    path={byBestRetailRouteNames.page_faq}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_job_listing"
    exact
    path={byBestRetailRouteNames.page_job_listing}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_privacy_policy"
    exact
    path={byBestRetailRouteNames.page_privacy_policy}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_cookies"
    exact
    path={byBestRetailRouteNames.page_cookies}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_prices_and_currency"
    exact
    path={byBestRetailRouteNames.page_prices_and_currency}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_payment_methods"
    exact
    path={byBestRetailRouteNames.page_payment_methods}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_shipping_methods"
    exact
    path={byBestRetailRouteNames.page_shipping_methods}
    element={<PlaceholderEmptyComponent />}
  />,
  <Route
    key="page_product_exchange"
    exact
    path={byBestRetailRouteNames.page_product_exchange}
    element={<PlaceholderEmptyComponent />}
  />,
];

export default byBestRetailRoutes;
