import { useEffect, useState } from "react";

import FormSelect from "../../../components/FormSelect";
import FormText from "../../../components/FormText";
import { SubmitBtn } from "../../../components/FormBtn";
import { generateDateArray, generateTimeArray } from "../../../utils/common";
import RouteNames from "../../../constants/route_names";

const BowlingBook = (props) => {
  const [lane, setLane] = useState('a34');
  const [person, setPerson] = useState(1);
  const [date, setDate] = useState('2023-7-20');
  const [time, setTime] = useState('7:00 AM');
  const [listDates, setListDates] = useState([]);
  const [listTimes, setListTimes] = useState([]);

  useEffect(()=> {
    let dates = generateDateArray();
    setListDates(dates);
    let times = generateTimeArray();
    setListTimes(times);
  },[]);

  return (
    <div className='md:col-span-2 flex flex-col md:pt-8'>
      <FormText title='Book now' type='itemtitle-sub' />
      <div className="flex flex-col justify-center border rounded-xl mt-4 h-full px-4 md:px-8 py-5 md:py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
          <FormSelect
            placeholder='Select lane'
            value={lane}
            dataList={[{ value: 'a34', title: 'A34 Lane'}, { value: 'a36', title: 'A36 Lane'}, { value: 'expert', title: 'Golf Expert'}]}
            onChanage={(e) => setLane(e.target.value)}
          />
          <FormSelect
            placeholder='Nr. of People'
            value={person}
            dataList={[{ value: 1, title: '1 person'}, { value: 2, title: '2 person'}, { value: 4, title: '4 person'}]}
            onChanage={(e) => setPerson(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 md:gap-8">
          <FormSelect
            value={date}
            placeholder="Date"
            dataList={listDates}
            onChanage={(e) => setDate(e.target.value)}
          />
          <FormSelect
            value={time}
            placeholder="Time"
            dataList={listTimes}
            onChanage={(e) => setTime(e.target.value)}
          />
        </div>
        <a href={'book'}>
          <SubmitBtn
            title='Book now'
            classes='mt-5'
            dark={true}
          />
        </a>
      </div>
    </div>
  )
}

export default BowlingBook;
