import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { blogs } from "./data";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestBlogDetails = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const data = blogs.find((b) => b.slug === slug);

  const goBack = () => {
    navigate(-1);
  };

  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[16px] leading-[24px] text-[#000] uppercase ">
            BYBEST {`>`} FAQE {`>`} {data?.title}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div
            className=" cursor-pointer text-[16px] leading-[24px] text-[#000]"
            onClick={goBack}
          >
            {"<"} Pas
          </div>
          <h1 className="mt-5 w-full text-[32px] leading-[48px] text-[#000]">
            {data?.title}
          </h1>
          <p className=" mt-3 text-[12px] leading-[18px] text-[#000]">
            {data?.date}
          </p>
          <img
            src={data?.image}
            className="mt-2 w-full h-[516px] object-cover"
            alt=""
          />
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            Kur vjen momenti për të zgjedhur dhurata të përsosura, cilësia dhe
            stili janë thelbësorë.
          </p>
          <br />
          <p className="text-[16px] leading-[24px] text-[#000]">
            Villeroy & Boch, me një trashëgimi që daton që nga viti 1748, ofron
            zgjedhje të shkëlqyera për çdo rast, duke përfshirë ditëlindje,
            dasma, ose thjesht për të shprehur mirënjohje. Ja disa ide dhuratash
            nga Villeroy & Boch që me siguri do të lënë mbresa të paharrueshme:
          </p>
          <h3 className="mt-5 text-[16px] leading-[24px] text-[#000] font-bold">
            1. Setet e Darkave
          </h3>
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            Elegante dhe funksionale, një set darkash nga Villeroy&Boch është
            zgjedhja ideale për çiftet e reja ose për ata që duan të rinovojnë
            koleksionin e tyre të enëve të tavolinës. Nga dizajnet klasike deri
            tek ato moderne, çdo set reflekton angazhimin ndaj cilësisë dhe
            stilit.
          </p>
          <h3 className="mt-5 text-[16px] leading-[24px] text-[#000] font-bold">
            2. Setet e Darkave
          </h3>
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            Elegante dhe funksionale, një set darkash nga Villeroy&Boch është
            zgjedhja ideale për çiftet e reja ose për ata që duan të rinovojnë
            koleksionin e tyre të enëve të tavolinës. Nga dizajnet klasike deri
            tek ato moderne, çdo set reflekton angazhimin ndaj cilësisë dhe
            stilit.
          </p>
          <h3 className="mt-5 text-[16px] leading-[24px] text-[#000] font-bold">
            3. Setet e Darkave
          </h3>
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            Elegante dhe funksionale, një set darkash nga Villeroy&Boch është
            zgjedhja ideale për çiftet e reja ose për ata që duan të rinovojnë
            koleksionin e tyre të enëve të tavolinës. Nga dizajnet klasike deri
            tek ato moderne, çdo set reflekton angazhimin ndaj cilësisë dhe
            stilit.
          </p>
          <h3 className="mt-5 text-[16px] leading-[24px] text-[#000] font-bold">
            4. Setet e Darkave
          </h3>
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            Elegante dhe funksionale, një set darkash nga Villeroy&Boch është
            zgjedhja ideale për çiftet e reja ose për ata që duan të rinovojnë
            koleksionin e tyre të enëve të tavolinës. Nga dizajnet klasike deri
            tek ato moderne, çdo set reflekton angazhimin ndaj cilësisë dhe
            stilit.
          </p>
          <h3 className="mt-5 text-[16px] leading-[24px] text-[#000] font-bold">
            5. Setet e Darkave
          </h3>
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            Elegante dhe funksionale, një set darkash nga Villeroy&Boch është
            zgjedhja ideale për çiftet e reja ose për ata që duan të rinovojnë
            koleksionin e tyre të enëve të tavolinës. Nga dizajnet klasike deri
            tek ato moderne, çdo set reflekton angazhimin ndaj cilësisë dhe
            stilit.
          </p>
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            Blin online në:{" "}
            <a className="text-[#EAB600]" href="www.bybest.shop">
              www.bybest.shop
            </a>
          </p>
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            Për më shumë informacion, na vizitoni në: 📍Metropol - Qendra
            Tregtare, Rr. Sami Frashëri, Tiranë{" "}
          </p>
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            <a
              className="text-[#EAB600]"
              href="https://goo.gl/maps/uffAEHTnWFx "
            >
              https://goo.gl/maps/uffAEHTnWFx
            </a>{" "}
            Tel : +355 42 230 872 Mob : +355 68 90 79 796
          </p>
          <p className="mt-5 text-[16px] leading-[24px] text-[#000]">
            Për ata që kërkojnë dhurata me vlera të qëndrueshme dhe estetikë të
            pakompromis, Villeroy&Boch ofron zgjedhje të pakrahasueshme.
            Eksploroni gamën tonë dhe gjeni dhuratën perfekte që flet nga zemra.
          </p>
          <div className="flex justify-center items-center w-full mt-8">
            <div className="rounded-[12px] border border-[#4B4B4B] w-full max-w-[850px] px-6 pt-6 pb-4">
              <h1 className="text-[30px] leading-[48px] font-bold text-[#000]">
                Mire se vjen ne quiz-in shperblyes te ByBest
              </h1>
              <h3 className="mt-6 text-[20px] leading-[24px] font-semibold text-[#000]">
                3 pyetje
              </h3>
              <p className="mt-6 text-[18px] leading-[24px] text-[#000]">
                Pergjigju sakte pyetjeve lidhur me arikullin dhe fito menjhere
                100 ALL ne kuleten tende virtuale.
              </p>
              <button className="mt-5 h-[42px] rounded-[5px] bg-[#ED1C24] text-[14px] leading-[32px] text-white">
                FILLO LOJEN
              </button>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestBlogDetails;
