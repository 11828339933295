import React from "react";
import SerafinaIcon from "../../../assets/images/restaurants/serafina-icon.png";
import {
  breakfastIcon,
  checkIcon,
  dollarIcon,
  steakIcon,
  wifiIcon,
} from "../icons";
import Menus from "./menus";
import { useLocation, useNavigate } from "react-router-dom";
import RestaurantButton from "../restaurant-button";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { isEmpty } from "../../../utils/common";

const General = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const venueData = useSelector((state) => state.venue);

  const goToAvaibaility = () => {
    navigate(
      pathname.endsWith("/")
        ? pathname + "reserve/availability"
        : pathname + "/reserve/availability"
    );
  };

  const tags = useMemo(() => {
    return [
      {
        label: "Free WiFi",
        icon: wifiIcon,
        show: venueData?.additional_information?.has_free_wifi
      },
      {
        label: "Free Breakfast",
        icon: breakfastIcon,
        show: venueData?.additional_information?.has_free_breakfast
      },
    ];
  }, [venueData])

  return (
    <div className="mb-5 bg-[#fdfdfd]">
      <div className="flex items-center gap-2">
        <div className="mt-3 flex justify-center items-center w-[60px] h-[60px] rounded-[10px] border-[1px] border-[#D8D9DB]">
          <img
            className="w-[42px] h-[42px] object-contain object-center"
            src={venueData?.overview?.logo}
            alt="Restaurant"
          />
        </div>
        <div className="flex flex-col">
          <h2 className="restaurant-name-title">{venueData?.overview?.venue_name}</h2>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              <span>{dollarIcon}</span>
              <h1 className="price-details">{venueData?.currency}{venueData?.additional_information?.min_money_value} to {venueData?.currency}{venueData?.additional_information?.max_money_value}</h1>
            </div>
            <div className="flex items-center gap-2">
              <span>{steakIcon}</span>
              <h1 className="price-details">{venueData?.additional_information?.main_cuisine}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2 my-2">
        {tags.filter(t => t.show).map((item, index) => (
          <div className="wifi-layer" key={index}>
            <span>{item.icon}</span>
            <span className="wifi-text">{item.label}</span>
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-2">
        <div className="description-title">Description</div>
        <p className="description-label !text-[13px]">
          {
            venueData?.additional_information?.description?.length > 240
              ? `${venueData?.additional_information?.description.substring(
                  0,
                  237
                )}...`
              : venueData?.additional_information?.description
          }
        </p>
      </div>
      {
        !isEmpty(venueData?.additional_information?.benefit_title) &&
        <div className="mt-4">
          <div className="description-title mb-1">{venueData?.additional_information?.benefit_title}</div>
          {
            venueData?.additional_information?.benefits?.length > 0 &&
            <div className="grid grid-cols-2 gap-2">
              {venueData?.additional_information?.benefits.map((item, index) => (
                <div className="flex items-center gap-1" key={index}>
                  <span>{checkIcon}</span>
                  <span className="facilities-item">{item}</span>
                </div>
              ))}
            </div>
          }
        </div>
      }
      <Menus />

      <RestaurantButton
        onClick={goToAvaibaility}
        title="View Available Tables"
      />
    </div>
  );
};

export default General;
