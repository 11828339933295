import React from 'react';

import ServiceBtn from '../../../components/FormBtn/ServiceBtn';
import FormText from '../../../components/FormText';

import HotelImg from '../../../assets/images/services/hotel.png';
import HotelMobileImg from '../../../assets/images/services/hotel_mobile.png';

import starIcon from '../../../assets/svgs/starIcon.svg';
import forkIcon from '../../../assets/svgs/fork.svg';
import wifiIcon from '../../../assets/svgs/wifi.svg';
import spaIcon from '../../../assets/svgs/spa.svg';
import gymIcon from '../../../assets/svgs/gym.svg';
import events_hall from '../../../assets/svgs/events_hall.svg';

const HotelHeader = ({ data }) => {
  const handleAction = () => {
    console.log('button clicked');
  };

  return (
    <div className="md:bg-gray2 bg-white">
      <div className='block md:hidden h-48 w-full'>
        <img src={data?.overview?.logo} alt='restaurant' className='h-full w-full' />
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-10 md:py-14 max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full">
        <div className='flex flex-col justify-center'>
          {/* <div className='flex flex-row mt-4'>
            {Array(5).fill(0).map(item => (
              <img src={starIcon} alt='starIcon' className='mr-1' />
            ))}
            <FormText title="5 stars" customClass="ml-2" />
          </div> */}
          <FormText
            title={data?.overview?.venue_name}
            type='page-title'
            customClass='mt-5'
          />
          <FormText
            title={data?.additional_information?.description}
            type='subtitle-dark'
            customClass='mt-4 md:mt-8'
          />
          <div className='hidden md:flex flex-row flew-wrap mt-5'>
          {data?.additional_information?.has_restaurant ? 
            <div className='flex flex-row items-center mr-6'>
              <img src={forkIcon} alt='icon' />
              <FormText title="Restaurant" type='subtitle-dark' customClass='ms-4' />
            </div> : null}
            {data?.additional_information?.has_free_wifi ? <div className='flex flex-row items-center mr-6'>
              <img src={wifiIcon} alt='icon' />
              <FormText title="Wifi" type='subtitle-dark' customClass='ms-4' />
            </div> : null}
            {data?.additional_information?.has_spa ? <div className='flex flex-row items-center mr-6'>
              <img src={spaIcon} alt='icon' />
              <FormText title="Spa" type='subtitle-dark' customClass='ms-4' />
            </div> : null}
            {/* TODO Grisled, no has_gym found  */}
            {data?.additional_information?.has_gym ? <div className='flex flex-row items-center mr-6'>
              <img src={gymIcon} alt='icon' />
              <FormText title="Gym" type='subtitle-dark' customClass='ms-4' />
            </div> : null}
            {data?.additional_information?.has_events_hall ? <div className='flex flex-row items-center mr-6'>
              <img src={events_hall} alt='icon' />
              <FormText title="Events Hall" type='subtitle-dark' customClass='ms-4' />
            </div> : null}
          </div>
          <div className='md:hidden flex flex-row flew-wrap'>
            
            <ServiceBtn label="Greek" classes="mr-3" onActin={() => handleAction()} />
            <ServiceBtn label="Breakfast" classes="mr-3" onActin={() => handleAction()} />
            <ServiceBtn label="Lunch" classes="mr-3" onActin={() => handleAction()} />
            <ServiceBtn label="Dinner" classes="mr-3" onActin={() => handleAction()} />
          </div>
        </div>
        <div className='hidden md:block h-96'>
        <img src={data?.overview?.logo} alt='restaurant' className='rounded-xl w-full h-full' />
        </div>
      </div>
    </div>
  )
}

export default HotelHeader;
