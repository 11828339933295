import {
  orderIcon,
  personalInfoIcon,
  promotionIcon,
  securityIcon,
  shopIcon,
  walletIcon,
  wishlistIcon,
} from "./icons";

export const links = (selected) => [
  {
    href: "/shop",
    icon: shopIcon(selected === "shop"),
    label: "Shop",
    key: "shop",
  },
  {
    href: "/personal-information",
    icon: personalInfoIcon(selected === "personal-information"),
    label: "Personal Info",
    key: "personal-information",
  },
  {
    href: "/orders",
    icon: orderIcon(selected === "orders"),
    label: "Orders",
    key: "orders",
  },
  {
    href: "/wallet",
    icon: walletIcon(selected === "wallet"),
    label: "Wallet",
    key: "wallet",
  },
  {
    href: "/promotions",
    icon: promotionIcon(selected === "promotions"),
    label: "Promotions",
    key: "promotions",
  },
  {
    href: "/wishlist",
    icon: wishlistIcon(selected === "wishlist"),
    label: "Wishlist",
    key: "wishlist",
  },
  {
    href: "/login-security",
    icon: securityIcon(selected === "login-security"),
    label: "Security",
    key: "login-security",
  },
];

function extractAppKey(pathname = "") {
  const segments = pathname?.split("/");
  return segments[2];
}
export const appKey = extractAppKey(window.location.pathname);
