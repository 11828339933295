import React from "react";

const BlogCard = ({ img, tag, title, date, slug }) => {
  return (
    <a href={`/blog/${slug}`}>
      <div className="flex flex-col rounded-[13px] border-[1px] border-[#E0E0E0] bg-white blog-card overflow-hidden">
        <img
          className="w-full h-[160px] md:h-[258px] object-cover"
          src={img}
          alt="aboutus"
        />
        <div className="py-9 px-6 relative">
          {/* <div className="absolute flex  px-[18px] py-[9px] rounded-[60px] border-[#DEDFE3] border-[1px] top-[-17px] bg-white">
            <p className="text-[#373941] text-14 leading-4 ">{tag}</p>
          </div> */}

          <a class="absolute flex rounded-[60px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px] top-[-17px] w-[212px] h-[36px]">
            <div class="flex h-full w-full items-center justify-center bg-white rounded-[60px]">
              <h1 class="text-14 font-normal text-[#454545]">{tag}</h1>
            </div>
          </a>

          <p className="text-[#373941] text-20 leading-7 font-normal">{title}</p>
          <p className="text-[#717484] text-14 leading-5 font-normal mt-5">
            {date}
          </p>
        </div>
      </div>
    </a>
  );
};

export default BlogCard;
