import React, { useEffect, useState } from "react";
import FormTextInput from "../../../components/FormTextInput";
import FormTextarea from "../../../components/FormTextarea";
import { Seo } from "../../../components/Seo";
import { t } from "i18next";
import { CircularProgress } from "@mui/material";
import { NotificationManager } from "react-notifications";
import WhiteLabelService from "../../../services/whiteLabelService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SET_VENUE_DATA } from "../../../redux/actions/types";
import { useDispatch } from "react-redux";
import { isEmpty } from "../../../utils/common";

const intialValues = {
  contact_page_main_title_string: null,
  contact_page_toplabel_string: "",
  contact_page_address_string: "",
  contact_page_phone_string: "",
  contact_page_email_string: "",
  contact_page_open_hours_string: "",
  contact_page_form_subtitle_string: null,
  contact_page_form_submit_btn_txt: "",
  contact_page_fullname_label_string: "",
  contact_page_phone_number_label_string: "",
  contact_page_phone_number_show: 0,
  contact_page_email_label_string: "",
  contact_page_subject_label_string: "",
  contact_page_subject_show: 0,
  contact_page_content_label_strin: null,
  contact_page_content_show: 0,
  contact_page_enable: 1,
  contact_page_opening_hours_enable: 0,
  openingHours: null,
  contact_page_opening_hours_string: "",
  brandProfile: null,
};

const BeachbarContact = () => {
  const { app_key } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const newPath = pathname.replace("/contact", "");
  const [data, setData] = useState({
    subscribe: false,
  });

  const [submitting, setSubmitting] = useState(false);
  const [formConfig, setFormConfig] = useState(intialValues);
  const [seoDocTitle, setSeoDocTitle] = useState(
    "VenueBoost Beach bar Contact Us Page"
  );

  const handleChange = (e) =>
    setData((info) => ({ ...info, [e.target.name]: e.target.value }));

  const getFormConfigurations = () => {
    WhiteLabelService.getFormConfigurations(app_key).then((res) => {
      setFormConfig(res.data);
      setSeoDocTitle(res.venue.overview.venue_name + " - Contact Us Page");
      dispatch({ type: SET_VENUE_DATA, payload: res.venue });
    });
  };

  useEffect(() => {
    getFormConfigurations();
  }, []);

  useEffect(() => {
    if (formConfig.contact_page_enable === 0) {
      navigate(newPath);
    }
  }, [formConfig.contact_page_enable]);

  const contactDetails = (
    <div className="w-full bg-transparent text-white">
      <div className="bg-gray-200 py-4 bg-transparent text-white">
        <h2 className="font-extrabold md:text-[42px] text-[30px] text-white">
          {formConfig.contact_page_form_subtitle_string}
        </h2>
      </div>
      <div className="flex items-center mb-6 bg-transparent text-white">
        <div>
          <h6 className="font-bold">
            {formConfig.contact_page_address_string}
          </h6>
          <p>{formConfig.contact_page_address_value ?? "-"}</p>
        </div>
      </div>
      <div className="flex items-center mb-6">
        <div>
          <h6 className="font-bold">{formConfig.contact_page_phone_string}</h6>
          <p>{formConfig.contact_page_phone_value ?? "-"}</p>
        </div>
      </div>
      <div className="flex items-center mb-6">
        <div>
          <h6 className="font-bold">
            {formConfig.contact_page_email_string ?? "-"}
          </h6>
          <p>{formConfig.contact_page_email_value ?? "-"}</p>
        </div>
      </div>
      {formConfig.contact_page_opening_hours_enable === 1 && (
        <div className="flex items-center mb-6">
          <div>
            <h6 className="font-bold">
              {formConfig.contact_page_open_hours_string ?? "-"}
            </h6>
            <p>{formConfig.contact_page_opening_hours_value ?? "-"}</p>
          </div>
        </div>
      )}
    </div>
  );

  const onSubmit = () => {
    if (!data.full_name) {
      NotificationManager.warning(
        `Full name field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["email"]) {
      NotificationManager.warning(`Email is required`, "Warning", 3000);
      return;
    }

    // Email validation
    if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) {
      NotificationManager.warning(`Invalid email format`, "Warning", 3000);
      return;
    }

    if (!data.email) {
      NotificationManager.warning(`Email is required`, "Warning", 3000);
      return;
    }

    setSubmitting(true);

    WhiteLabelService.submitContact(data, app_key)
      .then(() => {
        setSubmitting(false);
        setData(null);
        NotificationManager.success(
          "Your application was submitted successfully. Thank you for contacting us, we will reach out to you as soon as possible.\n",
          "Success",
          5000
        );
      })
      .catch((err) => {
        setSubmitting(false);
        const errorKeys = Object.keys(err?.error || {});
        if (errorKeys.length > 0) {
          const firstErrorKey = errorKeys[0];
          const firstErrorMessage = err?.error[firstErrorKey][0];
          NotificationManager.error(
            firstErrorMessage || "Something went wrong!"
          );
        } else {
          NotificationManager.error(
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io."
          );
        }

        throw new Error("An error occurred while joining VenueBoost");
      });
  };

  const LeftBlockBrand = formConfig?.brandProfile?.filter(
    (item) => item.element_name === "ContactFormLeftBlock"
  )[0];
  const LeftBlockBrandData = {
    text: isEmpty(
      LeftBlockBrand?.venue_brand_profile_customizations?.find(
        (c) => c.customization_key == "text"
      )?.customization_value
    )
      ? LeftBlockBrand?.default_name
      : LeftBlockBrand?.venue_brand_profile_customizations?.find(
          (c) => c.customization_key == "text"
        )?.customization_value,
    bgColor: LeftBlockBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key == "color"
    )?.customization_value,
    textColor: LeftBlockBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key == "text_color"
    )?.customization_value,
  };

  const ContactFormBtnBrand = formConfig?.brandProfile?.filter(
    (item) => item.element_name === "ContactFormBtn"
  )[0];
  const ContactFormBtnBrandData = {
    text: isEmpty(
      ContactFormBtnBrand?.venue_brand_profile_customizations?.find(
        (c) => c.customization_key == "text"
      )?.customization_value
    )
      ? ContactFormBtnBrand?.default_name
      : ContactFormBtnBrand?.venue_brand_profile_customizations?.find(
          (c) => c.customization_key == "text"
        )?.customization_value,
    bgColor: ContactFormBtnBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key == "color"
    )?.customization_value,
    textColor: ContactFormBtnBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key == "text_color"
    )?.customization_value,
  };

  const ContactFormTopLabel = formConfig?.brandProfile?.filter(
    (item) => item.element_name === "ContactFormTopLabel"
  )[0];
  const ContactFormTopLabelData = {
    text: isEmpty(
      ContactFormTopLabel?.venue_brand_profile_customizations?.find(
        (c) => c.customization_key == "text"
      )?.customization_value
    )
      ? ContactFormTopLabel?.default_name
      : ContactFormTopLabel?.venue_brand_profile_customizations?.find(
          (c) => c.customization_key == "text"
        )?.customization_value,
    bgColor: ContactFormTopLabel?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key == "color"
    )?.customization_value,
    textColor: ContactFormTopLabel?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key == "text_color"
    )?.customization_value,
  };

  const currentUrl =
    "https://venueboost.io/venue/beach-bar/" + app_key + "/contact";
  const seoTitle = seoDocTitle;
  const seoDescription = formConfig.contact_page_main_title_string;
  return (
    <div className="align-col-middle">
      <Seo title={seoTitle} description={seoDescription} url={currentUrl} />
      <div className="align-col-middle">
        <div className="w-[100vw]">
          <div className="w-full bg-[#F3F3F3] pb-[60px] md:pb-[180px]">
            <div className="max-w-screen-main mx-auto ">
              <div className="flex flex-col px-4 md:px-0">
                <div className="w-full my-[32px] md:my-[40px] flex align-middle">
                  <h1 className="text-20 md:text-24 font-semibold text-[#000000] mt-8">
                    {formConfig.contact_page_main_title_string}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-[#FFFFFF] flex">
            <div className="w-full max-w-screen-main px-[20px] md:px-0 md:mx-auto md:mt-[-140px]">
              <div className="w-full contact-box flex flex-col gap-2 md:gap-0 md:flex-row rounded-[16px] overflow-hidden bg-[#f9f7f7]  mt-4 mb-8  md:mb-20">
                <div
                  style={
                    LeftBlockBrandData?.text
                      ? {
                          backgroundColor: LeftBlockBrandData?.bgColor,
                          color: LeftBlockBrandData?.textColor,
                        }
                      : { backgroundColor: "#240b3b", color: "#fff" }
                  }
                  className="md:flex flex-col justify-center md:w-[40%] p-10 rounded-tl-[16px]  rounded-bl-[16px]"
                >
                  {contactDetails}
                </div>
                <div className="w-full md:w-[60%] z-10 mt-0 rounded-tr-[16px] rounded-br-[16px]">
                  <div
                    style={
                      ContactFormTopLabelData?.text
                        ? {
                            backgroundColor: ContactFormTopLabelData?.bgColor,
                            color: ContactFormTopLabelData?.textColor,
                          }
                        : { backgroundColor: "#f9f7f7", color: "#fff" }
                    }
                    className="py-3 px-6 md:px-8 text-16 font-medium "
                  >
                    {formConfig.contact_page_toplabel_string}
                  </div>
                  <div className="grid grid-cols-1 gap-7 px-6 md:px-8 pt-6 bg-white">
                    <div className="grid grid-cols-2 gap-7">
                      <FormTextInput
                        name="full_name"
                        placeholder={
                          formConfig.contact_page_fullname_label_string ??
                          "Full Name"
                        }
                        value={data?.full_name ?? ""}
                        onChange={handleChange}
                      />
                      {formConfig.contact_page_phone_number_show === 1 && (
                        <FormTextInput
                          name="phone_number"
                          placeholder={
                            formConfig.contact_page_phone_number_label_string ??
                            t("components.contact.PhoneNumber")
                          }
                          value={data?.phone_number ?? ""}
                          onChange={handleChange}
                          type="number"
                        />
                      )}
                    </div>
                    <FormTextInput
                      name="email"
                      type="email"
                      placeholder={
                        formConfig.contact_page_email_label_string ??
                        t("components.contact.email")
                      }
                      value={data?.email || ""}
                      onChange={handleChange}
                    />
                    {formConfig.contact_page_phone_number_show === 1 && (
                      <FormTextInput
                        name="subject"
                        placeholder={
                          formConfig.contact_page_subject_label_string ??
                          t("components.contact.Subject")
                        }
                        value={data?.subject || ""}
                        onChange={handleChange}
                      />
                    )}

                    {formConfig.contact_page_content_show === 1 && (
                      <FormTextarea
                        name="content"
                        rows={10}
                        placeholder={
                          formConfig.contact_page_content_label_strin ??
                          t("components.contact.Content")
                        }
                        value={data?.content || ""}
                        onChange={handleChange}
                        inputClassName="w-full h-[100px]"
                      />
                    )}
                  </div>
                  <div className="flex flex-row md:justify-end w-full bg-white px-6 md:px-8 py-4">
                    <button
                      disabled={submitting}
                      style={
                        ContactFormBtnBrandData?.text
                          ? {
                              backgroundColor: ContactFormBtnBrandData?.bgColor,
                              color: ContactFormBtnBrandData?.textColor,
                            }
                          : { backgroundColor: "#240b3b", color: "#fff" }
                      }
                      className="submit-button font-normal mt-4 mb-3  md:w-max h-auto py-2 md:py-3 md:px-6 text-[13px] md:text-16 rounded-[5px] transition ease-in-out hover:scale-101 "
                      onClick={onSubmit}
                    >
                      {submitting ? (
                        <CircularProgress size={16} />
                      ) : (
                        formConfig.contact_page_form_submit_btn_txt
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeachbarContact;
