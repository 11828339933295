import React from "react";
import Sidebar from "../../../../components/Sidebar";
import GenericTable from "../../../../components/Table/generic-table";
import { orderDetailsColumn, orderDetailsData } from "./../columns";
import CustomerHeader from "../../customer-header";
import { useLocation, useParams } from "react-router-dom";
import { getStatus } from "../../../../components/columns/get-status";
import { chatIcon, trackIcon } from "../icons";
import Subtotal from "./subtotal";
import "./index.css";

const OrderDetails = () => {
  const { orderId } = useParams();
  const { state } = useLocation();

  return (
    <div className="flex gap-4 w-full bg-[#FCFCFD] pr-6">
      <Sidebar selected="orders" />
      <div className="flex flex-1 flex-col mt-6">
        <div className="w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
          <div className="flex justify-between items-center w-full border p-6">
            <CustomerHeader
              description="June 27, 2024 2:10 AM"
              title={`Order #${orderId}`}
              showItems={false}
            />
            <div className="flex flex-col gap-5 items-end w-full">
              <div className="status-card">
                <span>Status: </span>
                <span>{getStatus(state?.status)}</span>
              </div>

              <div className="flex items-center gap-2">
                <div className="status-card cursor-pointer">
                  <span>{trackIcon}</span>
                  <span>Track order</span>
                </div>
                <div className="status-card cursor-pointer">
                  <span>{chatIcon}</span>
                  <span>Chat now</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-6">
            <div className="text-[#101828] text-18 font-semibold leading-7">
              Address
            </div>
          </div>

          <div className="bg-[#FAFAFA] p-6">
            <div className="bg-white p-6 rounded-[12px]">
              <div className="text-[#101828] text-18 font-semibold leading-7">
                <div className="flex flex-col gap-1">
                  <span>Megi Skendaj,</span>
                  <span>Tirane, Tirane, 1001,</span>
                  <span>slemdaj.megi@gmail.com</span>
                  <span>+35569324235</span>
                </div>
              </div>
            </div>
          </div>

          <div className="p-6 flex flex-col gap-5">
            <GenericTable
              columns={orderDetailsColumn}
              data={orderDetailsData}
              className="w-full md:block pr-4"
              showPagination={false}
            />
            <div className="grid grid-cols-3 w-full bg-white">
              <div className="col-span-2"></div>
              <div className="flex flex-col gap-5">
                <Subtotal />
                <hr className="border-[1px]  border-[#E5E7EB]" />
                <div className="subtotal-wrapper">
                  <p>Total</p>
                  <p>8300.00 LEK</p>
                </div>
                <hr className="border-[1px]  border-[#E5E7EB]" />
                <div className="subtotal-wrapper">
                  <span>Exchange rate (EUR{">"}ALL)</span>
                  <p>106</p>
                </div>
                <div className="subtotal-wrapper">
                  <span>Payment Method</span>
                  <p>Cash</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
