import { useState } from "react";
import { Modal, Slider } from "antd";
import { FilterOptions } from "./filter-options";

const TYPE_FILTER = [
  {
    title: "Women",
  },
  {
    title: "Men",
  },
  {
    title: "Kids",
  },
];

const SIZE_FILTER = [
  {
    title: "XXL",
  },
  {
    title: "XL",
  },
  {
    title: "L",
  },
  {
    title: "M",
  },
  {
    title: "S",
  },
];

const COLOR_FILTER = [
  {
    title: "White",
    icon: (
      <span className="bg-white rounded-full w-[14px] h-[14px] border border-[#131118]"></span>
    ),
  },
  {
    title: "Red ",
    icon: <span className="bg-red-800 rounded-full w-[14px] h-[14px]"></span>,
  },
  {
    title: "Green",
    icon: <span className="bg-green-800 rounded-full w-[14px] h-[14px]"></span>,
  },
  {
    title: "Black",
    icon: <span className="bg-black rounded-full w-[14px] h-[14px]"></span>,
  },
  {
    title: "Blue",
    icon: <span className="bg-blue-800 rounded-full w-[14px] h-[14px]"></span>,
  },
];

export const FilterLayoutMobile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [inputValue, setInputValue] = useState(0.0);

  const onChange = (value) => {
    if (isNaN(value)) {
      return;
    }
    setInputValue(value);
  };

  return (
    <>
      <a
        className="bg-[#131118] w-[34px] h-[34px] rounded-lg flex items-center justify-center"
        onClick={() => setIsModalOpen(true)}
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.60091 2.05542H12.4009C13.1342 2.05542 13.7342 2.65542 13.7342 3.38875V4.85542C13.7342 5.38875 13.4009 6.05542 13.0676 6.38875L10.2009 8.92209C9.80091 9.25542 9.53424 9.92209 9.53424 10.4554V13.3221C9.53424 13.7221 9.26758 14.2554 8.93424 14.4554L8.00091 15.0554C7.13425 15.5888 5.93424 14.9888 5.93424 13.9221V10.3888C5.93424 9.92209 5.66758 9.32209 5.40091 8.98875L2.86758 6.32209C2.53424 5.98875 2.26758 5.38875 2.26758 4.98875V3.45542C2.26758 2.65542 2.86758 2.05542 3.60091 2.05542Z"
            stroke="#EFF3FA"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.28667 2.05542L4 7.32209"
            stroke="#EFF3FA"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </a>

      <Modal open={isModalOpen} footer={null} closable={false}>
        <div className="flex flex-col">
          <div className="text-[#131118] text-20 font-semibold leading-8">
            Filter Search
          </div>

          <div className="mt-3 mb-8 flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <div className="text-[#131118] font-semibold text-16">Type:</div>
              <FilterOptions list={TYPE_FILTER} />
            </div>

            <div className="flex flex-col gap-3">
              <div className="text-[#131118] font-semibold text-16">Size:</div>
              <FilterOptions list={SIZE_FILTER} />
            </div>

            <div className="flex flex-col">
              <div className="text-[#131118] font-semibold text-16 mb-3">
                Price:
              </div>
              <div className="flex justify-between items-center ">
                <span className="text-[#131118] text-12 font-semibold leading-6">
                  $0.00
                </span>
                <span className="text-[#131118] text-12 font-semibold leading-6">
                  $199.00
                </span>
              </div>
              <div>
                <Slider
                  min={0.0}
                  max={199.0}
                  onChange={onChange}
                  value={typeof inputValue === "number" ? inputValue : 0}
                  step={0.01}
                  handleStyle={[
                    { borderColor: "red", backgroundColor: "yellow" },
                    { borderColor: "blue", backgroundColor: "lightblue" },
                  ]}
                  trackStyle={{ backgroundColor: "#636363" }}
                  railStyle={{ backgroundColor: "#B7CFFC" }}
                />
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="text-[#131118] font-semibold text-16">Color:</div>
              <FilterOptions list={COLOR_FILTER} />
            </div>
          </div>

          <a
            className="rounded-lg bg-[#131118] py-[13px] px-[24px] text-white flex gap-2 items-center justify-center text-16 font-semibold"
            onClick={() => setIsModalOpen(false)}
          >
            Show Filter
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0254 4.94165L17.0837 9.99998L12.0254 15.0583"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.91602 10H16.941"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </Modal>
    </>
  );
};
