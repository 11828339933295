// AuthRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import ByBestVerifyEmail from "../../views/register/verify-email/bb-verify-email.jsx";
import ByBestLogin from "../../views/login/by-best-login.jsx";
import ByBestResetPassword from "../../views/resetPassword/by-best-reset-password.jsx";
import ByBestRegister from "../../views/register/by-best-register.jsx";
import ByBestEmailNotVerified from "../../views/register/not_verified/by-best-not-verified.jsx";
import ByBestContact from "../../views/bybest/contact.js";
import ByBestAbout from "../../views/bybest/about.js";
import ByBestStoreAndHours from "../../views/bybest/store_hours.js";
import ByBestBlogs from "../../views/bybest/blog.js";
import ByBestBlogDetails from "../../views/bybest/blog-details.js";
import ByBestHome from "../../views/bybest/home";
import ByBestCategories from "../../views/bybest/category.js";
import ByBestProductDetails from "../../views/bybest/product-details.js";
import ByBestCartProducts from "../../views/bybest/cart-products.js";
import ByBestCartUserInfo from "../../views/bybest/cart-userinfo.js";
import ByBestCartPaymethod from "../../views/bybest/cart-paymentmethod.js";
import ByBestCartSuccess from "../../views/bybest/cart-success.js";
import ByBestClub from "../../views/bybest/myclub.js";
import ByBestOrderTracking from "../../views/bybest/order_tracking.js";
import ByBestWishList from "../../views/bybest/wishlist.js";
import ByBestVertualGiftCard from "../../views/bybest/virtual-giftcard.js";
import ByBestHelp from "../../views/bybest/help.js";
import ByBestFaqs from "../../views/bybest/faqs.js";
import ByBestVacancies from "../../views/bybest/vacancies.js";
import ByBestPrivacyPolicy from "../../views/bybest/privacypolicy.js";
import ByBestCookies from "../../views/bybest/cookies.js";
import ByBestPriceCurrency from "../../views/bybest/price-currency.js";
import ByBestPaymentMethod from "../../views/bybest/payment-method.js";
import ByBestShippingMethod from "../../views/bybest/shipping-method.js";
import ByBestReturnPolicy from "../../views/bybest/return-policy.js";

const bybestGuestRoutes = [
  <Route exact path={"shop/:app_key"} element={<ByBestHome />} />,
  <Route exact path={"shop/:app_key/reset-password"} element={<ByBestResetPassword />} />,
  <Route exact path={"shop/:app_key/login"} element={<ByBestLogin />} />,
  <Route exact path={"shop/:app_key/register"} element={<ByBestRegister />} />,
  <Route exact path={"shop/:app_key/verify-email"} element={<ByBestVerifyEmail />} />,
    // todo: regarding reset password, verify email and email not verified we need to compare with readme
  <Route
    exact
    path={"shop/:app_key/email-not-verified"}
    element={<ByBestEmailNotVerified />}
  />,
  <Route exact path={"shop/:app_key/contact-us"} element={<ByBestContact />} />,
  <Route exact path={"shop/:app_key/about-us"} element={<ByBestAbout />} />,
  <Route
    exact
    path={"shop/:app_key/stores-and-hours"}
    element={<ByBestStoreAndHours />}
  />,
  <Route
    exact
    path={"shop/:app_key/virtual-gift-card"}
    element={<ByBestVertualGiftCard />}
  />,
  <Route exact path={"shop/:app_key/help"} element={<ByBestHelp />} />,
  <Route
    exact
    path={"shop/:app_key/frequently-asked-questions"}
    element={<ByBestFaqs />}
  />,
  <Route
    exact
    path={"shop/:app_key/job-listing"}
    element={<ByBestVacancies />}
  />,
  <Route
    exact
    path={"shop/:app_key/privacy-policy"}
    element={<ByBestPrivacyPolicy />}
  />,
  <Route exact path={"shop/:app_key/cookies"} element={<ByBestCookies />} />,
  <Route
    exact
    path={"shop/:app_key/prices-and-currency"}
    element={<ByBestPriceCurrency />}
  />,
  <Route
    exact
    path={"shop/:app_key/payment-methods"}
    element={<ByBestPaymentMethod />}
  />,
  <Route
    exact
    path={"shop/:app_key/shipping-methods"}
    element={<ByBestShippingMethod />}
  />,
  <Route
    exact
    path={"shop/:app_key/return-policy"}
    element={<ByBestReturnPolicy />}
  />,
  <Route exact path={"shop/:app_key/blog"} element={<ByBestBlogs />} />,
  <Route
    exact
    path={"shop/:app_key/blog/:slug"}
    element={<ByBestBlogDetails />}
  />,
  <Route
    exact
    path={"shop/:app_key/category/:slug"}
    element={<ByBestCategories />}
  />,
  <Route
    exact
    path={"shop/:app_key/products/:id/:slug"}
    element={<ByBestProductDetails />}
  />,
  <Route
    exact
    path={"shop/:app_key/cart/products"}
    element={<ByBestCartProducts />}
  />,
  <Route
    exact
    path={"shop/:app_key/cart/user-info"}
    element={<ByBestCartUserInfo />}
  />,
  <Route
    exact
    path={"shop/:app_key/cart/payment-method"}
    element={<ByBestCartPaymethod />}
  />,
  <Route
    exact
    path={"shop/:app_key/cart/success"}
    element={<ByBestCartSuccess />}
  />,
  <Route exact path={"shop/:app_key/my-club"} element={<ByBestClub />} />,
  <Route
    exact
    path={"shop/:app_key/tracking"}
    element={<ByBestOrderTracking />}
  />,
  <Route exact path={"shop/:app_key/wishlist"} element={<ByBestWishList />} />,
];

export default bybestGuestRoutes;
