import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BybestCategoryFilter from "../../components/BybestCategoryFilter";
import BybestProductItem from "../../components/BybestProductItem";
import { products } from "./data";
import BBMainLayout from "../../components/Seo/whitelabel/bybest/BBMainLayout";
import useSeoData from "../../services/hooks/use-seo";

const ByBestCategories = () => {
  const { slug, app_key } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onGoProductDetails = (p) => {
    navigate(`/shop/${app_key}/products/${p.id}/${slug}`);
  };

  const { pageSEO } = useSeoData();

  return (
    <BBMainLayout clientKey="bybest" seo={pageSEO}>
      <div className="w-full flex flex-col justify-center items-center bg-white">
        <div className="w-full flex justify-center items-center bg-[#F2F2F2] py-7 px-4">
          <p className="text-[24px] leading-[36px] md:text-[16px] md:leading-[24px] text-center text-[#000] uppercase ">
            BYBEST {`>`} {state?.category} {`>`} {state?.item}
          </p>
        </div>
        <div className={`w-full max-w-screen-main mt-7 mb-5`}>
          <div className="w-full flex justify-center items-center pt-[20px] pb-[60px]">
            <hr className="flex-1"/>
            <img src='https://admin.bybest.shop/storage/brands/brands_colored_1654870085.png'
              className="max-w-[250px] max-h-[80px] min-h-[30px] px-[20px]"
            />
            <hr className="flex-1"/>
          </div>
          <div className="w-full px-4 md:px-0 grid grid-cols-1 md:grid-cols-4 gap-0 md:gap-8">
            <BybestCategoryFilter />
            <div className=" col-span-3">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-8">
                {products.map((p, index) => (
                  <BybestProductItem
                    key={index}
                    data={p}
                    type='home'
                    onClick={() => onGoProductDetails(p)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BBMainLayout>
  );
};

export default ByBestCategories;
