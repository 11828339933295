import React from "react";
import { useSelector } from "react-redux";

import "react-multi-carousel/lib/styles.css";
import { CarusellImages } from "./carusell-images";

const AccommodationServices = () => {
  const venueData = useSelector((state) => state.venue);

  return (
    <div className="flex flex-col px-4 md:px-[10%] md:py-8">
      <h2 className="text-[#2D3748] text-24 md:text-[30px] font-bold leading-[120%] mb-2">
        Rooms available at {venueData?.overview?.venue_name}
      </h2>
      <span className="text-s text-black1 mb-4">
        Experience Comfort and Convenience: Explore the Range of Rooms Available
        at {venueData?.overview?.venue_name} for Your Perfect Stay
      </span>
      <CarusellImages />
    </div>
  );
};

export default AccommodationServices;
