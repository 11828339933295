import { useEffect, useState } from "react";

import FormSelect from "../../../components/FormSelect";
import FormText from "../../../components/FormText";
import { SubmitBtn } from "../../../components/FormBtn";

import ringIcon from "../../../assets/svgs/ring.svg";
import FormTextInput from "../../../components/FormTextInput";
import { generateDateArray, generateTimeArray } from "../../../utils/common";

const typeList = [
  { value: "restaurant", title: "Restaurant" },
  { value: "events_hall", title: "Events Hall" },
  { value: "help", title: "help" },
];

const HotelReservation = (props) => {
  const { onAction } = props;

  const [people, setPeople] = useState(1);
  const [reservation, setReseravation] = useState("restaurant");
  const [date, setDate] = useState("2023-7-20");
  const [time, setTime] = useState("7:00 AM");
  const [listDates, setListDates] = useState([]);
  const [listTimes, setListTimes] = useState([]);

  const handleChange = (e) => {
    setPeople(e.target.value);
  };

  useEffect(() => {
    let dates = generateDateArray();
    setListDates(dates);
    let times = generateTimeArray();
    setListTimes(times);
  }, []);
  const handleSubmit = () => {
    const data = {
      people: people,
      reservation,
      date,
      time,
    };
    onAction(data);
  };

  return (
    <div className="md:col-span-2 flex flex-col md:pt-8">
      <FormText title="Make a reservation" type="itemtitle-sub" />
      <div className="flex flex-col justify-center border rounded-xl mt-4 h-full px-4 md:px-8 py-5 md:py-8">
        <FormSelect
          placeholder="Choose reservation"
          value={reservation}
          dataList={[
            { value: "hotel_restaurant", title: "Restaurant" },
            { value: "hotel_gym", title: "Gym" },
            { value: "hotel_events_hall", title: "Events Hall" },
          ]}
          onChanage={(e) => setReseravation(e.target.value)}
        />
        <FormTextInput
          placeholder="How Many People"
          value={people}
          className="mt-4"
          type="number"
          onChange={(e) => {
            if (e.target.value) {
              handleChange(e);
            }
          }}
        />
        <FormSelect
          placeholder="Date"
          value={date}
          dataList={listDates}
          onChanage={(e) => setDate(e.target.value)}
        />
        <div className="grid grid-cols-2 mt-5 gap-6">
          <FormSelect
            placeholder="Date"
            value={date}
            dataList={listDates}
            onChanage={(e) => setDate(e.target.value)}
          />
          <FormSelect
            placeholder="Time"
            value={time}
            dataList={listTimes}
            onChanage={(e) => setTime(e.target.value)}
          />
        </div>
        <SubmitBtn
          title="Continue"
          classes="mt-5"
          onAction={() => handleSubmit()}
          dark={true}
        />
      </div>
      <div className="hidden md:flex flex-row bg-blue2 border border-primary2 rounded-xl px-4 py-2 mt-4">
        <img src={ringIcon} alt="ring" />
        <FormText
          title="Room service starts at 10:00 AM"
          type="normal"
          customClass="ms-4"
        />
      </div>
    </div>
  );
};

export default HotelReservation;
