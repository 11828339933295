import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import GenericTable from "../../../components/Table/generic-table";
import { data, orderColumns } from "./columns";
import DropdownFilter from "../../../components/Dropdown/dropdown-filter";
import CustomerHeader from "../customer-header";
import { useNavigate } from "react-router-dom";
import { appKey } from "../../../components/Sidebar/data";
import LoggedInFooter from "../../../containers/footer/logged-in-footer";

const CustomerOrders = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    total: data.length,
  });

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const goToOrderDetails = (row) => {
    if (row) {
      navigate(`/${appKey}/customer/orders/${row.id}`, {
        state: row,
      });
    }
  };

  return (
    <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
      <div className="flex justify-between items-center w-full   p-6">
        <CustomerHeader
          description="Keep track of orders  and much more..."
          numberOfItems={data.length}
          items="orders"
          title="Orders"
        />
        <DropdownFilter />
      </div>
      <GenericTable
        columns={orderColumns}
        data={data.slice(
          (pagination.current - 1) * 10,
          pagination.current * 10
        )}
        className="w-full md:block pr-4"
        pagination={{
          ...pagination,
          onChange: handlePageChange,
        }}
        onDoubleClick={goToOrderDetails}
      />
    </div>
  );
};

export default CustomerOrders;
