// AuthRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import RouteNames from "../../constants/route_names.js";
import ResetPassword from "../../views/resetPassword/index.js";
import LoginSecurity from "../../views/customer/login-security/index.js";
import Login from "../../views/login/index.js";
import Register from "../../views/register/index.js";
import VerifyEmail from "../../views/register/verify-email/index.js";
import EmailNotVerified from "../../views/register/not_verified/index.js";
import PersonalInformation from "../../views/customer/personal-information/index.jsx";
import Payments from "../../views/customer/payments/index.js";
import Reservations from "../../views/customer/reservations/index.js";
import Promotions from "../../views/customer/promotions/index.js";
import Wallet from "../../views/customer/wallet/index.jsx";
import AuthMiddleware from "../authMiddleware.js";
import CustomerOrders from "../../views/customer/orders/index.jsx";
import CustomerWishlist from "../../views/customer/wishlist/index.jsx"; 
import OrderDetails from "../../views/customer/orders/order-details/index.jsx";

const AuthRoutes = [ 
  <Route
    key="reset-password"
    exact
    path={"/reset-password/:token"}
    element={<ResetPassword />}
  />,

  // Non logged in users
  <Route
    key={RouteNames.login}
    exact
    path={RouteNames.login}
    element={<Login />}
  />,
  <Route
    key={RouteNames.register}
    exact
    path={RouteNames.register}
    element={<Register />}
  />,
  <Route
    key={RouteNames.verify_email}
    exact
    path={RouteNames.verify_email + "/:token"}
    element={<VerifyEmail />}
  />,
  <Route
    key={RouteNames.email_not_verified}
    exact
    path={RouteNames.email_not_verified}
    element={<EmailNotVerified />}
  />,

  // Dashboard - Logged In
  // TODO Ndricimi: part of same logic, routes under {app key of bybest} should have empty footer, empty header
  // <Route
  //   exact
  //   path={RouteNames.dashboard}
  //   element={
  //     <AuthMiddleware>
  //       <Dashboard />
  //     </AuthMiddleware>
  //   }
  // />,

  // Logged in users
  <Route
    exact
    path={RouteNames.personal_information}
    element={
      <AuthMiddleware>
        <PersonalInformation />
      </AuthMiddleware>
    }
  />,
  <Route
    exact
    path={RouteNames.payments}
    element={
      <AuthMiddleware>
        <Payments />
      </AuthMiddleware>
    }
  />,
  <Route
    key={RouteNames.login_security}
    exact
    path={RouteNames.login_security}
    element={
      <AuthMiddleware>
        <LoginSecurity />
      </AuthMiddleware>
    }
  />,
  <Route
    exact
    path={RouteNames.reservations}
    element={
      <AuthMiddleware>
        <Reservations />
      </AuthMiddleware>
    }
  />,
  <Route
    exact
    path={RouteNames.orders}
    element={
      <AuthMiddleware>
        <CustomerOrders />
      </AuthMiddleware>
    }
  />,
  <Route
    exact
    path={RouteNames.orders_details}
    element={
      <AuthMiddleware>
        <OrderDetails />
      </AuthMiddleware>
    }
  />,
  <Route
    exact
    path={RouteNames.wishlist}
    element={
      <AuthMiddleware>
        <CustomerWishlist />
      </AuthMiddleware>
    }
  />,
  <Route
    exact
    path={RouteNames.promotions}
    element={
      <AuthMiddleware>
        <Promotions />
      </AuthMiddleware>
    }
  />,
  <Route
    exact
    path={RouteNames.wallet}
    element={
      <AuthMiddleware>
        <Wallet />
      </AuthMiddleware>
    }
  />,
];

export default AuthRoutes;
